import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-as-multiselect',
  templateUrl: './as-multiselect.component.html',
  styleUrls: ['./as-multiselect.component.css']
})
export class AsMultiselectComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  _selectedValues: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
