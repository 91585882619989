<div class="tasks" (dragover)="allowDrop($event)" (drop)="drop($event)" style="padding-right: 0px; width: {{bucketWidth}}px;" *ngIf="theme == 'base'">
  <p class="task-header" style="margin-right: 0px;"><strong>{{bucket.Description}}</strong></p>
  <div class="cards" style="margin-right: 0px; height: {{listHeight}}px; overflow-y: auto;">
    <div class="inner-cards">
      <app-as-kanban-card class="card" draggable="true" (drag)="dragCard($event, row.Id)" (dragstart)="dragStart($event, row.Id)" (dragend)="dragEnd($event, row.Id)" id="{{row.Id}}" (editRow)="openCard($event)" (canvasReady)="canvasInfo($event)" *ngFor="let row of tableRows" [tableRow]="row"></app-as-kanban-card>
      <div class="card-body p-3 drag-dummy" style="opacity: 0.85; pointer-events: none; display: none; padding-top: 1rem !important; padding-bottom: 0 !important;" id="dummy_{{bucket.Id}}"></div>
    </div>
  </div>
</div>

<div class="card card-refresh" (dragleave)="dragLeave()" (dragover)="allowDrop($event)" (drop)="drop($event)" style="width: {{bucketWidth}}px;" *ngIf="theme=='dg'">
  <div class="refresh-container">
    <div class="loader-pendulums"></div>
  </div>
  <div class="card-header card-header-action" style="flex-wrap: wrap;">
    <div class="row" style="width: 100%;">
      <div class="col-12" style="display: flex; padding-right: 0;">
        <h6 style="font-size: 1rem; color: #324148; font-family: inherit; font-weight: 500; line-height: 1.2; margin: 0;">{{bucket.Description}}</h6>
        <div style="margin-left: auto; margin-right: -15px;" class="d-flex align-items-center card-action-wrap">
          <div class="inline-block dropdown" style="margin-right: 0;">
            <a class="dropdown-toggle no-caret" onclick="return false;" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i class="ion ion-md-more"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="width: 100%;">
      <div style="display: flex; padding-right: 0; font-size: 10px;" *ngIf="bucketSumString!=''">
        <div>{{tableRows.length}}&nbsp;{{miniTitle}}</div>
        <div style="margin-left: auto; float: right; margin-right: -15px;">{{bucketSumString}}</div>
      </div>
    </div>
    <!--<div class="d-flex align-items-center card-action-wrap">
      <a class="inline-block mr-15" data-toggle="collapse" href="#collapse_1" aria-expanded="true">
        <i class="zmdi zmdi-chevron-down"></i>
      </a>
      <div class="inline-block dropdown mr-15">
        <a class="dropdown-toggle no-caret" data-toggle="dropdown" href="#" aria-expanded="false" role="button"><i class="ion ion-md-more"></i></a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="profile.html"><i class="dropdown-icon zmdi zmdi-account"></i><span>Profile</span></a>
          <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-card"></i><span>my balance</span></a>
          <a class="dropdown-item" href="inbox.html"><i class="dropdown-icon zmdi zmdi-email"></i><span>Inbox</span></a>
          <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-settings"></i><span>Settings</span></a>
        </div>
      </div>
      <a href="#" class="inline-block refresh mr-15">
        <i class="ion ion-md-radio-button-off"></i>
      </a>
      <a href="#" class="inline-block full-screen mr-15">
        <i class="ion ion-md-expand"></i>
      </a>
      <a class="inline-block card-close" href="#" data-effect="fadeOut">
        <i class="ion ion-md-close"></i>
      </a>
    </div>-->
  </div>
  <div class="collapse show">
    <div class="card-body bucket-lane">
      <div (dblclick)="openCard(row.Id)" *ngFor="let row of tableRows">
        <app-as-kanban-card  [newTab]="newTab" [detailsPageLink]="detailsPageLink" [cardMode]="cardMode" requestTheme="{{row.CardClasses}}" (contextmenu)="onRightClick($event, {card: row})" class="{{theme == 'base'?'card':''}}" draggable="true" (drag)="dragCard($event, row.Id)" (dragstart)="dragStart($event, row.Id)" (dragend)="dragEnd($event, row.Id)" id="{{row.Id}}" (editRow)="openCard($event)" (canvasReady)="canvasInfo($event)" [tableRow]="row"></app-as-kanban-card>
      </div>
      <div class="card" style="display: none; pointer-events: none;" id="dummy_insert_card_{{bucket.Id}}">
        <div class="card-body p-3" style="cursor: pointer; padding-top: 1rem !important; padding-bottom: 0px !important; opacity: 0.4;" id="dummy_insert_{{bucket.Id}}"></div>
      </div>
      <div class="card-body p-3 drag-dummy" style="opacity: 0.85; pointer-events: none; display: none; padding-top: 1rem !important; padding-bottom: 0 !important;" id="dummy_{{bucket.Id}}"></div>
    </div>
  </div>
</div>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <!--<button mat-menu-item>Clicked {% raw %}{{item.content}}{% endraw %}</button>-->
    <button mat-menu-item (click)="setCardTheme('bg-primary', item.card)" class="bg-primary text-white">Hintergrund Primär</button>
    <button mat-menu-item (click)="setCardTheme('border-primary', item.card)" class="border-primary text-primary">Rahmen Primär</button>
    <button mat-menu-item (click)="setCardTheme('bg-warning', item.card)" class="bg-warning text-white">Hintergrund Warnung</button>
    <button mat-menu-item (click)="setCardTheme('border-warning', item.card)" class="border-warning text-warning">Rahmen Warnung</button>
    <button mat-menu-item (click)="setCardTheme('bg-danger', item.card)" class="bg-danger text-white">Hintergrund Gefahr</button>
    <button mat-menu-item (click)="setCardTheme('border-danger', item.card)" class="border-danger text-danger">Rahmen Gefahr</button>
    <button mat-menu-item (click)="setCardTheme('bg-dark', item.card)" class="bg-dark text-white">Hintergrund Dunkel</button>
    <button mat-menu-item (click)="setCardTheme('border-dark', item.card)" class="border-dark">Rahmen Dunkel</button>
  </ng-template>
</mat-menu>
