import {v4 as uuidv4} from 'uuid';

export class ApiNotifications {
  private subscriptions : { [name: string]: ApiSubscription|null } = {}

  public subscribe(delegate: any): string {
    const subscriberId = uuidv4();
    this.subscriptions[subscriberId] = delegate;
    return subscriberId;
  }

  public unsubscribe(subscriberId: string) {
    if (subscriberId != undefined && subscriberId != null && subscriberId.trim() != '') {
      this.subscriptions[subscriberId] = null;
    }
  }

  public targetNotify(type: string, data: any, targetSubscriberId: string) {
    const notification = new ApiNotification();
    notification.EventType = type;
    notification.EventData = data;
    Object.keys(this.subscriptions).forEach((key) => {
      try {
        if (key == targetSubscriberId) {
          if (this.subscriptions[key] != null) {
            this.subscriptions[key](notification);
          }
        }
      } catch (e) {
        //
      }
    });
  }

  public notify(type: string, data: any, sourceSubscriberId: string = '') {
    const notification = new ApiNotification();
    notification.EventType = type;
    notification.EventData = data;
    Object.keys(this.subscriptions).forEach((key) => {
      try {
        if (key != sourceSubscriberId) {
          if (this.subscriptions[key] != null) {
            this.subscriptions[key](notification);
          }
        }
      } catch (e) {
        //
      }
    });
  }


}

export interface ApiSubscription {
  (notification: ApiNotification): void;
}

export class ApiNotification {
  public EventType: string;
  public EventData: any;
}
