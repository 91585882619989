import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FieldMeta, Page, PageItem, PageItemMove, TableRow, Tenant} from "../../api-classes/api-classes";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {Options} from "select2";
import {Options as GoogleOptions} from "ngx-google-places-autocomplete/objects/options/options";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-as-renderer-input-label',
  templateUrl: './as-renderer-input-label.component.html',
  styleUrls: ['./as-renderer-input-label.component.css']
})
export class AsRendererInputLabelComponent implements OnInit {
  options: Options = {
    allowClear: true
  };

  _sessionId = uuidv4();
  googleOptions: GoogleOptions;

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Input() sessionId = '';
  @Input() parentId = '';
  @Input() page: Page
  @Input() item: PageItem;
  @Input() row: TableRow;
  @Input() set mode(value: number) {
    if (value != 0) {
      this.singleEdit = false;
    }

    this._mode = value;
  }
  @Input() schema: FieldMeta[] = [];
  @Input() schemaResolver: { [name: string]: FieldMeta } = {}
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  _mode = 0;
  singleEdit = false;
  _lazyLoadCount = 0;
  tenant: Tenant;
  move: PageItemMove;
  constructor(private apiManagerService: ApiManagerService) { }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.singleEdit) {
        this.saveEdit();
      }
    }
  }

  openLink(row: TableRow, field: FieldMeta) {
    if (field.FieldType.startsWith('link')) {
      if (field.LinkedTable != null && field.LinkedTable.trim() != '') {
        const t = field.LinkedTable;
        if (this.apiManagerService.recordOpenTargets[t] != undefined && this.apiManagerService.recordOpenTargets[t] != null && this.apiManagerService.recordOpenTargets[t].trim() != '') {
          let targetId = ''
          switch(field.FieldType) {
            case 'linked':
            case 'linked1':
              targetId = row.LinkedId1;
              break;
            case 'linked2':
              targetId = row.LinkedId2;
              break;
            case 'linked3':
              targetId = row.LinkedId3;
              break;
            case 'linked4':
              targetId = row.LinkedId4;
              break;
          }


          if (targetId != null && targetId != '') {
            const tmp = this.apiManagerService.recordOpenTargets[t].replace('{id}', targetId);
            //if (this.newTab) {
            window.open(tmp);
            return;
            //} else {
            //  this.router.navigateByUrl(tmp);
            //}
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.tenant = this.apiManagerService.tenant;
  }

  saveEdit() {
    if (this._mode == 0 && this.page.AllowEdit && this.singleEdit) {
      this.singleEdit = false;
      this.apiManagerService.setTableEntity(this.row).then((e) => {

      });
    }
  }

  doEdit() {
    if (this._mode == 0 && this.page.AllowEdit && !this.item.Type.startsWith('ro_')) {
      this.singleEdit = true;
      setTimeout(() => {
        const tmp = document.getElementById(this.sessionId);
        if (tmp != undefined && tmp != null) {
          try {
            tmp.focus();
          } catch (e) {
            //
          }
        }
      }, 500);
    }
  }

  reportChanges() {

  }

  convertDateField(fieldId) {
    if (this.row.Fields[fieldId] == '' == null || this.row.Fields[fieldId] == '') {
      return '';
    } else {
      return this.apiManagerService.formatDate2(new Date(Date.parse(this.row.Fields[fieldId])), false);
    }
  }

  public handleAddressChange(field: FieldMeta, address: Address) {
    this.reportChanges();
    setTimeout(()=>this.doHhandleAddressChange(this, field, address),25);
  }

  public doHhandleAddressChange(self: AsRendererInputLabelComponent,field: FieldMeta, address: Address) {
    let street_number = "";
    let street = "";
    let zip = "";
    let city = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
        {
          street_number = component.long_name;
          break;
        }

        case "route":
        {
          street = component.long_name;
          break;
        }

        case "postal_code":
        {
          zip = component.long_name;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

      }
    }

    Object.entries(this.schema).forEach(([key, meta]) => {
      switch(meta.FieldType) {
        case "google-result-street":
        case "google-search-street":
          self.row.Fields[meta.Id] = street + ' ' + street_number;
          self.row.Fields[meta.Id] = self.row.Fields[meta.Id].trim();
          break;
        case "google-result-city":
        case "google-search-city":
          self.row.Fields[meta.Id] = city;
          break;
        case "google-result-postalcode":
        case "google-search-postalcode":
          self.row.Fields[meta.Id] = zip;
          break;
        case "google-result-name":
        case "google-search-name":
          self.row.Fields[meta.Id] = address.name;
          break
        case "google-result-country":
        case "google-search-country":
          self.row.Fields[meta.Id] = "DE";
          break
      }
    });

    setTimeout(() => {

      // @ts-ignore
      const input = document.getElementsByClassName('form_field_' + field.Id);
      if (input != null && input.length > 0) {
        // @ts-ignore
        input[0].value = self.tableRow.Fields[field.Id]
      }
    }, 250);
    // Do some stuff
  }

  setLink(linkType, linkedTable, linkField, linkId) {
    this.reportChanges();
    switch(linkType) {
      case 'linked':
      case 'linked1':
        this.row.LinkedId1 = linkId;
        break;
      case 'linked2':
        this.row.LinkedId2 = linkId;
        break;
      case 'linked3':
        this.row.LinkedId3 = linkId;
        break;
      case 'linked4':
        this.row.LinkedId4 = linkId;
        break;
    }
    for (let i = 0; i < this.apiManagerService.cacheRows[linkedTable].length; i++) {
      if (this.apiManagerService.cacheRows[linkedTable][i].Id == linkId || this.apiManagerService.cacheRows[linkedTable][i].PublicId == linkId) {
        this.row.Fields[linkField] = this.apiManagerService.cacheRows[linkedTable][i].Description
      }
    }

    if (linkId == null || linkId == '-' || linkId == '') {
      this.row.Fields[linkField] = '';
    }

  }

  processStartDrag(event, item: PageItem) {
    if (this._mode === -1) {
      this.move = new PageItemMove();
      this.move.ItemId = item.Id;
      this.move.SourceParentItemId = this.parentId;
    }

    return false;
  }

  processMove(req: PageItemMove) {
    if (req.ItemId !== this.item.Id) {
      this.moveRequest.emit(req);
    }
  }
}
