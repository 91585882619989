<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Admin</a></li>
    <li class="breadcrumb-item"><a routerLink="/mail/distributionlists">Verteiler</a></li>
    <li class="breadcrumb-item active" aria-current="page">Details</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <a style="margin: 2px 2px 2px auto;" class="btn btn-outline-secondary" routerLink="/mail/distributionlists">Zurück</a>
  <button [disabled]="working" style="margin: 2px;" class="btn btn-success" (click)="save()">Speichern</button>
</ul>

<div class="col-xl-12">
  <section class="hk-sec-wrapper" *ngIf="mlist!=null">
    <h1 class="hk-sec-title">Verteiler {{actionString}}</h1>

    <ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
      <li class="nav-item" style="display: flex;">
        <a style="cursor: pointer;" class="nav-link {{activeTab==1?'active':''}}" (click)="setTab(1)"><i class="fa-solid fa-buildings"></i>&nbsp;Allgemein</a>
        <a style="cursor: pointer;" class="nav-link {{activeTab==2?'active':''}}" (click)="setTab(2)"><span style="font-size: 14px;" class="mat-icon  material-icons">contacts</span>&nbsp;Mitglieder</a>
      </li>
    </ul>

    <div class="row" *ngIf="activeTab==1">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap" *ngIf="mlist != null">
          <label for="subdomain">E-Mail:&nbsp;</label>
          <input *ngIf="isNew" type="text" [value]="name" (change)="name = $any($event.target).value" placeholder="E-Mail" id="subdomain" />
          <input *ngIf="isNew" type="text" id="subdomaintwo" value="@{{domain}}" disabled/>
          <span  *ngIf="!isNew">{{mlist.listname}}</span>
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activeTab==2">
      <div class="col-sm" style="margin-top:5px;">
        <div class="table-wrap">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th>E-Mail</th>
              <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><input class="form-control" type="email" (keydown.enter)="addMember()" [value]="newMember" (change)="newMember=$any($event.target).value"/></td>
              <td>
                <a title="Hinzufügen" (click)="addMember()" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-plus-circle" style="font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            <tr *ngFor="let member of mlist.associations">
              <td>{{member}}</td>
              <td>
                <a title="Entfernen" (click)="removeMember(member)" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-trash" style="color: darkred; font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
      </div>
    </div>
  </section>
</div>
