<nav class="hk-breadcrumb" aria-label="breadcrumb" *ngIf="record != null">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Einkauf</a></li>
    <li class="breadcrumb-item"><a routerLink="/crm/orders">Bestellungen</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{record.Description}}</li>
  </ol>

  <div style="display: flex; float: right; flex-wrap: wrap;width: 55px;margin-top: -15px;">
    <div class="nav-item" style="margin-left: auto; float: right">
      <app-as-flex-drop-button [menuEntries]="menu"></app-as-flex-drop-button>
    </div>
    <div style="float: right;">
      <i tooltipClass="tooltip-light" ngbTooltip="Diese Ansicht anpassen" class="fa-solid fa-layer-plus" style="float:right; margin-top: 10px; cursor: pointer; margin-left: 5px; margin-right: 20px;" *ngIf="mode==0 && profile.IsAdmin && page.AllowChange" (click)="mode=-1"></i>
      <i tooltipClass="tooltip-light" ngbTooltip="Schließen" class="fa-solid fa-circle-xmark" style="float:right; margin-top: 10px; cursor: pointer; margin-right: 20px;" *ngIf="mode==-1 && page.AllowChange" (click)="mode=0"></i>
      <i tooltipClass="tooltip-light" ngbTooltip="Daten bearbeiten" class="fa-solid fa-pencil" style="float:right; margin-top: 10px; cursor: pointer;" *ngIf="mode==0 && page.AllowEdit" (click)="mode=1"></i>
      <i tooltipClass="tooltip-light" ngbTooltip="Speichern" class="fa-solid fa-check-circle" style="float:right; margin-top: 10px; cursor: pointer; margin-right: 20px;" *ngIf="mode==1 && page.AllowEdit" (click)="saveData()"></i>
    </div>
  </div>
</nav>
<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">

</ul>



<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item" style="display: flex;">
    <a style="cursor: pointer;" class="nav-link {{activeTab==1?'active':''}}" (click)="setTab(1)"><i class="fa-solid fa-info"></i>&nbsp;Allgemein</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==2?'active':''}}" (click)="setTab(2)"><i class="fa-solid fa-location-crosshairs"></i>&nbsp;Bestellung</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==3?'active':''}}" (click)="setTab(3)"><i class="fa-solid fa-buildings"></i>&nbsp;Lieferant</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==4?'active':''}}" (click)="setTab(4)"><i class="fa-solid fa-money-bill-1-wave"></i>&nbsp;Abrechnung</a>
    <!--<a style="cursor: pointer;" class="nav-link {{activeTab==5?'active':''}}" (click)="setTab(5)"><span style="font-size: 14px;" class="mat-icon  material-icons">contacts</span>&nbsp;Kontakte</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==2?'active':''}}" (click)="setTab(2)"><i class="fa-solid fa-location-crosshairs"></i>&nbsp;Verlaufschancen</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==3?'active':''}}" *ngIf="hasSupport" (click)="setTab(3)"><i class="fa-solid fa-message"></i>&nbsp;Support Cases</a>
    <a style="cursor: pointer;" class="nav-link {{activeTab==4?'active':''}}" (click)="setTab(4)" *ngIf="hasStructureData"><i class="fa-solid fa-timeline-arrow"></i>&nbsp;Strukturdaten&nbsp;<span class="badge badge-soft-info">Alpha</span></a>-->
  </li>
</ul>
<div style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">
  <app-as-renderer [mode]="mode" [rowId]="recordId" [pageName]="pageName" *ngIf="activeTab>0 && activeTab<5"></app-as-renderer>

</div>

