import { Component, OnInit } from '@angular/core';
import {TildaPage, TildaProject} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";

@Component({
  selector: 'app-tilda',
  templateUrl: './tilda.component.html',
  styleUrls: ['./tilda.component.css']
})
export class TildaComponent implements OnInit {

  projects: TildaProject[] = [];
  pages: TildaPage[] = [];
  project: TildaProject;
  exporting = false;
  showPages = false;


  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.apiManagerService.getTildaProjects().then((projects) => {
      this.projects = projects;
    }).catch((e) => {
      this.toast.showErrorToastMessage('Loading of projectlist failed');
    });
  }

  closePages(){
    this.showPages = false;
  }

  getPages(project: TildaProject){
    this.project = project;
    this.apiManagerService.getTildaProjectPages(project.id).then((p) => {
      this.pages = p;
      this.showPages = true;
    })
  }

  doPageExport(project: TildaProject, pageId: number){
    if (this.exporting) {
      return;
    }

    this.toast.showInfoToastMessage('Export von ' + project.title + ' eingeleitet')
    this.exporting = true;
    this.apiManagerService.exportTildaProject(project.id).then((success) => {
      this.exporting = false;
      this.toast.showSuccessToastMessage('Export von ' + project.title + ' erfolgreich');
    }).catch((e) => {
      this.exporting = false;
      this.toast.showErrorToastMessage('Export von ' + project.title + ' fehlgeschlagen');
    });
  }

  doExport(project: TildaProject) {
    if (this.exporting) {
      return;
    }

    this.toast.showInfoToastMessage('Export von ' + project.title + ' eingeleitet')
    this.exporting = true;
    this.apiManagerService.exportTildaProject(project.id).then((success) => {
      this.exporting = false;
      this.toast.showSuccessToastMessage('Export von ' + project.title + ' erfolgreich');
    }).catch((e) => {
      this.exporting = false;
      this.toast.showErrorToastMessage('Export von ' + project.title + ' fehlgeschlagen');
    });
  }

}
