import {Component, HostListener, OnInit} from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AsToastService} from "../as-toast.service";
import {Options} from "select2";
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";
import {AuthUser, Company, Contact} from "../api-classes/api-classes";
import {v4 as uuidv4} from 'uuid';
import {Title} from "@angular/platform-browser";
import {AsFlexDropButtonItem} from "../as-flex-drop-button/as-flex-drop-button.component";
import {ApiNotification} from "../api-notifications/api-notifications";

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.css']
})
export class DealsComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private toast: AsToastService,private title: Title) { }
  options: Options = {
    width: '300'
  };

  menu: AsFlexDropButtonItem[] = []
  subscriberId = '';

  activeMainTab = 1;
  companies: Company[];
  companyOptions: Array<Select2OptionData> = [];
  contacts: Contact[];
  editContact: Contact = null;
  editContactCompanyId: string|null = null;
  newContact: Contact = null;
  profile = new AuthUser;
  focusCellId = '';
  saveTimeout: any = null;
  activeModal: any = null;
  placeholderText: string|null = '';
  focusCell: HTMLDivElement = null;
  pageW = 1000;
  kanbanMode = false;

  ngOnDestroy() {
    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
  }

  ngOnInit(): void {
    this.subscriberId = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType=='add-deal') {
        this.activeMainTab = 1;
        setTimeout(() => {
          this.apiManagerService.notifications.notify('add-row-flex-deals', null, this.subscriberId);
        }, 250);
      }

      if (notification.EventType == 'add-company') {
        this.activeMainTab = 2;
        setTimeout(() => {
          this.apiManagerService.notifications.notify('add-row-flex-companies', null, this.subscriberId);
        }, 250);
      }
    });

    this.menu.push({
      Displayname: 'Verkaufschance hinzufügen',
      EventTarget: this.subscriberId,
      EventType: 'add-deal',
      EventData: null
    });

    this.menu.push({
      Displayname: 'Unternehmen hinzufügen',
      EventTarget: this.subscriberId,
      EventType: 'add-company',
      EventData: null
    });

    this.title.setTitle('Verkaufschancen - Astralus Admin Portal');
    this.profile = this.apiManagerService.getProfile();
    /*this.apiManagerService.getTableSchema('contacts').then((s) => {
      this.apiManagerService.getTenantContacts().then((data) => {
        this.contacts = data;
      });
    }).catch((e) => console.log(e));*/
    this.apiManagerService.getTenantCompanies().then((data) => {

      this.companyOptions = [];
      this.companies = data;
      for (let i = 0; i < data.length; i++) {
        this.companyOptions.push({
          id: data[i].Id,
          text: data[i].Displayname
        })
      }
    });

    this.onResize();
  }

  setTab(tabId) {
    this.activeMainTab = tabId;
  }

  onScroll(evt) {
    this.onResize();
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.pageW = window.innerWidth;
    if (this.focusCell != null && this.focusCellId != '') {
      const rect = this.focusCell.getBoundingClientRect();
      const cell = document.getElementById(this.focusCellId);
      if (cell != null) {
        let coordFix = this.getLeftFix();
        const lCoord = rect.left - coordFix;
        cell.style.left = lCoord + 'px';
        cell.style.width = (rect.width - this.getLeftWFix()) + 'px';
        cell.focus();
      }
    }
  }

  getLeftWFix(){
    return 35;
  }

  isMinimizedMenu() {
    const wrapper = document.getElementsByClassName('hk-vertical-nav');
    if (wrapper.length > 0) {
      if (wrapper[0].classList.contains('hk-nav-toggle'))
        return true;
    }

    return false;
  }

  getLeftFix(){

    if (this.pageW < 1199) { // Mobile view
      return 16;
    } else {
      if (this.pageW > 1599)
      {
        if (!this.isMinimizedMenu())
          return 310;

        return 121;
      } else {
        if (!this.isMinimizedMenu())
          return 265;

        return 76;
      }
    }
  }

  closeModal() {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }
  }

  removeContactCompany() {
    this.closeModal();
    this.editContact.CompanyName = "(keine)";
    this.editContact.CompanyId = null;

    this.saveEditContact()
  }

  setContactCompany() {
    this.closeModal();
    if (this.editContact.CompanyId == null || this.editContact.CompanyId == '') {
      this.editContact.CompanyName = "(keine)";
      this.editContact.CompanyId = null;
    } else {
      for(let i = 0; i < this.companyOptions.length; i++) {
        if (this.companyOptions[i].id == this.editContact.CompanyName) {
          this.editContact.CompanyName = this.companyOptions[i].text;
          break;
        }
      }
    }

    this.saveEditContact()
  }

  saveEditContact(){
    const tmp = this.editContact;
    this.editContact = null;
    this.apiManagerService.setContact(tmp).then((e) => {
      if (e != null) {
        let found = false;
        for (let x = 0; x < this.contacts.length; x++) {
          if (this.contacts[x].Id === e.Id) {
            found = true;
            this.contacts[x] = e;
          }
        }

        if (!found) {
          this.contacts.push(e);
        }
      }
    });
  }

  editContactCompany(modalRef, contact: Contact) {
    if (this.companyOptions.length == 0) {
      this.toast.showErrorToastMessage("Keine Firmen gefunden.");
      return;
    }

    this.editContact = contact;
    this.editContactCompanyId = contact.CompanyId;
    this.activeModal = this.modalService.open(modalRef);
  }

  setFocusCell(event, cellId, placeholder) {
    this.focusCell = event.target;
    const rect = this.focusCell.getBoundingClientRect();
    if (this.focusCellId !== '') {
      this.focusLost();
    }

    if (this.saveTimeout != null) {
      clearTimeout(this.saveTimeout);
      this.saveTimeout = null;
    }

    this.placeholderText = placeholder;
    if (this.placeholderText === '' || this.placeholderText == null)
    {
      this.placeholderText = '&nbsp;';
    }
    this.focusCellId = cellId;

    setTimeout(() => {
      const cell = document.getElementById(cellId);
      if (cell != null) {
        let coordFix = this.getLeftFix();
        const lCoord = rect.left - coordFix;
        cell.style.display = '';
        cell.style.left = lCoord + 'px';
        cell.style.width = (rect.width - this.getLeftWFix()) + 'px';
        cell.focus();
      }
    }, 250);
  }

  addContact(modalRef) {
    this.focusLost()
    this.newContact = new Contact();
    this.newContact.Id = uuidv4();
    this.newContact.Displayname = '';
    // this.setFocusCell(this.newContact.Id + "_Displayname");

    this.activeModal = this.modalService.open(modalRef);
  }

  addContactConfirm() {
    if (this.newContact != null) {
      if (this.newContact.Displayname.trim() !== '' && this.newContact.Email.trim() !== '') {
        this.closeModal();
        const tmp = this.newContact;
        this.newContact = null;
        this.apiManagerService.setContact(tmp).then((e) => {
          if (e != null) {
            let found = false;
            for (let x = 0; x < this.contacts.length; x++) {
              if (this.contacts[x].Id === e.Id) {
                found = true;
                this.contacts[x] = e;
              }
            }

            if (!found) {
              this.contacts.push(e);
            }
          }
        });
      } else {
        this.toast.showErrorToastMessage("Anzeigename und E-Mail sind pflicht.");
      }
    }
  }

  focusLost() {
    if (this.focusCellId !== '') {
      this.focusCellId = '';
      if (this.saveTimeout != null) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = null;
      }

      this.saveTimeout = setTimeout(() => {
        const tmp = this.contacts;
        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i].Displayname.trim() !== '' && tmp[i].Email.trim() !== '') {
            this.apiManagerService.setContact(tmp[i]).then((e) => {
              if (e != null) {
                let found = false;
                for (let x = 0; x < this.contacts.length; x++) {
                  if (this.contacts[x].Id === e.Id) {
                    found = true;
                    this.contacts[x] = e;
                  }
                }

                if (!found) {
                  this.contacts.push(e);
                }
              }
            });
          }
        }
      }, 3000);
    }
  }
}
