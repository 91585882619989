import {Component, Input, OnInit} from '@angular/core';
import {FieldMeta} from "../api-classes/api-classes";

@Component({
  selector: 'app-as-visible-fields',
  templateUrl: './as-visible-fields.component.html',
  styleUrls: ['./as-visible-fields.component.css']
})
export class AsVisibleFieldsComponent implements OnInit {

  constructor() { }

  _fields: FieldMeta[] = []

  @Input() fields: FieldMeta[] = []

  ngOnInit(): void {
    setTimeout(() => {
      this._fields = [];
      Object.entries(this.fields).forEach(([key, field]) => {
        if (!field.CantChangeHidden) {
          this._fields.push(field);
        }
      });
    },25);
  }

}
