import {Component, HostListener, OnInit} from '@angular/core';
import {AuthUser, ButtonEvent, Page, TableRow} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AsToastService} from "../as-toast.service";
import {v4 as uuidv4} from 'uuid';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.css']
})
export class CaseDetailsComponent implements OnInit {

  recordId = '-';
  record: TableRow = new TableRow();
  rows: TableRow[] = []
  tableName = 'cases';
  pageName = 'test-case';
  returnPage = '/support';
  profile = new AuthUser();
  page = new Page();
  mode = 0;
  marginOverride = '';
  widthOverride = '';
  showSidebar = false;
  converting = false;
  sidebarHeightRule = '';

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private toast: AsToastService,private title: Title) { }

  ngOnDestroy(): void {
    //document.body.style.overflow = '';
    setTimeout(() => {
      if (!this.apiManagerService.tenant.Modules.includes('51038834-320f-4c31-8f85-cfdfb2a3050c')) {
        this.router.navigateByUrl('/');
      }
    },5000);
    this.apiManagerService.noContainerBorder = false;
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  onButton(evt: ButtonEvent) {
    if (evt.EventId == 'convert') {
      if (this.converting) {
        this.toast.showWarningToastMessage('Konvertierung läuft bereits');
        return;
      }

      this.converting=true;
      this.toast.showInfoToastMessage('Lead wird konvertiert');
      const tmp: TableRow = JSON.parse(JSON.stringify(evt.Row))
      tmp.TableName = 'deals';
      tmp.Id = uuidv4();

      evt.Row.IsComplete = true;
      this.apiManagerService.setTableEntity(evt.Row);
      this.apiManagerService.setTableEntity(tmp).then((e) => {
        if (this.apiManagerService.cacheRows['deals'] != undefined && this.apiManagerService.cacheRows['deals'] != null) {
          this.apiManagerService.cacheRows['deals'].push(e);
          this.router.navigateByUrl('/crm/deals/' + e.Id);
        }
        else{
          this.apiManagerService.getTableEntities('deals').then((rows) => {
            this.router.navigateByUrl('/crm/deals/' + e.Id);
          }).catch((e) => {
            this.router.navigateByUrl('/crm/deals/' + e.Id);
          });
        }
      }).catch((e) => {
        this.toast.showErrorToastMessage('Konvertierung fehlgeschlagen');
        this.converting = false;
      });
    }
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    if (w > 1400) {
      this.marginOverride = 'margin-left: 250px;';
      this.widthOverride = 'width: 250px;';
    } else {
      this.marginOverride = '';
      this.widthOverride = '';
    }
    this.sidebarHeightRule = 'height: ' + (h - 58) + 'px;';
  }

  ngOnInit(): void {
    this.detectScreenSize();
    this.apiManagerService.getPage(this.pageName).then((page) => {
      this.page = page;
      if (page.DefaultMode == -1 && page.AllowChange) {
        this.mode = -1;
      } else if (page.DefaultMode == 1 && page.AllowEdit) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    });

    this.profile = this.apiManagerService.getProfile();
    this.activatedRoute.paramMap.subscribe(params => {
      const id = this.recordId = params.get('id');
      if (this.apiManagerService.cacheSchemas[this.tableName] && this.apiManagerService.cacheRows[this.tableName]) {
        this.rows = this.apiManagerService.syncRowsSchema(this.apiManagerService.cacheRows[this.tableName], this.apiManagerService.cacheSchemas[this.tableName]);
        this.loadRecord(this.apiManagerService.cacheRows[this.tableName], id)
      } else {
        this.apiManagerService.getTableSchema(this.tableName).then((schema) =>
        {
          this.apiManagerService.getTableEntities(this.tableName).then((rows) => {
            this.rows = this.apiManagerService.syncRowsSchema(rows, schema);
            this.loadRecord(rows, id)
          }).catch((e) => {
            this.router.navigateByUrl(this.returnPage);
          });
        }).catch((e) => {
          this.router.navigateByUrl(this.returnPage);
        });
      }
    });
  }

  saveData() {
    this.apiManagerService.setTableEntity(this.record);
    this.mode = 0;
  }

  loadRecord(rows: TableRow[], id: string) {
    let found = false;
    for(let i = 0; i < rows.length; i++) {
      if (rows[i].Id == id || rows[i].PublicId == id) {
        this.record = rows[i];
        this.title.setTitle(rows[i].Description + ' - Astralus Admin Portal');
        found = true;
      }
    }

    if (!found) {
      this.router.navigateByUrl(this.returnPage);
    } else {
      this.recordId = id;
    }
  }
}
