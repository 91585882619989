<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/admin/apps">Apps</a></li>
    <li class="breadcrumb-item active" aria-current="page">Details</li>
  </ol>
</nav>

<div class="hk-pg-header">
  <h4 class="hk-pg-title"><span class="pg-title-icon"><span class="feather-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></span></span>App details</h4>
</div>

<div class="col-xl-12">
  <section class="hk-sec-wrapper hk-gallery-wrap">
    <div style="display: flex;" *ngIf="loading">
      <div style="margin-left: auto; margin-right: auto" class="lds-default" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="row hk-gallery" *ngIf="!loading">
      <ul class="nav nav-light nav-tabs" role="tablist">
        <li class="nav-item">
          <a href="#" onclick="return false;" class="nav-link active">Allgemein</a>
        </li>
      </ul>
      <div class="tab-content">
        <br>
        <h5>{{feature.Name}}</h5><br/>
        <span>Version: {{feature.Version}}</span><br/>
        <span>Preis: Frei</span><br>
        <span *ngIf="orderDate != ''">Bestellt am: {{orderDate}}</span><br *ngIf="orderDate != ''">
        <span>Beschreibung:</span><br>
        <span>{{feature.Description}}</span><br/>
        <br/>
        <a class="btn btn-success" (click)="order()" *ngIf="!feature.IsActive">Bestellen</a>
        <a class="btn btn-danger" (click)="cancel()" *ngIf="feature.IsActive && feature.CancelTime == null">Kündigen</a>
        <a class="btn btn-success" (click)="order()" *ngIf="feature.IsActive && feature.CancelTime != null">Kündigung aufheben</a>
      </div>
    </div>
  </section>
</div>
