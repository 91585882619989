import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FieldMeta, Page, PageItem, PageItemMove, TableRow} from "../../api-classes/api-classes";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {ApiNotification} from "../../api-notifications/api-notifications";

@Component({
  selector: 'app-as-renderer-contact-details',
  templateUrl: './as-renderer-contact-details.component.html',
  styleUrls: ['./as-renderer-contact-details.component.css']
})
export class AsRendererContactDetailsComponent implements OnInit, OnDestroy {

  _schemaResolver:{ [name: string]: FieldMeta } = {}
  _row: TableRow;

  _pageItem: PageItem;

  @Output() uploadFile = new EventEmitter<File>();
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Input() sessionId = '';
  @Input() parentId = ''
  @Input() page: Page;

  @Input() set item(value: PageItem){
    this._pageItem = value;
    this.loadDetails();
  }
  @Input() set row(value: TableRow) {
    this._row = value;
    this.loadDetails();
  }

  @Input() mode: number = 0;
  @Input() schema: FieldMeta[] = [];
  @Input() set schemaResolver( value: { [name: string]: FieldMeta }) {
    this._schemaResolver = value;
    this.loadDetails();
  }
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  email = '';
  phone = '';
  fax = '';
  mobile = '';
  subscriberId = '';
  constructor(private apiManagerService: ApiManagerService) { }

  ngOnDestroy() {
    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
  }

  ngOnInit(): void {
    this.subscriberId = this.apiManagerService.notifications.subscribe((notification:ApiNotification) => {
      if (notification.EventType == 'signalr-row-update') {
        const tmp: TableRow = notification.EventData;
        if (this._row != null && this._row.Id == tmp.Id) {
          this._row = tmp;
          this.loadDetails();
        }
      }
    });
  }

  loadDetails() {
    if (this._row != null && this._pageItem != null && this._schemaResolver[this._pageItem.Options] != undefined && this._schemaResolver[this._pageItem.Options] != null) {
      const schema = this._schemaResolver[this._pageItem.Options];
      this.email = '';
      this.phone = '';
      this.fax = '';
      this.mobile = '';
      let linkId = '';
      switch(schema.FieldType) {
        case 'linked':
        case 'linked1':
          linkId = this._row.LinkedId1;
          break;
        case 'linked2':
          linkId = this._row.LinkedId2;
          break;
        case 'linked3':
          linkId = this._row.LinkedId3;
          break;
        case 'linked4':
          linkId = this._row.LinkedId4;
          break;
      }

      if (linkId != null && linkId != '') {
        if (this.apiManagerService.cacheRows[schema.LinkedTable] != undefined && this.apiManagerService.cacheRows[schema.LinkedTable] != null) {
            this.parseRows(linkId, this.apiManagerService.cacheRows[schema.LinkedTable]);
        } else {
          this.apiManagerService.getTableEntities(schema.LinkedTable).then((rows) => {
            this.parseRows(linkId, rows);
          });
        }
      }
    }
  }

  parseRows(linkId: string, rows: TableRow[]) {
    if (rows != null) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].Id == linkId){
          if (rows[i].Fields['email'] != undefined &&  rows[i].Fields['email'] != null &&  rows[i].Fields['email'].trim() != '') {
            this.email =  rows[i].Fields['email'];
          }
          if (rows[i].Fields['fax'] != undefined &&  rows[i].Fields['fax'] != null &&  rows[i].Fields['fax'].trim() != '') {
            this.fax =  rows[i].Fields['fax'];
          }
          if (rows[i].Fields['phone'] != undefined &&  rows[i].Fields['phone'] != null &&  rows[i].Fields['phone'].trim() != '') {
            this.phone =  rows[i].Fields['phone'];
          }
          if (rows[i].Fields['mobile'] != undefined &&  rows[i].Fields['mobile'] != null &&  rows[i].Fields['mobile'].trim() != '') {
            this.mobile =  rows[i].Fields['mobile'];
          }

          break;
        }
      }
    }
  }
}

