import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FieldMeta, Page, PageItem, PageItemMove, TableRow} from "../../api-classes/api-classes";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-as-renderer-ro-text',
  templateUrl: './as-renderer-ro-text.component.html',
  styleUrls: ['./as-renderer-ro-text.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererRoTextComponent implements OnInit {

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  _item: PageItem;
  _row: TableRow;
  _tag = 'span'
  _fieldId = '';
  _schemaResolver: { [name: string]: FieldMeta } = {}
  _schema: FieldMeta[] = [];


  @Output() singleEditStart = new EventEmitter<void>();
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Input() isEdit = false;
  @Input() sessionId = '';
  @Input() parentId = '';
  @Input() page: Page;
  @Input() set item(value: PageItem) {
    this._item = value;
    this.loadText();
  }
  @Input() set row(value: TableRow) {
    this._row = value;
    this.loadText();
  }
  @Input() mode: number = 0;
  @Input() set schema(value: FieldMeta[]) {
    this._schema = value;
    this.loadText();
  }
  @Input() set schemaResolver(value: { [name: string]: FieldMeta }) {
    this._schemaResolver = value;
    this.loadText();
  }
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  move: PageItemMove;
  constructor(private sanitizer: DomSanitizer) { }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }
  doEdit() {
    if (this.mode == 0 && this.page.AllowEdit && this.isEdit) {
      try {
        this.singleEditStart.emit();
      } catch (e) {
        //
      }
    }
  }

  loadText() {
    if (this._item != null && this._row != null) {
      if (this._item.Type.includes('-')) {
        const parts = this._item.Type.split('-');
        if (parts.length > 1 && parts[1].trim() !== '') {
          this._tag = parts[1].trim();
        }
      }

      const meta = this._schemaResolver[this._item.Options];
      if (meta != null && meta !== undefined) {
        this._fieldId = this._item.Options
      }

    } else {
      this._fieldId = '';
      this._tag = 'span';
    }
  }

  ngOnInit(): void {
  }

  processStartDrag(event, item: PageItem) {
    if (this.mode === -1) {
      this.move = new PageItemMove();
      this.move.ItemId = item.Id;
      this.move.SourceParentItemId = this.parentId;
    }

    return false;
  }

  processMove(req: PageItemMove) {
    if (req.ItemId !== this._item.Id) {
      this.moveRequest.emit(req);
    }
  }
}
