<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Admin</a></li>
    <li class="breadcrumb-item"><a routerLink="/mail/mailboxes">Mailboxen</a></li>
    <li class="breadcrumb-item active" aria-current="page">Details</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <a style="margin: 2px 2px 2px auto;" class="btn btn-outline-secondary" routerLink="/mail/mailboxes">Zurück</a>
  <button [disabled]="working" style="margin: 2px;" class="btn btn-success" (click)="save()">Speichern</button>
</ul>

<div class="col-xl-12">
  <section class="hk-sec-wrapper" *ngIf="mailbox!=null">
    <h1 class="hk-sec-title">Mailbox {{actionString}}</h1>
    <ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
      <li class="nav-item" style="display: flex;">
        <a style="cursor: pointer;" class="nav-link {{activeTab==1?'active':''}}" (click)="setTab(1)"><i class="fa-solid fa-buildings"></i>&nbsp;Allgemein</a>
        <a style="cursor: pointer;" class="nav-link {{activeTab==2?'active':''}}" (click)="setTab(2)"><span style="font-size: 14px;" class="mat-icon  material-icons">contacts</span>&nbsp;Aliase</a>
      </li>
    </ul>
    <div class="row" *ngIf="activeTab==1">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap" *ngIf="mailbox != null">
          <label for="subdomain">E-Mail:&nbsp;</label>
          <input *ngIf="isNew" type="text" [value]="name" (change)="name = $any($event.target).value" placeholder="E-Mail" id="subdomain" />
          <input *ngIf="isNew" type="text" id="subdomaintwo" value="@{{domain}}" disabled/>
          <span  *ngIf="!isNew">{{mailbox.username}}</span>
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <label class="form-label" for="displayname">Anzeigename: </label>
          <input id="displayname" type="text" [value]="mailbox.properties.displayname" (change)="mailbox.properties.displayname = $any($event.target).value" class="form-control" />
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          <span>Platform:</span>
          <select [disabled]="!isNew" class="form-control" (change)="mailbox.platform = $any($event.target).value">
            <option value="Exchange" [selected]="mailbox.platform=='Exchange'">Exchange</option>
            <option value="grm-be01.stack.cybacor.tech" [selected]="mailbox.platform=='grm-be01.cybacor.tech'">grm-be01.stack.cybacor.tech</option>
            <option value="dco-be01.stack.astralus.cloud" [selected]="mailbox.platform=='dco-be01.stack.astralus.cloud'">dco-be01.stack.astralus.cloud</option>
          </select>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3" *ngIf="isNew">
          <label class="form-label" for="displayname">Passwort: </label>
          <input id="newpassword" type="password" [value]="newPassword" (change)="newPassword = $any($event.target).value" class="form-control" />
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3" *ngIf="isNew">
          <label class="form-label" for="displayname">Passwort bestätigen: </label>
          <input id="newpasswordconfirm" type="password" [value]="newPasswordConfirm" (change)="newPasswordConfirm = $any($event.target).value" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row"  *ngIf="activeTab==2">
      <div class="col-sm" style="margin-top:5px;">
        <div class="table-wrap">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th>E-Mail</th>
              <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><input class="form-control" type="email" (keydown.enter)="addAlias()" [value]="newAlias" (change)="newAlias=$any($event.target).value"/></td>
              <td>
                <a title="Hinzufügen" (click)="addAlias()" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-plus-circle" style="font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            <tr *ngFor="let member of mailbox.aliases">
              <td>{{member}}</td>
              <td>
                <a title="Entfernen" (click)="removeAlias(member)" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-trash" style="color: darkred; font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
      </div>
    </div>
  </section>
</div>
