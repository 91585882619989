<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item active" aria-current="page">Benutzer</li>
  </ol>
</nav>

<!--<div class="hk-pg-header">
  <h4 class="hk-pg-title"><span class="pg-title-icon"><span class="feather-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></span></span>Benutzer</h4>
</div>-->

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item" style="margin-left: auto;">
    <app-as-flex-drop-button [menuEntries]="menu" [disable]="loading"></app-as-flex-drop-button>
  </li>
</ul>


<div class="col-xl-12">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title"><i class="fa-thin fa-users" style="font-size: 15px; min-width: 17px; font-size: 30px;"></i>&nbsp;Benutzer</h1>

    <!--<button class="btn btn-success" *ngIf="!kanbanMode" (click)="kanbanMode = !kanbanMode">Kanban Test</button>
    <button class="btn btn-success" *ngIf="kanbanMode" (click)="kanbanMode = !kanbanMode">Kanban Test beenden</button>-->
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <!--<ng-select2 [data]="testList" [(ngModel)]="testVal"> </ng-select2>-->
        <div class="table-wrap">
          <div style="display: flex;" *ngIf="loading">
            <div style="margin-left: auto; margin-right: auto" class="lds-default" >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="table-responsive" *ngIf="!loading">
            <!--<div class="col-12" style="position: absolute;top: -55px;">
              <a style="cursor: pointer; font-size: 14px; float: right;" class="btn btn-gradient-success" (click)="addUser(addUserModalRef)">Hinzufügen</a>
            </div>-->
            <table class="table table-hover mb-0">
              <thead>
              <tr>
                <th>
                  <span> E-Mail-Adresse / Login</span>
                </th>
                <th>
                  <span>Anzeigename</span>
                </th>
                <th>
                  <span>Berechtigung</span>
                </th>
                <th>
                  <span>Login Provider</span>
                </th>
                <th>
                  <span>Status</span>
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of users"  >
                <td>{{user.Username}}</td>
                <td>{{user.DisplayName}}</td>
                <td>
                  <span class="badge badge-info badge-pill mt-15 mr-10" *ngIf="!user.IsAdmin" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserAdmin(user.Id, true)">Benutzer</span>
                  <span class="badge badge-warning badge-pill mt-15 mr-10" *ngIf="user.IsAdmin" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserAdmin(user.Id, false)">Administrator</span>
                </td>
                <td>Identiqa SSO</td>
                <td>
                  <span class="badge badge-danger badge-pill mt-15 mr-10" *ngIf="!user.IsEnabled" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserEnable(user.Id, true)">Gesperrt</span>
                  <span class="badge badge-success badge-pill mt-15 mr-10" *ngIf="user.IsEnabled" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserEnable(user.Id, false)">Aktiv</span>
                </td>
                <td><i class="fa-solid fa-magnifying-glass" style="cursor: pointer; float: right;" (click)="showUser(userDetailsModalRef, user)"></i></td>
              </tr>
              <tr *ngIf="users.length == 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ng-template #addUserModalRef let-modal style="z-index: 1100;" class="modal fade" id="addUserModal" aria-labelledby="addUserModalLabel">
  <!--<div class="modal-dialog" role="document">
    <div class="modal-content">-->
  <div class="modal-header">
    <h5 class="modal-title" id="addUserModalLabel">Benutzer hinzufügen</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">E-Mail*</label>
        <div class="col-sm-8">
          <input style="margin: 2px;" type="text" [value]="newUsername" (input)="newUsername=$any($event.target).value" class="form-control" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Anzeigename</label>
        <div class="col-sm-8">
          <input style="margin: 2px;" type="text" [value]="newDisplayname" (input)="newDisplayname=$any($event.target).value" class="form-control" />
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()" data-dismiss="modal">Abbrechen</button>
    <button type="button" (click)="addUserConfirm()" class="btn btn-primary">Hinzufügen</button>
  </div>
  <!--</div>
</div>-->
</ng-template>


<ng-template #userDetailsModalRef let-modal style="z-index: 1100;" class="modal fade" id="userDetailsModal" aria-labelledby="userDetailsModalLabel">
  <!--<div class="modal-dialog" role="document">
    <div class="modal-content">-->
  <div class="modal-header">
    <h5 class="modal-title" id="userDetailsModalLabel">Details</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">E-Mail</label>
        <div class="col-sm-8">
          <input style="margin: 2px;" type="text" [value]="user.Username" readonly class="form-control" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Anzeigename</label>
        <div class="col-sm-8">
          <input style="margin: 2px;" type="text" [value]="user.DisplayName" readonly class="form-control" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Typ</label>
        <div class="col-sm-8">
          <span class="badge badge-info badge-pill mt-15 mr-10" *ngIf="!user.IsAdmin" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserAdmin(user.Id, true)">Benutzer</span>
          <span class="badge badge-warning badge-pill mt-15 mr-10" *ngIf="user.IsAdmin" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserAdmin(user.Id, false)">Administrator</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Status</label>
        <div class="col-sm-8">
          <span class="badge badge-danger badge-pill mt-15 mr-10" *ngIf="!user.IsEnabled" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserEnable(user.Id, true)">Gesperrt</span>
          <span class="badge badge-success badge-pill mt-15 mr-10" *ngIf="user.IsEnabled" style="margin-top: 0 !important; cursor: pointer;" (click)="setUserEnable(user.Id, false)">Aktiv</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Letzter Login</label>
        <div class="col-sm-8">
          <input style="margin: 2px;" type="text" [value]="user.LastLoginParsed" readonly class="form-control" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()" data-dismiss="modal">Abbrechen</button>
    <button type="button" (click)="addUserConfirm()" class="btn btn-primary">Hinzufügen</button>
  </div>
  <!--</div>
</div>-->
</ng-template>
