<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Admin</a></li>
    <li class="breadcrumb-item active" aria-current="page">Tilda</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;" *ngIf="showPages">
  <button style="margin: 2px;" class="btn btn-info" (click)="closePages()">Zurück</button>
  <button [disabled]="exporting" style="margin: 2px;" class="btn btn-success" (click)="doExport(project)">Export</button>
</ul>

<div class="col-xl-12" *ngIf="projects != null && !showPages">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title" style="display: flex;">Tilda Projekte</h1>
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th>Projekt</th>
              <th>Server</th>
              <th>Verzeichniss</th>
              <th>S3 Bucket</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let list of projects">
              <td>{{list.title}}</td>
              <td>{{list.ssh}}</td>
              <td>{{list.directory}}</td>
              <td>{{list.s3_bucket}}</td>
              <td style="display: flex;">
                <a title="Details" (click)="getPages(list)" class="" style="display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-folder-tree" style="font-size: 11px;"></i>
                  </span>
                </a>
                <a title="Export" (click)="doExport(list)" class="" style="display: flex; cursor:pointer; color: darkgray; margin-right: 4px;" *ngIf="!exporting">
                  <span class="">
                    <i class="fa-solid fa-file-export" style="font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>

          <!--<div style="display: flex; float: right; margin-left: auto;">
            <a href="#" *ngIf="pageId>0" (click)="setPage(0)">&lt;&lt;</a>&nbsp;
            <a href="#" *ngIf="pageId>0" (click)="setPage(pageId-1)">&lt;</a>
            <span>Seite {{visiblePageId}} / {{pageCount}} </span>
            <a href="#" *ngIf="pageId!=pageCount-1" (click)="setPage(pageId+1)">&gt;</a>&nbsp;
            <a href="#" *ngIf="pageId!=pageCount-1" (click)="setPage(pageCount-1)">&gt;&gt;</a>
          </div>-->
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
      </div>
    </div>
  </section>
</div>

<div class="col-xl-12" *ngIf="projects != null && showPages">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title" style="display: flex;">Tilda Projekt {{project.title}}</h1>
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th>Seite</th>
              <th>Beschreibung</th>
              <th>Alias</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let list of pages">
              <td>{{list.title}}</td>
              <td>{{list.descr}}</td>
              <td>{{list.alias}}</td>
              <td style="display: flex;">
                <a title="Export" (click)="doPageExport(project, list.id)" class="" style="display: flex; cursor:pointer; color: darkgray; margin-right: 4px;" *ngIf="!exporting">
                  <span class="">
                    <i class="fa-solid fa-file-export" style="font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</div>
