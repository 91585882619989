import { Component, OnInit } from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {Mailbox} from "../api-classes/api-classes";

@Component({
  selector: 'app-mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.css']
})
export class MailboxComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  actionString = '';
  isNew = false;
  mailbox: Mailbox = null;
  domain = '';
  name = '';
  newAlias = '';
  working = false;
  activeTab = 1;
  newPassword = '';
  newPasswordConfirm = '';


  setTab(id): void {
    this.activeTab = id;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id').trim();
      if (id.indexOf('@') > 0 || (id.length == 36 && id.indexOf('-') > 0)) {
        this.isNew = false;
        this.apiManagerService.getMailboxes().then((data) => {
          if (data != null) {
            for(let i = 0; i < data.length; i ++) {
              if (data[i].username == id.toLowerCase().trim() || data[i].uniqueid == id.toLowerCase().trim()) {
                this.mailbox = data[i];
                this.domain = this.mailbox.username.split('@')[1]
                this.name = this.mailbox.username.split('@')[0]
                this.actionString = 'bearbeiten';
                break;
              }
            }

            if (this.mailbox == null) {
              this.router.navigateByUrl('/mail/mailboxes');
            }
          } else {
            this.router.navigateByUrl('/mail/mailboxes');
          }
        }).catch((e) => {
          this.router.navigateByUrl('/mail/mailboxes');
        })
      } else {
        this.isNew = true;
        this.actionString = 'anlegen';
        this.domain = id;
        this.mailbox = new Mailbox();
      }
    });
  }

  addAlias() {
    this.newAlias = this.newAlias.trim().toLowerCase();
    if (this.newAlias !== '' && this.validateEmail(this.newAlias)) {
      let targetId = -1;
      for(let i = 0; i < this.mailbox.aliases.length; i++) {
        if (this.mailbox.aliases[i].toLowerCase().trim() == this.newAlias) {
          targetId = i;
          break;
        }
      }

      if (targetId == -1) {
        this.mailbox.aliases.push(this.newAlias);
      }

      this.newAlias = '';

    } else {
      this.toast.showWarningToastMessage('Ungültige E-Mail');
    }
  }

  validateEmail(input:string) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    if(input.match(mailformat))
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  removeAlias(member: string) {
    let targetId = -1;
    for(let i = 0; i < this.mailbox.aliases.length; i++) {
      if (this.mailbox.aliases[i].toLowerCase().trim() == member.toLowerCase().trim()) {
        targetId = i;
        break;
      }
    }

    if (targetId != -1) {
      this.mailbox.aliases.splice(targetId, 1);
    }
  }

  save() {
    this.newPassword = this.newPassword.trim();
    this.newPasswordConfirm = this.newPasswordConfirm.trim();
    if (this.name.trim() == '') {
      this.toast.showErrorToastMessage('E-Mail benötigt');
    } else if (this.mailbox.properties.displayname.trim() == '') {
      this.toast.showErrorToastMessage('Anzeigename benötigt');
    } else if (this.isNew && this.newPassword == '') {
      this.toast.showErrorToastMessage('Passwort benötigt');
    } else if (this.isNew && this.newPassword != this.newPasswordConfirm) {
      this.toast.showErrorToastMessage('Passwörter stimmen nicht überein');
    } else {
      this.working = true;
      this.mailbox.username = this.name + '@' + this.domain;
      if (this.isNew) {
        this.mailbox.password = this.newPassword;
      }
      this.apiManagerService.setMailbox(this.mailbox).then((data) => {
        this.working = false;
        this.mailbox.password = '';
        this.toast.showSuccessToastMessage('Daten gespeichert');
        this.isNew = false;
      }).catch((e) => {
        this.working = false;
        this.toast.showErrorToastMessage('Speichern fehlgeschlagen');
      })
    }
  }

}
