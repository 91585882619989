<div class="card">
  <div class="card-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs nav-sm nav-light mb-25">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="setTab(1)">Notizen</a>
        <ng-template ngbNavContent>
          <app-as-renderer-input [permEdit]="true" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [page]="_page" [mode]="mode" [item]="_noteItem" [row]="_row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="setTab(2)">Aktivität</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-xxl-8 col-xl-7 col-md-7 col-sm-12">
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Betreff">

                </label>
                <div class="col-11">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['title']" (input)="_newAcitivity.Fields['title']=$any($event.target).value;processDates()" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label" title="Typ">

                </label>
                <div class="col-11">
                  <a class="btn {{_newAcitivity.Fields['type'] == 'call'?'btn-info':'btn-outline-dark'}} btn-select" title="Anruf" style="cursor: pointer;" (click)="switchActType('call')"><i class="fa-solid fa-phone"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'meeting'?'btn-info':'btn-outline-dark'}} btn-select" title="Meeting" style="cursor: pointer;" (click)="switchActType('meeting')"><i class="fa-solid fa-users"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'task'?'btn-info':'btn-outline-dark'}} btn-select" title="Task" style="cursor: pointer;" (click)="switchActType('task')"><i class="fa-solid fa-timer"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'frist'?'btn-info':'btn-outline-dark'}} btn-select" title="Frist" style="cursor: pointer;" (click)="switchActType('frist')"><i class="fa-solid fa-flag"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'email'?'btn-info':'btn-outline-dark'}} btn-select" title="E-Mail" style="cursor: pointer;" (click)="switchActType('email')"><i class="fa-solid fa-paper-plane"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'lunch'?'btn-info':'btn-outline-dark'}} btn-select" title="Mittagessen" style="cursor: pointer;" (click)="switchActType('lunch')"><i class="fa-solid fa-fork-knife"></i></a>
                  <a class="btn {{_newAcitivity.Fields['type'] == 'presentation'?'btn-outline-info':'btn-outline-dark'}} btn-select" title="Präsentation" style="cursor: pointer;" (click)="switchActType('presentation')"><i class="fa-solid fa-screen-users"></i></a>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Start">
                  <span class="mat-icon  material-icons">schedule</span>
                </label>
                <div class="col-11">
                  <input style="margin: 2px;" type="datetime-local" [value]="_newAcitivity.Fields['start']" (input)="_newAcitivity.Fields['start']=$any($event.target).value;processDates()" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Ende">
                 <span class="mat-icon  material-icons">history</span>
                </label>
                <div class="col-11">
                  <input style="margin: 2px;" type="datetime-local" [value]="_newAcitivity.Fields['end']" (input)="_newAcitivity.Fields['end']=$any($event.target).value;processDates()" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Frei/Beschäftigt">
                  <span class="mat-icon  material-icons">free_cancellation</span>
                </label>
                <div class="col-11">
                  <!--<textarea style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['details']" (input)="_newAcitivity.Fields['details']=$any($event.target).value;" class="form-control"></textarea>-->
                  <select [value]="_newAcitivity.Fields['end']" (change)="_newAcitivity.Fields['freebusy'] = $any($event.target).value" class="form-control">
                    <option value="busy" [defaultSelected]="_newAcitivity.Fields['freebusy'] == '' || _newAcitivity.Fields['freebusy']=='busy'">Beschäftigt</option>
                    <option value="free" [defaultSelected]="_newAcitivity.Fields['freebusy']=='free'">Frei</option>
                    <option value="conditional" [defaultSelected]="_newAcitivity.Fields['freebusy']=='conditional'">Vorbehalt</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Standort">
                  <span class="mat-icon  material-icons">share_location</span>
                </label>
                <div class="col-11">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['location']" (input)="_newAcitivity.Fields['location']=$any($event.target).value;processDates()" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Beschreibung">
                  <span class="mat-icon  material-icons">event_note</span>
                </label>
                <div class="col-11">
                  <!--<textarea style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['details']" (input)="_newAcitivity.Fields['details']=$any($event.target).value;" class="form-control"></textarea>-->
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['details']" (ngModelChange)="_newAcitivity.Fields['details']=$event"></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Notizen">
                  <span class="mat-icon  material-icons">sticky_note_2</span>
                </label>
                <div class="col-11">
                  <!--<textarea style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['note']" (input)="_newAcitivity.Fields['note']=$any($event.target).value;" class="form-control"></textarea>-->
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 font-18 text-light-20" title="Erledigt" >
                  <span class="mat-icon  material-icons">task_alt</span>
                </label>
                <div class="col-sm-11 col-md-10" style="display: flex;">
                  <div class="form-check form-check-warning">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" id="IsEnabled" style="margin-top: -12px;" [checked]="_newAcitivity.Fields['complete'] == 'true'" (change)="_newAcitivity.Fields['complete']=$any($event.target).checked.toString()">
                      <i class="input-helper"></i>
                    </label>
                  </div>
                  Als erledigt markieren
                </div>
              </div>
              <div class="form-group row">
                <label class="col-1 col-form-label font-18 text-light-20" title="Besitzer">
                  <span class="mat-icon  material-icons">account_circle</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsUser" [value]="_newAcitivity.OwnerId" (valueChanged)="_newAcitivity.OwnerId=$event;" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
              <div class="form-group row" *ngIf="showLinkMerged">
                <label class="col-1 col-form-label font-18 text-light-20" title="{{mergedName}}">
                  <span class="mat-icon  material-icons">link</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsLinkMerged" [value]="mergedLinkValue" (valueChanged)="mergedLinkValue=$event;" [options]="options" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
              <div class="form-group row" *ngIf="showLink1">
                <label class="col-1 col-form-label font-18 text-light-20" title="{{nameLink1}}">
                  <span class="mat-icon  material-icons">link</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsLink1" [value]="_newAcitivity.LinkedId1" (valueChanged)="_newAcitivity.LinkedId1=$event;" [options]="options" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
              <div class="form-group row" *ngIf="showLink2">
                <label class="col-1 col-form-label font-18 text-light-20" title="{{nameLink2}}">
                  <span class="mat-icon  material-icons">link</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsLink2" [value]="_newAcitivity.LinkedId2" (valueChanged)="_newAcitivity.LinkedId2=$event;" [options]="options" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
              <div class="form-group row" *ngIf="showLink3">
                <label class="col-1 col-form-label font-18 text-light-20" title="{{nameLink3}}">
                  <span class="mat-icon  material-icons">link</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsLink3" [value]="_newAcitivity.LinkedId3" (valueChanged)="_newAcitivity.LinkedId3=$event;" [options]="options" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
              <div class="form-group row" *ngIf="showLink4">
                <label class="col-1 col-form-label font-18 text-light-20" title="{{nameLink4}}">
                  <span class="mat-icon  material-icons">link</span>
                </label>
                <div class="col-11">
                  <as-ng-select2 [data]="dropOptionsLink4" [value]="_newAcitivity.LinkedId4" (valueChanged)="_newAcitivity.LinkedId4=$event;" [options]="options" (change)="debugEvt($event.target)"> </as-ng-select2>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-5 col-md-5 col-sm-12">
              <full-calendar style="height: 98%;" [options]="calendarOptions" ></full-calendar>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="saveActivity()">{{_isEditActivity?'Aktualisieren':'Erfassen'}}</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="setTab(3)">Anruf</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-1 col-md-2 col-form-label">Rufnummer:</label>
                <div class="col-sm-11 col-md-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control"/>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="doCall()">Anrufen</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="setTab(4)">E-Mail</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-1 col-md-2 col-form-label">Von:</label>
                <div class="col-sm-11 col-md-10">
                  <input style="margin: 2px;" type="text" [value]="mailFrom" disabled class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 col-md-2 col-form-label">An:</label>
                <div class="col-sm-11 col-md-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 col-md-2 col-form-label">Betreff:</label>
                <div class="col-sm-11 col-md-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['title']" (input)="_newAcitivity.Fields['title']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-1 col-md-2 col-form-label">Nachricht:</label>
                <div class="col-sm-11 col-md-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>

                  <!--<textarea style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['message']" (input)="_newAcitivity.Fields['message']=$any($event.target).value;" class="form-control"></textarea>-->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendMail()">Senden</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>Dateien</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12" style="height: 60px; display: flex;">
              <input type="file" placeholder="Upload" (change)="handleFileInput($any($event.target).files)" style="width: 250px; margin: auto;" class="form-control"/>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2" style="min-height: 60px;"></div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs nav-sm nav-light mb-25">
      <li class="nav-item">
        <a (click)="setActivityFilter('')" style="cursor: pointer;" class="nav-link {{_activityFilter==''?'active':''}}">Alle</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('open')" style="cursor: pointer;" class="nav-link {{_activityFilter=='open'?'active':''}}">Offen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('closed')" style="cursor: pointer;" class="nav-link {{_activityFilter=='closed'?'active':''}}">Erledigt</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('call')" style="cursor: pointer;" class="nav-link {{_activityFilter=='call'?'active':''}}">Anrufe</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('meeting')" style="cursor: pointer;" class="nav-link {{_activityFilter=='meeting'?'active':''}}">Meetings</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('task')" style="cursor: pointer;" class="nav-link {{_activityFilter=='task'?'active':''}}">Tasks</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('frist')" style="cursor: pointer;" class="nav-link {{_activityFilter=='frist'?'active':''}}">Fristen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('email')" style="cursor: pointer;" class="nav-link {{_activityFilter=='email'?'active':''}}">E-Mails</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('lunch')" style="cursor: pointer;" class="nav-link {{_activityFilter=='lunch'?'active':''}}">Mittagessen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('presentation')" style="cursor: pointer;" class="nav-link {{_activityFilter=='presentation'?'active':''}}">Präsentationen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('file')" style="cursor: pointer;" class="nav-link {{_activityFilter=='presentation'?'active':''}}">Uploads</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="user-activity">
      <div class="media" *ngFor="let activity of _activitiesFiltered">
        <div class="media-img-wrap">

          <div class="avatar avatar-sm">
            <span style=" width: unset;" title=" {{activity.Fields['complete']=='true'||activity.Fields['type']=='email'||activity.Fields['type']=='file'?'Erledigt':'Offen'}}" class="avatar-text {{activity.Fields['complete']=='true'||activity.Fields['type']=='email'||activity.Fields['type']=='file'?'avatar-text-primary':'avatar-text-warning'}} rounded-circle">
              <span class="initial-wrap" style="text-align: center; line-height: 3; ">
                <!--<img src="dist/img/avatar4.jpg" alt="user" class="avatar-img rounded-circle">-->
                <i *ngIf="activity.Fields['type'] == 'call'" class="fa-solid fa-phone avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'meeting'" class="fa-solid fa-users avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'task'" class="fa-solid fa-timer avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'frist'" class="fa-solid fa-flag avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'email'" class="fa-solid fa-paper-plane avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'lunch'" class="fa-solid fa-fork-knife avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'presentation'" class="fa-solid fa-screen-users avatar-img rounded-circle "></i>
                <i *ngIf="activity.Fields['type'] == 'file'" class="fa-solid fa-file avatar-img rounded-circle"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="media-body">
          <div>
            <span class="d-block mb-5">
              <span class="font-weight-500 text-dark">{{activity.OwnerName}}</span>
              <span class="pl-5" style="display: flex; padding-left: 0 !important;">
                <div class="{{activity.Fields['type']!='file'?'activity-link':''}}" (click)="editActivity(activity.Id)">{{activity.Description}}&nbsp;</div>
                <a *ngIf="activity.Fields['type'] == 'file'" target="_blank" href="/v1.0/entity-attachments/{{_row.Id}}?file={{activity.Fields['to']}}&token={{profile.Token}}"><i class="fa-solid fa-download"></i></a>
              </span>
              <div>
                <span class="card-text" [innerHtml]="transform(activity.Fields['note'])"></span>
              </div>
              <div>
                <span class="card-text" [innerHtml]="transform(activity.Fields['message'])"></span>
              </div>
            </span>
            <span class="d-block font-13">{{activity.CreatedString}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div *ngFor="let activity of _activitiesFiltered" class="card">
  <div class="card-body">
    <span class="card-title"><h4>
      <i *ngIf="activity.Fields['type'] == 'call'" class="fa-solid fa-phone"></i>
      <i *ngIf="activity.Fields['type'] == 'meeting'" class="fa-solid fa-users"></i>
      <i *ngIf="activity.Fields['type'] == 'task'" class="fa-solid fa-timer"></i>
      <i *ngIf="activity.Fields['type'] == 'frist'" class="fa-solid fa-flag"></i>
      <i *ngIf="activity.Fields['type'] == 'email'" class="fa-solid fa-paper-plane"></i>
      <i *ngIf="activity.Fields['type'] == 'lunch'" class="fa-solid fa-fork-knife"></i>
      <i *ngIf="activity.Fields['type'] == 'presentation'" class="fa-solid fa-screen-users"></i>
      <i *ngIf="activity.Fields['type'] == 'file'" class="fa-solid fa-file"></i>
      &nbsp;{{activity.Description}}&nbsp;

      <a *ngIf="activity.Fields['type'] == 'file'" target="_blank" href="/v1.0/entity-attachments/{{_row.Id}}?file={{activity.Fields['to']}}&token={{profile.Token}}"><i class="fa-solid fa-download"></i></a>
    </h4></span><br/>
    <span class="card-text">{{activity.CreatedString}} {{activity.OwnerName}}</span>
  </div>
  <div class="card-footer" *ngIf="activity.Fields['note'].trim()!=''">
    <span class="card-text">{{activity.Fields['note']}}</span>
  </div>
  <div class="card-footer" *ngIf="activity.Fields['message'].trim()!=''">
    <span class="card-text">{{activity.Fields['message']}}</span>
  </div>
</div>-->
