import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FieldMeta, TableRow, Tenant} from "../api-classes/api-classes";
import {Options} from "select2";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";
import {v4 as uuidv4} from 'uuid';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete'
import {Options as GoogleOptions} from 'ngx-google-places-autocomplete/objects/options/options'
import {Address} from "ngx-google-places-autocomplete/objects/address";

@Component({
  selector: 'app-as-edit-form',
  templateUrl: './as-edit-form.component.html',
  styleUrls: ['./as-edit-form.component.css']
})
export class AsEditFormComponent implements OnInit {

  options: Options = {
    allowClear: true
  };

  googleOptions: GoogleOptions;


  tenant: Tenant = new Tenant();
  //_tableRow: TableRow;
  //_schema: FieldMeta[] = [];
  _filteredSchema: FieldMeta[] = [];
  _lazyLoadCount = 0;
  _dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  _revision = 0;

  formId = uuidv4();
  _isCreate = false;
  _reportedChanges = false;
  _parentTable = '';
  _parentRowId = '';
  @Output() hasChanges = new EventEmitter<boolean>();
  @Output() logoSet = new EventEmitter<File>();
  @Input() hasLogos = false;
  @Input() set isCreate(value: boolean) {
    this._isCreate = value;
    setTimeout(()=>this.processSchema(), 25);
  }
  @Input() tableRow: TableRow = new TableRow();
  @Input() schema: FieldMeta[];
  @Input() tableName(value: string) {
    this.tableRow.TableName = value;
    if (this.apiManagerService.cacheSchemas[value]) {
      this.schema = this.apiManagerService.cacheSchemas[value];
    }

    this.processSchema();
  }

  @Input() set parentTable(value: string) {
    this._parentTable = value;
    setTimeout(()=>this.processSchema(), 25);
  }

  @Input() set parentRowId(value: string) {
    this._parentRowId = value;
    setTimeout(()=>this.processSchema(), 25);
  }

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  reportChanges() {
    if (!this._reportedChanges) {
      this.hasChanges.emit(true);
      this._reportedChanges = true;
    }
  }

  handleFileInput(files: FileList) {
    if (files.length == null) {
      this.logoSet.emit(null)
    } else {
      this.logoSet.emit(files.item(0))
    }
  }

  public handleAddressChange(field: FieldMeta, address: Address) {
    this.reportChanges();
    setTimeout(()=>this.doHhandleAddressChange(this, field, address),25);
  }

  public doHhandleAddressChange(self: AsEditFormComponent,field: FieldMeta, address: Address) {
    let street_number = "";
    let street = "";
    let zip = "";
    let city = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
        {
          street_number = component.long_name;
          break;
        }

        case "route":
        {
          street = component.long_name;
          break;
        }

        case "postal_code":
        {
          zip = component.long_name;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

      }
    }

    Object.entries(this.schema).forEach(([key, meta]) => {
      switch(meta.FieldType) {
        case "google-result-street":
        case "google-search-street":
          self.tableRow.Fields[meta.Id] = street + ' ' + street_number;
          self.tableRow.Fields[meta.Id] = self.tableRow.Fields[meta.Id].trim();
          break;
        case "google-result-city":
        case "google-search-city":
          self.tableRow.Fields[meta.Id] = city;
          break;
        case "google-result-postalcode":
        case "google-search-postalcode":
          self.tableRow.Fields[meta.Id] = zip;
          break;
        case "google-result-name":
        case "google-search-name":
          self.tableRow.Fields[meta.Id] = address.name;
          break
        case "google-result-country":
        case "google-search-country":
          self.tableRow.Fields[meta.Id] = "DE";
          break
      }
    });

    setTimeout(() => {

      // @ts-ignore
      const input = document.getElementsByClassName('form_field_' + field.Id);
      if (input != null && input.length > 0) {
        // @ts-ignore
        input[0].value = self.tableRow.Fields[field.Id]
      }
    }, 250);
    // Do some stuff
  }

  setLink(linkType, linkedTable, linkField, linkId) {
    this.reportChanges();
    switch(linkType) {
      case 'linked':
      case 'linked1':
        this.tableRow.LinkedId1 = linkId;
        break;
      case 'linked2':
        this.tableRow.LinkedId2 = linkId;
        break;
      case 'linked3':
        this.tableRow.LinkedId3 = linkId;
        break;
      case 'linked4':
        this.tableRow.LinkedId4 = linkId;
        break;
    }
    for (let i = 0; i < this.apiManagerService.cacheRows[linkedTable].length; i++) {
      if (this.apiManagerService.cacheRows[linkedTable][i].Id == linkId) {
        this.tableRow.Fields[linkField] = this.apiManagerService.cacheRows[linkedTable][i].Description
      }
    }

    if (linkId == null || linkId == '-' || linkId == '') {
      this.tableRow.Fields[linkField] = '';
    }

  }

  processSchema() {
    let linkType = '';
    let linkField = '';
    if (this.tableRow != null && this.schema != null) {
      Object.entries(this.schema).forEach(
        ([string, meta]) => {
          if (meta.FieldType.startsWith("linked")) {
            if (meta.LinkedTable != null && meta.LinkedTable.trim() != "") {
              if (this.apiManagerService.cacheRows[meta.LinkedTable]) {
                if (meta.LinkedTable == this._parentTable && this._parentTable != '' && this._parentRowId != '') {
                  linkType = meta.FieldType
                  linkField = meta.Id;
                }
                let opts: Array<Select2OptionData> = [];
                const t1: Select2OptionData = {
                  id: "-",
                  text: '(kein/e)',
                }

                opts.push(t1)
                for (let i = 0; i < this.apiManagerService.cacheRows[meta.LinkedTable].length; i++) {
                  let desc: string = this.apiManagerService.cacheRows[meta.LinkedTable][i].Description;
                  if (desc == '') {
                    let keys = Object.keys(this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields);
                    for(let x = 0; x < keys.length; x++) {
                      if (desc == '' && (keys[x] == 'company' || keys[x] == 'companyname') && this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]] != null) {
                        desc = this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]];
                        break;
                      }
                    }

                    if (desc == '') {
                      for (let x = 0; x < keys.length; x++) {
                        if (desc == '' && keys[x] == 'lastname' && this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]] != null) {
                          desc = this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]];
                          break;
                        }
                      }
                    }
                  }
                  const tmp: Select2OptionData = {
                    id: this.apiManagerService.cacheRows[meta.LinkedTable][i].Id,
                    text: desc//this.apiManagerService.cacheRows[meta.LinkedTable][i].Description
                  }

                  opts.push(tmp)
                }

                this._dropOptions[meta.Id] = opts;
              } else {
                this._lazyLoadCount++;
                this.apiManagerService.getTableEntities(meta.LinkedTable).then((linked) => {
                  this.apiManagerService.cacheRows[meta.LinkedTable] = linked;
                  let opts: Array<Select2OptionData> = [];
                  const t1: Select2OptionData = {
                    id: "-",
                    text: '(kein/e)'
                  }

                  opts.push(t1)
                  for (let i = 0; i < linked.length; i++) {
                    const tmp: Select2OptionData = {
                      id: linked[i].Id,
                      text: linked[i].Description
                    }

                    opts.push(tmp)
                  }

                  this._dropOptions[meta.Id] = opts;
                  this._lazyLoadCount--;
                }).catch((e) => {
                  this._dropOptions[meta.Id] = [];
                  this._lazyLoadCount--;
                });
              }
            }
          }

          if (meta.FieldType == "dropdown") {
            let opts: Array<Select2OptionData> = [];
            if (meta.DropOptions != null) {
              for (let i = 0; i < meta.DropOptions.length; i++) {
                const tmp: Select2OptionData = {
                  id: meta.DropOptions[i].Id,
                  text: meta.DropOptions[i].Description
                }

                opts.push(tmp)
              }
            }

            this._dropOptions[meta.Id] = opts;
          }
        }
      );

      this.filterSchema();
      if (linkType != '' && linkField != '' && this._parentTable != '' && this._parentRowId != '' && this._isCreate) {
        this.setLink(linkType, this._parentTable, linkField, this._parentRowId);
      }

    } else {
      setTimeout(()=>this.processSchema(), 25);
    }
  }

  filterSchema() { // filter the schema fields to only show visible fields in the table
    this.tenant = this.apiManagerService.tenant;
    let schema = this.schema;
    schema.sort((a,b) => a.TableOrder - b.TableOrder);
    let countId = 0;
    for (let i = 0; i < schema.length; i++) {
      schema[i].TableOrder = countId;
      countId += 10;
    }

    this.schema = schema;
    let filtered: FieldMeta[] = [];
    for (let i = 0; i < schema.length; i++) {
      if (!schema[i].IsFormHidden) {
        if ((this._isCreate && !schema[i].IsCreateHidden) || (!this._isCreate && !schema[i].IsEditHidden)) {
          filtered.push(schema[i]);
        }
      }
    }

    this._filteredSchema = filtered;
  }

  constructor(private apiManagerService: ApiManagerService) { }

  ngOnInit(): void {
    this.googleOptions = new GoogleOptions();
    this.googleOptions.componentRestrictions ={ country: "de" };
    this.googleOptions.strictBounds = false;
    this.googleOptions.fields = ["address_components", "geometry", "icon", "name"];
    this.googleOptions.types = ["establishment"];

    setTimeout(()=>this.processSchema(), 25);
  }

}
