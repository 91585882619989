<div class="card">
  <div class="card-body">
    <h5 class="card-title">Kontakte</h5>
    <div style="display: flex; flex-wrap: wrap; margin-top: 5px;">
      <!--<a *ngFor="let item of dependencyRows" href="/crm/contacts/{{item.PublicId}}" target="_blank">{{item.Description}}<br/></a>-->
      <a *ngFor="let row of dependencyRows" href="/crm/contacts/{{row.PublicId}}" target="_blank" style="margin-top: 3px; margin-bottom: 3px; width: 100%;">
        <div style="display: flex;">
          <div class="avatar avatar-xs"  style="margin-right: 5px; margin-top: auto; margin-bottom: auto;">
                    <span class="avatar-text avatar-text-primary rounded-circle"  *ngIf="!row.HasLogo">
                      <span class="initial-wrap">
                        <span>{{row.LogoInitials}}</span>
                      </span>
                    </span>
            <img src="{{row.LogoUrl}}" alt="{{row.LogoInitials}}" *ngIf="row.HasLogo" class="avatar-img rounded-circle">
          </div>
          <div style="margin-top: auto; margin-bottom: auto; text-transform: none;">{{row.Description}}</div>
        </div>
      </a>
    </div>
  </div>
</div>
