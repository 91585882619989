import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
  FieldMeta,
  Page,
  PageAddItemRequestData,
  PageItem,
  PageItemMove,
  PageParentChildBundle,
  TableRow
} from "../../api-classes/api-classes";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiManagerService} from "../../api-manager/api-manager.service";

@Component({
  selector: 'app-as-renderer-card',
  templateUrl: './as-renderer-card.component.html',
  styleUrls: ['./as-renderer-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererCardComponent implements OnInit {

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  @Output() uploadFile = new EventEmitter<File>();
  @Output() buttonClicked = new EventEmitter<string>();
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Output() pageAddRequest = new EventEmitter<PageAddItemRequestData>();
  @Output() removeRequest = new EventEmitter<PageParentChildBundle>();
  @Input() sessionId = '';
  @Input() parentId = '';
  @Input() page: Page
  @Input() item: PageItem;
  @Input() row: TableRow;
  @Input() mode: number = 0;
  @Input() schema: FieldMeta[] = [];
  @Input() schemaResolver: { [name: string]: FieldMeta } = {}
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  move: PageItemMove;
  constructor(private modalService: NgbModal, private apiManagerService: ApiManagerService) { }

  ngOnInit(): void {

  }

  handleFileInput(file: File) {
    if (file != null) {
      this.uploadFile.emit(file)
    }
  }

  buttonClick(eventId) {
    this.buttonClicked.emit(eventId);
  }

  addItem(section: string, append: boolean) {
    const req = new PageAddItemRequestData();
    req.Parent = this.item;
    req.Append = append;
    req.Section = section;
    this.pageAddRequest.emit(req);
  }

  removeItem(item: PageItem) {
    const req = new PageParentChildBundle();
    req.Parent = this.item;
    req.Child = item;
    this.removeRequest.emit(req);
  }

  onRemoveRequest(req: PageParentChildBundle) {
    this.removeRequest.emit(req);
  }

  onPageAddRequest(req: PageAddItemRequestData) {
    this.pageAddRequest.emit(req);
  }

  processStartDrag(event, item: PageItem) {
    if (this.mode === -1) {
      this.move = new PageItemMove();
      this.move.ItemId = item.Id;
      this.move.SourceParentItemId = this.parentId;
    }

    return false;
  }

  processMove(req: PageItemMove) {
    if (req.ItemId !== this.item.Id) {
      this.moveRequest.emit(req);
    }
  }
}
