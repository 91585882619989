<div class="card">
  <div class="card-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs nav-sm nav-light mb-25">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="setTab(1)">Interne Notiz</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Externer Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendNote()">Übermitteln</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="setTab(3)">Anruf</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Rufnummer:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control"/>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Externer Kommentar:</label>
              <div class="col-sm-10">
                <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
              <div class="col-sm-10">
                <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="doCall()">Erfassen</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="setTab(4)">E-Mail</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">An:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Betreff:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['title']" (input)="_newAcitivity.Fields['title']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Nachricht:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>

                  <!--<textarea style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['message']" (input)="_newAcitivity.Fields['message']=$any($event.target).value;" class="form-control"></textarea>-->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendMail()">Senden</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink (click)="setTab(5)">WhatsApp&nbsp;<span class="badge badge-soft-info">Alpha</span></a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Rufnummer:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Nachricht:</label>
                <div class="col-sm-10">
                  <!--<div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>-->

                  <textarea style="margin: 2px; min-height: 150px;" type="text" [value]="_newAcitivity.Fields['message']" (input)="_newAcitivity.Fields['message']=$any($event.target).value;" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendWA()">Senden</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink (click)="setTab(6)">SMS</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Rufnummer:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['to']" (input)="_newAcitivity.Fields['to']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Nachricht:</label>
                <div class="col-sm-10">
                  <!--<div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['message']" (ngModelChange)="_newAcitivity.Fields['message']=$event"></div>-->

                  <textarea style="margin: 2px; min-height: 150px;" type="text" [value]="_newAcitivity.Fields['message']" (input)="_newAcitivity.Fields['message']=$any($event.target).value;" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendSMS()">Senden</button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="7">
        <a ngbNavLink (click)="setTab(7)">E-Postbrief&nbsp;<span class="badge badge-soft-info">Alpha</span></a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Betreff:</label>
                <div class="col-sm-10">
                  <input style="margin: 2px;" type="text" [value]="_newAcitivity.Fields['title']" (input)="_newAcitivity.Fields['title']=$any($event.target).value;" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Nachricht:</label>
                <div class="col-sm-10">
                  <textarea style="margin: 2px; min-height: 150px;" type="text" [value]="_newAcitivity.Fields['message']" (input)="_newAcitivity.Fields['message']=$any($event.target).value;" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Interner Kommentar:</label>
                <div class="col-sm-10">
                  <div [ngxSummernote]="summerConfig"  [ngModel]="_newAcitivity.Fields['note']" (ngModelChange)="_newAcitivity.Fields['note']=$event"></div>

                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-success" style="float: right" (click)="sendEPost()">Senden</button>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2" style="min-height: 60px;"></div>
  </div>
</div>


<div class="card">
  <div class="card-header">
    <ul class="nav nav-tabs nav-sm nav-light mb-25">
      <li class="nav-item">
        <a (click)="setActivityFilter('')" style="cursor: pointer;" class="nav-link {{_activityFilter==''?'active':''}}">Alle</a>
      </li>
      <!--<li class="nav-item">
        <a (click)="setActivityFilter('open')" style="cursor: pointer;" class="nav-link {{_activityFilter=='open'?'active':''}}">Offen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('closed')" style="cursor: pointer;" class="nav-link {{_activityFilter=='closed'?'active':''}}">Erledigt</a>
      </li>-->
      <li class="nav-item">
        <a (click)="setActivityFilter('call')" style="cursor: pointer;" class="nav-link {{_activityFilter=='call'?'active':''}}">Anrufe</a>
      </li>
      <!--<li class="nav-item">
        <a (click)="setActivityFilter('meeting')" style="cursor: pointer;" class="nav-link {{_activityFilter=='meeting'?'active':''}}">Meetings</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('task')" style="cursor: pointer;" class="nav-link {{_activityFilter=='task'?'active':''}}">Tasks</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('frist')" style="cursor: pointer;" class="nav-link {{_activityFilter=='frist'?'active':''}}">Fristen</a>
      </li>-->
      <li class="nav-item">
        <a (click)="setActivityFilter('email')" style="cursor: pointer;" class="nav-link {{_activityFilter=='email'?'active':''}}">E-Mails</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('whatsapp')" style="cursor: pointer;" class="nav-link {{_activityFilter=='whatsapp'?'active':''}}">WhatsApp</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('sms')" style="cursor: pointer;" class="nav-link {{_activityFilter=='sms'?'active':''}}">SMS</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('epost')" style="cursor: pointer;" class="nav-link {{_activityFilter=='epost'?'active':''}}">E-Postbrief</a>
      </li>
      <!--<li class="nav-item">
        <a (click)="setActivityFilter('lunch')" style="cursor: pointer;" class="nav-link {{_activityFilter=='lunch'?'active':''}}">Mittagessen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('presentation')" style="cursor: pointer;" class="nav-link {{_activityFilter=='presentation'?'active':''}}">Präsentationen</a>
      </li>-->
      <li class="nav-item">
        <a (click)="setActivityFilter('internal')" style="cursor: pointer;" class="nav-link {{_activityFilter=='internal'?'active':''}}">Notizen</a>
      </li>
      <li class="nav-item">
        <a (click)="setActivityFilter('file')" style="cursor: pointer;" class="nav-link {{_activityFilter=='presentation'?'active':''}}">Anhänge</a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div class="user-activity">
      <div class="media" *ngFor="let activity of _activitiesFiltered">
        <div class="media-img-wrap">

          <div class="avatar avatar-sm">
            <span style="width: unset;" title=" {{activity.Fields['complete']=='true'||activity.Fields['type']=='email'||activity.Fields['type']=='file'?'Erledigt':'Offen'}}" class="avatar-text {{activity.Fields['complete']=='true'||activity.Fields['type']=='email'||activity.Fields['type']=='file'?'avatar-text-primary':'avatar-text-warning'}} rounded-circle">
              <span class="initial-wrap" style="text-align: center; line-height: 3;">
                <!--<img src="dist/img/avatar4.jpg" alt="user" class="avatar-img rounded-circle">-->
                <i *ngIf="activity.Fields['type'] == 'email'" class="fa-solid fa-envelope avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'epost'" class="fa-solid fa-envelope-circle-check avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'whatsapp'" class="fa-solid fa-message avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'sms'" class="fa-solid fa-message-sms avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'meeting'" class="fa-solid fa-users avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'task'" class="fa-solid fa-timer avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'frist'" class="fa-solid fa-flag avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'email'" class="fa-solid fa-paper-plane avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'lunch'" class="fa-solid fa-fork-knife avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'presentation'" class="fa-solid fa-screen-users avatar-img rounded-circle "></i>
                <i *ngIf="activity.Fields['type'] == 'internal'" class="fa-solid fa-message avatar-img rounded-circle"></i>
                <i *ngIf="activity.Fields['type'] == 'file'" class="fa-solid fa-file avatar-img rounded-circle"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="media-body">
          <div>
            <span class="d-block mb-5">
              <span class="font-weight-500 text-dark">{{activity.OwnerName}}</span>
              <span class="pl-5" style="display: flex; padding-left: 0 !important;">
                <div class="{{activity.Fields['type']!='file'?'':''}}">{{activity.Description}}&nbsp;</div>
                <a *ngIf="activity.Fields['type'] == 'file'" target="_blank" href="/v1.0/entity-attachments/{{_row.Id}}?file={{activity.Fields['to']}}&token={{profile.Token}}"><i class="fa-solid fa-download"></i></a>
              </span>
              <div>
                <span class="card-text" [innerHtml]="transform(activity.Fields['note'])"></span>
              </div>
              <div>
                <span class="card-text" [innerHtml]="transform(activity.Fields['message'])"></span>
              </div>
            </span>
            <span class="d-block font-13">{{activity.CreatedString}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
