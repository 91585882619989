import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CanvasReadyInfo, TableRow} from "../../api-classes/api-classes";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import html2canvas from "html2canvas";
import {MatMenuTrigger} from "@angular/material/menu";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-as-kanban-card',
  templateUrl: './as-kanban-card.component.html',
  styleUrls: ['./as-kanban-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsKanbanCardComponent implements OnInit {


  _lastTheme = ''

  @Input() newTab = false;
  @Input() detailsPageLink = '';
  @Input() requestTheme(value: string) {
    if (value != '' && value != this._lastTheme) {
      this._lastTheme = value;
      this.setCardTheme(value);
    }
  }
  cardCss = 'border-dark';
  cardBodyCss = '';
  @Input() cardMode = 'default';
  @Input() theme = 'dg';
  @Output() editRow = new EventEmitter<string>();
  @Output() canvasReady = new EventEmitter<CanvasReadyInfo>();
  @Input() tableRow: TableRow = new TableRow();
  isDark = false;
  starFavorite = '/assets/images/star-gray.svg';
  starNoFavorite = '/assets/images/star-lightgray.svg';
  constructor(private apiManagerService: ApiManagerService, private router: Router, private route: ActivatedRoute) { }
  canvasData: HTMLCanvasElement;
  canvasGenerating = false;

  ngOnInit(): void {
    const profile = this.apiManagerService.getProfile();
    this.isDark = profile.Theme === 'dark';
    if (this.isDark) {
      this.starNoFavorite = '/assets/images/star-gray.svg';
      this.starFavorite = '/assets/images/star-lightgray.svg';
    }
    /*if (this.tableRow.Id != null && this.tableRow.Id !== '') {
      this.setApplication(this.tableRow.Id);
    }*/
    setTimeout(() => {
      this.processCardTheme();
    }, 25);
  }

  mouseOver() {
    return;
    if (!this.canvasGenerating) {
      this.canvasGenerating = true;
      const card = document.getElementById('card_' + this.tableRow.Id);
      html2canvas(document.body, {width: 318, height: 145}).then((canvas) => {
        const info = new CanvasReadyInfo();
        info.ApplicationId = this.tableRow.Id;
        info.Canvas = canvas;
        this.canvasData = canvas;
        this.canvasReady.emit(info);
      });
    }
  }

  openDetails() {

  }
  ngAfterViewChecked(): void {
    //this.canvasGenerating = false;
    //if (this.tableRow.Id !== '') {
    //  let card = document.getElementById('card_' + this.tableRow.Id);
    //  html2canvas(document.body).then((canvas) => {
    //    const info = new CanvasReadyInfo();
    //    info.ApplicationId = this.tableRow.Id;
    //    info.Canvas = canvas;
    //    this.canvasReady.emit(info);
    //    //document.body.appendChild(canvas);
    //  });
    //}
  }

  openCard(): boolean {
    //this.editRow.emit(this.tableRow.Id);
    //this.openDetails();
    return false;
  }

  /*setFavorite(enable): boolean {
    this.application.IsFavorite = enable;
    this.apiManagerService.setFavorite(this.tableRow.Id, enable).then((res) => {
      console.log('Favorite updated');
    });
    return false;
  }

  setMarked(enable): boolean {
    this.application.IsMarked = enable;
    this.apiManagerService.setMarked(this.tableRow.Id, enable).then((res) => {
      console.log('Marked updated');
    });
    return false;
  }*/

  /*setApplication(appId) {
    for (let i = 0; i < this.apiManagerService.kanbanBundle.Categories.length; i++) {
      const catId = this.apiManagerService.kanbanBundle.Categories[i].Id;
      for (let x = 0; x < this.apiManagerService.kanbanBundle.Applications[catId].length; x++) {
        const app = this.apiManagerService.kanbanBundle.Applications[catId][x];
        if (app.Id === appId) {
          this.application = app;
        }
      }
    }
  }*/

  setCardTheme(theme) {
    this.tableRow.CardClasses = theme;
    this.processCardTheme();
  }

  processCardTheme() {
    switch(this.tableRow.CardClasses) {
      case "bg-primary":
        this.cardCss = 'bg-primary';
        this.cardBodyCss = 'text-white';
        break;
      case "border-primary":
        this.cardCss = 'border-primary';
        this.cardBodyCss = 'text-primary';
        break;
      case "bg-warning":
        this.cardCss = 'bg-warning';
        this.cardBodyCss = 'text-white';
        break;
      case "border-warning":
        this.cardCss = 'border-warning';
        this.cardBodyCss = 'text-warning';
        break;
      case "bg-danger":
        this.cardCss = 'bg-danger';
        this.cardBodyCss = 'text-white';
        break;
      case "border-danger":
        this.cardCss = 'border-danger';
        this.cardBodyCss = 'text-danger';
        break;
      case "bg-dark":
        this.cardCss = 'bg-dark';
        this.cardBodyCss = 'text-white';
        break;
      case "border-dark":
        this.cardCss = 'border-dark';
        this.cardBodyCss = '';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
     this.processCardTheme();

    //if (changes.applicationId.currentValue !== changes.applicationId.previousValue) {
      // this.setApplication(changes.applicationId.currentValue);
      //this.canvasGenerating = false;
    //}
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }

  dragStart(ev) {
    ev.dataTransfer.setData('text', this.tableRow.Id);
  }



}
