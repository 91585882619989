import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthUser, Feature} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";

@Component({
  selector: 'app-as-feature-shop-dg',
  templateUrl: './as-feature-shop-dg.component.html',
  styleUrls: ['./as-feature-shop-dg.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class AsFeatureShopDgComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private router: Router, private toast: AsToastService) { }

  features: Feature[] = [];
  profile: AuthUser
  loading = true;
  ngOnInit(): void {
    this.profile = this.apiManagerService.getProfile();
    if (!this.profile.IsAdmin) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.loadShop();
    }
  }

  order(featureId) {
    this.loading = true;
    this.apiManagerService.orderFeature(featureId).then((e) => {
      this.apiManagerService.loadTenant()
      this.loadShop(true);
      this.toast.showSuccessToastMessage('Bestellung ausgeführt', null,'top-center-fullwidth' )
    }).catch((e) => {
      this.loadShop(true);
    });
  }

  cancel(featureId) {
    this.loading = true;
    this.apiManagerService.cancelFeature(featureId).then((e) => {

      this.apiManagerService.loadTenant()
      this.toast.showSuccessToastMessage('Kündigung ausgeführt', null,'top-center-fullwidth' )
      this.loadShop(true);
    }).catch((e) => {
      this.loadShop(true);
    });
  }


  loadShop(force = false) {
    if (!force &&this.apiManagerService.cacheFeatures != null && this.apiManagerService.cacheFeatures.length > 0) {
      this.features = this.apiManagerService.cacheFeatures;
      this.loading = false;
    } else {
      this.apiManagerService.getFeatureShop().then((features) => {
        if (features != null) {
          this.features = features;
        }

        this.loading = false;
      });
    }
  }

}
