import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Options} from "select2";
import {
  AuthUser,
  FieldMeta,
  Page,
  PageItem,
  PageItemMove,
  TableAttachment,
  TableRow, Tenant
} from "../../api-classes/api-classes";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {CalendarOptions} from "@fullcalendar/core";
import deLocale from "@fullcalendar/core/locales/de";
import {SummernoteOptions} from "ngx-summernote/lib/summernote-options";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {AsToastService} from "../../as-toast.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-as-renderer-communication',
  templateUrl: './as-renderer-communication.component.html',
  styleUrls: ['./as-renderer-communication.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererCommunicationComponent implements OnInit {

  options: Options = {
    allowClear: true
  };

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  _noteItem: PageItem;
  _newAcitivity = new TableRow();
  _activitySchema: FieldMeta[] = [];
  _row: TableRow;
  _activityFilter = '';
  _files: TableAttachment[] = [];
  _activities: TableRow[] = [];
  _activitiesFiltered: TableRow[] = [];
  _activityLinkTarget = '';
  _activityLinkId = '';
  _page: Page;
  profile: AuthUser;
  _isEditActivity = false;

  //dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  dropOptionsUser: Array<Select2OptionData> = [];
  dropOptionsLink1: Array<Select2OptionData> = [];
  dropOptionsLink2: Array<Select2OptionData> = [];
  dropOptionsLink3: Array<Select2OptionData> = [];
  dropOptionsLink4: Array<Select2OptionData> = [];
  dropOptionsLinkMerged: Array<Select2OptionData> = [];
  mergedLinkValue = '-';
  showLink1 = false;
  showLink2 = false;
  showLink3 = false;
  showLink4 = false;
  showLinkMerged = false;
  mergedMode = 0;
  mergedName = '';
  //fieldIdLink1 = '';
  //fieldIdLink2 = '';
  //fieldIdLink3 = '';
  //fieldIdLink4 = '';
  nameLink1 = '';
  nameLink2 = '';
  nameLink3 = '';
  nameLink4 = '';
  @Output() uploadFile = new EventEmitter<File>();
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Input() sessionId = '';
  @Input() parentId = ''
  @Input() set page(value: Page) {
    this._page = value;
    this.loadDependencies()
    this.initNewActivity();
  }

  @Input() set item(value: PageItem){
    this._noteItem = JSON.parse(JSON.stringify(value))
    this._noteItem.Options = 'note';
    this._noteItem.Type = 'rte';
    this.initNewActivity()
  }
  @Input() set row(value: TableRow) {
    this._row = value;
    this.initNewActivity()
    this.loadActivities();
  }

  @Input() mode: number = 0;
  @Input() schema: FieldMeta[] = [];
  @Input() schemaResolver: { [name: string]: FieldMeta } = {}
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  @ViewChild(FullCalendarComponent) calendarComponent: FullCalendarComponent;
  //@Output() crendered = new EventEmitter();
  calendarOptions: CalendarOptions = {
    //eventChange: this.calendarChange.bind(this),
    //eventClick: this.calendarEventClick.bind(this),
    initialView: 'timeGridDay',
    locales: [deLocale],
    locale: 'de',
    height: 622,
    allDaySlot: false,
    eventDurationEditable: false, // Disable Resize
    eventStartEditable: false, // disable dreage drop
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
  };

  summerConfig: SummernoteOptions = {
    placeholder: '',
    tabsize: 2,
    //height: 150,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['para', ['ul', 'ol']],
      ['insert', ['link']]
    ],
    airMode: false,
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  _lazyLoadCount = 0;
  tenant: Tenant;
  move: PageItemMove;
  activeTab = 1;
  clock: any = null;
  constructor(private apiManagerService: ApiManagerService, private toast: AsToastService, private sanitizer: DomSanitizer) {
  }

  loadDependencies() {
    if (this._page != null && this._page.TableName != null && this._page.TableName != '' && this._activitySchema != null && this._activitySchema.length > 0) {
      if (this.apiManagerService.cacheUsers == null || this.apiManagerService.cacheUsers.length == 0) {
        this.apiManagerService.getUsers().then((users) => {
          this.dropOptionsUser = [];
          Object.entries(users).forEach(([key, user]) => {
            this.dropOptionsUser.push({
              id: user.Id,
              text: user.DisplayName,
            });
          });
        });
      } else {
        this.dropOptionsUser = [];
        Object.entries(this.apiManagerService.cacheUsers).forEach(([key, user]) => {
          this.dropOptionsUser.push({
            id: user.Id,
            text: user.DisplayName,
          });
        });
      }

      this.showLink1 = false;
      this.showLink2 = false;
      this.showLink3 = false;
      this.showLink4 = false;
      this.mergedMode = 0;
      let foundCases = false;
      Object.entries(this._activitySchema).forEach(([key, field]) => {
        if (field.FieldType.startsWith('link')) {
          if (field.LinkedTable == 'cases' && field.FieldType == 'linked3') {
            //oundCases = true;
          }

        }
      });

      if (foundCases) {
        this.mergedMode = 1;
        this.mergedLinkValue = '-';
        this.mergedName = 'Support Case';
        this.dropOptionsLinkMerged = [];
        this.dropOptionsLinkMerged.push({
          id: "-",
          text: "(kein/e)",
        });

        this.showLinkMerged = true;
      } else{
        this.showLinkMerged = false;
        this.mergedMode = 0;
      }

      Object.entries(this._activitySchema).forEach(([key, field]) => {
        if (field.FieldType.startsWith('link')) {
          if (field.LinkedTable != null && field.LinkedTable !== '') {
            if (this.apiManagerService.cacheRows[field.LinkedTable] == null || this.apiManagerService.cacheRows[field.LinkedTable].length == 0) {
              this.apiManagerService.getTableEntities(field.LinkedTable).then((entries) => {
                this.processDependency(field.Id, field.FieldType, field.LinkedTable, entries);
              });
            } else {
              this.processDependency(field.Id, field.FieldType, field.LinkedTable, this.apiManagerService.cacheRows[field.LinkedTable]);
            }
          }
        }
      });

    }
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getDisplayName(tableName: string) {
    switch(tableName) {
      case 'cases':
        return 'Case';
      case 'deals':
        return 'Deal';
      case 'leads':
        return 'Verkaufschance';
      case 'contacts':
        return 'Kontakt';
      case 'companies':
        return 'Unternehmen';
      default:
        return this.capitalizeFirstLetter(tableName);
    }
  }

  debugEvt(evt: EventTarget) {

  }

  processDependency(fieldId, linkId: string, tableName: string, rows: TableRow[]){
    switch(linkId) {
      case 'linked':
      case 'linked1':
        this.dropOptionsLink1 = [];
        this.dropOptionsLink1.push({
          id: "-",
          text: "(kein/e)",
        });
        Object.entries(rows).forEach(([key, row]) => {
          this.dropOptionsLink1.push({
            id: row.Id,
            text: row.Description,
          });
        });

        //this.fieldIdLink1 = fieldId;
        this.nameLink1 = this.getDisplayName(tableName);
        this.showLink1 = true;
        break;
      case 'linked2':
        this.dropOptionsLink2 = [];
        this.dropOptionsLink2.push({
          id: "-",
          text: "(kein/e)",
        });
        Object.entries(rows).forEach(([key, row]) => {
          this.dropOptionsLink2.push({
            id: row.Id,
            text: row.Description,
          });
        });

        //this.fieldIdLink2 = fieldId;
        this.nameLink2 = this.getDisplayName(tableName);
        this.showLink2 = true;
        break;
      case 'linked3':
        if (this.mergedMode == 1) {
          Object.entries(rows).forEach(([key, row]) => {
            this.dropOptionsLinkMerged.push({
              id: row.Id,
              text: 'Case: ' + row.Description,
            });

            if (row.Id == this._newAcitivity.LinkedId3) {
              this.mergedLinkValue = row.Id;
            }
          });
        }else {

          this.dropOptionsLink3 = [];
          this.dropOptionsLink3.push({
            id: "-",
            text: "(kein/e)",
          });
          Object.entries(rows).forEach(([key, row]) => {
            this.dropOptionsLink3.push({
              id: row.Id,
              text: row.Description,
            });
          });

          //this.fieldIdLink3 = fieldId;
          this.nameLink3 = this.getDisplayName(tableName);
          this.showLink3 = true;
        }
        break;
      case 'linked4':
        if (this.mergedMode == 1) {
          Object.entries(rows).forEach(([key, row]) => {
            this.dropOptionsLinkMerged.push({
              id:  row.Id,
              text: 'Verkaufschance: ' + row.Description,
            });

            if (row.Id == this._newAcitivity.LinkedId4) {
              this.mergedLinkValue = row.Id;
            }
          });
        }else {
          this.dropOptionsLink4 = [];
          this.dropOptionsLink4.push({
            id: "-",
            text: "(kein/e)",
          });
          Object.entries(rows).forEach(([key, row]) => {
            this.dropOptionsLink4.push({
              id: row.Id,
              text: row.Description,
            });
          });

          //this.fieldIdLink4 = fieldId;
          this.nameLink4 = this.getDisplayName(tableName);
          this.showLink4 = true;
        }
        break;
    }
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }

  setActivityFilter(newFilter: string) {
    this._activityFilter = newFilter;
    this.filterActivities();
  }

  processDates() {
    if (this.activeTab == 2 && this._newAcitivity != null) {
      try {
        const api = this.calendarComponent.getApi();
        api.removeAllEvents();
        const today = this.getDateToday();
        if (this._newAcitivity.Fields['start'] != '') {
          if (this._newAcitivity.Fields['end']) {
            const event = {
              id: this._newAcitivity.Id,
              title: this._newAcitivity.Fields['title'],
              start: new Date((Date.parse(this._newAcitivity.Fields['start']))),
              end:  new Date((Date.parse(this._newAcitivity.Fields['end']))),
              editable: false,
              startEditable: false,
              durationEditable: false,
              overlap: true,
              allDay: false,
              //backgroundColor: eventColor,
              //borderColor: eventColor
            };
            api.addEvent(event);
          } else {
            const event = {
              id: this._newAcitivity.Id,
              title: this._newAcitivity.Fields['title'],
              start: new Date((Date.parse(this._newAcitivity.Fields['start']))),
              end: new Date((Date.parse(this._newAcitivity.Fields['start']))),
              editable: false,
              startEditable: false,
              durationEditable: false,
              overlap: true,
              allDay: false,
              //backgroundColor: eventColor,
              //borderColor: eventColor
            };
            api.addEvent(event);
          }
        }

        const now = new Date();
        const evtNow = {
          id: this._newAcitivity.Id,
          title: this._newAcitivity.Fields['title'],
          start: now,
          end: this.addMinutes(now,1),
          editable: false,
          startEditable: false,
          durationEditable: false,
          overlap: true,
          allDay: false,
          backgroundColor: 'red',
          borderColor: 'red',
          textColor: 'red',
          classNames: ['event-current-time']
        };
        api.addEvent(evtNow);

      } catch (e) {
        //
      }
    }
  }

  addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes*60000);
  }

  doCall() {
    if (this._newAcitivity.Fields['to'].trim() == '') {
      this.toast.showWarningToastMessage('Telefonnummer ist pflicht');
      return;
    }

    this._newAcitivity.Fields['title'] = "Ausgehender Anruf " + this._newAcitivity.Fields['to'];
    this._newAcitivity.Fields['type'] = 'call';
    this._newAcitivity.Fields['complete'] = 'true';
    const tmp = this._newAcitivity.Fields['to'];
    this.saveActivity();
    location.href = "tel:" + tmp;
  }

  setTab(tabId: number) {
    this._isEditActivity = false;
    this.activeTab = tabId;
    this._newAcitivity = this.createNewActivityObject();
    this.mergedLinkValue = '-';
    if (this._newAcitivity == null) {
      return;
    }

    if (tabId == 2) {
      setTimeout(() => {
        try {
          const api = this.calendarComponent.getApi();
          api.render();

          //this.loadCalendar();
        } catch (e) {
          //
        }
      }, 100);
      setTimeout(() => {
        try {
          const api = this.calendarComponent.getApi();
          api.render();
          this.processDates();
          //this.loadCalendar();
        } catch (e) {
          //
        }
      }, 500);
    }

    if (tabId == 3) {
      this._newAcitivity.Fields['to'] = '';//this._row.Fields['phone'];
      if (this._newAcitivity.Fields['to'].trim() == '') {
        this._newAcitivity.Fields['to'] = '';//this._row.Fields['mobile'];
      }
    } else {
      this._newAcitivity.Fields['to'] = '';//this._row.Fields['email'];
    }
  }

  ngAfterViewChecked() {

  }

  filterActivities() {
    this.profile = this.apiManagerService.getProfile();
    let mergedActivities: TableRow[] = JSON.parse(JSON.stringify(this._activities));
    Object.entries(this._files).forEach(([key, file]) => {
      const act = this.createNewActivityObject();
      if (act != null) {
        act.Id = file.Id;
        act.Fields['type'] = 'file';
        act.Fields['title'] = file.Filename
        act.Description = file.Filename
        act.Fields['to'] = file.Hash;
        act.Fields['complete'] = 'true';
        act.OwnerName = file.OwnerName;
        act.OwnerId = file.OwnerId;
        act.Created = file.UploadTime
        act.CreatedString = file.UploadTimeString
        act.CreatedDate = file.UploadTimeDate
        mergedActivities.push(act);
      }
    });
    if (this._activityFilter == '') {
      this._activitiesFiltered = mergedActivities;
    } else {
      this._activitiesFiltered = [];
      Object.entries(mergedActivities).forEach(([key, row]) => {
        if (this._activityFilter == 'open') {
          if (row.Fields['complete'] != 'true' && row.Fields['type'] != 'file' && row.Fields['type'] != 'email') {
            this._activitiesFiltered.push(row)
          }

        } else if (this._activityFilter == 'closed') {
          if (row.Fields['complete'] == 'true' || row.Fields['type'] == 'file' || row.Fields['type'] == 'email') {
            this._activitiesFiltered.push(row)
          }
        } else {
          if (row.Fields['type'] == this._activityFilter) {
            this._activitiesFiltered.push(row)
          }
        }

      });
    }

    Object.entries(this._activitiesFiltered).forEach(([key, row]) => {
      row.CreatedDate = new Date(Date.parse(row.Created))
      if (row.Fields['start'] != '') {
        try {
          row.CreatedDate = new Date(Date.parse(row.Fields['start']))
        } catch(e) {
          //
        }
      }
      row.CreatedString = this.apiManagerService.formatDate2(row.CreatedDate, true);
    });
    this._activitiesFiltered.sort((a, b) => (a.CreatedDate > b.CreatedDate) ? -1 : (a.CreatedDate === b.CreatedDate) ? 0 : 1);
  }

  getDateToday(): string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  loadActivities() {
    if (this._row != null) {
      this.apiManagerService.getTableRowDependencies(this._row.Id, 'case-activities').then((rows) => {
        if (rows != null) {
          Object.entries(rows).forEach(([key, row]) => {
            if (row.Created != null && row.Created.trim() != '') {
              row.CreatedDate = new Date(Date.parse(row.Created));
              row.CreatedString = this.apiManagerService.formatDate2(row.CreatedDate, true);
            }
          });

          rows.sort((a, b) => (a.CreatedDate > b.CreatedDate) ? -1 : (a.CreatedDate === b.CreatedDate) ? 0 : 1);
          this._activities = rows;
        } else {
          this._activities = [];
        }
        this.filterActivities();
      });

      this.apiManagerService.getTableEntityAttachments(this._row.Id).then((rows) => {
        if (rows != null){
          Object.entries(rows).forEach(([key, row]) => {
            if (row.UploadTime != null && row.UploadTime.trim() != '') {
              row.UploadTimeDate = new Date(Date.parse(row.UploadTime));
              row.UploadTimeString = this.apiManagerService.formatDate2(row.UploadTimeDate, true);
            }});

          rows.sort((a, b) => (a.UploadTimeDate > b.UploadTimeDate) ? -1 : (a.UploadTimeDate === b.UploadTimeDate) ? 0 : 1);
          this._files = rows;
        } else {
          this.filterActivities();
        }
        this.filterActivities();
      });
    }
  }

  handleFileInput(files: FileList) {
    if (files.length != null) {
      this.uploadFile.emit(files.item(0));
      setTimeout(() => {
        this.loadActivities();
      }, 1000);
      setTimeout(() => {
        this.loadActivities();
      }, 5000);
      setTimeout(() => {
        this.loadActivities();
      }, 15000);
    }
  }

  createNewActivityObject(): TableRow {
    if (this._activitySchema.length > 0 && this._page != null && this._row != null) {
      const newAcitivity = new TableRow();
      try {
        let found = false;
        for (let i = 0; i < this._activitySchema.length; i++) {
          if (this._activitySchema[i].FieldType.startsWith('linked')) {
            if (this._activitySchema[i].LinkedTable == this._page.TableName) {
              this._activityLinkTarget = this._activitySchema[i].FieldType;
              this._activityLinkId = this._activitySchema[i].Id;
              found = true;
            }
          }
        }

        if (!found) {
          return;
        }

        newAcitivity.Fields[this._activityLinkId] = this._row.Description;
        switch (this, this._activityLinkTarget) {
          case 'linked1':
            newAcitivity.LinkedId1 = this._row.Id;
            break;
          case 'linked2':
            newAcitivity.LinkedId2 = this._row.Id;
            break;
          case 'linked3':
            newAcitivity.LinkedId3 = this._row.Id;
            break;
          case 'linked4':
            newAcitivity.LinkedId4 = this._row.Id;
            break;
        }

        newAcitivity.OwnerId = this.profile.Id;
        newAcitivity.Fields['type'] = 'call';
        newAcitivity.Fields['title'] = '';
        newAcitivity.Fields['location'] = '';
        newAcitivity.Fields['note'] = '';
        newAcitivity.Fields['details'] = '';
        newAcitivity.Fields['to'] = '';//this._row.Fields['email'];
        newAcitivity.Fields['message'] = '';
        newAcitivity.Fields['start'] = '';
        newAcitivity.Fields['end'] = '';
        newAcitivity.Fields['location'] = '';
        newAcitivity.Fields['freebusy'] = 'busy';
        newAcitivity.Fields['complete'] = 'false';
        newAcitivity.TableName = 'case-activities';
        newAcitivity.TenantId = this._row.TenantId;
        return newAcitivity;
      } catch (e) {
      }

    }

    return null;
  }

  initNewActivity() {
    if (this._activitySchema.length > 0 && this._page != null && this._row != null) {
      this._newAcitivity = this.createNewActivityObject();
      this.mergedLinkValue = '-';
    }
  }

  sendMail() {
    this._newAcitivity.Fields['type'] = 'email'
    this._newAcitivity.Fields['complete'] = 'true';
    this.saveActivity();
  }


  sendEPost() {
    this._newAcitivity.Fields['type'] = 'epost'
    this._newAcitivity.Fields['complete'] = 'true';
    this.saveActivity();
  }

  sendWA() {
    this._newAcitivity.Fields['type'] = 'whatsapp';
    this._newAcitivity.Fields['title'] = "WhatsApp " + this._newAcitivity.Fields['to'];
    //this._newAcitivity.Fields['title'] = 'WhatsApp';
    this._newAcitivity.Fields['complete'] = 'true';
  }

  sendSMS() {
    this._newAcitivity.Fields['type'] = 'sms';
    this._newAcitivity.Fields['title'] = "SMS an " + this._newAcitivity.Fields['to'];
    //this._newAcitivity.Fields['title'] = 'WhatsApp';
    this._newAcitivity.Fields['complete'] = 'true';
  }

  sendNote() {
    this._newAcitivity.Fields['type'] = 'internal';
    this._newAcitivity.Fields['title'] = 'Interne Notiz'
    this._newAcitivity.Fields['complete'] = 'true';
    this.saveActivity();
  }

  editActivity(id) {
    this.setTab(2);
    for(let i = 0; i < this._activities.length; i++) {
      if (this._activities[i].Id == id) {
        if (this._activities[i].Fields['type'] != 'file') {
          this._isEditActivity = true;
          this._newAcitivity = this._activities[i];
          if (this.mergedMode == 1) {
            if (this._newAcitivity.LinkedId3 != null && this._newAcitivity.LinkedId3 != '') {
              this.mergedLinkValue = this._newAcitivity.LinkedId3;
            } else if (this._newAcitivity.LinkedId4 != null && this._newAcitivity.LinkedId4 != '') {
              this.mergedLinkValue = this._newAcitivity.LinkedId4;
            }
          }
        }
      }
    }
  }

  saveActivity() {
    if (this._newAcitivity.Fields['title'].trim() == '') {
      this.toast.showWarningToastMessage('Betreff ist pflicht');
      return;
    }

    if (this._newAcitivity.LinkedId1 == '-' || this._newAcitivity.LinkedId1 == ''){
      this._newAcitivity.LinkedId1 = null;
    }

    if (this._newAcitivity.LinkedId2 == '-' || this._newAcitivity.LinkedId2 == ''){
      this._newAcitivity.LinkedId2 = null;
    }

    if (this.mergedMode == 1) {
      this._newAcitivity.LinkedId3 = null;
      this._newAcitivity.LinkedId4 = null;
      Object.entries(this.apiManagerService.cacheRows['cases']).forEach(([key, row]) => {
        if (row.Id == this.mergedLinkValue) {
          this._newAcitivity.LinkedId3 = row.Id;
        }
      });
    }

    if (this._newAcitivity.LinkedId3 == '-' || this._newAcitivity.LinkedId3 == ''){
      this._newAcitivity.LinkedId3 = null;
    }

    if (this._newAcitivity.LinkedId4 == '-' || this._newAcitivity.LinkedId4 == ''){
      this._newAcitivity.LinkedId4 = null;
    }

    if (this._newAcitivity.LinkedId1 == null && this._newAcitivity.LinkedId2 == null && this._newAcitivity.LinkedId3 == null && this._newAcitivity.LinkedId4 == null ) {
      this.toast.showWarningToastMessage('Mindestens eine Zuweisung ist notwendig');
      return;
    }

    this.apiManagerService.setTableEntity(this._newAcitivity).then((e) => {
      if (this._isEditActivity) {
        this.toast.showInfoToastMessage('Aktivität aktualisiert');
      }

      this.initNewActivity();
      this.setTab(this.activeTab);
      this.loadActivities();
    }).catch((e) => {
      console.log(e);
      this.toast.showErrorToastMessage('Unbekannter fehler');
    })
  }

  switchActType(newType: string) {
    this._newAcitivity.Fields['type'] = newType;
  }

  ngOnInit(): void {
    this.apiManagerService.getTableSchema('case-activities').then((schema) => {
      this._activitySchema = schema;
      this.initNewActivity();
      this.loadDependencies();
      this.filterActivities();

    });
    this.clock = setInterval(() => this.processDates(), 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.clock);
  }
}
