import { Component, OnInit } from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {AuthUser, Feature} from "../api-classes/api-classes";

@Component({
  selector: 'app-as-app-shop-dg',
  templateUrl: './as-app-shop-dg.component.html',
  styleUrls: ['./as-app-shop-dg.component.css']
})
export class AsAppShopDgComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private router: Router, private toast: AsToastService) { }

  features: Feature[] = [];
  profile: AuthUser
  loading = true;
  ngOnInit(): void {
    this.profile = this.apiManagerService.getProfile();
    if (!this.profile.IsAdmin) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.loadShop();
    }
  }

  order(featureId) {
    this.loading = true;
    this.apiManagerService.orderFeature(featureId).then((e) => {
      this.apiManagerService.loadTenant()
      this.loadShop(true);
      this.toast.showSuccessToastMessage('Bestellung ausgeführt', null,'top-center-fullwidth' )
    }).catch((e) => {
      this.loadShop(true);
    });
  }

  cancel(featureId) {
    this.loading = true;
    this.apiManagerService.cancelFeature(featureId).then((e) => {

      this.apiManagerService.loadTenant()
      this.toast.showSuccessToastMessage('Kündigung ausgeführt', null,'top-center-fullwidth' )
      this.loadShop(true);
    }).catch((e) => {
      this.loadShop(true);
    });
  }


  loadShop(force = false) {
    if (!force &&this.apiManagerService.cacheApps != null && this.apiManagerService.cacheApps.length > 0) {
      this.features = this.apiManagerService.cacheApps;
      this.loading = false;
    } else {
      this.apiManagerService.getAppShop().then((features) => {
        if (features != null) {
          this.features = features;
        }

        this.loading = false;
      });
    }
  }

}
