import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {Tenant} from "../api-classes/api-classes";
import {AsToastService} from "../as-toast.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-settings-common',
  templateUrl: './settings-common.component.html',
  styleUrls: ['./settings-common.component.css']
})
export class SettingsCommonComponent implements OnInit, OnDestroy {

  activeTab = 1
  googleKey = '';
  showGoogle = false;
  tenant = new Tenant()
  domainState = 0
  domainStateClass = 'fa-circle-question';
  domainStateColor = 'gray';
  domainStateTooltip = 'Prüfe...';
  domainStateTimer: any = null;

  public origSubdomain = '';
  public subdomain = '';
  public gradientAngle = '';
  public gradientStop1 = '';
  public gradientStop2 = '';
  constructor(private apiManagerService: ApiManagerService, private toast: AsToastService, private modalService: NgbModal) { }

  saveGoogle() {
    this.apiManagerService.tenant.GoogleApiKey = this.googleKey;
    this.apiManagerService.setGoogleSettings(this.apiManagerService.tenant).then(() => {
    });
  }

  setSubdomain(subdomain: string) {
    if (this.domainStateTimer != null) {
      clearTimeout(this.domainStateTimer);
      this.domainStateTimer = null;
    }

    this.subdomain = subdomain;
    if (this.subdomain == this.origSubdomain) {
      this.domainStateClass = 'fa-circle-check';
      this.domainStateColor = 'green';
      this.domainStateTooltip = 'Aktiv';
    } else {
      this.domainStateClass = 'fa-circle-question';
      this.domainStateColor = 'gray';
      this.domainStateTooltip = 'prüfe';
      this.domainStateTimer = setTimeout(() => this.checkSubdomain(), 1000);
    }
  }

  checkSubdomain() {
    this.domainStateTimer = null;
    if (this.subdomain == this.origSubdomain) {
      this.domainStateClass = 'fa-circle-check';
      this.domainStateColor = 'green';
      this.domainStateTooltip = 'Aktiv';
    } else {
      this.domainStateClass = 'fa-circle-question';
      this.domainStateColor = 'gray';
      this.domainStateTooltip = 'Prüfe';
      this.apiManagerService.verifySubdomain(this.subdomain).then((state) => {
        this.domainState = state;
        switch(state) {
          case -1: {
            this.domainStateClass = 'fa-circle-exclamation';
            this.domainStateColor = 'red';
            this.domainStateTooltip = 'Ungültig';
            break;
          }
          case 0: {
            this.domainStateClass = 'fa-circle-xmark';
            this.domainStateColor = 'red';
            this.domainStateTooltip = 'Vergeben';
            break;
          }
          case 1: {
            this.domainStateClass = 'fa-circle-check';
            this.domainStateColor = 'green';
            this.domainStateTooltip = 'Frei';
            break;
          }
          default: {
            this.domainStateClass = 'fa-circle-exclamation';
            this.domainStateColor = 'darkred';
            this.domainStateTooltip = 'Fehler';
            break;
          }
        }
      }).catch((e) => {
        this.domainStateClass = 'fa-circle-exclamation';
        this.domainStateColor = 'darkred';
        this.domainStateTooltip = 'Fehler';
      })
    }
  }


  handleFileInput(files: FileList, type) {
    if (files.length != null) {
      this.apiManagerService.uploadLogo(files[0], type).then((result) => {
        location.reload();
      });
    }
  }

  saveGradient() {
    this.parseTenant();
    this.apiManagerService.setTenantGradient(this.tenant).then((success) => {
      if (success) {
        this.toast.showInfoToastMessage('Einstellungen aktualisiert');
      } else {
        this.toast.showErrorToastMessage('Aktualisierung fehlgeschlagen');
      }

      this.apiManagerService.loadTenant();
      setTimeout(() => this.loadTenant(), 500);
    })
  }

  saveName() {
    this.apiManagerService.setTenantName(this.tenant).then((success) => {
      if (success) {
        this.toast.showInfoToastMessage('Einstellungen aktualisiert');
      } else {
        this.toast.showErrorToastMessage('Aktualisierung fehlgeschlagen');
      }

      this.apiManagerService.loadTenant();
      setTimeout(() => {
        this.loadTenant()
        this.apiManagerService.getProfile().TenantName = this.tenant.Name;
      }, 500);
    })
  }

  askDomainChange(domainRef) {
    /*if (!this.tenant.LoginDomain.endsWith('.ordiance.app')) {
      this.tenant.LoginDomain = this.tenant.LoginDomain + '.ordiance.app'
    }*/


    this.apiManagerService.openModal(this.modalService, domainRef);
  }

  saveDomain() {
    this.tenant.LoginDomain = this.subdomain + '.ordiance.app';
    this.apiManagerService.setTenantDomain(this.tenant).then((success) => {
      if (success) {
        this.toast.showInfoToastMessage('Domain aktualisiert');
      } else {
        this.toast.showErrorToastMessage('Aktualisierung fehlgeschlagen');
      }

      setTimeout(() => {
        this.loadTenant()
        if (success) {
          location.href = "https://" + this.tenant.LoginDomain;
        }
      }, 500);
    })
  }
  ngOnDestroy() {
    this.apiManagerService.loadTenant()
  }

  parseTenant() {
    if (this.gradientAngle.trim() == '') {
      this.tenant.GradientAngle = null;
    } else {
      this.tenant.GradientAngle = parseFloat(this.gradientAngle);
    }

    if (this.gradientStop1.trim() == '') {
      this.tenant.GradientStop1 = null;
    } else {
      this.tenant.GradientStop1 = parseFloat(this.gradientStop1);
    }

    if (this.gradientStop2.trim() == '') {
      this.tenant.GradientStop2 = null;
    } else {
      this.tenant.GradientStop2 = parseFloat(this.gradientStop2);
    }
  }

  loadTenant() {
    this.tenant = this.apiManagerService.tenant
    this.subdomain = this.tenant.LoginDomain.replace('.ordiance.app', '');
    this.origSubdomain = this.subdomain;
    this.domainStateClass = 'fa-circle-check';
    this.domainStateColor = 'green';
    this.domainStateTooltip = 'Aktiv';
    if (this.tenant.AccentSuccess == null || this.tenant.AccentSuccess.trim() == '') {
      this.tenant.AccentSuccess = '#22af47';
    }

    if (this.tenant.GradientAngle == null) {
      this.gradientAngle = '';
    } else {
      this.gradientAngle = this.tenant.GradientAngle.toString();
    }

    if (this.tenant.GradientStop1 == null) {
      this.gradientStop1 = '';
    } else {
      this.gradientStop1 = this.tenant.GradientStop1.toString();
    }

    if (this.tenant.GradientStop2 == null) {
      this.gradientStop2 = '';
    } else {
      this.gradientStop2 = this.tenant.GradientStop2.toString();
    }
  }

  ngOnInit(): void {
    this.loadTenant();
    if (this.apiManagerService.tenant.GooglePlaces) {
      this.showGoogle = true;
      this.googleKey = this.apiManagerService.tenant.GoogleApiKey;
    }
  }

}
