<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-3">Feld</div>
      <div class="col-2">Tabelle</div>
      <div class="col-2">Formulare</div>
      <div class="col-2">Anlage</div>
      <div class="col-2">Bearbeiten</div>
      <div class="col-1">&nbsp;</div>
    </div>
    <div class="row" *ngFor="let field of _fields">
      <div class="col-3">{{field.DisplayName}}</div>
      <div class="col-2"><fa-icon (click)="field.IsHidden = !field.IsHidden" style="cursor: pointer;" label="{{field.IsFormHidden?'Versteckt':'Sichtbar'}}" [icon]="['fas', field.IsHidden?'eye-slash':'eye']"></fa-icon></div>
      <div class="col-2"><fa-icon (click)="field.IsFormHidden = !field.IsFormHidden" style="cursor: pointer;" label="{{field.IsFormHidden?'Versteckt':'Sichtbar'}}" [icon]="['fas', field.IsFormHidden?'eye-slash':'eye']"></fa-icon></div>
      <div class="col-2"><fa-icon (click)="field.IsCreateHidden = !field.IsCreateHidden" style="cursor: pointer;" label="{{field.IsCreateHidden?'Versteckt':'Sichtbar'}}" *ngIf="!field.IsFormHidden" [icon]="['fas', field.IsCreateHidden?'eye-slash':'eye']"></fa-icon></div>
      <div class="col-2"><fa-icon (click)="field.IsEditHidden = !field.IsEditHidden" style="cursor: pointer;" label="{{field.IsEditHidden?'Versteckt':'Sichtbar'}}" *ngIf="!field.IsFormHidden" [icon]="['fas', field.IsEditHidden?'eye-slash':'eye']"></fa-icon></div>
      <div class="col-1">&nbsp;</div>
    </div>
  </div>
</div>
