import {Component, HostListener, OnInit} from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AsToastService} from "../as-toast.service";
import {Title} from "@angular/platform-browser";
import {AsFlexDropButtonItem} from "../as-flex-drop-button/as-flex-drop-button.component";
import {AuthUser} from "../api-classes/api-classes";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private toast: AsToastService,private title: Title) { }

  menu: AsFlexDropButtonItem[] = []
  profile = new AuthUser;
  focusCellId = '';
  saveTimeout: any = null;
  activeModal: any = null;
  placeholderText: string|null = '';
  focusCell: HTMLDivElement = null;
  pageW = 1000;
  contactCount = '(lade)';
  companyCount = '(lade)';

  ngOnInit(): void {
    this.menu.push({
      Displayname: 'Bestellung hinzufügen',
      EventTarget: '',
      EventType: 'add-row-flex-orders',
      EventData: null
    });

    this.menu.push({
      Displayname: 'Gefilterte Liste exportieren',
      EventTarget: '',
      EventType: 'export-rows-flex-orders',
      EventData: null
    });

    this.menu.push({
      Displayname: 'Vollständiger Export',
      EventTarget: '',
      EventType: 'full-export-rows-flex-orders',
      EventData: null
    });

    this.title.setTitle('Bestellungen - Astralus Admin Portal');
    this.profile = this.apiManagerService.getProfile();
    if (this.apiManagerService.cacheRows['orders'] == undefined || this.apiManagerService.cacheRows['orders'] == null) {
      this.apiManagerService.getTableEntities('orders').then((data) => {
        if (data == null) {
          this.companyCount = "0";
        } else {
          this.companyCount = data.length.toString();
        }
      }).catch((e) => {
        this.companyCount = "0";
      });
    } else {
      this.companyCount = this.apiManagerService.cacheRows['orders'].length.toString();
    }

    if (this.apiManagerService.cacheRows['orders'] == undefined || this.apiManagerService.cacheRows['orders'] == null) {
      this.apiManagerService.getTableEntities('orders').then((data) => {
      //
      }).catch((e) => {
        //this.contactCount = "0";
      });
    } else {
      //this.contactCount = this.apiManagerService.cacheRows['contacts'].length.toString();
    }

    this.onResize();
  }

  onScroll(evt) {
    this.onResize();
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.pageW = window.innerWidth;
    if (this.focusCell != null && this.focusCellId != '') {
      const rect = this.focusCell.getBoundingClientRect();
      const cell = document.getElementById(this.focusCellId);
      if (cell != null) {
        let coordFix = this.getLeftFix();
        const lCoord = rect.left - coordFix;
        cell.style.left = lCoord + 'px';
        cell.style.width = (rect.width - this.getLeftWFix()) + 'px';
        cell.focus();
      }
    }
  }

  getLeftWFix(){
    return 35;
  }

  isMinimizedMenu() {
    const wrapper = document.getElementsByClassName('hk-vertical-nav');
    if (wrapper.length > 0) {
      if (wrapper[0].classList.contains('hk-nav-toggle'))
        return true;
    }

    return false;
  }

  getLeftFix(){

    if (this.pageW < 1199) { // Mobile view
      return 16;
    } else {
      if (this.pageW > 1599)
      {
        if (!this.isMinimizedMenu())
          return 310;

        return 121;
      } else {
        if (!this.isMinimizedMenu())
          return 265;

        return 76;
      }
    }
  }

  closeModal() {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }
  }

}
