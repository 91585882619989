<!--<div class="kanban-board" style="display: flex;" *ngIf="loading">
  <div style="margin-left: auto; margin-right: auto" class="lds-default" >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>-->
<div class="table-loader" *ngIf="loading">
  <svg>
    <g>
      <path d="M 50,100 A 1,1 0 0 1 50,0"/>
    </g>
    <g>
      <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
    </g>
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:#FF56A1;stop-opacity:1" />
        <stop offset="100%" style="stop-color:#FF9350;stop-opacity:1" />
      </linearGradient>
    </defs>
  </svg>
</div>
<div class="kanban-board {{baseThemeCss}}" style="" *ngIf="!loading">
  <app-as-kanban-list [newTab]="newTab" [detailsPageLink]="detailsPageLink" [bucketSumString]="sortedSumsParsed[bucket.Id]" [miniTitle]="miniTitle" [cardMode]="_cardMode" [tableName]="_tableName" [bucketH]="bucketH"  style="{{bucketStyle}}" [bucketWidth]="bucketWidth" [theme]="_theme" (editRow)="openCard($event)" (moveRow)="moveRequest($event)" *ngFor="let bucket of buckets" [tableRows]="sortedRows[bucket.Id]" [bucket]="bucket"></app-as-kanban-list>
</div>


<ng-template #editRowModalRef let-modal style="z-index: 1100;" class="modal fade" id="editRowModal" aria-labelledby="editRowModalLabel">
  <!--<div class="modal-dialog" role="document">
    <div class="modal-content">-->
  <div class="modal-header">
    <h5 class="modal-title" id="editRowModalLabel">Eintrag {{isNewRow?'hinzufügen':'bearbeiten'}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-as-edit-form [hasLogos]="hasLogos" (hasChanges)="_hasChanges=$event" [schema]="tableSchema" [tableRow]="tableRow" [isCreate]="isNewRow" (logoSet)="onLogoSet($event)"></app-as-edit-form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()" data-dismiss="modal">Abbrechen</button>
    <button type="button" (click)="saveRow()" class="btn btn-primary">{{isNewRow?'Hinzufügen':'Speichern'}}</button>
  </div>
  <!--</div>
</div>-->
</ng-template>
