<!--<div style="display: flex;"  *ngIf="loading">
  <div style="margin-left: auto; margin-right: auto" class="lds-default" >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>-->

<div class="table-loader" *ngIf="loading">
  <svg>
    <g>
      <path d="M 50,100 A 1,1 0 0 1 50,0"/>
    </g>
    <g>
      <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
    </g>
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:#FF56A1;stop-opacity:1" />
        <stop offset="100%" style="stop-color:#FF9350;stop-opacity:1" />
      </linearGradient>
    </defs>
  </svg>
</div>

<div *ngIf="!loading" class="row">
  <!--<div *ngIf="_mode==-1" style="display: flex; margin: 4px;"><i style="margin-left: auto; cursor: pointer;" class="fa-solid fa-gears" title="Formular Eigenschaften"></i></div>-->
  <div *ngIf="_mode==-1" style="display: flex; margin: 4px; width: 100%;"><i (click)="addItem(addPageItemRef, null, false)" style="margin-left: auto; cursor: pointer; margin-right: auto; font-size: 20px;" class="fa-regular fa-plus-circle" title="Element hier hinzufügen"></i></div>
  <div id="item_{{child.Id}}" *ngFor="let child of _children" [draggable]="_mode==-1" style="{{_mode==-1?_styleEditMode:''}} {{_mode!=-1?child.Style:''}}" class="{{child.CssClasses}}">
    <div *ngIf="_mode==-1" style="display: flex; margin: 4px;">
      <input style="max-width: 100px;" placeholder="CSS Klassen" type="text" [value]="child.CssClasses" (input)="child.CssClasses=$any($event.target).value"/>&nbsp;
      <input style="max-width: 100px;" placeholder="Style" type="text" [value]="child.Style" (input)="child.Style=$any($event.target).value"/>
      <i style="margin-left: auto; cursor: pointer;" class="fa-solid fa-trash" title="Element entfernen" (click)="removeItem(removeItemRef, child, null)"></i>
    </div>
    <app-as-renderer-card (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='card'"  [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-card>
    <app-as-renderer-div (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='div'" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-div>
    <app-as-renderer-ro-text [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type.startsWith('ro_field')" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-ro-text>
    <app-as-renderer-input [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type.startsWith('input-') || child.Type=='input'" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-input>
    <app-as-renderer-input-label [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='input_label' || child.Type=='ro_input_label'" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-input-label>
    <app-as-renderer-activity-tab (uploadFile)="handleFileInput($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='activity_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-activity-tab>
    <app-as-renderer-contact-link-list (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='link-contacts'" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-contact-link-list>
    <app-as-renderer-button (buttonClicked)="buttonClick($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='button'" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-button>
    <app-as-renderer-gauge *ngIf="child.Type=='gauge'"></app-as-renderer-gauge>
    <app-as-renderer-stars *ngIf="child.Type=='stars'"></app-as-renderer-stars>
    <app-as-renderer-communication (uploadFile)="handleFileInput($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='communication_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-communication>
    <app-as-renderer-contact-details (uploadFile)="handleFileInput($event)" [dropOptions]="_dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='contact_details'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="_page" [mode]="_mode" [item]="child" [row]="_row" [schema]="_schema" [schemaResolver]="_schemaResolver"></app-as-renderer-contact-details>

  </div>
  <div *ngIf="_mode==-1 && _children != null && _children.length > 0" style="display: flex; margin: 4px;"><i (click)="addItem(addPageItemRef, null, true)" style="cursor: pointer; margin-left: auto; margin-right: auto; font-size: 20px;" class="fa-regular fa-plus-circle" title="Element hier hinzufügen"></i></div>
</div>


<ng-template #addPageItemRef let-modal class="modal fade" id="addPageItemRef-2" aria-labelledby="addPageItemRefLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="addPageItemRefLabel-2">
        Element Hinzufügen
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" id="modal_addItem_{{sessionId}}">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Typ</label>
            <div class="col-sm-8">
              <as-ng-select2 dropdownParent="modal_addItem_{{sessionId}}" [data]="fieldTypes" [value]="_addItem.Type" (valueChanged)="setFieldType($event)"></as-ng-select2>
            </div>
          </div>
          <div class="form-group row" *ngIf="_addItemShowFields">
            <label class="col-sm-4 col-form-label">Feld</label>
            <div class="col-sm-8">
              <as-ng-select2 dropdownParent="modal_addItem_{{sessionId}}" [data]="fields" [value]="_addItem.Options" (valueChanged)="_addItem.Options=$event;"> </as-ng-select2>
            </div>
          </div>
          <div class="form-group row" *ngIf="_addItem.Type == 'button'">
            <label class="col-sm-4 col-form-label">Button Titel</label>
            <div class="col-sm-8">
              <input style="margin: 2px;" type="text" [value]="_addItem.Title" (input)="_addItem.Title=$any($event.target).value;" class="form-control" />
            </div>
          </div>
          <div class="form-group row" *ngIf="_addItem.Type == 'button'">
            <label class="col-sm-4 col-form-label">Button Typ</label>
            <div class="col-sm-8">
              <as-ng-select2 dropdownParent="modal_addItem_{{sessionId}}" [data]="buttonTypes" [value]="_addItem.Options" (valueChanged)="_addItem.Options=$event;"> </as-ng-select2>
            </div>
          </div>
          <div class="form-group row" *ngIf="_addItem.Type == 'button'">
            <label class="col-sm-4 col-form-label">Event ID</label>
            <div class="col-sm-8">
              <input style="margin: 2px;" type="text" [value]="_addItem.Options2" (input)="_addItem.Options2=$any($event.target).value;" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="confirmAddItem()"><fa-icon [icon]="['fas','plus']" class="ml-2" style="margin-left: 0 !important;"></fa-icon>&nbsp;Hinzufügen</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  </div>
</ng-template>


<ng-template #removeItemRef let-modal class="modal fade" id="removeFieldRef-2" aria-labelledby="removeFieldRefLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="removeFieldRefLabel-2">
        Element entfernen
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <span>Soll das Element wirklich gelöscht werden?</span><br/>
          <span><b>Warnung alle zugehörigen Unterlemente werden mit gelöscht!</b></span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="confirmRemoveItem()"><fa-icon [icon]="['fas','trash']" class="ml-2" style="margin-left: 0 !important;"></fa-icon>&nbsp;Löschen</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  </div>
</ng-template>
