import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-loader',
  templateUrl: './profile-loader.component.html',
  styleUrls: ['./profile-loader.component.css']
})
export class ProfileLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
