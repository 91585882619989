import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ApiManagerService} from '../api-manager/api-manager.service';
import {v4 as uuidv4} from 'uuid';
import {FieldMeta, SizeUpdate} from '../api-classes/api-classes';

@Component({
  selector: 'th[resizable]',
  templateUrl: './resizable.component.html',
  styleUrls: ['./resizable.component.css'],
})
export class ResizableComponent implements OnInit {
  constructor(private apiManagerService: ApiManagerService) {}

  sourceId = uuidv4();

  @HostBinding('style.min-width.px')
  width: number | null = null;

  @Input() tableField: FieldMeta = null;

  @Input() isLastField = false;

  setInitialWidth() {
    if (this.tableField != null) {
      this.width = this.tableField.TableWidth;
    } else {
      this.width = null;
    }


  }

  ngOnInit() {
    /*this.apiManagerService.sizeUpdate.subscribe((sizeUpdate) => {
      try {
        if (sizeUpdate != null && sizeUpdate.sourceId !== this.sourceId && this.tableField === sizeUpdate.fieldId && sizeUpdate.typeId === 'min-width') {
          this.width = sizeUpdate.size;
        }
      } catch (e) {
        console.log(e);
      }
    });*/

    this.setInitialWidth();
  }

  onResize(width: any) {
    if (width < 0 || width === this.width) {
      return;
    }

    this.width = width;
    if (this.tableField != null) {
      this.tableField.TableWidth = width;
      const update = new SizeUpdate();
      update.sourceId = this.sourceId;
      update.typeId = 'min-width';
      update.size = width;
      update.fieldId = this.tableField.Id;
      try {
        //this.apiManagerService.sizeUpdate.emit(update);
      } catch (e) {
        console.log(e);
      }
    }
  }
}
