<a href="#" style="{{colorOverride}}" aria-expanded="false" (click)="doClick($event)" data-toggle="dropdown" class="btn btn-link dropdown-toggle btn-icon-dropdown {{disable?'disabled':''}}">
  <span class="mat-icon  material-icons">add_box</span>
  <span class="caret"></span>
</a>

<div style="visibility: hidden; position: fixed;"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent>
    <button (click)="fireEvent(entry)" mat-menu-item *ngFor="let entry of menuEntries">{{entry.Displayname}}</button>
  </ng-template>
</mat-menu>
