import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {DashboardComponent} from './dashboard/dashboard.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import {faSolid} from './fa6-solid';
import {faRegular} from './fa6-regular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClipboardModule} from 'ngx-clipboard';
import {AppRoutingModule} from './app-routing.module'
import {ToastrModule} from 'ngx-toastr';
import {ApiManagerService} from './api-manager/api-manager.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import { MatIconModule } from '@angular/material/icon';
import {AsToastService} from './as-toast.service';
import { ThemeAosDgComponent } from './theme-aos-dg/theme-aos-dg.component';
import { AsFlexTableComponent } from './as-flex-table/as-flex-table.component';
import { AsKanbanComponent } from './as-kanban/as-kanban.component';
import {ResizableDirective} from "./resizable.directive";
import {ResizableComponent} from "./resizable/resizable.component";
import { AsKanbanCardComponent } from './as-kanban/as-kanban-card/as-kanban-card.component';
import { AsKanbanListComponent } from './as-kanban/as-kanban-list/as-kanban-list.component';
import { AsEditFormComponent } from './as-edit-form/as-edit-form.component';
import { AsDropdownComponent } from './as-dropdown/as-dropdown.component';
import {MatMenuModule} from "@angular/material/menu";
import { DealsComponent } from './deals/deals.component';
import {NgSelect2Module} from "./as-ng-select2/lib/ng-select2.module";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import { AsVisibleFieldsComponent } from './as-visible-fields/as-visible-fields.component';
import { AsFeatureShopDgComponent } from './as-feature-shop-dg/as-feature-shop-dg.component';
import { AsFeatureShopDgDetailsComponent } from './as-feature-shop-dg-details/as-feature-shop-dg-details.component';
import { AsUsersAdminComponent } from './as-users-admin/as-users-admin.component';
import { AsRendererComponent } from './as-renderer/as-renderer.component';
import { AsRendererDivComponent } from './as-renderer/as-renderer-div/as-renderer-div.component';
import { AsRendererCardComponent } from './as-renderer/as-renderer-card/as-renderer-card.component';
import { AsRendererRoTextComponent } from './as-renderer/as-renderer-ro-text/as-renderer-ro-text.component';
import { AsRendererInputComponent } from './as-renderer/as-renderer-input/as-renderer-input.component';
import { AsRendererInputLabelComponent } from './as-renderer/as-renderer-input-label/as-renderer-input-label.component';
import { AsRendererActivityTabComponent } from './as-renderer/as-renderer-activity-tab/as-renderer-activity-tab.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {NgxSummernoteModule} from "ngx-summernote";
import { AsMultiselectComponent } from './as-multiselect/as-multiselect.component';
import { AsLinkListComponent } from './as-renderer/as-renderer-contact-link-list/as-link-list.component';
import { AsRendererButtonComponent } from './as-renderer/as-renderer-button/as-renderer-button.component';
import { DealDetailsComponent } from './deal-details/deal-details.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {NgChartsModule} from "ng2-charts";
import { AsAppShopDgComponent } from './as-app-shop-dg/as-app-shop-dg.component';
import { AsAppShopDgDetailsComponent } from './as-app-shop-dg-details/as-app-shop-dg-details.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { AsRendererContactDetailsComponent } from './as-renderer/as-renderer-contact-details/as-renderer-contact-details.component';
import { AsRendererGaugeComponent } from './as-renderer/as-renderer-gauge/as-renderer-gauge.component';
import { AsRendererStarsComponent } from './as-renderer/as-renderer-stars/as-renderer-stars.component';
import { AsRendererCommunicationComponent } from './as-renderer/as-renderer-communication/as-renderer-communication.component';
import { SettingsCommonComponent } from './settings-common/settings-common.component';
import { AsFlexDropButtonComponent } from './as-flex-drop-button/as-flex-drop-button.component';
import { AsDependendLabelComponent } from './as-renderer/as-dependend-label/as-dependend-label.component';
import { DigitOnlyDirective } from './digit-only.directive';
import {ColorPickerModule} from "ngx-color-picker";
import { AsUserDetailsComponent } from './as-user-details/as-user-details.component';


import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MlistsComponent } from './mlists/mlists.component';
import { MlistComponent } from './mlist/mlist.component';
import { MailboxComponent } from './mailbox/mailbox.component';
import { MailboxesComponent } from './mailboxes/mailboxes.component';
import { TildaComponent } from './tilda/tilda.component';
import { BlockedSendersComponent } from './blocked-senders/blocked-senders.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    ThemeAosDgComponent,
    AsFlexTableComponent,
    AsKanbanComponent,
    ResizableComponent,
    ResizableDirective,
    AsKanbanCardComponent,
    AsKanbanListComponent,
    AsEditFormComponent,
    AsDropdownComponent,
    DealsComponent,
    AsVisibleFieldsComponent,
    AsFeatureShopDgComponent,
    AsFeatureShopDgDetailsComponent,
    AsUsersAdminComponent,
    AsRendererComponent,
    AsRendererDivComponent,
    AsRendererCardComponent,
    AsRendererRoTextComponent,
    AsRendererInputComponent,
    AsRendererInputLabelComponent,
    AsRendererActivityTabComponent,
    AsMultiselectComponent,
    AsLinkListComponent,
    AsRendererButtonComponent,
    DealDetailsComponent,
    AsAppShopDgComponent,
    AsAppShopDgDetailsComponent,
    CaseDetailsComponent,
    AsRendererContactDetailsComponent,
    AsRendererGaugeComponent,
    AsRendererStarsComponent,
    AsRendererCommunicationComponent,
    SettingsCommonComponent,
    AsFlexDropButtonComponent,
    AsDependendLabelComponent,
    DigitOnlyDirective,
    AsUserDetailsComponent,
    OrdersComponent,
    OrderDetailsComponent,
    MlistsComponent,
    MlistComponent,
    MailboxComponent,
    MailboxesComponent,
    TildaComponent,
    BlockedSendersComponent,
  ],
  imports: [
    ChartModule,
    ClipboardModule,
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    BrowserAnimationsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgSelect2Module,
    MatIconModule,
    MatMenuModule,
    GooglePlaceModule,
    FullCalendarModule,
    NgxSummernoteModule,
    GoogleMapsModule,
    NgChartsModule,
    ColorPickerModule,
    ChartModule
  ],
  providers: [
    DeviceDetectorService,
    ApiManagerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (apiManagerService: ApiManagerService) => () => apiManagerService.init(),
      deps: [ApiManagerService],
      multi: true,
    },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, solidGauge] },
    AsToastService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(
      fal,
      far,
      fas,
      fad,
      faSolid,
      faRegular
    );
  }
}


