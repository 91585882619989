import { Component, OnInit } from '@angular/core';
import {MListData} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";

@Component({
  selector: 'app-mlist',
  templateUrl: './mlist.component.html',
  styleUrls: ['./mlist.component.css']
})
export class MlistComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  actionString = '';
  isNew = false;
  mlist: MListData = null;
  domain = '';
  name = '';
  newMember = '';
  working = false;
  activeTab = 1;

  setTab(id): void {
    this.activeTab = id;
  }

  processExistingId(self:MlistComponent, id: string) {
    self.apiManagerService.getMailDomains().then((domains) => {
      let incompleteCache = false;
      for(let i = 0; i < domains.length; i++) {
        if (self.apiManagerService.distributionCache[domains[i].domainName] != null && self.apiManagerService.distributionCache[domains[i].domainName] != undefined) {
          const data = self.apiManagerService.distributionCache[domains[i].domainName];
          for(let x = 0; x < data.length; x++) {
            if (data[x].listname == id.toLowerCase().trim() || data[x].uniqueid == id.toLowerCase().trim()) {
              self.mlist = data[x];
              self.domain = self.mlist.listname.split('@')[1]
              self.name = self.mlist.listname.split('@')[0]
              self.actionString = 'bearbeiten';
              break;
            }
          }
        } else {
          incompleteCache= true;
        }

        if (self.mlist != null) {
          break;
        }
      }

      if (self.mlist == null && incompleteCache) {
        setTimeout(() => {
          self.processExistingId(self, id);
        }, 250);
      } else if (self.mlist == null) {
        self.router.navigateByUrl('/mail/distributionlists');
      }
    }).catch((e) => {
      self.router.navigateByUrl('/mail/distributionlists');
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id').trim();
      if (id.indexOf('@') > 0 || (id.length == 36 && id.indexOf('-') > 0) ) {
        this.isNew = false;
        this.processExistingId(this, id);
      } else {
        this.isNew = true;
        this.actionString = 'anlegen';
        this.domain = id;
        this.mlist = new MListData();
        this.mlist.associations  = [];
      }
    });
  }

  addMember() {
    this.newMember = this.newMember.trim().toLowerCase();
    if (this.newMember !== '' && this.validateEmail(this.newMember)) {
      let targetId = -1;
      for(let i = 0; i < this.mlist.associations.length; i++) {
        if (this.mlist.associations[i].toLowerCase().trim() == this.newMember) {
          targetId = i;
          break;
        }
      }

      if (targetId == -1) {
        this.mlist.associations.push(this.newMember);
      }

      this.newMember = '';

    } else {
      this.toast.showWarningToastMessage('Ungültige E-Mail');
    }
  }

  validateEmail(input:string) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      if(input.match(mailformat))
      {
        return true;
      }
      else
      {
        return false;
      }
  }

  removeMember(member: string) {
    let targetId = -1;
    for(let i = 0; i < this.mlist.associations.length; i++) {
      if (this.mlist.associations[i].toLowerCase().trim() == member.toLowerCase().trim()) {
        targetId = i;
        break;
      }
    }

    if (targetId != -1) {
      this.mlist.associations.splice(targetId, 1);
    }
  }

  save() {
    if (this.name.trim() == '') {
      this.toast.showErrorToastMessage('E-Mail benötigt');
    } else {
      this.working = true;
      this.mlist.listname = this.name + '@' + this.domain;
      this.apiManagerService.setMailList(this.mlist).then((data) => {
        this.working = false;
        this.toast.showSuccessToastMessage('Daten gespeichert');
        this.isNew = false;
      }).catch((e) => {
        this.working = false;
        this.toast.showErrorToastMessage('Speichern fehlgeschlagen');
      })
    }
  }

}
