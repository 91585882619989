<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item active" aria-current="page">Features</li>
  </ol>
</nav>

<div class="hk-pg-header">
  <h4 class="hk-pg-title"><span class="pg-title-icon"><span class="feather-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></span></span>Features</h4>
</div>

<div class="col-xl-12">
  <section class="hk-sec-wrapper hk-gallery-wrap" style="border: none; box-shadow: none;">
    <div style="display: flex;" *ngIf="loading">
      <div style="margin-left: auto; margin-right: auto" class="lds-default" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="row hk-gallery" *ngIf="!loading" style="border: none; box-shadow: none;">
      <div class="card" *ngFor="let feature of features" style="width: 300px; margin: 5px;">
        <div class="card-header">
          <span class="badge badge-danger badge-pill mt-15 mr-10" *ngIf="!feature.IsActive" style="margin-top: 0 !important; margin-right: 0 !important; float: right;">Inaktiv</span>
          <span class="badge badge-success badge-pill mt-15 mr-10" *ngIf="feature.IsActive" style="margin-top: 0 !important; margin-right: 0 !important; float: right;">Aktiv</span>
        </div>
        <div class="card-body">
          <h5 class="card-title">{{feature.Name}}</h5>
          <p class="card-text">{{feature.DescriptionShort}}</p>
        </div>
        <div class="card-footer text-muted">
          <a routerLink="/admin/features/{{feature.Id}}" style="float: right; margin-left: auto;"><i class="fa-solid fa-pencil" title="Details" ></i></a>
          <a (click)="order(feature.Id)" style="margin-left: 4px; cursor: pointer;" *ngIf="!feature.IsActive"><i class="fa-solid fa-cart-plus" title="Bestellen" ></i></a>
          <a (click)="cancel(feature.Id)" style="margin-left: 4px; cursor: pointer" *ngIf="feature.IsActive && feature.CancelTime==null"><i class="fa-solid fa-cart-minus text-danger" title="Kündigen" ></i></a>
          <a (click)="order(feature.Id)" style="margin-left: 4px; cursor: pointer" *ngIf="feature.IsActive && feature.CancelTime!=null"><i class="fa-solid fa-cart-plus" title="Kündigung aufheben" ></i></a>
        </div>
      </div>
      <!--<div class="col-lg-2 col-md-4 col-sm-4 col-6 mb-10" *ngFor="let feature of features">
        <a routerLink="/admin/features/{{feature.Id}}">
          <div class="gallery-img" style="text-align: center; background-image:url('{{feature.MainScreenshot}}'); display: flex;">
            <div style="width: 100%;margin-top: auto;margin-bottom: auto;color: white;font-size: 24px;font-weight: 500;">{{feature.Name}}</div>
          </div>
        </a>
      </div>-->
    </div>
  </section>
</div>
