<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Einkauf</a></li>
    <li class="breadcrumb-item active" aria-current="page">Bestellungen</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item" style="margin-left: auto;">
    <app-as-flex-drop-button [menuEntries]="menu"></app-as-flex-drop-button>
  </li>
</ul>

<div class="col-xl-12">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title">Bestellungen</h1>
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap">
          <app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="orders" detailsPageLink="/crm/orders/{id}"></app-as-flex-table>
        </div>
      </div>
    </div>
  </section>
</div>
