import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-as-renderer-stars',
  templateUrl: './as-renderer-stars.component.html',
  styleUrls: ['./as-renderer-stars.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererStarsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
