<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Admin</a></li>
    <li class="breadcrumb-item active" aria-current="page">Verteiler</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item" style="margin-left: auto;">
    <!--<span>Domain:</span>
    <select (change)="setDomain($any($event.target).value)" class="form-control">
      <option *ngFor="let domain of domains" [selected]="domain.domainName == activeDomain" [value]="domain.domainName">{{domain.domainName}}</option>
    </select>-->
    <!--<app-as-flex-drop-button [menuEntries]="menu"></app-as-flex-drop-button>-->
  </li>
  <li class="nav-item">

  </li>
</ul>

<div class="col-xl-12" *ngIf="mlists != null">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title" style="display: flex;">Verteiler
      <a title="Neuer Verteiler" (click)="editList(activeDomain)" class="" style="margin-left: 4px; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
      <span class="">
        <i class="fa-solid fa-plus-circle" style="font-size: 30px;"></i>
      </span>
      </a>
    </h1>
    <div style="display: flex;">
      <div class="col-3" id="domainselect" *ngIf="dropDomains != null">
        <!--<span>Domain:</span>-->
        <!--<select (change)="setDomain($any($event.target).value)" class="form-control">
          <option *ngFor="let domain of domains" [selected]="domain.domainName == activeDomain" [value]="domain.domainName">{{domain.domainName}}</option>
        </select>-->
        <div class="col-sm-9">
          <as-ng-select2 dropdownParent="domainselect" [data]="dropDomains" [value]="activeDomain" (valueChanged)="setDomain($event)"> </as-ng-select2>
        </div>
      </div>
      <div style="margin-left: auto;">
        <input type="text" [value]="filter" (change)="filterUpdate($any($event.target).value)" class="form-control" placeholder="Suche" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <div class="table-wrap">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th>Verteiler</th>
              <th>Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let list of mlistPage">
              <td>{{list.listname}}</td>
              <td style="display: flex;">
                <a title="Bearbeiten" (click)="editList(list.uniqueid)" class="" style="display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-pencil" style="font-size: 11px;"></i>
                  </span>
                </a>
                <a title="Entfernen" (click)="removeList(list, removeListdRef)" class="" style="color: darkred; margin-left: 5px; display: flex; cursor:pointer; margin-right: auto;">
                  <span class="">
                    <i class="fa-solid fa-trash" style="font-size: 11px;"></i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>

          <div style="display: flex; float: right; margin-left: auto;">
            <a href="#" *ngIf="pageId>0" (click)="setPage(0)">&lt;&lt;</a>&nbsp;
            <a href="#" *ngIf="pageId>0" (click)="setPage(pageId-1)">&lt;</a>
            <span>Seite {{visiblePageId}} / {{pageCount}} </span>
            <a href="#" *ngIf="pageId!=pageCount-1" (click)="setPage(pageId+1)">&gt;</a>&nbsp;
            <a href="#" *ngIf="pageId!=pageCount-1" (click)="setPage(pageCount-1)">&gt;&gt;</a>
          </div>
          <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #removeListdRef let-modal class="modal fade" id="removeListdRef-2" aria-labelledby="removeListdRefLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="removeListdRefLabel-2">
        Verteiler entfernen
        <!--<a style="cursor: pointer;" (click)="copyStreetFinal()" title="Adresse kopieren" >
          <fa-icon [icon]="['far','copy']" class="ml-2" style="margin-left: auto; margin-right: auto;"></fa-icon>
        </a>-->
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <span>Soll der Verteiler {{mlistDelete.listname}} wirklich gelöscht werden?</span><br/>
          <span><b>Warnung alle zugehörigen Daten werden entgültig gelöscht!</b></span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="removeListConfirm()"><fa-icon [icon]="['fas','trash']" class="ml-2" style="margin-left: 0 !important;"></fa-icon>&nbsp;Löschen</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  </div>
</ng-template>
