import { Component, OnInit } from '@angular/core';
import {AuthUser, Contact} from '../api-classes/api-classes';
import {ApiManagerService} from '../api-manager/api-manager.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService,private title: Title) { }

  profile = new AuthUser;

  ngOnInit(): void {
    this.profile = this.apiManagerService.getProfile();
    this.title.setTitle('Dashboard - Astralus Admin Portal')
  }



}
