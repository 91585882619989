import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AuthUser, ButtonEvent,
  FieldMeta,
  Page, PageAddItemRequestData,
  PageItem,
  PageItemMove,
  PageParentChildBundle,
  TableRow
} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {v4 as uuidv4} from 'uuid';
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiNotification} from "../api-notifications/api-notifications";

@Component({
  selector: 'app-as-renderer',
  templateUrl: './as-renderer.component.html',
  styleUrls: ['./as-renderer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererComponent implements OnInit,OnDestroy {

  dependendFields: { [name: string]: FieldMeta[] } = {}
  dependendTables: Array<Select2OptionData> = []
  _hasFormChanges = false;
  profile = new AuthUser()
  fields: Array<Select2OptionData> = []
  buttonTypes: Array<Select2OptionData> = [
    {
      text: 'Success',
      id: 'btn-success'
    },
    {
      text: 'Info',
      id: 'btn-info'
    },
    {
      text: 'Warning',
      id: 'btn-warning'
    },
    {
      text: 'Danger',
      id: 'btn-danger'
    },
    {
      text: 'Outline Success',
      id: 'btn-outline-success'
    },
    {
      text: 'Outline Info',
      id: 'btn-outline-info'
    },
    {
      text: 'Outline Warning',
      id: 'btn-outline-warning'
    },
    {
      text: 'Outline Danger',
      id: 'btn-outline-danger'
    },
  ]
  fieldTypes: Array<Select2OptionData> = [
    {
      text: 'Raw <div>',
      id: 'div'
    },
    {
      text: 'Card <div>',
      id: 'card'
    },
    {
      text: 'ReadOnly <span>',
      id: 'ro_field'
    },
    {
      text: 'ReadOnly <p>',
      id: 'ro_field-p'
    },
    {
      text: 'ReadOnly <h1>',
      id: 'ro_field-h1'
    },
    {
      text: 'ReadOnly <h2>',
      id: 'ro_field-h2'
    },
    {
      text: 'ReadOnly <h3>',
      id: 'ro_field-h3'
    },
    {
      text: 'ReadOnly <h4>',
      id: 'ro_field-h4'
    },
    {
      text: 'ReadOnly <h5>',
      id: 'ro_field-h5'
    },
    {
      text: 'Label Input',
      id: 'input_label'
    },
    {
      text: 'ReadOnly Label Input',
      id: 'ro_input_label'
    },
    {
      text: 'Input',
      id: 'input'
    },
    {
      text: 'Input <p>',
      id: 'input-p'
    },
    {
      text: 'Input <h1>',
      id: 'input-h1'
    },
    {
      text: 'Input <h2>',
      id: 'input-h2'
    },
    {
      text: 'Input <h3>',
      id: 'input-h3'
    },
    {
      text: 'Input <h4>',
      id: 'input-h4'
    },
    {
      text: 'Input <h5>',
      id: 'input-h5'
    },
    {
      text: 'Button',
      id: 'button'
    },
    {
      text: 'Aktivitäten Tab',
      id: 'activity_tab'
    },
    {
      text: 'Verknüpfte Kontakte',
      id: 'link-contacts'
    },
    {
      text: 'Gauge',
      id: 'gauge'
    },
    {
      text: 'Stars',
      id: 'stars'
    },
    {
      text: 'Ticket Kommunikation',
      id: 'communication_tab'
    },
    {
      text: 'Kontakt Details',
      id: 'contact_details'
    },

  ]

  loading = true;
  sessionId = uuidv4();
  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  _mode = 0
  _requestedMode = 0
  _page: Page = new Page()
  _rowId = '';
  _children: PageItem[] = [];
  _rows: TableRow[] = [];
  _row: TableRow = new TableRow();
  _schema: FieldMeta[] = [];
  _schemaResolver: { [name: string]: FieldMeta } = {}
  move: PageItemMove;
  _dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  _lazyLoadCount = 0;

  @ViewChild('removeItemRef', { read: TemplateRef }) removeItemTemplate:TemplateRef<any>;
  @ViewChild('addPageItemRef', { read: TemplateRef }) addItemTemplate:TemplateRef<any>;
  @Output() rowSaved = new EventEmitter<TableRow>();
  @Output() buttonClicked = new EventEmitter<ButtonEvent>();
  @Input() set pageName(value: string){
    this.loadPage(value);
  }

  @Input() set mode(value: number) {
    this._requestedMode = value;
    this.checkMode();
  }

  @Input() set rowId(value: string) {
    this._rowId = value;
    this.loadRow();
    this.parsePage(true);
  }

  @Input() set page(value: Page) {
    this._page = value;
    this.parsePage();
  }

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private router: Router, private toast: AsToastService) { }

  _removeParent: PageItem;
  _removeItem: PageItem;

  _addItemParent: PageItem;
  _addItem: PageItem;
  _addItemAppend = false;
  _addItemSection = '';
  _addItemShowFields = false;
  subscriberId = '';

  setFieldType(fieldId) {
    this._addItem.Type = fieldId;
    this._addItemShowFields = this._addItem.Type.startsWith('ro_input_label') || this._addItem.Type.startsWith('ro_field') || this._addItem.Type.startsWith('input') || this._addItem.Type == 'contact_details';
  }

  buttonClick(eventId) {
    const evt = new ButtonEvent();
    evt.EventId=eventId;
    evt.Row=this._row;
    this.buttonClicked.emit(evt);
  }

  onRemoveRequest(req: PageParentChildBundle) {
    this.removeItem(this.removeItemTemplate, req.Child, req.Parent);
  }

  onPageAddRequest(req: PageAddItemRequestData) {
    this.addItem(this.addItemTemplate, req.Parent, req.Append, req.Section);
  }

  addItem(modalRef, parentItem: PageItem, append: boolean, section = '') {
    this._addItemShowFields = false;
    this._addItemParent = parentItem;
    this._addItem = new PageItem();
    this._addItemAppend = append;
    this._addItemSection = section;
    if (this._schema.length > 0) {
      this._addItem.Options = this._schema[0].Id;
    }

    this._addItem.Type = 'div';
    this.apiManagerService.openModal(this.modalService, modalRef);
  }

  removeItem(modalRef, item: PageItem, parentItem: PageItem) {
    this._removeItem = item;
    this._removeParent = parentItem;
    this.apiManagerService.openModal(this.modalService, modalRef);
  }

  confirmAddItem() {
    this.apiManagerService.closeModal(this.modalService);
    if (this._addItem != null) {
      this._hasFormChanges = true;
      if (this._addItemParent == null) {
        if (this._addItemAppend) {
          this._children.push(this._addItem);
        } else {
          this._children = this.prepend(this._addItem, this._children);
        }
      } else {
        if (this._addItemAppend) {
          switch(this._addItemSection) {
            case 'header':
              this._addItemParent.ChildrenHeader.push(this._addItem);
              break;
            case 'footer':
              this._addItemParent.ChildrenFooter.push(this._addItem);
              break;
            default:
              this._addItemParent.Children.push(this._addItem);
              break;
          }
        } else {
          switch (this._addItemSection) {
            case 'header':
              this._addItemParent.ChildrenHeader = this.prepend(this._addItem, this._addItemParent.ChildrenHeader);
              break;
            case 'footer':
              this._addItemParent.ChildrenFooter = this.prepend(this._addItem, this._addItemParent.ChildrenFooter);
              break;
            default:
              this._addItemParent.Children = this.prepend(this._addItem, this._addItemParent.Children);
              break;
          }
        }
      }

      this._addItemParent = null;
      this._addItem = null;
    }
  }

  prepend(value: PageItem, array: PageItem[]): PageItem[] {
    return [value, ...array];
  }

  confirmRemoveItem() {
    this.apiManagerService.closeModal(this.modalService);
    if (this._removeItem != null && this._removeParent == null) {
      this._hasFormChanges = true;
      for (let i = 0; i < this._children.length; i++) {
        if (this._children[i].Id == this._removeItem.Id) {
          this._children.splice(i,1);
          break;
        }
      }

      this._removeItem = null;
    } else if (this._removeItem != null) {
      this._hasFormChanges = true;
      for (let i = 0; i < this._removeParent.ChildrenHeader.length; i++) {
        if (this._removeParent.ChildrenHeader[i].Id == this._removeItem.Id) {
          this._removeParent.ChildrenHeader.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this._removeParent.Children.length; i++) {
        if (this._removeParent.Children[i].Id == this._removeItem.Id) {
          this._removeParent.Children.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this._removeParent.ChildrenFooter.length; i++) {
        if (this._removeParent.ChildrenFooter[i].Id == this._removeItem.Id) {
          this._removeParent.ChildrenFooter.splice(i, 1);
          break;
        }
      }

      this._removeItem = null;
    }
  }

  checkMode() {
    const profile = this.apiManagerService.getProfile();
    if (this._requestedMode === 1 && !this._page.AllowEdit) {
      this._mode = 0;
    } else if (this._requestedMode == -1 && !profile.IsAdmin) {
      this._mode = 0;
    } else {
      if (this._mode == 1 && this._requestedMode != this._mode && this._row != null) {
        this._mode = 2;
        this.apiManagerService.translateRow(this._row, this._schema)
        this.apiManagerService.setTableEntity(this._row).then((e) => {
          this.toast.showInfoToastMessage('Daten gespeichert');
          this._mode = this._requestedMode;
        }).catch((e) => {
          this.toast.showErrorToastMessage('Speichern fehlgeschlagen');
          this._mode = this._requestedMode;
        });
      } else if (this._mode == -1 && this._requestedMode != this._mode && this._page.AllowChange) {
        // if (this._hasFormChanges) {
          this._mode = 2;
          this._page.Data = JSON.stringify(this._children);
          this.apiManagerService.setPage(this._page).then((success) => {
            if (success) {
              this.toast.showInfoToastMessage('Formular aktualisiert');
            } else {
              this.toast.showErrorToastMessage('Aktualisierung fehlgeschlagen');
            }

            this._mode = this._requestedMode;
          }).catch((e) => {
            this.toast.showErrorToastMessage('Aktualisierung fehlgeschlagen');
            this._mode = this._requestedMode;
          });
        // } else {
        //   this._mode = this._requestedMode;
        // }
      } else {
        this._hasFormChanges = false;
        this._mode = this._requestedMode;
      }
    }
  }

  loadPage(pageName: string) {
    if (this._page.PageName != pageName) {
      this.apiManagerService.getPage(pageName).then((page) => {
        if (page != null) {
          this._page = page;
          this.parsePage();
        }
      });
    } else {
      this.parsePage();
    }
  }

  parsePage(keepLoading = false, force = false) {
    if (this._page.TableName == '') {
      if (!keepLoading) {
        this.initializePage();
        if (this._rowId != '-') {
          this.loading = false;
        }
      }
    } else {
      if (this.apiManagerService.cacheSchemas[this._page.TableName] && this.apiManagerService.cacheRows[this._page.TableName] && !force) {
        this._schema = this.apiManagerService.cacheSchemas[this._page.TableName];
        this._rows = this.apiManagerService.cacheRows[this._page.TableName];
        this.filterSchema();
        this.loadRow();
        this.initializePage();
        if (this._rowId != '-') {
          this.loading = false;
        }
      }
      this.apiManagerService.getTableSchema(this._page.TableName).then((schema) => {
        if (schema != null) {
          this._schema = schema;
          this.filterSchema();
          this.apiManagerService.cacheSchemas[this._page.TableName] = this._schema;
          this.apiManagerService.getTableEntities(this._page.TableName).then((rows) => {
            if (rows != null) {
              this._rows = this.apiManagerService.syncRowsSchema(rows, this._schema);
              this.apiManagerService.cacheRows[this._page.TableName] = this._rows;
              this.loadRow();
            }

            this.initializePage();
            this.loading = false;
          }).catch((e) => {
            if (this._rowId != '-') {
              this.loading = false;
            }
          })
        }
      });
    }
  }

  handleFileInput(file: File) {
    if (file != null) {
      this.toast.showInfoToastMessage('Upload eingeleitet');
      this.apiManagerService.uploadEntityFile(this._rowId, file, false).then((success) => {
        this.parsePage(false, true);
        this.toast.showInfoToastMessage('Upload abgeschlossen');
      }).catch((e) => {
        this.toast.showErrorToastMessage('Upload fehlgeschlagen');
        this.parsePage(false, true);
      })
    }
  }

  filterSchema() {
    this.dependendFields = {};
    this.dependendTables = [];
    let fields : Array<Select2OptionData> = [];
    for (let i = 0; i < this._schema.length; i++) {
      if (this._schema[i].FieldType.startsWith('linked')) {
        this.dependendTables.push({
          id:this._schema[i].Id,
          text:this._schema[i].DisplayName
        });
      }

      this._schemaResolver[this._schema[i].Id] = this._schema[i];
      fields.push({
        id:this._schema[i].Id,
        text:this._schema[i].DisplayName
      });
    }

    this.fields = fields;
  }

  loadRow() {
    for (let i = 0; i < this._rows.length; i++) {
      if (this._rows[i].Id == this._rowId  || this._rows[i].PublicId == this._rowId) {
        this._row = this._rows[i];
      }
    }

    Object.entries(this._schema).forEach(
      ([string, meta]) => {
        if (meta.FieldType.startsWith("linked")) {
          if (meta.LinkedTable != null && meta.LinkedTable.trim() != "") {
            if (this.apiManagerService.cacheRows[meta.LinkedTable]) {
              let opts: Array<Select2OptionData> = [];
              const t1: Select2OptionData = {
                id: "-",
                text: '(kein/e)'
              }

              opts.push(t1)
              for (let i = 0; i < this.apiManagerService.cacheRows[meta.LinkedTable].length; i++) {
                let desc: string = this.apiManagerService.cacheRows[meta.LinkedTable][i].Description;
                if (desc == '') {
                  let keys = Object.keys(this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields);
                  for(let x = 0; x < keys.length; x++) {
                    if (desc == '' && (keys[x] == 'company' || keys[x] == 'companyname') && this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]] != null) {
                      desc = this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]];
                      break;
                    }
                  }

                  if (desc == '') {
                    for (let x = 0; x < keys.length; x++) {
                      if (desc == '' && keys[x] == 'lastname' && this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]] != null) {
                        desc = this.apiManagerService.cacheRows[meta.LinkedTable][i].Fields[keys[x]];
                        break;
                      }
                    }
                  }
                }
                const tmp: Select2OptionData = {
                  id: this.apiManagerService.cacheRows[meta.LinkedTable][i].Id,
                  text: desc
                }

                opts.push(tmp)
              }

              this._dropOptions[meta.Id] = opts;
            } else {
              this._lazyLoadCount++;
              this.apiManagerService.getTableEntities(meta.LinkedTable).then((linked) => {
                this.apiManagerService.cacheRows[meta.LinkedTable] = linked;
                let opts: Array<Select2OptionData> = [];
                const t1: Select2OptionData = {
                  id: "-",
                  text: '(kein/e)'
                }

                opts.push(t1)
                for (let i = 0; i < linked.length; i++) {
                  const tmp: Select2OptionData = {
                    id: linked[i].Id,
                    text: linked[i].Description
                  }

                  opts.push(tmp)
                }

                this._dropOptions[meta.Id] = opts;
                this._lazyLoadCount--;
              }).catch((e) => {
                this._dropOptions[meta.Id] = [];
                this._lazyLoadCount--;
              });
            }
          }
        }

        if (meta.FieldType == "dropdown") {
          let opts: Array<Select2OptionData> = [];
          if (meta.DropOptions != null) {
            for (let i = 0; i < meta.DropOptions.length; i++) {
              const tmp: Select2OptionData = {
                id: meta.DropOptions[i].Id,
                text: meta.DropOptions[i].Description
              }

              opts.push(tmp)
            }
          }

          this._dropOptions[meta.Id] = opts;
        }
      }
    );

    if (this._row != null && this._schema != null) {
      this.apiManagerService.translateRow(this._row, this._schema);
    }
  }

  initializePage() {
    this._children = JSON.parse(this._page.Data);
    this.checkMode();
  }

  ngOnDestroy() {
    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
  }

  ngOnInit(): void {
    this.subscriberId = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType == 'signalr-row-update') {
        const tmp: TableRow = notification.EventData;
        if (tmp.Id == this._row.Id) {
          this.loadRow();
        }
      }
    });
    this.profile = this.apiManagerService.getProfile();
  }

  processStartDrag(event, item: PageItem) {
    if (this.mode === -1) {
      this.move = new PageItemMove();
      this.move.ItemId = item.Id;
      this.move.SourceParentItemId = '';
    }

    return false;
  }

  syncSingleRowSchema(row: TableRow): TableRow {
    let exists: { [name: string]: boolean } = {}
    for (let x = 0; x < this._schema.length; x++) { // reset the existing state per row
      exists[this._schema[x].Id] = false;
    }

    Object.entries(row.Fields).forEach( // detect existing fields
      ([key, value]) => {
        exists[key] = true;
      }
    );

    Object.entries(exists).forEach( // create missing fields
      ([key, value]) => {
        if (!value) {
          row.Fields[key] = '';
        }
      }
    );
    return row;
  }
}
