import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FieldMeta,
  Page,
  PageAddItemRequestData,
  PageItem,
  PageItemMove,
  PageParentChildBundle,
  TableRow
} from "../../api-classes/api-classes";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";

@Component({
  selector: 'app-as-renderer-contact-link-list',
  templateUrl: './as-link-list.component.html',
  styleUrls: ['./as-link-list.component.css']
})
export class AsLinkListComponent implements OnInit {

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  _schema: FieldMeta[] = [];
  _row: TableRow;
  @Output() uploadFile = new EventEmitter<File>();
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Output() pageAddRequest = new EventEmitter<PageAddItemRequestData>();
  @Output() removeRequest = new EventEmitter<PageParentChildBundle>();
  @Input() sessionId = '';
  @Input() parentId = '';
  @Input() page: Page
  @Input() item: PageItem;
  @Input() set row(value: TableRow) {
    this._row = value;
    this.loadDependency();
  }
  @Input() mode: number = 0;
  @Input() set schema(value: FieldMeta[]) {
    this._schema = value;
  }
  @Input() schemaResolver: { [name: string]: FieldMeta } = {}
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;

  dependencyRows: TableRow[] = [];
  move: PageItemMove;
  constructor(private modalService: NgbModal, private apiManagerService: ApiManagerService) { }

  loadDependency() {
    this.apiManagerService.getTableSchema('contacts').then((schema) => {
      this.apiManagerService.getTableRowDependencies(this._row.Id, 'contacts').then((data) => {
        if (data != null) {
          this.dependencyRows = this.apiManagerService.syncRowsSchema(data, schema);
        }
      });
    });
  }

  ngOnInit(): void {
  }

}
