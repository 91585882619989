<!--<div class="line-center" *ngIf="loading">
  <div class="loading">
    <p>Lade Profil</p>
    <span></span>
  </div>
</div>-->


<!--<div class="{{divCss}}" style="display: flex;" (scroll)="onScroll($event)" *ngIf="loading">
  <div style="margin-left: auto; margin-right: auto" class="lds-default" >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>-->

<div class="table-loader" (scroll)="onScroll($event)" *ngIf="loading">
  <svg>
    <g>
      <path d="M 50,100 A 1,1 0 0 1 50,0"/>
    </g>
    <g>
      <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
    </g>
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" style="stop-color:#FF56A1;stop-opacity:1" />
        <stop offset="100%" style="stop-color:#FF9350;stop-opacity:1" />
      </linearGradient>
    </defs>
  </svg>
</div>

<div class="{{divCss}}" (scroll)="onScroll($event)" *ngIf="!loading">
  <div class="col-12" style="{{addBtnStyle}}" *ngIf="!loading2">
    <!--<a style="cursor: pointer; font-size: 14px; float: right; margin-top: 6px; margin-left: 10px;" (click)="onRightClick($event, {mode: 'visible', data: null})"><fa-icon [icon]="['fas', 'ellipsis-vertical']"></fa-icon></a>-->
    <a *ngIf="allowAddField" style="cursor: pointer; font-size: 14px; float: right; margin-left: 4px" class="btn btn-gradient-success" (click)="addRow(editRowModalRef)">Hinzufügen</a>
    <a *ngIf="allowExport" style="cursor: pointer; font-size: 14px; float: right;" class="btn btn-gradient-info" (click)="exportXlsx()">Export</a>
  </div>
  <div class="{{!noBreakSearch?'col-3':'col-9'}}" style="display: flex; flex-wrap: wrap">
    <input type="text" placeholder="Suche" (input)="setFilter($any($event.target).value)" *ngIf="hasSearch" style="width: 150px; margin-right: 4px;" class="form-control"/>
    <select title="Limiter" [value]="ownerFilter"  *ngIf="hasSearch" (change)="setOwnerFilter($any($event.target).value)" style="width: 80px; margin-right: 4px;" class="form-control">
      <option value="all" [defaultSelected]="ownerFilter == 'all'">Alle</option>
      <option value="self" [defaultSelected]="ownerFilter == 'self'">Eigene</option>
    </select>
    <select title="Status" [value]="stateFilter"  *ngIf="hasSearch && hasStateSearch" (change)="setStateFilter($any($event.target).value)" style="width: 120px;" class="form-control">
      <option value="open" [defaultSelected]="stateFilter == 'open'">Offen</option>
      <option value="closed" [defaultSelected]="stateFilter == 'closed'">Geschlossen</option>
      <option value="all" [defaultSelected]="stateFilter == 'all'">Alle</option>
    </select>
  </div>
  <table class="{{tableCss}}">
    <thead>
    <tr>
      <th style="text-transform: none;" (contextmenu)="onRightClick($event, {mode: 'field', data: field})"  *ngFor="let field of filteredSchema"  [draggable]="allowDrag" (drag)="dragFieldMove($event)" id="col_{{field.Id}}"  (dragstart)="dragFieldStart($event, field)" (dragend)="dragStop($event)"  (dragover)="dragOverFieldStart($event, field)" (drop)="dropField($event,field)" resizable [tableField]="field.Id" [isLastField]="field.Id == lastFieldId || !allowResize">
        <span style="cursor: pointer;" (click)="startEditFieldName(field.Id)" (dblclick)="editFieldProperties(field.Id, fieldProperties)" *ngIf="!editFieldName || editFieldId !== field.Id">{{field.DisplayName}}</span>
        &nbsp;  <input  id="name-editor"  (focusout)="nameEditLostFocus()" (keyup.escape)="cancelEditFieldName()" [style.width]="editFieldWidth + 'px'" (keyup.enter)="saveFieldName()" *ngIf="editFieldName && editFieldId === field.Id" type="text" [value]="editFieldNameText" (input)="editFieldNameText=$any($event.target).value" class="form-control" />
        <!--<a (click)="cancelEditFieldName()" class="nav-link" style="display: flex; cursor:pointer;" *ngIf="editFieldName && editFieldId === field.Id">
          <fa-icon [icon]="['fas','ban']"></fa-icon>
        </a>
        <a (click)="saveFieldName()" class="nav-link" style="display: flex; cursor:pointer;" *ngIf="editFieldName && editFieldId === field.Id">
          <fa-icon [icon]="['fas','check']"></fa-icon>
        </a>-->
        <div id="table_dummy_{{field.Id}}" style="opacity: 0.95; pointer-events: none; display: none; padding-top: 1rem !important; padding: 0.95rem;">{{field.DisplayName}}</div>
    &nbsp; </th>
      <th style="display: flex; padding-right: 0;">
        <a title="Sichtbarkeit verwalten" (click)="openModal(editVisibleModalRef)" class="btn btn-icon btn-icon-circle btn-light btn-xs" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
          <span class="btn-icon-wrap">
           <span class="material-icons-outlined" style="font-size: 18px;">toggle_on</span>
          </span>
        </a>
        <a *ngIf="allowAddField" title="Feld hinzufügen" (click)="createFieldProperties(fieldProperties)" class="btn btn-icon btn-icon-circle btn-light btn-xs" style="display: flex; cursor:pointer; color: darkgray">
          <span class="btn-icon-wrap">
            <fa-icon [icon]="['fas','plus']"></fa-icon>
          </span>
        </a>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of tableRowsFiltered" (contextmenu)="onRightClick($event, {mode: 'row', data: row, modalref: editRowModalRef})" >
      <td *ngFor="let field of filteredSchema" (click)="openDetails(row, field)" style="{{detailsPageLink==''?'':'cursor: pointer;'}}">
        <div style="display: flex;">
          <div class="avatar avatar-xs" *ngIf="field.IsDefaultDisplay && hasLogos" style="margin-right: 5px;">
            <span class="avatar-text avatar-text-primary rounded-circle"  *ngIf="!row.HasLogo">
              <span class="initial-wrap">
                <span>{{row.LogoInitials}}</span>
              </span>
            </span>
            <img src="{{row.LogoUrl}}" alt="{{row.LogoInitials}}" *ngIf="row.HasLogo" class="avatar-img rounded-circle">
          </div>
          <div style="margin-top: auto; margin-bottom: auto; {{!useLinkIcon && field.LinkedTable != noLinkTable && field.FieldType.startsWith('link')?'text-decoration: underline;':''}}">
            <i *ngIf="row.FieldsTranslated[field.Id]!='' && useLinkIcon && field.LinkedTable != noLinkTable && field.FieldType.startsWith('link')" ngbTooltip="Öffnen" tooltipClass="tooltip-light" class="fa-solid fa-arrow-up-right-from-square" style="cursor: pointer; margin-right: 4px; color: grey; font-size: 11px;" (click)="openLink(row, field)"></i>
            {{row.FieldsTranslated[field.Id]}}
          </div>
        </div>
      </td>
      <td style="padding-right: 0;">
        <div style="display: flex;">
          <a *ngIf="allowEdit" title="Bearbeiten" (click)="editRow(editRowModalRef, row)" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
            <span class="">
              <i class="fa-solid fa-pencil" style="font-size: 11px;"></i>
            </span>
          </a>
        </div>
      </td>
    </tr>
    <tr *ngIf="tableRows.length == 0">
      <td *ngFor="let field of filteredSchema"></td>
      <td></td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #editVisibleModalRef let-modal style="z-index: 1100;" class="modal fade" id="editVisibleModal" aria-labelledby="editVisibleModalLabel">
  <!--<div class="modal-dialog" role="document">
    <div class="modal-content">-->
  <div class="modal-header">
    <h5 class="modal-title" id="editVisibleModalLabel">Sichtbare Felder</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-as-visible-fields [fields]="tableSchema"></app-as-visible-fields>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="saveVisible()" class="btn btn-primary">Übernehmen</button>
  </div>
  <!--</div>
</div>-->
</ng-template>

<ng-template #editRowModalRef let-modal style="z-index: 1100;" class="modal fade" id="editRowModal" aria-labelledby="editRowModalLabel">
  <!--<div class="modal-dialog" role="document">
    <div class="modal-content">-->
  <div class="modal-header">
    <h5 class="modal-title" id="editRowModalLabel">Eintrag {{isNewRow?'hinzufügen':'bearbeiten'}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-as-edit-form [parentRowId]="_linkedRowId" [parentTable]="_linkedTable" [hasLogos]="hasLogos" (hasChanges)="_hasChanges=$event" [schema]="tableSchema" [tableRow]="tableRow" [isCreate]="isNewRow" (logoSet)="onLogoSet($event)"></app-as-edit-form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close()" data-dismiss="modal">Abbrechen</button>
    <button type="button" (click)="saveRow()" class="btn btn-primary">{{isNewRow?'Hinzufügen':'Speichern'}}</button>
  </div>
  <!--</div>
</div>-->
</ng-template>


<ng-template #removeFieldRef let-modal class="modal fade" id="removeFieldRef-2" aria-labelledby="removeFieldRefLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="removeFieldRefLabel-2">
        Feld entfernen
        <!--<a style="cursor: pointer;" (click)="copyStreetFinal()" title="Adresse kopieren" >
          <fa-icon [icon]="['far','copy']" class="ml-2" style="margin-left: auto; margin-right: auto;"></fa-icon>
        </a>-->
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <span>Soll das Feld {{editField.DisplayName}} wirklich gelöscht werden?</span><br/>
          <span><b>Warnung alle zugehörigen Daten werden entgültig gelöscht!</b></span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="removeFieldProperties()"><fa-icon [icon]="['fas','trash']" class="ml-2" style="margin-left: 0 !important;"></fa-icon>&nbsp;Löschen</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  </div>
</ng-template>

<ng-template #fieldProperties let-modal class="modal fade" id="fieldProperties-2" aria-labelledby="fieldPropertiesLabel-2">
  <!--<div class="modal-content">-->
    <div class="modal-header">
      <h5 class="modal-title" id="fieldPropertiesLabel-2">
        {{newField ? 'Feld anlegen' : 'Feld bearbeiten'}}
        <!--<a style="cursor: pointer;" (click)="copyStreetFinal()" title="Adresse kopieren" >
          <fa-icon [icon]="['far','copy']" class="ml-2" style="margin-left: auto; margin-right: auto;"></fa-icon>
        </a>-->
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" id="as-form_modal_{{editField.Id}}">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-9">
              <input style="margin: 2px;" type="text" [value]="editField.DisplayName" (input)="setFieldDisplayName($any($event.target).value)"
                     class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">%%VAR_...%%</label>
            <div class="col-sm-9">
              <input style="margin: 2px;" type="text" [value]="editField.Name" [readOnly]="editField.IsEssential" (input)="editField.Name=$any($event.target).value"
                     class="form-control" />
            </div>
          </div>
          <div class="form-group row" *ngIf="!editField.IsEssential">
            <label class="col-sm-3 col-form-label">Typ</label>
            <div class="col-sm-9">
              <as-ng-select2 dropdownParent="as-form_modal_{{editField.Id}}" [data]="fieldTypes" [value]="editField.FieldType" (valueChanged)="editField.FieldType=$event"> </as-ng-select2>
            </div>
          </div>
          <div class="form-group row" *ngIf="!editField.IsEssential && editField.FieldType.startsWith('linked')">
            <label class="col-sm-3 col-form-label">Verknüpfte Tabelle</label>
            <div class="col-sm-9">
              <as-ng-select2 dropdownParent="as-form_modal_{{editField.Id}}" [data]="tablesFiltered" [value]="editField.LinkedTable" (valueChanged)="editField.LinkedTable=$event"> </as-ng-select2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="allowAddField && !editField.IsEssential && !newField" type="button" class="btn btn-danger" style="margin-right: auto;" (click)="askRemoveField(removeFieldRef)" ><fa-icon [icon]="['fas','trash']" class="ml-2" style="margin-left: 0 !important;"></fa-icon></button>
      <button type="button" class="btn btn-success" (click)="saveFieldProperties()"><fa-icon [icon]="['fas','check']" class="ml-2" style="margin-left: 0 !important; margin-right: auto;"></fa-icon>&nbsp;Speichern</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  <!--</div>-->
</ng-template>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div style="visibility: hidden; position: fixed;"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="rightMenu"></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <!--<button mat-menu-item>Clicked {% raw %}{{item.content}}{% endraw %}</button>-->
    <button mat-menu-item *ngIf="item.mode=='field'" (click)="editFieldProperties(item.data.Id, fieldProperties)">Bearbeiten</button>
    <button mat-menu-item *ngIf="item.mode=='row'" (click)="editRow(editRowModalRef, item.data)">Bearbeiten</button>
    <button mat-menu-item *ngIf="item.mode=='visible'" (click)="openModal(editVisibleModalRef)">Sichtbare Felder</button>
  </ng-template>
</mat-menu>
