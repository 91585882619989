
<container-element [ngSwitch]="_tag" *ngIf="mode!=-1">
  <h1 (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'h1'"><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></h1>
  <h2 (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'h2'"><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></h2>
  <h3 (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'h3'"><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></h3>
  <h4 (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'h4'"><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></h4>
  <h5 (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'h5'"><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></h5>
  <div (dblclick)="doEdit()" style="display: flex;" *ngSwitchCase="'p'" class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;<span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></div>
  <span (dblclick)="doEdit()" style="display: flex;" *ngSwitchDefault><div class="ro-field" [innerHtml]="transform(_row.FieldsTranslated[_fieldId])">&nbsp;</div><span (click)="doEdit()" *ngIf="page.AllowEdit && mode==0 && isEdit" class="mat-icon  material-icons ro-edit-icon ro-edit-icon ">edit_note</span></span>
</container-element>

<container-element [ngSwitch]="_tag" *ngIf="mode==-1">
  <h1 *ngSwitchCase="'h1'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</h1>
  <h2 *ngSwitchCase="'h2'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</h2>
  <h3 *ngSwitchCase="'h3'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</h3>
  <h4 *ngSwitchCase="'h4'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</h4>
  <h5 *ngSwitchCase="'h5'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</h5>
  <p *ngSwitchCase="'p'">{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</p>
  <span *ngSwitchDefault>{{_schemaResolver[_fieldId].FieldType.startsWith('input')?'Input Feld':'Feld'}}: {{_schemaResolver[_fieldId].DisplayName}}</span>
</container-element>
