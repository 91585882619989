
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
    typeof define === 'function' && define.amd ? define(['exports'], factory) :
      (factory((global['fa6-regular-svg-icons'] = {})));
}(this, (function (exports) { 'use strict';

  var prefix = "fa-regular";
  var faCircleEllipsis = {
    prefix: 'fa-regular',
    iconName: 'circle-ellipsis',
    icon: [512, 512, [], "e10a", "M256 224C238.3 224 224 238.3 224 256c0 17.67 14.33 32 32 32s32-14.33 32-32C288 238.3 273.7 224 256 224zM368 224c-17.67 0-32 14.33-32 32c0 17.67 14.33 32 32 32s32-14.33 32-32C400 238.3 385.7 224 368 224zM144 224c-17.67 0-32 14.33-32 32c0 17.67 14.33 32 32 32s32-14.33 32-32C176 238.3 161.7 224 144 224zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256S512 397.4 512 256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"]
  };


  var _iconsCache = {
    faCircleEllipsis: faCircleEllipsis
  };

  exports.faRegular = _iconsCache;
  exports.faCircleEllipsis = faCircleEllipsis;

  Object.defineProperty(exports, '__esModule', { value: true });

})));
