import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {KanbanMoveItem} from "../as-kanban.component";
import {CanvasReadyInfo, FieldDropdownOption, TableRow} from "../../api-classes/api-classes";
import {ApiManagerService} from "../../api-manager/api-manager.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {Router} from "@angular/router";

@Component({
  selector: 'app-as-kanban-list',
  templateUrl: './as-kanban-list.component.html',
  styleUrls: ['./as-kanban-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsKanbanListComponent implements OnInit {

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  menuTopLeftPosition =  {x: '0', y: '0'}


  @Input() newTab = false;
  @Input() miniTitle = '';
  @Input() bucketSumString = '';
  @Input() cardMode = 'default';
  @Input() tableName = '';
  @Input() bucketWidth = 250;
  @Input() theme = 'base';
  @Input() bucket: FieldDropdownOption = new FieldDropdownOption();
  @Input() tableRows: TableRow[] = [];
  @Input() detailsPageLink = '';

  canvasCache: { [id: string]: HTMLCanvasElement; } = {};
  @Output() editRow = new EventEmitter<string>();
  @Output() moveRow = new EventEmitter<KanbanMoveItem>()
  @Input() bucketH = 400;
  listHeight = 800;
  isDark = false;
  constructor(private apiManagerService: ApiManagerService, private router: Router) { }

  ngOnInit(): void {
    /*if (this.category != null && this.categoryId !== '') {
      this.setBucket(this.categoryId);
    }*/
    this.isDark = this.apiManagerService.getProfile().Theme === 'dark';
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    this.listHeight = h - 470 + 80;
    if (this.listHeight < 100) {
      this.listHeight = 100;
    }
  }

  canvasInfo(info: CanvasReadyInfo) {
    this.canvasCache[info.ApplicationId] = info.Canvas;
  }

  openCard(appId) {
    this.editRow.emit(appId);
    if (this.detailsPageLink !== '') {
      for(let i = 0; i < this.tableRows.length; i++) {
        if (this.tableRows[i].Id == appId) {
          const tmp = this.detailsPageLink.replace('{id}', this.tableRows[i].PublicId);
          if (this.newTab) {
            window.open(tmp);
          } else {
            this.router.navigateByUrl(tmp);
          }
        }
      }

    }
  }

  allowDrop($event) {
    $event.preventDefault();
    const data = this.apiManagerService.idBuffer;
    let isNotLocal = true;

    Object.entries(this.tableRows).forEach(([key, row]) => {
      if (row.Id == data) {
        isNotLocal=false;
      }
    });

    if (isNotLocal) {
      const outerCard = document.getElementById('dummy_insert_card_' + this.bucket.Id);
      outerCard.style.display = 'flex';
      const card = document.getElementById('dummy_insert_' + this.bucket.Id);
      card.innerHTML = this.apiManagerService.htmlBuffer;
    }

  }

  setBucket(bucketId) {

  }

  dragLeave() {
    const card = document.getElementById('dummy_insert_card_' + this.bucket.Id);
    card.style.display = 'none';
  }

  dragCard(ev, rowId) {
    const card = document.getElementById('dummy_' + this.bucket.Id);
    card.style.top = (ev.y - 72) + 'px';
    card.style.left = (ev.x - 157) + 'px';
  }

  dragStart(ev, rowId) {
    ev.dataTransfer.setData('text', rowId);

    const card = document.getElementById('card_' + rowId);
    const img = document.createElement('img');
    card.style.opacity = '.4';
    img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==';
    img.style.height = '1px';
    img.style.width = '1px';
    ev.dataTransfer.setDragImage(img, 25, 25);

    const dummy = document.getElementById('dummy_' + this.bucket.Id);
    dummy.innerHTML = card.innerHTML;
    this.apiManagerService.htmlBuffer = card.innerHTML;
    this.apiManagerService.idBuffer = rowId;
    dummy.style.display = '';
    dummy.style.position = 'fixed';
    dummy.style.top = (ev.y - 72) + 'px';
    dummy.style.left = (ev.x - 157) + 'px';
    dummy.style.zIndex = '1200';
    dummy.style.width = '314px';
    dummy.style.pointerEvents = 'none';
    if (!this.isDark) {
      dummy.style.background = '#fff';
      dummy.style.borderColor = '1px solid grey';
    } else {
      dummy.style.background = '#37404a';
      dummy.style.borderColor = '1px solid grey';
    }
  }

  dragEnd(ev, rowId) {
    const card = document.getElementById('card_' + rowId);
    const dummy = document.getElementById('dummy_' + this.bucket.Id);
    dummy.innerHTML = '';
    card.style.opacity = '1';
    this.clearDummys();
    /*dummy.style.display = 'none';
    dummy.style.position = '';
    dummy.style.top = '';
    dummy.style.left = '';
    dummy.style.zIndex = '';
    dummy.style.width = '';
    dummy.style.background = '';
    dummy.style.background = '';
    dummy.style.borderColor = '';*/
    // card.style.pointerEvents = '';
  }

  clearDummys() {
    const dummys = document.getElementsByClassName('drag-dummy');
    for (let i = 0; i < dummys.length; i++) {
      // @ts-ignore
      dummys[i].style.display = 'none';
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    //if (changes.categoryId.currentValue !== changes.categoryId.previousValue) {
      //this.setBucket(changes.categoryId.currentValue);
    //}
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }

  drop($event) {
    $event.preventDefault();
    const data = $event.dataTransfer.getData('text');
    this.clearDummys();
    if (data != null && data !== '') {
      const move = new KanbanMoveItem();
      move.TargetBucketId = this.bucket.Id;
      move.RowId = data;
      this.moveRow.emit(move);
    }

    const card = document.getElementById('dummy_insert_card_' + this.bucket.Id);
    card.style.display = 'none';
  }

  setCardTheme(theme: string, row: TableRow) {
    row.CardClasses = theme;
    const move = new KanbanMoveItem();
    move.RowId = row.Id;
    move.TargetTheme = theme;
    this.moveRow.emit(move);
  }

  onRightClick(event: MouseEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = {item: item}

    // we open the menu
    this.matMenuTrigger.openMenu();

  }
}
