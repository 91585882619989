import {Component, HostListener, OnInit} from '@angular/core';
import {AuthUser, Page, TableRow} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-deal-details',
  templateUrl: './deal-details.component.html',
  styleUrls: ['./deal-details.component.css']
})
export class DealDetailsComponent implements OnInit {

  recordId = '-';
  record: TableRow = new TableRow();
  rows: TableRow[] = []
  tableName = 'deals';
  pageName = 'test-deal';
  returnPage = '/crm/deals';
  profile = new AuthUser()
  page = new Page();
  mode = 0;
  marginOverride = '';
  widthOverride = '';
  showSidebar = false;

  sidebarHeightRule = '';

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private router: Router,private title: Title) { }

  ngOnDestroy(): void {
    //document.body.style.overflow = '';

    this.apiManagerService.noContainerBorder = false;
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    if (w > 1400) {
      this.marginOverride = 'margin-left: 250px;';
      this.widthOverride = 'width: 250px;';
    } else {
      this.marginOverride = '';
      this.widthOverride = '';
    }
    this.sidebarHeightRule = 'height: ' + (h - 58) + 'px;';
  }

  ngOnInit(): void {
    this.detectScreenSize();
    this.apiManagerService.getPage(this.pageName).then((page) => {
      this.page = page;
      if (page.DefaultMode == -1 && page.AllowChange) {
        this.mode = -1;
      } else if (page.DefaultMode == 1 && page.AllowEdit) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    });

    this.profile = this.apiManagerService.getProfile();
    this.activatedRoute.paramMap.subscribe(params => {
      const id = this.recordId = params.get('id');
      if (this.apiManagerService.cacheSchemas[this.tableName] && this.apiManagerService.cacheRows[this.tableName]) {
        this.rows = this.apiManagerService.syncRowsSchema(this.apiManagerService.cacheRows[this.tableName], this.apiManagerService.cacheSchemas[this.tableName]);
        this.loadRecord(this.apiManagerService.cacheRows[this.tableName], id)
      } else {
        this.apiManagerService.getTableSchema(this.tableName).then((schema) =>
        {
          this.apiManagerService.getTableEntities(this.tableName).then((rows) => {
            this.rows = this.apiManagerService.syncRowsSchema(rows, schema);
            this.loadRecord(rows, id)
          }).catch((e) => {
            this.router.navigateByUrl(this.returnPage);
          });
        }).catch((e) => {
          this.router.navigateByUrl(this.returnPage);
        });
      }
    });
  }

  saveData() {
    this.apiManagerService.setTableEntity(this.record);
    this.mode = 0;
  }

  loadRecord(rows: TableRow[], id: string) {
    let found = false;
    for(let i = 0; i < rows.length; i++) {
      if (rows[i].Id == id || rows[i].PublicId == id) {
        this.record = rows[i];
        this.title.setTitle(rows[i].Description + ' - Astralus Admin Portal');
        found = true;
      }
    }

    if (!found) {
      this.router.navigateByUrl(this.returnPage);
    } else {
      this.recordId = id;
    }
  }

}
