<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">Admin</a></li>
    <li class="breadcrumb-item"><a routerLink="/mail/blocked-senders">Gesperrte Absender</a></li>
    <li class="breadcrumb-item active" aria-current="page">Details</li>
  </ol>
</nav>

<div class="col-xl-12">
  <section class="hk-sec-wrapper">
    <div class="col-sm" style="margin-top:5px;">
      <div class="table-wrap">
        <table class="table table-hover mb-0">
          <thead>
          <tr>
            <th>E-Mail</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><input class="form-control" type="email" (keydown.enter)="addAlias()" [value]="newAlias" (change)="newAlias=$any($event.target).value"/></td>
            <td>
              <a title="Hinzufügen" (click)="addAlias()" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-plus-circle" style="font-size: 11px;"></i>
                  </span>
              </a>
            </td>
          </tr>
          <tr *ngFor="let member of blockList">
            <td>{{member.Mail}}</td>
            <td>
              <a title="Entfernen" (click)="removeAlias(member.Mail)" class="" style="margin-left: auto; display: flex; cursor:pointer; color: darkgray; margin-right: 4px;">
                  <span class="">
                    <i class="fa-solid fa-trash" style="color: darkred; font-size: 11px;"></i>
                  </span>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<app-as-flex-table [allowAddField]="false" [allowDrag]="true" [allowResize]="true" [allowEdit]="false" noBreakSearch="true" hasLogos="true" hasSearch="true" addBtnStyle="position: absolute;top: 0px;" tableName="contracts" detailsPageLink="/crm/contracts/{id}"></app-as-flex-table>-->
      </div>
    </div>
  </section>
</div>
