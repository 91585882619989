
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && schemaResolver[item.Options].FieldType == 'text' || schemaResolver[item.Options].FieldType == 'datetime-local' || ((!tenant.GooglePlaces || tenant.GoogleApiKey == '') && schemaResolver[item.Options].FieldType.startsWith('google-search')) || schemaResolver[item.Options].FieldType.startsWith('google-result')">
  <input id="{{sessionId}}" (keydown.escape)="saveEdit()" (keydown.enter)="saveEdit()" (focusout)="saveEdit()" style="margin: 2px;" type="{{schemaResolver[item.Options].FieldType=='datetime-local'?'datetime-local':'text'}}" [value]="row.Fields[item.Options]" (input)="row.Fields[item.Options]=$any($event.target).value; reportChanges()" class="form-control" />
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<!--<div style="display: flex; width: 100%;" *ngIf="(_mode==1 || singleEdit) && schemaResolver[item.Options].FieldType == 'textarea'">
  <textarea (keydown.escape)="saveEdit()" (focusout)="saveEdit()" style="margin: 2px;" type="text" [value]="row.Fields[item.Options]" (input)="row.Fields[item.Options]=$any($event.target).value; reportChanges()" class="form-control" ></textarea>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>-->
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && (tenant.GooglePlaces && tenant.GoogleApiKey != '' && schemaResolver[item.Options].FieldType.startsWith('google-search'))">
  <input id="{{sessionId}}" (keydown.escape)="saveEdit()" (keydown.enter)="saveEdit()" (focusout)="saveEdit()" style="margin: 2px;" ngx-google-places-autocomplete [value]="row.Fields[item.Options]" (input)="row.Fields[item.Options]=$any($event.target).value; reportChanges()" [options]="googleOptions" (onAddressChange)="handleAddressChange(schemaResolver[item.Options], $event)" class="form-control form_field_{{item.Options}}"/>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && (schemaResolver[item.Options].FieldType == 'linked' || schemaResolver[item.Options].FieldType == 'linked1') && _lazyLoadCount == 0">
  <as-ng-select2 style="width: 100%;" [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId1" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && schemaResolver[item.Options].FieldType == 'linked2' && _lazyLoadCount == 0">
  <as-ng-select2 style="width: 100%;" [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId2" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && schemaResolver[item.Options].FieldType == 'linked3' && _lazyLoadCount == 0">
  <as-ng-select2 style="width: 100%;" [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId3" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && schemaResolver[item.Options].FieldType == 'linked4' && _lazyLoadCount == 0">
  <as-ng-select2 style="width: 100%;" [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId4" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="(_mode==1 || singleEdit || permEdit) && schemaResolver[item.Options].FieldType == 'dropdown'">
  <as-ng-select2 style="width: 100%;" [data]="dropOptions[item.Options]" [value]="row.Fields[item.Options]" (valueChanged)="row.Fields[item.Options]=$event; reportChanges()"> </as-ng-select2>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>
<div style="display: flex; width: 100%;" *ngIf="!item.Type.startsWith('ro_') && (_mode==1 || singleEdit || permEdit) && (schemaResolver[item.Options].FieldType == 'rte' || schemaResolver[item.Options].FieldType == 'textarea')">
  <div [ngxSummernote]="summerConfig"  [ngModel]="row.Fields[item.Options]" (ngModelChange)="row.Fields[item.Options]=$event"></div>
  <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
</div>



<app-as-renderer-ro-text [isEdit]="!item.Type.startsWith('ro_')" (singleEditStart)="doEdit()" *ngIf="item.Type.startsWith('ro_') || (_mode!=1 && !singleEdit && !permEdit) || schemaResolver[item.Options].FieldType=='text-ro'" [dropOptions]="dropOptions" [lazyLoadCount]="_lazyLoadCount" [sessionId]="sessionId" [page]="page" [mode]="_mode" [item]="item" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-ro-text>
