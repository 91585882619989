import { Injectable } from '@angular/core';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AsToastService {

  toasts: ToastManager[] = [];

  constructor() {
  }

  public defaultOptions: ToastOptions = {
    text: '',
    heading: '',
    showHideTransition: 'fade',
    allowToastClose: true,
    hideAfter: 3000,
    loader: true,
    loaderBg: '#9EC600',
    stack: 5,
    position: 'top-right',
    bgColor: '',
    textColor: '',
    textAlign: 'left',
    icon: '',
    class: '',
    beforeShow: function (id: string) {
    },
    afterShown: function (id: string) {
    },
    beforeHide: function (id: string) {
    },
    afterHidden: function (id: string) {
    },
    onClick: function (id: string) {
    },
  };

  internalDefaultOptions: ToastOptions = {
    text: '',
    heading: '',
    showHideTransition: 'fade',
    allowToastClose: true,
    hideAfter: 3000,
    loader: true,
    loaderBg: '#9EC600',
    stack: 5,
    position: 'top-right',
    bgColor: '',
    textColor: '',
    textAlign: 'left',
    icon: '',
    class: '',
    beforeShow: function (id: string) {
    },
    afterShown: function (id: string) {
    },
    beforeHide: function (id: string) {
    },
    afterHidden: function (id: string) {
    },
    onClick: function (id: string) {
    },
  };

  public prepareOptions(options: ToastOptions): ToastOptions {
    // Auto restore defaults if set to null
    if (this.defaultOptions == null) {
      // Avoid a direct reference so that the internal fallback default can't be manipulated.
      this.defaultOptions = JSON.parse(JSON.stringify(this.internalDefaultOptions));
    }

    if (options == null) { // Fallback if the options object is empty.
      return this.prepareOptions(this.defaultOptions);
    }

    // Set null values to default
    if (options.text == null) {
      if (this.defaultOptions.text == null) {
        options.text = this.internalDefaultOptions.text;
      } else {
        options.text = this.defaultOptions.text;
      }
    }

    if (options.heading == null) {
      if (this.defaultOptions.heading == null) {
        options.heading = this.internalDefaultOptions.heading;
      } else {
        options.heading = this.defaultOptions.heading;
      }
    }

    if (options.showHideTransition == null) {
      if (this.defaultOptions.showHideTransition == null) {
        options.showHideTransition = this.internalDefaultOptions.showHideTransition;
      } else {
        options.showHideTransition = this.defaultOptions.showHideTransition;
      }
    }

    if (options.allowToastClose == null) {
      if (this.defaultOptions.allowToastClose == null) {
        options.allowToastClose = this.internalDefaultOptions.allowToastClose;
      } else {
        options.allowToastClose = this.defaultOptions.allowToastClose;
      }
    }

    if (options.hideAfter == null) {
      if (this.defaultOptions.hideAfter == null) {
        options.hideAfter = this.internalDefaultOptions.hideAfter;
      } else {
        options.hideAfter = this.defaultOptions.hideAfter;
      }
    }
    if (options.loader == null) {
      if (this.defaultOptions.loader == null) {
        options.loader = this.internalDefaultOptions.loader;
      } else {
        options.loader = this.defaultOptions.loader;
      }
    }
    if (options.loaderBg == null) {
      if (this.defaultOptions.loaderBg == null) {
        options.loaderBg = this.internalDefaultOptions.loaderBg;
      } else {
        options.loaderBg = this.defaultOptions.loaderBg;
      }
    }
    if (options.stack == null) {
      if (this.defaultOptions.stack == null) {
        options.stack = this.internalDefaultOptions.stack;
      } else {
        options.stack = this.defaultOptions.stack;
      }
    }
    if (options.position == null) {
      if (this.defaultOptions.position == null) {
        options.position = this.internalDefaultOptions.position;
      } else {
        options.position = this.defaultOptions.position;
      }
    }
    if (options.bgColor == null) {
      if (this.defaultOptions.bgColor == null) {
        options.bgColor = this.internalDefaultOptions.bgColor;
      } else {
        options.bgColor = this.defaultOptions.bgColor;
      }
    }
    if (options.textColor == null) {
      if (this.defaultOptions.textColor == null) {
        options.textColor = this.internalDefaultOptions.textColor;
      } else {
        options.textColor = this.defaultOptions.textColor;
      }
    }
    if (options.textAlign == null) {
      if (this.defaultOptions.textAlign == null) {
        options.textAlign = this.internalDefaultOptions.textAlign;
      } else {
        options.textAlign = this.defaultOptions.textAlign;
      }
    }
    if (options.icon == null) {
      if (this.defaultOptions.icon == null) {
        options.icon = this.internalDefaultOptions.icon;
      } else {
        options.icon = this.defaultOptions.icon;
      }
    }

    if (options.class == null) {
      if (this.defaultOptions.class == null) {
        options.class = this.internalDefaultOptions.class;
      } else {
        options.class = this.defaultOptions.class;
      }
    }

    if (options.beforeShow == null) {
      if (this.defaultOptions.beforeShow == null) {
        options.beforeShow = this.internalDefaultOptions.beforeShow;
      } else {
        options.beforeShow = this.defaultOptions.beforeShow;
      }
    }
    if (options.afterShown == null) {
      if (this.defaultOptions.afterShown == null) {
        options.afterShown = this.internalDefaultOptions.afterShown;
      } else {
        options.afterShown = this.defaultOptions.afterShown;
      }
    }
    if (options.beforeHide == null) {
      if (this.defaultOptions.beforeHide == null) {
        options.beforeHide = this.internalDefaultOptions.beforeHide;
      } else {
        options.beforeHide = this.defaultOptions.beforeHide;
      }
    }
    if (options.afterHidden == null) {
      if (this.defaultOptions.afterHidden == null) {
        options.afterHidden = this.internalDefaultOptions.afterHidden;
      } else {
        options.afterHidden = this.defaultOptions.afterHidden;
      }
    }
    if (options.onClick == null) {
      if (this.defaultOptions.onClick == null) {
        options.onClick = this.internalDefaultOptions.onClick;
      } else {
        options.onClick = this.defaultOptions.onClick;
      }
    }

    return options;
  }

  onToastClosed(id: string) {
    for (let i = 0; i < this.toasts.length; i++) {
      if (this.toasts[i].id === id) {
        this.toasts[i].cleanup();
        this.toasts.splice(i, 1);
        break;
      }
    }
  }

  public showInfoToastMessage(message, heading: string | null = null,
                              position: string|ToastPosition|null = null, showDelay: number | null = null): string {
    const options = new ToastOptions();
    options.text = message;
    options.heading = heading;
    options.icon = 'info';
    options.position = position;
    return this.showToast(options, showDelay);
  }

  public showSuccessToastMessage(message, heading: string | null = null,
                                 position: string|ToastPosition|null = null, showDelay: number | null = null): string {
    const options = new ToastOptions();
    options.text = message;
    options.heading = heading;
    options.icon = 'success';
    options.position = position;
    return this.showToast(options, showDelay);
  }

  public showWarningToastMessage(message, heading: string | null = null,
                                 position: string|ToastPosition|null = null, showDelay: number | null = null): string {
    const options = new ToastOptions();
    options.text = message;
    options.heading = heading;
    options.icon = 'warning';
    options.position = position;
    return this.showToast(options, showDelay);
  }

  public showErrorToastMessage(message, heading: string | null = null,
                               position: string|ToastPosition|null = null, showDelay: number | null = null): string {
    const options = new ToastOptions();
    options.text = message;
    options.heading = heading;
    options.icon = 'error';
    options.position = position;
    return this.showToast(options, showDelay);
  }

  public showToast(options = new ToastOptions(), showDelay: number | null = null): string {
    options = this.prepareOptions(options);
    const manager = new ToastManager(options, this.onToastClosed);
    this.toasts.push(manager);
    if (showDelay == null) {
      showDelay = 100;
    }

    if (showDelay < 100) {
      showDelay = 100;
    }

    manager.startProcess(showDelay);
    return manager.id;
  }

  public closeToast(toastId) {
    for (let i = 0; i < this.toasts.length; i++) {
      if (this.toasts[i].id === toastId) {
        this.toasts[i].close();
        break;
      }
    }
  }
}

export class ToastPosition {
  public top: number|null = null;
  public bottom: number|null = null;
  public left: number|null = null;
  public right: number|null = null;
}

export class ToastOptions {
  public text: string|null = null;
  public heading: string|null  = null;
  public showHideTransition: string|null = null;
  public allowToastClose: boolean|null = null;
  public hideAfter: number|null = null;
  public loader: boolean|null = null;
  public loaderBg: string|null = null;
  public stack: number|null = null;
  public position: string|ToastPosition|null = null;
  public bgColor: string|null = null;
  public textColor: string|null = null;
  public textAlign: string|null = null;
  public icon: string|null = null;
  public class: string|null = null;
  public beforeShow: (id: string) => void = null;
  public afterShown: (id: string) => void = null;
  public beforeHide: (id: string) => void = null;
  public afterHidden: (id: string) => void = null;
  public onClick: (id: string) => void = null;
}

class ToastManager {
  _positionClasses = ['bottom-left', 'bottom-right', 'top-right', 'top-left', 'bottom-center', 'top-center', 'mid-center'];
  _defaultIcons = ['success', 'error', 'info', 'warning'];

  public id = uuidv4();
  activeOptions: ToastOptions;
  onToastClosed: (id: string) => void;
  toastDiv: HTMLDivElement;
  containerDiv: HTMLDivElement;
  isClosed = false;
  constructor(options: ToastOptions, onClosed: (id: string) => void) {
    this.activeOptions = options;
    this.onToastClosed = onClosed;
  }

  public cleanup() {
    try {
        if (this.toastDiv != null) {
          this.toastDiv.remove();
        }
    } catch (e) {
    }
  }

  public startProcess(delay: number) {
    this.addToDom();
    this.setup();
    this.position();
    this.bindToast();
    setTimeout(() => this.doProcess(this), delay);
  }

  doProcess(self: ToastManager) {
    this.animate();
  }

  animate() {
    const self = this;

    self.toastDiv.style.display = 'none';

    if (self.activeOptions.showHideTransition.toLowerCase() === 'fade' ||
      self.activeOptions.showHideTransition.toLowerCase() === 'slide') {
      self.unfade(self.toastDiv);
    } else {
      this.toastDiv.dispatchEvent(new Event('beforeShow'));
      self.toastDiv.style.display = '';
      self.toastDiv.dispatchEvent(new Event('afterShown'));
    }

    if (this.canAutoHide()) {
      window.setTimeout(function () {
        self.close();
      }, self.activeOptions.hideAfter);
    }
  }

  addToDom() {
    this.toastDiv = document.createElement('div');
    this.toastDiv.style.display = 'none';
    this.toastDiv.classList.add('jq-toast-single');
    const containers = document.getElementsByClassName('jq-toast-wrap');
    if (containers.length === 0) {
      const container: HTMLDivElement = document.createElement('div');
      container.classList.add('jq-toast-wrap');
      container.setAttribute('role', 'alert');
      container.setAttribute('aria-live', 'polite');
      document.body.appendChild(container);
      this.containerDiv = container;
    } else if (this.activeOptions.stack != null && this.activeOptions.stack > 0) {
      this.containerDiv = containers[0] as HTMLDivElement;
      this.containerDiv.innerHTML = '';
    } else {
      this.containerDiv = containers[0] as HTMLDivElement;
    }

    this.containerDiv.appendChild(this.toastDiv);

    if (this.activeOptions.stack  && this.activeOptions.stack > 0) {

      const prevToastCount = this.containerDiv.childElementCount;
      const extToastCount = prevToastCount - this.activeOptions.stack;

      if ( extToastCount > 0 ) {
        const oldChild = this.containerDiv.children[0];
        this.containerDiv.removeChild(oldChild);
      }
    }
  }

  processLoader() {
    // Show the loader only, if auto-hide is on and loader is demanded
    if (!this.canAutoHide() || this.activeOptions.loader === false) {
      return false;
    }

    const loader = this.toastDiv.getElementsByClassName('jq-toast-loader')[0];

    // 400 is the default time that jquery uses for fade/slide
    // Divide by 1000 for milliseconds to seconds conversion
    const transitionTime = (this.activeOptions.hideAfter - 400) / 1000 + 's';
    const loaderBg = this.activeOptions.loaderBg;


    let style = loader.getAttribute('style') || '';
    style = style.substring(0, style.indexOf('-webkit-transition')); // Remove the last transition definition

    style += '-webkit-transition: width ' + transitionTime + ' ease-in; \
                          -o-transition: width ' + transitionTime + ' ease-in; \
                          transition: width ' + transitionTime + ' ease-in; \
                          background-color: ' + loaderBg + ';';

    loader.setAttribute('style', style);
    loader.classList.add('jq-toast-loaded');
  }

  fade(element) {
    let op = 1;  // initial opacity
    element.dispatchEvent(new Event('beforeHide'));
    const timer = setInterval(function () {
      if (op <= 0.1) {
        clearInterval(timer);
        element.style.display = 'none';
        element.dispatchEvent(new Event('afterHidden'));
      }
      element.style.opacity = op;
      element.style.filter = 'alpha(opacity=' + op * 100 + ')';
      op -= op * 0.1;
    }, 50);
  }

  unfade(element) {
    let op = 0.1;  // initial opacity
    element.dispatchEvent(new Event('beforeShow'));
    element.style.display = 'block';
    const timer = setInterval(function () {
      if (op >= 1) {
        clearInterval(timer);
        element.dispatchEvent(new Event('afterShown'));
      }
      element.style.opacity = op;
      element.style.filter = 'alpha(opacity=' + op * 100 + ')';
      op += op * 0.1;
    }, 10);
  }

  close() {
    if (!this.isClosed) {
      this.isClosed = true;
      if (this.activeOptions.showHideTransition === 'fade' ||
        this.activeOptions.showHideTransition === 'slide') {
        this.fade(this.toastDiv);
      } else {
        this.toastDiv.dispatchEvent(new Event('beforeHide'));
        this.toastDiv.style.display = 'none';
        this.toastDiv.dispatchEvent(new Event('afterHidden'));
      }
    }
  }

  bindToast() {
    const self = this;
    self.toastDiv.addEventListener('afterShown', function () {
      self.processLoader();
    });

    const closer = this.toastDiv.getElementsByClassName('close-jq-toast-single');
    if (closer.length > 0) {
      const tmp = closer[0] as HTMLDivElement;
      tmp.onclick = function (e) {
        e.preventDefault();
        self.close();
      };
    }

    self.toastDiv.addEventListener('beforeShow', function () {
      try {
        self.activeOptions.beforeShow(self.id);
      } catch (e) {
      }
    });

    self.toastDiv.addEventListener('afterShown', function () {
      try {
        self.activeOptions.afterShown(self.id);
      } catch (e) {
      }
    });

    self.toastDiv.addEventListener('beforeHide', function () {
      try {
        self.activeOptions.beforeHide(self.id);
      } catch (e) {
      }
    });

    self.toastDiv.addEventListener('afterHidden', function () {
      try {
        self.activeOptions.afterHidden(self.id);
      } catch (e) {
      }

      try {
        self.onToastClosed(self.id);
      } catch (e) {
      }

      self.isClosed = true;
    });

    self.toastDiv.onclick = function () {
      try {
        self.activeOptions.onClick(self.id);
      } catch (e) {
      }
    };

  }

  canAutoHide(): boolean {
    return this.activeOptions.hideAfter !== null && this.activeOptions.hideAfter > 0;
  }

  setup() {

    // For the loader on top
    let toastContent = '<span class="jq-toast-loader"></span>';

    if (this.activeOptions.allowToastClose ) {
      toastContent += '<span class="close-jq-toast-single">&times;</span>';
    }

    if (this.activeOptions.heading.trim()  !== '') {
      toastContent += '<h2 class="jq-toast-heading">' + this.activeOptions.heading + '</h2>';
    }
    toastContent += this.activeOptions.text;
    this.toastDiv.innerHTML = toastContent;

    if (this.activeOptions.bgColor.trim() !== '') {
      this.toastDiv.style.backgroundColor = this.activeOptions.bgColor;
    }

    if (this.activeOptions.textColor.trim() !== '') {
      this.toastDiv.style.color = this.activeOptions.textColor;
    }

    if (this.activeOptions.textAlign.trim() !== '') {
      this.toastDiv.style.textAlign = this.activeOptions.textAlign;
    }

    if (this.activeOptions.icon.trim() !== '') {
      this.toastDiv.classList.add('jq-has-icon');

      if (this._defaultIcons.includes(this.activeOptions.icon)) {
        this.toastDiv.classList.add('jq-icon-' + this.activeOptions.icon);
      }
    }

    if (this.activeOptions.class.trim() !== '') {
      this.toastDiv.classList.add(this.activeOptions.class);
    }
  }

  position() {
    this.containerDiv.style.top = '';
    this.containerDiv.style.bottom = '';
    this.containerDiv.style.left = '';
    this.containerDiv.style.right = '';
    for (let i = 0; i < this._positionClasses.length; i++) {
      if (this.containerDiv.classList.contains(this._positionClasses[i])) {
        this.containerDiv.classList.remove(this._positionClasses[i]);
      }
    }

    if (typeof this.activeOptions.position === 'string') {
      if (this.activeOptions.position === 'bottom-center' ) {
        this.containerDiv.style.left =  ((window.outerWidth / 2) - this.containerDiv.clientWidth / 2) + 'px';
        this.containerDiv.style.bottom =  '20px';
      } else if (this.activeOptions.position === 'top-center' ) {
        this.containerDiv.style.left =  ((window.outerWidth / 2) - this.containerDiv.clientWidth / 2) + 'px';
        this.containerDiv.style.top =  '20px';
      } else if (this.activeOptions.position === 'mid-center' ) {
        this.containerDiv.style.left =  ((window.outerWidth / 2) - this.containerDiv.clientWidth / 2) + 'px';
        this.containerDiv.style.top =  ((window.outerHeight / 2) - this.containerDiv.clientHeight / 2) + 'px';
      } else {
        this.containerDiv.classList.add(this.activeOptions.position );
      }
    } else if (typeof this.activeOptions.position === 'object') {
      const pos: ToastPosition = this.activeOptions.position;
      this.containerDiv.style.top =  this.activeOptions.position.top != null ? this.activeOptions.position.top + 'px' : 'auto';
      this.containerDiv.style.bottom =  this.activeOptions.position.bottom != null ? this.activeOptions.position.bottom + 'px' : 'auto';
      this.containerDiv.style.left =  this.activeOptions.position.left != null ? this.activeOptions.position.left + 'px' : 'auto';
      this.containerDiv.style.right =  this.activeOptions.position.right != null ? this.activeOptions.position.right + 'px' : 'auto';
    } else {
      this.containerDiv.classList.add('top-right');
    }
  }
}
