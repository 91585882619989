<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">CRM</a></li>
    <li class="breadcrumb-item active" aria-current="page">Verkaufschancen</li>
  </ol>
</nav>

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item">
    <a style="cursor: pointer;"  (click)="setTab(1)" class="nav-link {{activeMainTab==1?'active':''}}"><i class="fa-solid fa-location-crosshairs"></i>&nbsp;Verkaufschancen</a>
  </li>
  <li class="nav-item">
    <a style="cursor: pointer;" (click)="setTab(2)" class="nav-link {{activeMainTab==2?'active':''}}"><i class="fa-solid fa-buildings"></i>&nbsp;Unternehmen</a>
  </li>
  <li class="nav-item" style="margin-left: auto;">
    <app-as-flex-drop-button [menuEntries]="menu"></app-as-flex-drop-button>
  </li>
</ul>

<div class="col-xl-12">
  <section class="hk-sec-wrapper" *ngIf="activeMainTab==1">
    <h1 class="hk-sec-title">Verkaufschancen</h1>
    <div class="row">
      <div class="col-12" style="margin-top: 10px; margin-bottom: 5px;">
        <mat-icon (click)="kanbanMode = !kanbanMode" style="margin-left: 8px; float:left; cursor: pointer; position: absolute; left: 5px; margin-top: -10px; z-index: 5;" aria-label="Kanban" *ngIf="!kanbanMode">view_kanban</mat-icon>
        <mat-icon (click)="kanbanMode = !kanbanMode" style="margin-left: 8px; float:left; cursor: pointer; position: absolute; left: 5px; margin-top: -10px; z-index: 5;" aria-label="Tabellenansicht" *ngIf="kanbanMode">view_list</mat-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <app-as-flex-table hasStateSearch="true" hasSearch="true" [allowAddField]="false" noBreakSearch="true" addBtnStyle="position: absolute;top: -95px;" *ngIf="!kanbanMode" tableName="deals" detailsPageLink="/crm/deals/{id}"></app-as-flex-table>
        <div class="col-12" *ngIf="kanbanMode" style="overflow-x: auto; margin-top: 8px;">
          <app-as-kanban *ngIf="kanbanMode" bucketField="state" tableName="deals" theme="dg" detailsPageLink="/crm/deals/{id}"></app-as-kanban>
        </div>
      </div>
    </div>
  </section>

  <section class="hk-sec-wrapper" *ngIf="activeMainTab==2">
    <h1 class="hk-sec-title">Unternehmen</h1>
    <!--<div class="row">
      <div class="col-12" style="margin-top: 10px; margin-bottom: 5px;">
        <mat-icon (click)="kanbanMode = !kanbanMode" style="margin-left: 8px; float:left; cursor: pointer; position: absolute; left: 5px; margin-top: -10px; z-index: 5;" aria-label="Kanban" *ngIf="!kanbanMode">view_kanban</mat-icon>
        <mat-icon (click)="kanbanMode = !kanbanMode" style="margin-left: 8px; float:left; cursor: pointer; position: absolute; left: 5px; margin-top: -10px; z-index: 5;" aria-label="Tabellenansicht" *ngIf="kanbanMode">view_list</mat-icon>
      </div>
    </div>-->
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <app-as-flex-table [hasLogos]="true" [allowAddField]="false" [noBreakSearch]="true" [hasSearch]="true" addBtnStyle="position: absolute;top: -80px;" tableName="companies"  detailsPageLink="/crm/companies/{id}" [newTab]="true"></app-as-flex-table>
      </div>
    </div>
  </section>
</div>
