import {v4 as uuidv4} from 'uuid';

export enum FileUploadResults {
  Ok = 0,
  SshConnectError = 1,
  SshAuthError = 2,
  SshError = 3,
  VirusDetected = 4,
  ScanError = 5,
  UploadInvalid = 251,
  HttpError = 252,
  HttpNotFoundError = 253,
  UnknownError = 254,
  Forbidden = 255,
}

export class Page {
  public Id = '00000000-0000-0000-0000-000000000000';
  public TableName = '';
  public PageName = '';
  public IsHtml = false;
  public AllowEdit = false;
  public AllowChange = false;
  public DefaultMode = 0;
  public Data = '';
}

export class PageItem {
  public Id = uuidv4();
  public Type = 'div';
  public InnerHtml = '';
  public Options = '';
  public Options2 = '';
  public CssClasses = '';
  public Style = '';
  public Title = '';
  public Subtitle = '';
  public ChildrenHeader: PageItem[] = [];
  public Children: PageItem[] = [];
  public ChildrenFooter: PageItem[] = [];
}

export class PageItemMove {
  public ItemId = ''
  public SourceParentItemId = '';
  public TargetParentItemId = '';
  public Prepend = false;
}

export class ButtonEvent {
  public EventId = '';
  public Row: TableRow;
}

export class BroadcastData {
  public EventType = '';
  public EventData: any = null;
}

export class PageParentChildBundle {
  public Parent: PageItem;
  public Child: PageItem;
}

export class PageAddItemRequestData {
  public Parent: PageItem;
  public Section = ''
  public Append = false;
}

export class MenuEntry {
  public MenuName = '';
  public Route: string | null = null;
  public ActiveRoot: '';
  public SubMenuEntries: MenuEntry[] = [];
  public MenuId = 0;
  public Expanded = false;
  public IconClass = '';
  public IconHtml = '';
  public IconHtmlVertical = '';
}

export class Contact {
  public Id = '00000000-0000-0000-0000-000000000000';
  public TenantId = '00000000-0000-0000-0000-000000000000';
  public CompanyId: string|null = null;
  public CompanyName: string|null = null;
  public Displayname: string|null = '';
  public Firstname: string|null = '';
  public Lastname: string|null = '';
  public Email: string|null = '';
  public Phone: string|null = '';
  public Fax: string|null = '';
  public Mobile: string|null = '';
  public Street: string|null = '';
  public Street2: string|null = '';
  public City: string|null = '';
  public Region: string|null = '';
  public PostalCode: string|null = '';
  public Country: string|null = 'DE';
}

export class TransportEntry {
  public Mail = '';
  public Target = '';
}

export class Company {
  public Id = '00000000-0000-0000-0000-000000000000';
  public TenantId = '00000000-0000-0000-0000-000000000000';
  public CompanyName: string|null = null;
  public Displayname: '';
  public Email: string|null = '';
  public Phone: string|null = '';
  public Fax: string|null = '';
  public Street: string|null = '';
  public Street2: string|null = '';
  public City: string|null = '';
  public Region: string|null = '';
  public PostalCode: string|null = '';
  public Country: string|null = 'DE';
}

export class DashgrinOptions {
  public Theme = 'dashgrin';
  public NavDark = false;
  public TopDark = true;
  public Scroll = false;
  public Minimize = true;
  public Gradient = -1;
}


export class CanvasReadyInfo {
  public ApplicationId = '';
  public Canvas: HTMLCanvasElement;
}

export class Tenant {
  public Id =  '00000000-0000-0000-0000-000000000000';
  public IdentiqaId = '00000000-0000-0000-0000-000000000000';
  public Name = '';
  public IsEnabled = true;
  public LoginDomain = '';
  public DomainChangeCounter = 0;
  public GooglePlaces = false;
  public GoogleMaps = false;
  public GoogleApiKey: string|null = null;
  public Modules: string[] = [];
  public Notifications: Notification[] = [];
  public LogoDarkHash: string | null = null;
  public LogoDarkMime: string | null = null;
  public LogoLightHash: string | null = null;
  public LogoLightMime: string | null = null;
  public FavHash: string | null = null;
  public FavMime: string | null = null;
  public GradientAngle: number | null = null;
  public GradientStop1: number | null = null;
  public GradientStop2: number | null = null;
  public GradientColor1: string | null = null;
  public GradientColor2: string | null = null;
  public AccentSuccess: string | null = null;
  public CrmLink: string | null = null;
  public LastChange: string | null = null;
  public Created: string | null = null;
  public LastChangeString: string | null = null;
  public CreatedString: string | null = null;
  public UserCount = 0;
}

export class Notification {
  public Id =  '00000000-0000-0000-0000-000000000000';
  public TenantId =  '00000000-0000-0000-0000-000000000000';
  public Created =  '';
  public CreatedString =  '';
  public Message = '';
}

export class Feature {
  public Id =  '00000000-0000-0000-0000-000000000000';
  public ModuleId =  '00000000-0000-0000-0000-000000000000';
  public Name = '';
  public Description = '';
  public DescriptionShort = '';
  public Version = '';
  public MainScreenshot = '';
  public PriceSetup: number|null = null;
  public PriceInterval: number|null = null;
  public IsFree =false;
  public Interval = 0;
  public IntervalType = 'days';
  public NoticePeriod = 0;
  public NoticePeriodType = 'days';
  public HasTrial = false;
  public TrialInterval = 0;
  public TrialIntervalType = 'days';
  public OrderTime: string|null = null;
  public CancelTime: string|null = null;
  public CancelCompleted: string|null = null;
  public IsActive = false;
}

export class User {
  public Id = '00000000-0000-0000-0000-000000000000';
  public IdentiqaId = '00000000-0000-0000-0000-000000000000';
  public TenantId = '00000000-0000-0000-0000-000000000000';
  public Username = '';
  public DisplayName = '';
  public Firstname: string|null = null;
  public Lastname: string|null = null;
  public IsEnabled = false;
  public IsAdmin = false;
  public LastLogin: string|null = null;
  public LastLoginParsed = 'n/a';
  public CrmLink: string | null = null;
}

export class AuthUser {
  public Id = '00000000-0000-0000-0000-000000000000';
  public IdentiqaId = '00000000-0000-0000-0000-000000000000';
  public TenantId = '00000000-0000-0000-0000-000000000000';
  public TenantName = '';
  public TenantUrl = '';
  public Username = '';
  public DisplayName = '';
  public IsEnabled = false;
  public IsAdmin = false;
  public IsSuccess = false;
  public ModuleAccessLevels: { [name: string]: number } = {};
  public Menu: MenuEntry[] = [];
  public LastChange = new Date();
  public Token = '';
  public TokenValidUntil: Date | null = null;
  public RenewToken = '';
  public RenewTokenValidUntil: Date | null = null;
  public DefaultLayoutId = '';
  public Theme = 'light';
  public MenuDetached = false;
  public MenuCompact = false;
  public Settings: string|null = null;
}

export class TabSchema {
  public Id = uuidv4();
  public TabId = 99999;
  public Order = 99999;
  public Name = '';
  public IsHidden = false;
  public IsProtected = false;
  public IsDefault = false;
}

export class FieldDropdownOption {
  public Id = uuidv4();
  public Description = '';
  public IsDefault = false;
}

export class FieldMeta {
  public Id = uuidv4();
  public Name = '';
  public FieldType = 'text'; // text, longtext, checkbox, date, datetime
  public DisplayName = '';
  public TableOrder = 99999;
  public TableWidth: number|null = null;
  public IsHidden = false;
  public IsFormHidden = false;
  public IsCreateHidden = false;
  public IsEditHidden = false;
  public CantChangeHidden = false;
  public IsEssential = false;
  public IsDefaultDisplay = false;
  public LinkedTable: string|null = null;
  public DropOptions: FieldDropdownOption[] = null;
  public ExtraMeta: string|null = null;
}

export class TenantRange {
  public Id = uuidv4();
  public Name = '';
  public RangeTag = '';
  public RangeType = 0;
  public TenantId = '';
  public StartSeed = 1;
  public LastSeed: number|null = null;
  public RngMin: number|null = null;
  public RngMax: number|null = null;
  public Prepend: string|null = null;
  public Append: string|null = null;
  public SeedLen = 6;
}

export class FieldData {
  public FieldId = '';
  public Name: string|null = '';
  public Data: string|null = null;
}

export class MailAddress {
  public Email = '';
  public DisplayName: string|null = null;
}

export class MailAttachment {
  public Mime = '';
  public Filename = '';
  public IsEmbbedded = false;
  public ContendId: string|null = null;
  public Content: Uint8Array;
}

export class MailMessage {
  public From: MailAddress;
  public To: MailAddress[] = [];
  public Cc: MailAddress[] = [];
  public Bcc: MailAddress[] = [];
  public Sender: MailAddress = null;
  public ReturnPath: MailAddress = null;
  public Headers : { [name: string]: string } = {};
  public Attachments: MailAttachment[] = [];
  public Subject = '';
  public HtmlBody: string | null = null;
  public TextBody: string | null = null;
}

export class TableRow {
  public Id: string = uuidv4()
  public PublicId: string = '';
  public TenantId: string = '';
  public TableName: string = ''
  public LinkedId1: string | null = null;
  public LinkedId2: string | null = null;
  public LinkedId3: string | null = null;
  public LinkedId4: string | null = null;
  public Description: string = '';
  public LogoHash: string | null = null;
  public LogoMime: string | null = null;
  public OwnerId: string | null = null;
  public OwnerName: string | null = null;
  public GroupId: string | null = null;
  public AssignedAclId: string | null
  public Fields: { [id: string]: string | null } = {}
  public FieldsTranslated: { [id: string]: string | null } = {}
  public CardClasses = '';
  public Permission: number = 0
  public Created: string | null = null;
  public Updated: string | null = null;
  public CreatedDate: Date = null;
  public UpdatedDate: Date = null;
  public CreatedString: string | null = null;
  public UpdatedString: string | null = null;
  public FilterDate: Date = null;
  public HasLogo = false;
  public IsComplete = false;
  public LogoUrl = '';
  public LogoInitials = '';
  public ParsedTemp = '';
  public ParsedTemp2 = '';
  public FilterFields: { [id: string]: string | null } = {}
}

export class ApiResponseCacheEntry {
  public Id = '';
  public ResponseTime = '';
  public ResponseCode = 0;
  public Json = '';
  public RawUri = '';
}

export class TableAttachment {
  public Id: string = uuidv4()
  public RowId = '';
  public UploadTime = '';
  public UploadTimeDate = new Date();
  public UploadTimeString = '';
  public Filename = '';
  public Mime = '';
  public Hash = '';
  public OwnerId: string | null = null;
  public OwnerName: string | null = null;
}

export class SizeUpdate {
  public sourceId = '';
  public typeId = '';
  public fieldId = '';
  public size: number|null = null;
}

export class LauncherItem {
  public priority = 0;
  public id =  '';
  public name =  '';
  public shortName: string|null =  '';
  public link: string|null =  '';
  public group: string|null = '';
  public iconUrl: string|null = null;
  public cssIcon: string|null =  'fad fa-sign-out-alt';
  public cssIconColor: string|null =  null;
  public cssIconDarkColor: string|null =  null;
  public newTab = false;
  public expanded = false;
}

export class D3Data
{
  public names: string[] = [];
  public searchIds: string[] = [];
  public links: D3Link[] = [];
  public nodes: D3Node[] = [];
  //public tree: D3TreeNode[][] = [];
}

export class D3Link
{
  public source: string = '';
  public target: string = '';
  public value: number = 1;
}

export class D3Node
{
  public searchId: string = '';
  public id: string = '';
  public ver: string = '';
  public group: number = 0;
  public links: number = 1;
  public color: string = 'blue';
}

export class D3TreeNode {
  public id: string = '';
  public parents: string[] | null = null;
}

export class DepInfo {
  public appName: string = '';
  public data: D3Data = new D3Data();
}

export class MListData {
  public id = 0;
  public uniqueid: string|null = null;
  public listname = '';
  public listType = 0;
  public listPrivilege = 0;
  public associations: string[] = [];
}

export class DomainData {
  public id = 0;
  public domainName = '';
}

export class TildaProject {
  public id = 0;
  public title = '';
  public descr = '';
  public directory = '';
  public nginx_conf = '';
  public ssh = '';
  public s3_bucket = '';
}

export class TildaPage {
  public id = 0;
  public projectid = 0;
  public title = '';
  public descr = '';
  public alias = '';
  public filename = '';
}

export class Mailbox {
  public username = "";
  public uniqueid: string|null = null;
  public password: string|null = null;
  public status = 0;
  public properties = {
    displaytypeex: 0,
    displayname: "",
    displayto: "Exchange"
  }
  public aliases: string[] = [];
  public pop3_imap = false;
  public smtp = false;
  public changePassword = false;
  public lang = "de_DE";
  public platform = "Exchange";
}

export class MailboxShort {
  public domainId = 0;
  public id = 0;
  public status = 0;
  public username = '';
}

export const svgCodeIconVacancies: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.8 39.06"><defs><style></style></defs><g id="Layer_2" data-name="Layer 2"><g id="icons"><path class="menu-icon-fill" d="M17.4,19.08a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,17.4,19.08Zm0-7.63a2.13,2.13,0,1,1-2.13,2.13A2.13,2.13,0,0,1,17.4,11.45Zm0,17.41a1.5,1.5,0,0,0-1.5,1.5v7a1.5,1.5,0,0,0,3,0v-7A1.5,1.5,0,0,0,17.4,28.86Zm10.74-.27H24.51a.47.47,0,0,0-.46.32l-3.68,9.47a.49.49,0,0,0,.46.68H34.3a.5.5,0,0,0,.5-.5V35.25A6.66,6.66,0,0,0,28.14,28.59Zm-17.37.32a.5.5,0,0,0-.47-.32H6.67A6.67,6.67,0,0,0,0,35.25v3.31a.5.5,0,0,0,.5.5H14a.5.5,0,0,0,.47-.68Zm6.63-1.75A13.58,13.58,0,1,0,3.82,13.58,13.58,13.58,0,0,0,17.4,27.16Zm0-22.56a9,9,0,1,1-9,9A9,9,0,0,1,17.4,4.6Z"/></g></g></svg>';
