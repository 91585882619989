<div class="form-group row">
  <label class="col-sm-4 col-form-label text-md-right">E-Mail</label>
  <div class="col-sm-8 col-form-label" style="display:flex;">
    <div class="" *ngIf="mode==-1">Feld: {{_schemaResolver[_pageItem.Options].DisplayName}}</div>
    <div class="" *ngIf="mode!=-1">
      <span *ngIf="email!=''">{{email}}</span>
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-4 col-form-label text-md-right">Telefon</label>
  <div class="col-sm-8 col-form-label" style="display:flex;">
    <div class="" *ngIf="mode==-1">Feld: {{_schemaResolver[_pageItem.Options].DisplayName}}</div>
    <div class="" *ngIf="mode!=-1">
      <span *ngIf="phone!=''">{{phone}} (Büro)</span><br *ngIf="phone!=''">
      <span *ngIf="fax!=''">{{fax}} (Fax)</span><br *ngIf="fax!=''">
      <span *ngIf="mobile!=''">{{mobile}} (Mobil)</span><br *ngIf="mobile!=''">
    </div>
  </div>
</div>
