import {
  Component,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  SimpleChanges, TemplateRef, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {FieldDropdownOption, FieldMeta, TableRow} from "../api-classes/api-classes";
import {ApiNotification} from "../api-notifications/api-notifications";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AsToastService} from "../as-toast.service";

export class KanbanMoveItem {
  public RowId = '';
  public TargetBucketId = '';
  public TargetTheme = '';
}

@Component({
  selector: 'app-as-kanban',
  templateUrl: './as-kanban.component.html',
  styleUrls: ['./as-kanban.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsKanbanComponent implements OnInit,OnDestroy {


  _hasChanges = false;
  resetOnClose = false;
  tableRowLogo: File = null;
  tableRow: TableRow;
  isNewRow = false;

  @ViewChild('editRowModalRef', { read: TemplateRef }) editRowModalRef:TemplateRef<any>;

  _theme = 'base';
  _tableName = ''
  _bucketField = ''
  _cardMode = 'default';
  @Input() hasLogos = false;
  @Input() set cardMode(value: string) {
    try {
      if (value == null || value.trim() == '') {
        value = 'default';
      }

      this._cardMode = value
      this.orderSchema();
      this.sortRows();
    } catch (e) {
      //
    }
  }

  @Input() newTab = false;
  @Input() detailsPageLink = '';
  @Input() miniTitle = '';
  @Input() bucketStyle = 'margin-right: 16px;';
  @Input() bucketWidth = 250;
  @Output() editRow = new EventEmitter<string>();
  @Input() revision = 0;
  @Input() baseThemeCss = '';
  @Input() set bucketField(value: string) {
    this._bucketField = value;
    this.reloadAll();
  }
  @Input() set tableName(value: string) {
    this._tableName = value;
    this.reloadAll();
  }

  @Input() set theme(value: string) {
    switch(value) {
      case 'dg':
      case 'dashgrin':
        this._theme = 'dg';
        break;
      default:
        this._theme = 'base';
        break;
    }
  }

  buckets: FieldDropdownOption[] = []

  // @Output() moveApplication = new EventEmitter<KanbanMoveItem>();
  listHeight = 300;
  bucketH = 200;
  loading = true;

  tableSchema: FieldMeta[] = [];
  //filteredSchema: FieldMeta[] = [];

  tableRows: TableRow[] = [];
  sortedRows: { [name: string]: TableRow[] } = {};
  sortedSums: { [name: string]: number } = {};
  sortedSumsParsed: { [name: string]: string } = {};

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private toast: AsToastService) { }
  notificationSession = '';
  ngOnDestroy(): void {
    this.apiManagerService.notifications.unsubscribe(this.notificationSession);
  }

  checkAddReady(self: AsKanbanComponent) {
    if (self.loading) {
      setTimeout(() => self.checkAddReady(self), 250);
    } else {
      self.addRow(self.editRowModalRef);
    }
  }

  ngOnInit(): void {
    this.notificationSession = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType == "signalr-row-update") {
        const data:TableRow = notification.EventData;
        if (data != null && data.TableName == this._tableName) {
          this.reloadAll();
        }
      }

      if ("add-row-flex-" + this._tableName == notification.EventType) {
        this.checkAddReady(this);
      }

    });

    this.detectScreenSize();
    this.calcMax();
  }

  onLogoSet(file: File) {
    this.tableRowLogo = file;
    this._hasChanges = true;
  }

  addRow(modalRef) {
    this.apiManagerService.closeModal(this.modalService);

    this._hasChanges = false;
    this.resetOnClose = false;
    this.isNewRow = true;
    this.tableRowLogo = null;
    this.tableRow = this.syncSingleRowSchema(new TableRow());
    const p = this.apiManagerService.getProfile();
    this.tableRow.TenantId = p.TenantId;
    this.tableRow.OwnerId = p.Id;
    this.tableRow.TableName = this._tableName;
    this.apiManagerService.openModal(this.modalService, modalRef);
    this.apiManagerService.unhideEditor();
  }

  syncSingleRowSchema(row: TableRow): TableRow {
    let exists: { [name: string]: boolean } = {}
    for (let x = 0; x < this.tableSchema.length; x++) { // reset the existing state per row
      exists[this.tableSchema[x].Id] = false;
    }

    Object.entries(row.Fields).forEach( // detect existing fields
      ([key, value]) => {
        exists[key] = true;
      }
    );

    Object.entries(exists).forEach( // create missing fields
      ([key, value]) => {
        if (!value) {
          row.Fields[key] = '';
        }
      }
    );

    this.apiManagerService.translateRow(row, this.tableSchema);
    return row;
  }

  saveRow() {
    this.apiManagerService.closeModal(this.modalService);
    if (this._hasChanges) {
      const file = this.tableRowLogo;
      this.resetOnClose = false;
      if (this.isNewRow) {
        this.apiManagerService.setTableEntity(this.tableRow).then((e) => {
          if (e != null) {
            this.tableRows.push(e);
            this.tableRows = this.apiManagerService.syncRowsSchema(this.tableRows, this.tableSchema);

            this.orderSchema();
            this.sortRows();
            if (file != null) {
              this.apiManagerService.uploadEntityFile(e.Id, file, true).then((success) => {
                this.reloadAll(true);
              }).catch((e) => {
                this.reloadAll(true);
              })
            }
          }
        });
      } else {
        for (let i = 0; i < this.tableRows.length; i++) {
          if (this.tableRows[i].Id == this.tableRow.Id) {
            this.tableRows[i] = this.tableRow;
          }
        }

        this.apiManagerService.setTableEntity(this.tableRow).then((e) => {
          if (file != null) {
            this.apiManagerService.uploadEntityFile(e.Id, file, true).then((success) => {
              this.reloadAll(true);
            }).catch((e) => {
              this.reloadAll(true);
            })
          }
        });
      }
    } else {
      this.toast.showInfoToastMessage('Keine Änderungen festgestellt');
    }
  }



  orderSchema() { // filter the schema fields to only show visible fields in the table
    let schema = this.tableSchema;
    schema.sort((a,b) => a.TableOrder - b.TableOrder);
    let countId = 0;
    for (let i = 0; i < schema.length; i++) {
      schema[i].TableOrder = countId;
      countId += 10;
      if (schema[i].Id == this._bucketField) {
        if (schema[i].DropOptions != null) {
          this.buckets = schema[i].DropOptions;
        }
      }
    }
  }


  // make sure that always all fields from the schema exists in the rows (could be missing for new fields that were not set for the record
  syncRowSchema(rows: TableRow[]): TableRow[] {
    let exists: { [name: string]: boolean } = {} // dictionary to keep track of existing fields

    for (let i = 0; i < rows.length; i++) {
      for (let x = 0; x < this.tableSchema.length; x++) { // reset the existing state per row
        exists[this.tableSchema[x].Id] = false;
      }

      Object.entries(rows[i].Fields).forEach( // detect existing fields
        ([key, value]) => {
          exists[key] = true;
        }
      );

      Object.entries(exists).forEach( // create missing fields
        ([key, value]) => {
          if (!value) {
            rows[i].Fields[key] = '';
          }
        }
      );

      // ensure that dropdowns are populated with the default value
      Object.entries(this.tableSchema).forEach(
        ([key, meta]) => {
          if (meta.FieldType === 'dropdown') {
            if (rows[i].Fields[meta.Id] == null || rows[i].Fields[meta.Id].trim() === '') {
              if (meta.DropOptions != null) {
                Object.entries(meta.DropOptions).forEach(
                  ([key, opt]) => {
                    if (opt.IsDefault) {
                      rows[i].Fields[meta.Id] = opt.Id;
                    }
                  });
              }
            }
          }
        });
    }

    return rows;
  }

  sortRows() {
    let sorted: { [name: string]: TableRow[] } = {}
    let sums: { [name: string]: number } = {}
    let parsedSums: { [name: string]: string } = {}
    Object.entries(this.buckets).forEach(
      ([key,bucket]) => {
        sorted[bucket.Id] = [];
        sums[bucket.Id] = 0.0;

        if (this._cardMode == 'currency') {
          parsedSums[bucket.Id] = '0,00 €';
        } else {
          parsedSums[bucket.Id] = '';
        }
      });

    let defaultBucketVal = '';
    Object.entries(this.tableSchema).forEach(
      ([key, meta]) => {
        if (meta.Id === this._bucketField) {
          if (meta.DropOptions != null) {
            for(let i = 0; i < meta.DropOptions.length - 1; i++) {
              if (meta.DropOptions[i].IsDefault) {
                defaultBucketVal = meta.DropOptions[i].Id;
                break;
              }
            }
          }
        }
      });

    for (let i = 0; i < this.tableRows.length; i++) {
      const row = this.tableRows[i];
      if (this.tableRows[i].Fields[this._bucketField] == '') {
        this.tableRows[i].Fields[this._bucketField] = defaultBucketVal;
      }

      if (this.tableRows[i].Fields[this._bucketField] != '') {
        row.CreatedDate = new Date(Date.parse(row.Created))

        row.CreatedString = this.apiManagerService.formatDate2(row.CreatedDate, false);

        if (this._cardMode == 'currency') {
          if (row.Fields['value'] != undefined && row.Fields['value'] != null &&  row.Fields['value'].trim() != '') {
            let euroGerman = Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            });

            let raw =  row.Fields['value'].trim();
            let re = /\./gi;
            let re2 = /,/gi;

            let result = raw.replace(re, "");
            result = result.replace(re2, ".");
            let parsed = parseFloat(result);
            sums[this.tableRows[i].Fields[this._bucketField]] += parsed;
            parsedSums[this.tableRows[i].Fields[this._bucketField]] = row.ParsedTemp = euroGerman.format(sums[this.tableRows[i].Fields[this._bucketField]]);

            row.ParsedTemp = euroGerman.format(parsed);
          } else {
            parsedSums[this.tableRows[i].Fields[this._bucketField]] = '0,00 €';
            row.ParsedTemp = '0,00 €';
          }
        }

        sorted[this.tableRows[i].Fields[this._bucketField]].push(this.tableRows[i]);
      }
    }

    this.sortedSumsParsed = parsedSums;
    this.sortedSums = sums;
    this.sortedRows = sorted;
  }

  reloadAll(force = false){
    if (this._tableName.trim() == '' || this._bucketField == '') {
      this.tableSchema = [];
      this.tableRows = [];
      this.buckets = [];
      this.loading = false;
    } else {
      this.loading = true;
      if (this.apiManagerService.cacheSchemas[this._tableName] &&  this.apiManagerService.cacheRows[this._tableName]) {
        this.tableSchema = this.apiManagerService.cacheSchemas[this._tableName];
        this.tableRows =  this.apiManagerService.cacheRows[this._tableName];
        this.orderSchema();
        this.sortRows();
        this.loading = false;
      }
      this.apiManagerService.getTableSchema(this._tableName).then((schema) => {
        if (schema != null) {
          this.tableSchema = schema;
          this.orderSchema();
          this.apiManagerService.getTableEntities(this._tableName). then((rows) => {
            if (rows != null) {
              this.tableRows = this.syncRowSchema(rows);
              this.sortRows();
              setTimeout(() => this.sortRows(), 250);
            }
          })
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    /*if (changes.revision.currentValue !== changes.revision.previousValue) {
      //this.bundle = this.apiManagerService.kanbanBundle;
      this.calcMax();
    }*/
  }

  calcMax() {
    let newH = 400;
    for (let i = 0; i < this.buckets.length; i++) {
      const catId = this.buckets[i].Id;
      const tmpH = (this.sortedRows[this.buckets[i].Id].length + 1) * 200;
      if (tmpH > newH) {
        newH = tmpH;
      }
    }

    this.bucketH = newH;
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    this.listHeight = h - 370 + 80;
    if (this.listHeight < 300) {
      this.listHeight = 300;
    }
  }

  openCard(rowId) {
    this.editRow.emit(rowId);
  }

  moveRequest(move: KanbanMoveItem) {
    Object.entries(this.tableRows).forEach(([keys, row]) => {
      if (row.Id === move.RowId) {
        let save = false;
        if (move.TargetBucketId != '') {
          row.Fields[this._bucketField] = move.TargetBucketId;
          save = true;
        }
        if (move.TargetTheme != '') {
          row.CardClasses = move.TargetTheme;
          save = true;
        }

        if (save) {
          this.apiManagerService.setTableEntity(row).then((e) => {

          });
        }
      }
    });

    this.sortRows();
    // this.moveApplication.emit(move);
  }

}
