import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ProfileLoaderComponent} from './profile-loader/profile-loader.component';
import {DealsComponent} from "./deals/deals.component";
import {AsFeatureShopDgComponent} from "./as-feature-shop-dg/as-feature-shop-dg.component";
import {AsFeatureShopDgDetailsComponent} from "./as-feature-shop-dg-details/as-feature-shop-dg-details.component";
import {AsUsersAdminComponent} from "./as-users-admin/as-users-admin.component";
import {DealDetailsComponent} from "./deal-details/deal-details.component";
import {AsAppShopDgDetailsComponent} from "./as-app-shop-dg-details/as-app-shop-dg-details.component";
import {AsAppShopDgComponent} from "./as-app-shop-dg/as-app-shop-dg.component";
import {CaseDetailsComponent} from "./case-details/case-details.component";
import {SettingsCommonComponent} from "./settings-common/settings-common.component";
import {AsUserDetailsComponent} from "./as-user-details/as-user-details.component";
import {OrdersComponent} from "./orders/orders.component";
import {OrderDetailsComponent} from "./order-details/order-details.component";
import {MlistsComponent} from "./mlists/mlists.component";
import {MlistComponent} from "./mlist/mlist.component";
import {MailboxesComponent} from "./mailboxes/mailboxes.component";
import {MailboxComponent} from "./mailbox/mailbox.component";
import {TildaComponent} from "./tilda/tilda.component";
import {BlockedSendersComponent} from './blocked-senders/blocked-senders.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'features', component: DashboardComponent },
  { path: 'settings', component: DashboardComponent },
  { path: 'identiqa/login', component: ProfileLoaderComponent },
  { path: 'admin/settings', component: SettingsCommonComponent },
  { path: 'admin/users', component: AsUsersAdminComponent },
  { path: 'admin/users/:id', component: AsUserDetailsComponent },
  { path: 'admin/features', component: AsFeatureShopDgComponent },
  { path: 'admin/features/:id', component: AsFeatureShopDgDetailsComponent },
  { path: 'admin/apps', component: AsAppShopDgComponent },
  { path: 'admin/apps/:id', component: AsAppShopDgDetailsComponent },
  { path: 'support/case/:id', component: CaseDetailsComponent },
  { path: 'mail/distributionlists', component: MlistsComponent },
  { path: 'mail/distributionlists/:id', component: MlistComponent },
  { path: 'mail/blocked-senders', component: BlockedSendersComponent },
  { path: 'mail/mailboxes', component: MailboxesComponent },
  { path: 'mail/mailboxes/:id', component: MailboxComponent },
  { path: 'tilda', component: TildaComponent },
  { path: 'crm/deals', component: DealsComponent },
  { path: 'crm/deals/:id', component: DealDetailsComponent },
  { path: 'crm/orders', component: OrdersComponent },
  { path: 'crm/orders/:id', component: OrderDetailsComponent },
  { path: 'login', component: ProfileLoaderComponent },
  { path: 'login', component: ProfileLoaderComponent },
  { path: 'logout', component: ProfileLoaderComponent },
  { path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
