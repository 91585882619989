<div class="form-group row" *ngIf="!item.Type.startsWith('ro_') && item.Options!='' && schemaResolver[item.Options].FieldType!='text-ro' && schemaResolver[item.Options].FieldType!='generate_id' && (_mode==1 || (_mode==0 && singleEdit))">
  <label class="col-sm-4 col-form-label text-md-right">{{schemaResolver[item.Options].DisplayName}}</label>
  <div style="display: flex" class="col-sm-8" *ngIf="schemaResolver[item.Options].FieldType == 'text' || schemaResolver[item.Options].FieldType == 'datetime-local' || ((!tenant.GooglePlaces || tenant.GoogleApiKey == '') && schemaResolver[item.Options].FieldType.startsWith('google-search')) || schemaResolver[item.Options].FieldType.startsWith('google-result')">
    <input id="{{sessionId}}" (keydown.escape)="saveEdit()" (keydown.enter)="saveEdit()" (focusout)="saveEdit()" style="margin: 2px;" type="{{schemaResolver[item.Options].FieldType=='datetime-local'?'datetime-local':'text'}}" [value]="row.Fields[item.Options]" (input)="row.Fields[item.Options]=$any($event.target).value; reportChanges()" class="form-control" />
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && (tenant.GooglePlaces && tenant.GoogleApiKey != '' && schemaResolver[item.Options].FieldType.startsWith('google-search'))">
    <input id="{{sessionId}}" (keydown.escape)="saveEdit()" (keydown.enter)="saveEdit()" (focusout)="saveEdit()" style="margin: 2px;" ngx-google-places-autocomplete [value]="row.Fields[item.Options]" (input)="row.Fields[item.Options]=$any($event.target).value; reportChanges()" [options]="googleOptions" (onAddressChange)="handleAddressChange(schemaResolver[item.Options], $event)" class="form-control form_field_{{item.Options}}"/>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && (schemaResolver[item.Options].FieldType == 'linked' || schemaResolver[item.Options].FieldType == 'linked1') && _lazyLoadCount == 0">
    <as-ng-select2 [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId1" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && schemaResolver[item.Options].FieldType == 'linked2' && _lazyLoadCount == 0">
    <as-ng-select2 [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId2" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && schemaResolver[item.Options].FieldType == 'linked3' && _lazyLoadCount == 0">
    <as-ng-select2 [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId3" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && schemaResolver[item.Options].FieldType == 'linked4' && _lazyLoadCount == 0">
    <as-ng-select2 [options]="options" [data]="dropOptions[item.Options]" [value]="row.LinkedId4" (valueChanged)="setLink(schemaResolver[item.Options].FieldType, schemaResolver[item.Options].LinkedTable, item.Options, $event)"> </as-ng-select2>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
  <div class="col-sm-8" *ngIf="!item.Type.startsWith('ro_') && schemaResolver[item.Options].FieldType == 'dropdown'">
    <as-ng-select2 [data]="dropOptions[item.Options]" [value]="row.Fields[item.Options]" (valueChanged)="row.Fields[item.Options]=$event; reportChanges()"> </as-ng-select2>
    <span (click)="saveEdit()" *ngIf="page.AllowEdit && singleEdit" class="mat-icon  material-icons rw-edit-icon">check_circle</span>
  </div>
</div>
<div class="form-group row" *ngIf="item.Type.startsWith('ro_') || (item.Options!='' && _mode!=1 && !singleEdit) || schemaResolver[item.Options].FieldType=='text-ro' || schemaResolver[item.Options].FieldType=='generate_id'">
  <label class="col-sm-4 col-form-label text-md-right">{{schemaResolver[item.Options].DisplayName}}</label>
  <div class="col-sm-8 col-form-label" style="display:flex;">
    <div (click)="openLink(row, schemaResolver[item.Options])" style="{{schemaResolver[item.Options].FieldType.startsWith('link')?'text-decoration: underline; cursor: pointer':''}}" (dblclick)="doEdit()" class="ro-field">{{_mode==-1?'Feld: ' + schemaResolver[item.Options].DisplayName:schemaResolver[item.Options].FieldType=='datetime-local'?convertDateField(item.Options):row.FieldsTranslated[item.Options]}}</div>
    <span (click)="doEdit()" *ngIf="!item.Type.startsWith('ro_') && page.AllowEdit && _mode==0 && schemaResolver[item.Options].FieldType!='text-ro' && schemaResolver[item.Options].FieldType!='generate_id'" class="mat-icon  material-icons ro-edit-icon ">edit_note</span>
    <!--<i (click)="doEdit()" *ngIf="page.AllowEdit && _mode==0 && schemaResolver[item.Options].FieldType!='text-ro'" class="fa-solid fa-pen-to-square ro-edit-icon"></i>-->
    <!--<input *ngIf="_mode!=-1" style="margin: 2px;" type="text" [value]="row.Fields[item.Options]" class="form-control" disabled readonly />
    <input *ngIf="_mode==-1" style="margin: 2px;" type="text" value="Feld: {{schemaResolver[item.Options].DisplayName}}" class="form-control" disabled readonly />-->
  </div>
</div>
