<nav class="hk-breadcrumb" aria-label="breadcrumb" *ngIf="record != null">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/dashboard">CRM</a></li>
    <li class="breadcrumb-item"><a routerLink="/crm/leads">Verkaufschancen</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{record.Description}}</li>
  </ol>

</nav>

<div style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px;">
  <div style="display: flex; padding: 0; flex-wrap: wrap;">
    <div class="row" style="width: 100%; padding: 0;">
      <div class="col-12" style="padding: 0;">
        <i tooltipClass="tooltip-light" ngbTooltip="Diese Ansicht anpassen" class="fa-solid fa-layer-plus" style="float:right; margin-top: 0px; cursor: pointer; margin-left: 5px; margin-right: -15px;" *ngIf="mode==0 && profile.IsAdmin && page.AllowChange" (click)="mode=-1"></i>
        <i tooltipClass="tooltip-light" ngbTooltip="Schließen" class="fa-solid fa-circle-xmark" style="float:right; margin-top: 0px; cursor: pointer; margin-right: 15px;" *ngIf="mode==-1 && page.AllowChange" (click)="mode=0"></i>
        <i tooltipClass="tooltip-light" ngbTooltip="Daten bearbeiten" class="fa-solid fa-pencil" style="float:right; margin-top: 0px; cursor: pointer;" *ngIf="mode==0 && page.AllowEdit" (click)="mode=1"></i>
        <i tooltipClass="tooltip-light" ngbTooltip="Speichern" class="fa-solid fa-check-circle" style="float:right; margin-top: 0px; cursor: pointer; margin-right: 15px;" *ngIf="mode==1 && page.AllowEdit" (click)="saveData()"></i>
      </div>
    </div>
    <div class="row" style="margin-top:15px;">
      <div class="col-12">
        <app-as-renderer [mode]="mode" [rowId]="recordId" [pageName]="pageName"></app-as-renderer>
      </div>
    </div>
  </div>
</div>



