<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item active" aria-current="page">Einstellungen</li>
  </ol>
</nav>

<div class="col-xl-12">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title">Einstellungen</h1>

    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs nav-sm nav-light mb-25">
          <li [ngbNavItem]="1">
            <a ngbNavLink (click)="activeTab=1">Allgemein</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Tenant Name:</label>
                    <div class="col-sm-10" style="display: flex;">
                      <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30" [value]="tenant.Name" (input)="tenant.Name=$any($event.target).value" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" style="display: flex; width: 100%;">
                    <button class="btn btn-success btn-block btn-reset mt-30" style="width: 150px; float:right; margin-left:auto; margin-bottom: 15px;" (click)="saveName()">Speichern</button>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label" style="margin-top: auto;margin-bottom: auto;">Tenant Domain:</label>
                    <div class="col-sm-10" style="display: flex; flex-width: wrap;">
                      <input style="margin: 2px; width: 227px;" maxlength="20" [disabled]="tenant.DomainChangeCounter >= 5" type="text" [value]="subdomain" (input)="setSubdomain($any($event.target).value)" class="form-control" /><div style="margin-top: auto;margin-bottom: auto;">.ordiance.app <i style="color: {{domainStateColor}}" [ngbTooltip]="domainStateTooltip" tooltipClass="tooltip-light" class="fa-solid {{domainStateClass}}"></i></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" style="display: flex; width: 100%;" *ngIf="tenant.DomainChangeCounter<5">
                    <button class="btn btn-success btn-block btn-reset mt-30" [disabled]="origSubdomain==subdomain || domainState!=1" style="width: 150px; float:right; margin-left:auto;" (click)="askDomainChange(updateDomainRef)">Aktualisieren</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink (click)="activeTab=2">Layout</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="col-12" style="width: 100%;" >
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Dark Logo:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px;margin-top: auto;margin-bottom: auto; max-width: 360px;" type="file" (change)="handleFileInput($any($event.target).files, 'dark')" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Light Logo:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px;margin-top: auto;margin-bottom: auto; max-width: 360px;" type="file" (change)="handleFileInput($any($event.target).files, 'light')" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Aktzent Farbe:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [(colorPicker)]="tenant.AccentSuccess" [style.background]="tenant.AccentSuccess" [value]="tenant.AccentSuccess" readonly class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Gradient Winkel*:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [value]="gradientAngle" digitOnly [min]="0" max="360" (input)="gradientAngle=$any($event.target).value" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Gradient Farbe 1*:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [(colorPicker)]="tenant.GradientColor1" [style.background]="tenant.GradientColor1" [value]="tenant.GradientColor1" readonly class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Gradient Farbe 2*:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [(colorPicker)]="tenant.GradientColor2" [style.background]="tenant.GradientColor2" [value]="tenant.GradientColor2" readonly class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Gradient Stop 1 in %:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [value]="gradientStop1" digitOnly [min]="0" max="360" (input)="gradientStop1=$any($event.target).value" class="form-control" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2 col-form-label">Gradient Stop 2 in %:</label>
                      <div class="col-sm-10" style="display: flex;">
                        <input style="margin: 2px; max-width: 360px;" type="text" [value]="gradientStop2" digitOnly [min]="0" max="360" (input)="gradientStop2=$any($event.target).value" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" style="display: flex; width: 100%;">
                      <button class="btn btn-success btn-block btn-reset mt-30" style="width: 100px; float:right; margin-left:auto;" (click)="saveGradient()">Speichern</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="showGoogle">
            <a ngbNavLink (click)="activeTab=3">Features</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">
                  <div class="row" style="display: flex;">
                    <div class="col-12" style="width: 100%;" >

                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Google API Key:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px;margin-top: auto;margin-bottom: auto; max-width: 360px;" type="text" [value]="googleKey" (input)="googleKey=$any($event.target).value;" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" style="display: flex; width: 100%;">
                  <button class="btn btn-success btn-block btn-reset mt-30" style="width: 100px; float:right; margin-left:auto;" (click)="saveGoogle()">Speichern</button>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink (click)="activeTab=4">Listen</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12">

                </div>
              </div>

            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2" style="min-height: 60px;"></div>
      </div>
    </div>
  </section>
</div>

<ng-template #updateDomainRef let-modal class="modal fade" id="updateDomainRef-2" aria-labelledby="updateDomainRefLabel-2">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="updateDomainRefLabel-2">
        Domain aktualisieren
        <!--<a style="cursor: pointer;" (click)="copyStreetFinal()" title="Adresse kopieren" >
          <fa-icon [icon]="['far','copy']" class="ml-2" style="margin-left: auto; margin-right: auto;"></fa-icon>
        </a>-->
      </h5>

      <button type="button" style="margin-left: 0;" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <span>Soll die Domain wirklich geändert werden in {{subdomain}}.ordiance.app?</span><br/>
          <span> Es sind maximal noch {{5-tenant.DomainChangeCounter}} Änderungen möglich.</span><br/>
          <span><b>Warnung die Änderung wird sofort aktiv und unter der alten Domain {{tenant.LoginDomain}} wird kein Zugriff mehr möglich sein!</b></span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="saveDomain()"><fa-icon [icon]="['fas','check']" class="ml-2" style="margin-left: 0 !important;"></fa-icon>&nbsp;Aktualisieren</button>
      <button type="button" class="btn btn-light" (click)="modal.close()">Abbrechen</button>
    </div>
  </div>
</ng-template>
