<div class="card-body p-3" (dblclick)="openCard()" style="cursor: pointer; padding-top: 1rem !important; padding-bottom: 0 !important;" id="card_{{tableRow.Id}}" (mouseover)="mouseOver()" *ngIf="theme == 'base'">
  <!--<small class="float-right text-muted">{{application.CreatedString}}</small>-->
  <!--<span class="badge badge-danger">High</span>-->
  <!--<span style="float: left;">
    <mat-icon *ngIf="!application.IsMarked" (click)="setMarked(true)" class="material-icons-outlined" style="margin-left: 0px; float:right; cursor: pointer; margin-top: auto; font-size: 16px;" aria-label="Markieren">feedback</mat-icon>
    <mat-icon *ngIf="application.IsMarked"(click)="setMarked(false)" style="margin-left: 0px; float:right; cursor: pointer; margin-top: auto; font-size: 16px;" aria-label="Markierung aufheben">feedback</mat-icon>
    <mat-icon *ngIf="!application.IsFavorite" (click)="setFavorite(true)" style="margin-left: 0px; float:right; cursor: pointer; margin-top: auto; font-size: 18px;" aria-label="Als Favorit markieren">star_outline</mat-icon>
    <mat-icon *ngIf="application.IsFavorite"(click)="setFavorite(false)" style="margin-left: 0px; float:right; cursor: pointer; margin-top: auto; font-size: 18px;" aria-label="Favoriten Markierung aufheben">star</mat-icon>
  </span>-->
  <h4 class="mt-2 mb-2">
   <a href="#" (click)="openCard()" class="text-body">{{tableRow.Description}}</a>
  </h4>

  <!--<p class="mb-0">
    <span class="pr-2 text-nowrap mb-2 d-inline-block">
        <mat-icon class="text-muted" style="font-size: 16px;">work_outline</mat-icon>
        <a href="#" (click)="openCard()" class="text-body">{{application.Role}}</a>
    </span><br/>
    <span class="text-nowrap mb-2 d-inline-block">
        <mat-icon class="material-icons-outlined text-muted" style="font-size: 16px;">email</mat-icon>
        <a class="text-body" href="mailto:{{application.Email}}">{{application.Email}}</a>
    </span>
  </p>-->
</div>
<div style="pointer-events: none; cursor: pointer;" id="card_{{tableRow.Id}}"  (mouseover)="mouseOver()"  id="card_{{tableRow.Id}}" class="card {{cardCss}}" *ngIf="theme == 'dg'">

  <div class="card-body {{cardBodyCss}}" style="display: flex; flex-wrap: wrap;">
    <!--<h5 class="card-title text-warning">Warning Card</h5>-->
    <p  class="card-text" style="width: 100%;">{{tableRow.Description}}</p>
    <div style="display: flex; font-size: 10px; margin-top: auto; width: 100%;" *ngIf="cardMode=='currency'">
      <div>{{tableRow.CreatedString}}</div>
      <div style="margin-left: auto; float: right">{{tableRow.ParsedTemp}}</div>
    </div>
  </div>
</div>

