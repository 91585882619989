import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";
import {ApiManagerService} from "../api-manager/api-manager.service";

@Component({
  selector: 'app-as-flex-drop-button',
  templateUrl: './as-flex-drop-button.component.html',
  styleUrls: ['./as-flex-drop-button.component.css']
})
export class AsFlexDropButtonComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService) { }

  colorOverride = '';
  menuTopLeftPosition =  {x: '0', y: '0'}
  @Input() menuEntries: AsFlexDropButtonItem[] = []
  @Input() disable = false;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  ngOnInit(): void {
    if (this.apiManagerService.tenant.AccentSuccess != null && this.apiManagerService.tenant.AccentSuccess.trim() != '') {
      this.colorOverride = 'color: ' + this.apiManagerService.tenant.AccentSuccess + ';';
    }
  }

  closeMenu() {
    try {
      this.matMenuTrigger.closeMenu();
    }catch (e) {

    }
  }

  fireEvent(entry: AsFlexDropButtonItem) {
    this.closeMenu()
    if (entry.EventTarget != '') {
      this.apiManagerService.notifications.targetNotify(entry.EventType, entry.EventData, entry.EventTarget);
    } else {
      this.apiManagerService.notifications.notify(entry.EventType, entry.EventData);
    }
  }

  doClick(event: MouseEvent) {
    if (this.disable) {
      return;
    }
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    this.matMenuTrigger.openMenu();

  }
}

export class AsFlexDropButtonItem {
  public Displayname = '';
  public EventType = '';
  public EventTarget = '';
  public EventData: any;
}
