import {Component, HostListener, OnInit} from '@angular/core';
import {ApiResponseCacheEntry, AuthUser, D3Link, D3Node, DepInfo, Page, TableRow} from "../api-classes/api-classes";
import {AsFlexDropButtonItem} from "../as-flex-drop-button/as-flex-drop-button.component";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {Chart, ChartDataset, ChartOptions, ChartType} from "chart.js";
import {color} from "chart.js/helpers";
import {ApiNotification} from "../api-notifications/api-notifications";
import {TreemapController, TreemapElement} from "chartjs-chart-treemap";

class structureSearchResult {
  public id: string
  public name: string;
  public address: string
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  bilanzTab = 1;
  financeTab = 1;
  activeTab = 1;
  recordId = '-';
  record: TableRow = new TableRow();
  rows: TableRow[] = []
  tableName = 'orders';
  pageName = 'test-order';
  returnPage = '/crm/orders';
  profile = new AuthUser()
  page = new Page();
  mode = 0;
  marginOverride = '';
  widthOverride = '';
  showSidebar = false;
  hasStructureData = this.apiManagerService.hasStructureData();
  ndResponse: ApiResponseCacheEntry = new ApiResponseCacheEntry();
  hasSupport = this.apiManagerService.tenant.Modules.includes('51038834-320f-4c31-8f85-cfdfb2a3050c')
  sidebarHeightRule = '';
  ndResponseParsed: any;
  mapOptions: google.maps.MapOptions = null;
  marker: any;
  prevMarkers: any[] = [];
  ndHasPrevAddress = false;
  ndHasPrevNames = false;
  ndHasExtras = false;
  ndHasEvents = false;
  ndHasEventsMarketing = false;
  ndHasEventsControl = false;
  ndHasBilanz = false;
  ndHasGuV = false;
  ndHasNetwork = false;
  ndHasBilanzEnd = false;
  ndBilanzHtml = '';
  ndGuvHtml = '';
  ndBilanzEndDate = '';
  ndBilanzEndTitle = '';
  ndPublications: any[] = []
  ndPublicationsFiltered: any[] = []
  ndPublicationsMax = 50;
  searchResults: structureSearchResult[] = []
  subscriberId = '';

  menu: AsFlexDropButtonItem[] = []

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private router: Router,private title: Title) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  ndNetwork: DepInfo = new DepInfo();
  public searchId = '';
  public searchType = 'company';
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartLabels: string[] = [];
  public barChartDataProfit: ChartDataset[] = [
    { data: [], label: '' },
    { data: [], label: 'Schätzung' }
    //{ data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];
  public barChartDataRevenue: ChartDataset[] = [
    { data: [], label: '' },
    { data: [], label: 'Schätzung' }
  ];
  public barChartDataBalance: ChartDataset[] = [
    { data: [], label: '' },
    { data: [], label: 'Schätzung' }
  ];

  public treeOpts =  {
    maintainAspectRatio: false,
    legend: { display: false },
    tooltips: { enabled: false },
    responsive: true,
  }
  public treeBilanzActiva: any[] = [
    {
      tree: [],
      label: '',
      key: 'value',
      groups: ['tag'],
      spacing: 0.5,
      borderWidth: 1.5,
      labels: {
        display: true,
        color: color('white').rgbString()
      },
      backgroundColor: (ctx) => this.colorFromRawActiva(ctx),
    },
  ];

  public treeBilanzPassiva: any[] = [
    {
      tree: [],
      label: '',
      key: 'value',
      groups: ['tag'],
      spacing: 0.5,
      borderWidth: 1.5,
      labels: {
        display: true,
        color: color('white').rgbString()
      },
      color: color('black').rgbString(),
      backgroundColor: (ctx) => this.colorFromRawPassiva(ctx),
    },
  ];

  maxActiva = 0
  maxPassiva = 0

  colorFromRawActiva(ctx) {
    if (ctx.type !== 'data') {
      return 'transparent';
    }
    if (this.maxActiva == 0) {
      this.maxActiva = 1;
    }
    const value = ctx.raw.v;
    //let alpha = (1 + Math.log(value)) / this.maxActiva;
    let alpha = value / this.maxActiva
    if (alpha < 0.2) {
      alpha = 0.2;
    }
    const c = 'green';

    return color(c)
      .alpha(alpha)
      .rgbString();
  }

  colorFromRawPassiva(ctx) {
    if (ctx.type !== 'data') {
      return 'transparent';
    }
    if (this.maxPassiva == 0) {
      this.maxPassiva = 1;
    }
    const value = ctx.raw.v;
    let alpha = value / this.maxPassiva
    if (alpha < 0.2) {
      alpha = 0.2;
    }
    const c = 'blue';

    return color(c)
      .alpha(alpha)
      .rgbString();
  }

  ngOnDestroy(): void {
    //document.body.style.overflow = '';

    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
    this.apiManagerService.noContainerBorder = false;
  }

  generateNode(id, searchId, group, color = 'brown'): D3Node {
    const r = new D3Node();
    r.id=id;
    r.searchId=searchId;
    r.group=group;
    r.color = color;
    return r;
  }

  generateLink(source,target): D3Link {
    const r = new D3Link();
    r.value = 1;
    r.target = target;
    r.source = source;
    return r;
  }

  openNetworkPartner(id: string) {
    if (id != 'parent' && !id.startsWith('person-')) {
      this.ndResponse.ResponseCode = 0;
      id = id.replace('company-', '');

      //@ts-ignore
      window.cpDetails.searchId = id;
      //@ts-ignore
      window.cpDetails.activeTab = 0;
      //@ts-ignore
      window.cpDetails.resetNd();
      setTimeout(() => {
        //@ts-ignore
        window.cpDetails.setTab(4);
      }, 250);
    }
  }

  resetNd(){
    this.mapOptions = null;
    this.marker = null;
    this.prevMarkers = [];
    this.ndHasPrevAddress = false;
    this.ndHasPrevNames = false;
    this.ndHasExtras = false;
    this.ndHasEvents = false;
    this.ndHasEventsMarketing = false;
    this.ndHasEventsControl = false;
    this.ndHasBilanz = false;
    this.ndHasGuV = false;
    this.ndHasNetwork = false;
    this.ndHasBilanzEnd = false;
    this.ndBilanzHtml = '';
    this.ndGuvHtml = '';
    this.ndBilanzEndDate = '';
    this.ndBilanzEndTitle = '';
    this.ndPublications = []
  }

  morePublications(): boolean {
    this.ndPublicationsMax += 50;
    this.filterPublications();
    return false;
  }

  filterPublications() {
    this.ndPublicationsFiltered = [];
    for(let i = 0; i < this.ndPublications.length && i < this.ndPublicationsMax; i++) {
      this.ndPublicationsFiltered.push(this.ndPublications[i]);
    }
  }

  setTab(tabId: number) {
    switch(tabId) {
      case 1:
        this.pageName = 'test-order';
        break;
      case 2:
        this.pageName = 'test-order-detail';
        break;
      case 3:
        this.pageName = 'test-order-supplier';
        break;
      case 4:
        this.pageName = 'test-order-billing';
        break;
    }
    /*if (tabId == 4 && this.hasStructureData) {
      this.ndPublications = [];
      this.ndPublicationsFiltered = [];
      this.ndPublicationsMax = 50;
      this.ndNetwork = new DepInfo();
      this.ndHasNetwork = false;
      let searchCompany = 'extras=true&history=true&events=true&sheets=true&financials=true&relations=true&address=' + encodeURIComponent(this.record.Fields['street'] +", D-" + this.record.Fields['postalcode'] + ' ' + this.record.Fields['city']) + '&name=' + encodeURIComponent(this.record.Description)
      if (this.searchId != '') {
        searchCompany = 'extras=true&history=true&events=true&sheets=true&financials=true&relations=true&id=' +this.searchId;
      }
      this.apiManagerService.getStructureJson('company', searchCompany).then((raw) => {
        if (raw != null) {
          if (raw.ResponseCode == 200) {
            this.apiManagerService.getStructureJson('publications', searchCompany).then((rows) => {
              if (rows != null) {
                if (rows.ResponseCode == 200) {
                  const parsed = JSON.parse(rows.Json);
                  if (parsed.publications != undefined && parsed.publications != null) {
                    this.ndPublications = parsed.publications;
                    this.filterPublications();
                  }
                }
              }
            });
            let bilanzSplitter: { [name: string]: any[] } = {}
            bilanzSplitter['Aktiva'] = [];
            bilanzSplitter['Passiva'] = [];
            this.ndResponse = raw;
            this.ndResponseParsed = JSON.parse(raw.Json);

            this.barChartLabels = [];

            this.treeBilanzActiva[0].tree = [];
            this.treeBilanzPassiva[0].tree = [];
            this.barChartDataProfit[0].data = [];
            this.barChartDataRevenue[0].data = [];
            this.barChartDataBalance[0].data = [];
            this.barChartDataProfit[1].data = [];
            this.barChartDataRevenue[1].data = [];
            this.barChartDataBalance[1].data = [];

            this.ndNetwork.data.names.push(this.ndResponseParsed.name.name);
            this.ndNetwork.data.searchIds.push(this.ndResponseParsed.id);
            this.ndNetwork.data.nodes.push(this.generateNode(this.ndResponseParsed.name.name, 'parent', 0))
            try {
              Object.entries(this.ndResponseParsed.relatedCompanies.items).forEach(([key, item]) => {
                const i: any = item;
                try {
                  if (i.roles[0].group != 'Merger' && (i.roles[0].dir != 'Source' || i.roles[0].dir != 'Target')) {
                    if (i.roles[0].group == 'Control') {
                      this.ndNetwork.data.names.push(i.company.name.name)
                      this.ndNetwork.data.searchIds.push(i.company.id)
                      this.ndNetwork.data.nodes.push(this.generateNode(i.company.name.name, 'company-' + i.company.id, 1));
                      this.ndNetwork.data.links.push(this.generateLink(this.ndResponseParsed.name.name, i.company.name.name));
                    }
                  } else {
                    console.log('drop ' + JSON.stringify(i))
                  }
                } catch (e) {

                }

              });
            } catch (e) {

            }

            try {
              Object.entries(this.ndResponseParsed.relatedPersons.items).forEach(([key, item]) => {
                const i: any = item;
                const name = i.person.name.firstName + ' ' + i.person.name.lastName;
                this.ndNetwork.data.names.push(name)
                this.ndNetwork.data.searchIds.push(i.person.id)
                this.ndNetwork.data.nodes.push(this.generateNode(name, 'person-' + i.person.id, 3))
                this.ndNetwork.data.links.push(this.generateLink(this.ndResponseParsed.name.name, name))
              });
            } catch (e) {

            }

            this.ndHasNetwork = true;
            setTimeout(() => {
              //@ts-ignore
              window.initializeForceGraph('network', this.ndNetwork, this.openNetworkPartner);
            }, 500);

            let lastCol = 0;
            let activefield = '';
            if (this.ndResponseParsed.sheets != undefined && this.ndResponseParsed.sheets != null && this.ndResponseParsed.sheets.length > 0) {
              lastCol = this.ndResponseParsed.sheets[0].columnHeaders.length - 1;
              this.ndBilanzEndDate = this.ndResponseParsed.sheets[0].columnHeaders[lastCol].date;
              this.ndBilanzEndTitle = this.ndResponseParsed.sheets[0].columnHeaders[lastCol].source.publicationTitle;
              Object.entries(this.ndResponseParsed.sheets[0].rows).forEach(([key, row]) => {
                const r: any = row;
                if (r.level == 0) {
                  activefield = r.name;
                  if (r.cells[lastCol].value != undefined && r.cells[lastCol].value != null) {
                    if (r.name == 'Aktiva') {
                      this.maxActiva = r.cells[lastCol].value;
                    }
                    if (r.name == 'Passiva') {
                      this.maxPassiva = r.cells[lastCol].value;
                    }
                  }
                }

                if (r.level == 2) {
                  if (bilanzSplitter[activefield] == undefined || bilanzSplitter[activefield] == null) {
                    bilanzSplitter[activefield] = [];
                  }

                  bilanzSplitter[activefield].push(r)
                }
              });
            }

            if (bilanzSplitter['Aktiva'].length > 0 || bilanzSplitter['Passiva'].length > 0) {
              this.ndHasBilanzEnd = true;
              Object.entries(bilanzSplitter['Aktiva']).forEach(([key, row]) => {
                const r: any = row;
                if (r.cells[lastCol].value != undefined && r.cells[lastCol].value != null) {
                  const item = {tag: r.name, value: r.cells[lastCol].value}
                  this.treeBilanzActiva[0].tree.push(item)
                }
              });

              Object.entries(bilanzSplitter['Passiva']).forEach(([key, row]) => {
                const r: any = row;
                try {
                  if (r.cells[lastCol].value != undefined && r.cells[lastCol].value != null) {
                    const item = {tag: r.name, value: r.cells[lastCol].value}
                    this.treeBilanzPassiva[0].tree.push(item)
                  }
                } catch (e) {
                  //
                }
              });

            } else {
              this.ndHasBilanzEnd = false;
              this.ndBilanzEndTitle = '';
              this.ndBilanzEndDate = '';
            }

            if (this.ndResponseParsed.extras != undefined && this.ndResponseParsed.extras != null && this.ndResponseParsed.history.extras > 0) {
              this.ndHasExtras = true;
            } else {
              this.ndHasExtras = false;
            }

            if (this.ndResponseParsed.history.addresses != undefined && this.ndResponseParsed.history.addresses != null && this.ndResponseParsed.history.addresses.legnth > 0) {
              this.ndHasPrevAddress = true;
            } else {
              this.ndHasPrevAddress = false;
            }

            if (this.ndResponseParsed.history.names != undefined && this.ndResponseParsed.history.names != null && this.ndResponseParsed.history.names.length > 0) {
              this.ndHasPrevNames = true;
            } else {
              this.ndHasPrevNames = false;
            }

            if (this.ndResponseParsed.events != undefined && this.ndResponseParsed.events != null && this.ndResponseParsed.events.items.length > 0) {
              this.ndHasEvents = true;
            } else {
              this.ndHasEvents = false;
            }

            if (this.ndHasEvents = true) {
              let history = []
              let marketing = []
              let control = [];

              Object.entries(this.ndResponseParsed.events.items).forEach(([key, item]) => {
                const i: any = item;
                const tmpDate = new Date(Date.parse(i.date));
                i.date = this.apiManagerService.formatDate2(tmpDate, false, true, true);
                switch (i.type) {
                  case "Funding":
                  case "Trademark":
                    marketing.push(i);
                    break;
                  case "MergerOrAcquisition":

                    control.push(i);
                    break;
                  case "ControlChange":
                    break;
                  default:
                    history.push(i);
                }
              });

              this.ndHasEvents = history.length > 0
              this.ndHasEventsMarketing = marketing.length > 0
              this.ndHasEventsControl = control.length > 0
              setTimeout(() => {
                if (this.ndHasEvents) {
                  // @ts-ignore
                  window.initMilestones('#timeline', 'date', 'description', history, history > 2);
                }
                if (this.ndHasEventsMarketing) {
                  // @ts-ignore
                  window.initMilestones('#timeline-marketing', 'date', 'description', marketing, marketing > 2);
                }
                if (this.ndHasEventsControl) {
                  // @ts-ignore
                  window.initMilestones('#timeline-control', 'date', 'description', control, control > 2);
                }
              }, 500);

            }
            if (this.ndResponseParsed.financials != undefined && this.ndResponseParsed.financials != null && this.ndResponseParsed.financials.source != undefined && this.ndResponseParsed.financials.source != null && this.ndResponseParsed.financials.source.snippets != undefined && this.ndResponseParsed.financials.source.snippets != null && this.ndResponseParsed.financials.source.snippets.length > 0) {
              Object.entries(this.ndResponseParsed.financials.source.snippets).forEach(([key, val]) => {
                const v: any = val;
                if (v.title == 'Bilanz') {
                  this.ndHasBilanz = true;
                  this.ndBilanzHtml = v.content;
                }

                if (v.title == 'Gewinn- und Verlustrechnung') {
                  this.ndHasGuV = true;
                  this.ndGuvHtml = v.content;
                }
              });
            }
            if (this.ndResponseParsed.history.financials != undefined && this.ndResponseParsed.history.financials != null && this.ndResponseParsed.history.financials.length > 0) {
              try {
                for (let i = 0; i < this.ndResponseParsed.history.financials.length; i++) {
                  const item = this.ndResponseParsed.history.financials[i];
                  this.barChartLabels.push(item.date.split('-')[0])
                  Object.entries(item.items).forEach(([key, line]) => {
                    const l: any = line;
                    if (l.id == 'BalanceTotal') {
                      if (l.estimate) {
                        this.barChartDataBalance[0].data.push(0)
                        this.barChartDataBalance[1].data.push(l.value)
                      } else {
                        this.barChartDataBalance[0].data.push(l.value)
                        this.barChartDataBalance[1].data.push(0)
                      }
                    }

                    if (l.id == 'Revenue') {
                      if (l.estimate) {
                        this.barChartDataRevenue[0].data.push(0)
                        this.barChartDataRevenue[1].data.push(l.value)
                      } else {
                        this.barChartDataRevenue[0].data.push(l.value)
                        this.barChartDataRevenue[1].data.push(0)
                      }
                    }

                    if (l.id == 'Profit') {
                      if (l.estimate) {
                        this.barChartDataProfit[0].data.push(0)
                        this.barChartDataProfit[1].data.push(l.value)
                      } else {
                        this.barChartDataProfit[0].data.push(l.value)
                        this.barChartDataProfit[1].data.push(0)
                      }
                    }
                  });
                }
              } catch (e) {

              }
            } else {
              this.barChartLabels.push(new Date().toISOString().split('-')[0])
              this.barChartDataBalance[0].data.push(0)
              this.barChartDataRevenue[0].data.push(0)
              this.barChartDataProfit[0].data.push(0)
            }


            try {
              if (this.apiManagerService.tenant.GoogleMaps) {
                this.mapOptions = {
                  center: {lat: this.ndResponseParsed.address.lat, lng: this.ndResponseParsed.address.lng},
                  zoom: 14
                }

                this.marker = {
                  title: this.ndResponseParsed.name.name,
                  position: {lat: this.ndResponseParsed.address.lat, lng: this.ndResponseParsed.address.lng}
                }

                this.prevMarkers = [];
                if (this.ndHasPrevAddress) {
                  Object.entries(this.ndResponseParsed.history.addresses).forEach(([key, item]) => {
                    const tmp: any = item;
                    const marker = {
                      title: this.ndResponseParsed.name.name + tmp.date,
                      position: {lat: tmp.address.lat, lng: tmp.address.lng}
                    }

                    this.prevMarkers.push(marker);
                  });
                }
              }
            } catch (e) {
              this.mapOptions = null;
            }
          } else {
            this.ndResponse.ResponseCode = raw.ResponseCode;
            if (raw.ResponseCode == 404) {
              this.searchResults = []
              const query = 'domain=company&status=active|liquidation&countries=DE&history=false=censor=false&query=' +encodeURIComponent(this.record.Description.trim())
              this.apiManagerService.getStructureSearchJson('suggest', query).then((result) => {
                if (result != null && result.ResponseCode == 200) {
                  const parsed = JSON.parse(result.Json);
                  if (parsed != null && parsed.total > 0) {
                    Object.entries(parsed.results).forEach(([key, result]) => {
                      const r: any = result
                      if (r.company != undefined && r.company != null) {
                        try {
                          const sr = new structureSearchResult();
                          sr.id = r.company.id;
                          sr.name = r.company.name.name;
                          sr.address = r.company.address.formattedValue;
                          this.searchResults.push(sr);
                        } catch (e) {
                          //
                        }
                      }
                    });

                    if (this.searchResults.length == 1) {
                      this.openNetworkPartner(this.searchResults[0].id);
                    }
                  }
                }
              }).catch((e) => {

              })
            }
          }
        } else {
          this.ndResponse.ResponseCode = 500;
        }
      }).catch((e) => {
        this.ndResponse.ResponseCode = 500;
      });

      this.activeTab = 4;
    } else { */
      this.searchId = '';
      this.searchType = 'company';
      if ((tabId > 0 && tabId < 5) || tabId == 5) {
        this.activeTab = tabId
      } else {
        this.activeTab = 1;
      }
    //}
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    if (w > 1400) {
      this.marginOverride = 'margin-left: 250px;';
      this.widthOverride = 'width: 250px;';
    } else {
      this.marginOverride = '';
      this.widthOverride = '';
    }
    this.sidebarHeightRule = 'height: ' + (h - 58) + 'px;';
  }

  ngOnInit(): void {
    this.subscriberId = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType == 'signalr-row-update') {
        const tmp: TableRow = notification.EventData;
        if (tmp.Id == this.record.Id) {
          this.record = tmp;
        }
      }

      if (notification.EventType == 'add-deal') {
        this.setTab(2)
        setTimeout(() => {
          this.apiManagerService.notifications.notify('add-row-flex-deals', null, '')
        }, 500);
      }

      if (notification.EventType == 'add-contact') {
        this.setTab(5);
        setTimeout(() => {
          this.apiManagerService.notifications.notify('add-row-flex-contacts', null, '')
        }, 500);
      }

      if (notification.EventType == 'add-case') {
        this.setTab(3)
        setTimeout(() => {
          this.apiManagerService.notifications.notify('add-row-flex-cases', null, '')
        }, 500);
      }
    });


    this.menu.push({
      Displayname: 'Kontakt hinzufügen',
      EventTarget: '',
      EventType: 'add-contact',
      EventData: null
    });


    this.menu.push({
      Displayname: 'Verkaufschance hinzufügen',
      EventTarget: '',
      EventType: 'add-deal',
      EventData: null
    });

    if (this.apiManagerService.tenant.Modules.includes('51038834-320f-4c31-8f85-cfdfb2a3050c')) {
      this.menu.push({
        Displayname: 'Support Case hinzufügen',
        EventTarget: '',
        EventType: 'add-case',
        EventData: null
      });
    }


    this.hasSupport = this.apiManagerService.tenant.Modules.includes('51038834-320f-4c31-8f85-cfdfb2a3050c')
    //@ts-ignore
    window.cpDetails = this;
    Chart.register(TreemapController, TreemapElement);
    //document.body.style.overflow = 'hidden';
    this.detectScreenSize();
    /*const cnt = document.getElementById('main-container');
    if (cnt != null) {
      cnt.className = 'container-fluid pa-0';
    }*/

    //this.apiManagerService.noContainerBorder = true;
    this.apiManagerService.getPage(this.pageName).then((page) => {
      this.page = page;
      if (page.DefaultMode == -1 && page.AllowChange) {
        this.mode = -1;
      } else if (page.DefaultMode == 1 && page.AllowEdit) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    });

    this.profile = this.apiManagerService.getProfile();
    this.activatedRoute.paramMap.subscribe(params => {
      const id = this.recordId = params.get('id');
      if (this.apiManagerService.cacheSchemas[this.tableName] && this.apiManagerService.cacheRows[this.tableName]) {
        this.rows = this.apiManagerService.syncRowsSchema(this.apiManagerService.cacheRows[this.tableName], this.apiManagerService.cacheSchemas[this.tableName]);
        this.loadRecord(this.apiManagerService.cacheRows[this.tableName], id)
      } else {
        this.apiManagerService.getTableSchema(this.tableName).then((schema) =>
        {
          this.apiManagerService.getTableEntities(this.tableName).then((rows) => {
            this.rows = this.apiManagerService.syncRowsSchema(rows, schema);
            this.loadRecord(rows, id)
          }).catch((e) => {
            this.router.navigateByUrl(this.returnPage);
          });
        }).catch((e) => {
          this.router.navigateByUrl(this.returnPage);
        });
      }
    });

    setTimeout(() => {
      this.hasStructureData = this.apiManagerService.hasStructureData();
    }, 1000);
    setTimeout(() => {
      this.hasStructureData = this.apiManagerService.hasStructureData();
    }, 5000);
    setTimeout(() => {
      this.hasStructureData = this.apiManagerService.hasStructureData();
    }, 10000);
  }

  saveData() {
    this.apiManagerService.setTableEntity(this.record);
    this.mode = 0;
  }

  loadRecord(rows: TableRow[], id: string) {
    let found = false;
    for(let i = 0; i < rows.length; i++) {
      if (rows[i].Id == id || rows[i].PublicId == id) {
        this.recordId = rows[i].Id;
        this.title.setTitle(rows[i].Description + ' - Astralus Admin Portal');
        this.record = rows[i];
        found = true;
      }
    }

    if (!found) {
      this.router.navigateByUrl(this.returnPage);
    } else {
      //this.recordId = id;
    }
  }
}
