<app-theme-aos-dg [isDetached]="isDetached" [isVertical]="isVertical" [halfLoading]="halfLoading" *ngIf="!loading && !isDark && loadingTenantFinalized"></app-theme-aos-dg>
<app-theme-aos-dg [isDetached]="isDetached" [isVertical]="isVertical" [halfLoading]="halfLoading" *ngIf="!loading && isDark && loadingTenantFinalized"></app-theme-aos-dg>
<!--<div class="line-center" *ngIf="loading || !loadingTenantFinalized">
  <div class="loading">
    <p>Initialisiere</p>
    <span></span>
  </div>
</div>-->
<div class="start-loader" *ngIf="loading || !loadingTenantFinalized">
  <div class="ball"></div>
  <div class="shape"></div>
</div>
<!--<h1 *ngIf="loading" style="text-align: center;">Lade Profil</h1>
<div class="line-center" *ngIf="loading">
  <div style="margin-top: 100px;" class="lds-default">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>-->
