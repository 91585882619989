<nav class="hk-breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-light bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/admin/users">Benutzer</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{user.DisplayName}}</li>
  </ol>
</nav>

<!--<div class="hk-pg-header">
  <h4 class="hk-pg-title"><span class="pg-title-icon"><span class="feather-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></span></span>Benutzer</h4>
</div>-->

<ul class="nav nav-tabs nav-sm nav-light mb-25" style="padding-left: 20px;">
  <li class="nav-item" style="margin-left: auto;">
    <app-as-flex-drop-button [menuEntries]="menu" [disable]="loading || this.profile.Id == this.user.Id"></app-as-flex-drop-button>
  </li>
</ul>


<div class="col-xl-12">
  <section class="hk-sec-wrapper">
    <h1 class="hk-sec-title"><i class="fa-thin fa-users" style="font-size: 15px; min-width: 17px; font-size: 30px;"></i>&nbsp;Benutzerobjekt bearbeiten</h1>

    <!--<button class="btn btn-success" *ngIf="!kanbanMode" (click)="kanbanMode = !kanbanMode">Kanban Test</button>
    <button class="btn btn-success" *ngIf="kanbanMode" (click)="kanbanMode = !kanbanMode">Kanban Test beenden</button>-->
    <div class="row">
      <div class="col-sm" style="margin-top: 10px;">
        <!--<ng-select2 [data]="testList" [(ngModel)]="testVal"> </ng-select2>-->
        <div class="table-wrap">
          <div style="display: flex;" *ngIf="loading">
            <div style="margin-left: auto; margin-right: auto" class="lds-default" >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="table-responsive" *ngIf="!loading" style="overflow: hidden;">
            <!--<div class="col-12" style="position: absolute;top: -55px;">
              <a style="cursor: pointer; font-size: 14px; float: right;" class="btn btn-gradient-success" (click)="addUser(addUserModalRef)">Hinzufügen</a>
            </div>-->
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-tabs nav-sm nav-light mb-25">
              <li [ngbNavItem]="1">
                <a ngbNavLink (click)="activeTab=1">Allgemein</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Anzeigename:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30" [value]="user.DisplayName" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Vorname:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text" [value]="user.Firstname" maxlength="30" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Nachname:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text" [value]="user.Lastname" maxlength="30" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label"> E-Mail-Adresse / Login:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text"  [value]="user.Username" maxlength="30" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Berechtigung:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <span class="badge badge-info badge-pill mt-15 mr-10" *ngIf="!user.IsAdmin" style="margin: 2px; !important; cursor: pointer;" (click)="setUserAdmin(true)">Benutzer</span>
                          <span class="badge badge-warning badge-pill mt-15 mr-10" *ngIf="user.IsAdmin" style="margin-top: 7px !important; cursor: pointer;margin-bottom: 7px !important;" (click)="setUserAdmin(false)">Administrator</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Status:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <span class="badge badge-danger badge-pill mt-15 mr-10" *ngIf="!user.IsEnabled" style="margin-top: 7px !important; cursor: pointer;margin-bottom: 7px !important;" (click)="setUserEnable(true)">Gesperrt</span>
                          <span class="badge badge-success badge-pill mt-15 mr-10" *ngIf="user.IsEnabled" style="margin-top: 7px !important; cursor: pointer;margin-bottom: 7px !important;" (click)="setUserEnable(false)">Aktiv</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Login Provider:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text"  value="Identiqa SSO" maxlength="30" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-2 col-form-label">Letzter Login:</label>
                        <div class="col-sm-10" style="display: flex;">
                          <input style="margin: 2px; max-width: 360px;" type="text"  [value]="user.LastLoginParsed" maxlength="30" readonly class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>

                <!--<li [ngbNavItem]="2">
                  <a ngbNavLink (click)="activeTab=2">Adresse</a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label">Straße:</label>
                          <div class="col-sm-10" style="display: flex;">
                            <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30"  readonly class="form-control" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label">PLZ:</label>
                          <div class="col-sm-10" style="display: flex;">
                            <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30"  readonly class="form-control" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label">Bundesland:</label>
                          <div class="col-sm-10" style="display: flex;">
                            <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30"  readonly class="form-control" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label">Ort:</label>
                          <div class="col-sm-10" style="display: flex;">
                            <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30"  readonly class="form-control" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label">Land:</label>
                          <div class="col-sm-10" style="display: flex;">
                            <input style="margin: 2px; max-width: 360px;" type="text" maxlength="30"  readonly class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>-->
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2" style="min-height: 60px;"></div>
      </div>
        </div>
      </div>
    </div>
  </section>
</div>
