import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthUser, Feature, User} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ApiNotification} from "../api-notifications/api-notifications";
import {AsFlexDropButtonItem} from "../as-flex-drop-button/as-flex-drop-button.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AsToastService} from "../as-toast.service";

@Component({
  selector: 'app-as-user-details',
  templateUrl: './as-user-details.component.html',
  styleUrls: ['./as-user-details.component.css']
})
export class AsUserDetailsComponent implements OnInit, OnDestroy {

  activeTab = 1;
  profile: AuthUser;
  menu: AsFlexDropButtonItem[] = []
  loading = true;
  user = new User()
  subscriberId = ''
  constructor(private apiManagerService: ApiManagerService,  private activatedRoute: ActivatedRoute, private modalService: NgbModal, private router: Router, private toast: AsToastService) { }

  ngOnDestroy() {
    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
  }

  populateMenu() {
    if (this.user.IsEnabled) {
      this.menu.push({
        Displayname: 'Benutzer deaktivieren',
        EventTarget: this.subscriberId,
        EventType: 'disable-user',
        EventData: null
      });
    } else {
      this.menu.push({
        Displayname: 'Benutzer aktivieren',
        EventTarget: this.subscriberId,
        EventType: 'enable-user',
        EventData: null
      });
    }

    if (this.user.IsAdmin) {
      this.menu.push({
        Displayname: 'Adminrechte entfernen',
        EventTarget: this.subscriberId,
        EventType: 'demote-user',
        EventData: null
      });
    } else {
      this.menu.push({
        Displayname: 'Adminrechte hinzufügen',
        EventTarget: this.subscriberId,
        EventType: 'promote-user',
        EventData: null
      });
    }
  }

  loadUser(userId, force) {
    if (!force &&this.apiManagerService.cacheUsers != null && this.apiManagerService.cacheUsers.length > 0) {
      Object.entries(this.apiManagerService.cacheUsers).forEach(([key, user]) => {
        if (user.Id == userId) {
          this.user = user;
        }
      })
      this.loading = false;
    } else {
      this.apiManagerService.getUsers().then((users) => {
        if (users != null) {
          Object.entries(users).forEach(([key, user]) => {
            if (user.Id == userId) {
              this.user = user;
            }
          })
        }

        this.loading = false;
      });
    }

    this.populateMenu();
  }

  setUserAdmin(enable) {
    if (this.user.Id != this.profile.Id) {
      this.user.IsAdmin = enable;

      this.apiManagerService.setUserAdmin(this.user.Id, enable).then((success) => {
        if (!success) {
          this.loadUser( this.user.Id,true);
        }
      }).catch((e) => {
        this.loadUser(this.user.Id,true);
      });
    } else {
      this.toast.showWarningToastMessage('Eigener Benutzer ist vor Änderungen geschützt', null,'top-center-fullwidth' )
    }
  }

  setUserEnable(enable) {
    if (this.user.Id != this.profile.Id) {
      this.user.IsEnabled = enable;

      this.apiManagerService.setUserEnable(this.user.Id, enable).then((success) => {
        if (!success) {
          this.loadUser( this.user.Id,true);
        }
      }).catch((e) => {
        this.loadUser(this.user.Id,true);
      });
    } else {
      this.toast.showWarningToastMessage('Eigener Benutzer ist vor Änderungen geschützt', null,'top-center-fullwidth' )
    }
  }

  ngOnInit(): void {
    this.profile = this.apiManagerService.getProfile();
    if (!this.profile.IsAdmin) {
      this.router.navigateByUrl('/');
      return;
    }

    this.subscriberId = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      switch(notification.EventType) {
        case 'enable-user':
          this.setUserEnable(true);
          break;
        case 'disable-user':
          this.setUserEnable(false);
          break;
        case 'promote-user':
          this.setUserAdmin(true);
          break;
        case 'demote-user':
          this.setUserAdmin(false);
          break;
      }
    });

    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('id');
      this.loadUser(id, false);
    });
  }

}
