import { Component, OnInit } from '@angular/core';
import {DomainData, MListData} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";

@Component({
  selector: 'app-mlists',
  templateUrl: './mlists.component.html',
  styleUrls: ['./mlists.component.css']
})
export class MlistsComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  domains: DomainData[] = [];
  mlists: MListData[] = [];
  mlistFiltered: MListData[] = [];
  mlistPage: MListData[] = [];
  activeDomain = '';
  dropDomains: Array<Select2OptionData> = [];
  pageId = 0;
  visiblePageId = 1;
  pageCount = 1;
  pageSize = 15;
  filter = "";
  mlistDelete: MListData;
  public activeModal: NgbModalRef = null;

  ngOnInit(): void {
    this.apiManagerService.getMailDomains().then((data) => {
      this.domains = data;
      if (data.length > 0) {
        this.setDomain(data[0].domainName);
      }

      let dropDomains: Array<Select2OptionData> = [];
      for(let i = 0; i < data.length; i++) {
        dropDomains.push({
          id: data[i].domainName,
          text: data[i].domainName
        });
      }

      this.dropDomains = dropDomains;
    })
  }

  removeListConfirm() {
    this.closeModal();
    this.toast.showSuccessToastMessage('Verteiler wird entfernt');
    const domain = this.mlistDelete.listname.split('@')[1];
    this.apiManagerService.removeMailList(domain, this.mlistDelete.uniqueid).then((result) => {
      if (result) {
        this.toast.showSuccessToastMessage('Verteiler entfernt');
        this.apiManagerService.getMailLists(domain).then((lists) => {
          if (lists != null) {
            this.mlists = lists;
            this.calcPaging();
          }
        })
      } else {
        this.toast.showErrorToastMessage('Entfernung fehlgeschlagen');
      }
    }).catch((e) => {
      this.toast.showErrorToastMessage('Entfernung fehlgeschlagen');
    });
  }

  removeList(list: MListData, modalRef) {
    this.closeModal();
    this.mlistDelete = list;
    this.activeModal = this.modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
  }


  closeModal() {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }

  }


  setDomain(domain) {
    this.pageId = 0;
    this.pageCount = 1;
    this.mlistFiltered = [];
    this.activeDomain = domain;
    this.mlists = [];
    this.apiManagerService.getMailLists(domain).then((data) => {
      this.mlists = data;
      this.calcPaging();
    });
  }

  filterUpdate(filter) {
    this.filter = filter;
    this.calcPaging();
  }

  doFilter() {
    this.mlistFiltered = [];
    if (this.filter.trim() == '') {
      this.mlistFiltered = this.mlists;
    } else {
      for(let i = 0; i < this.mlists.length; i++) {
        let list = this.mlists[i];
        if (list.listname.toLowerCase().indexOf(this.filter.toLowerCase().trim()) != -1) {
          this.mlistFiltered.push(list);
        }
      }
    }
  }

  calcPaging() {
    this.doFilter();
    this.pageId = 0;
    this.visiblePageId = 1;
    this.pageCount = 1;
    this.mlistPage = [];
    this.pageCount = Math.floor(this.mlistFiltered.length / this.pageSize);
    if (this.mlistFiltered.length / this.pageSize - this.pageCount > 0){
      this.pageCount++;
    }

    this.setPage(0);
  }

  setPage(pageId) {
    if (pageId < 0){
      pageId = 0;
    }

    if (pageId > this.pageCount-1) {
      this.pageId = this.pageCount - 1;
    }

    this.pageId = pageId;
    this.visiblePageId = this.pageId + 1;
    this.mlistPage = [];
    for(let i = pageId * this.pageSize; i < this.mlistFiltered.length; i++) {
      this.mlistPage.push(this.mlistFiltered[i]);
      if (this.mlistPage.length == this.pageSize) {
        break;
      }
    }

    return false;
  }

  editList(listname: string) {
    this.router.navigateByUrl('/mail/distributionlists/' + listname)
  }

}
