import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthUser, Feature, User} from "../api-classes/api-classes";
import {AsToastService} from "../as-toast.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiNotification} from "../api-notifications/api-notifications";
import {AsFlexDropButtonItem} from "../as-flex-drop-button/as-flex-drop-button.component";

@Component({
  selector: 'app-as-users-admin',
  templateUrl: './as-users-admin.component.html',
  styleUrls: ['./as-users-admin.component.css']
})
export class AsUsersAdminComponent implements OnInit,OnDestroy {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private router: Router, private toast: AsToastService) { }

  @ViewChild('addUserModalRef', { read: TemplateRef }) addUserModalRef:TemplateRef<any>;
  menu: AsFlexDropButtonItem[] = []
  user: User = new User();
  users: User[] = [];
  profile: AuthUser
  activeModal: any = null;
  loading = true;
  newUsername = '';
  newDisplayname = '';
  subscriberId = '';

  ngOnDestroy() {
    this.apiManagerService.notifications.unsubscribe(this.subscriberId);
  }

  ngOnInit(): void {
    this.subscriberId = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType=='add-user') {
        this.addUser(this.addUserModalRef);
      }
    });

    this.menu.push({
      Displayname: 'Benutzer autorisieren',
      EventTarget: this.subscriberId,
      EventType: 'add-user',
      EventData: null
    });

    this.profile = this.apiManagerService.getProfile();
    if (!this.profile.IsAdmin) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.loadUsers();
    }
  }

  showUser(modalRef, user: User) {
    this.user = user;
    //this.apiManagerService.openModal(this.modalService, modalRef);
    this.router.navigateByUrl('/admin/users/' + user.Id);
  }

  closeModal() {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }
  }

  openModal(modalRef) {
    this.closeModal();
    this.activeModal = this.modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
    this.unhideEditor();
  }

  addUser(modalRef) {
    this.newDisplayname = '';
    this.newUsername = '';
    this.openModal(modalRef)
  }

  addUserConfirm() {
    if (this.newUsername.trim() == '' ) {

      this.toast.showWarningToastMessage('E-Mail ist Pflicht!', null,'top-center-fullwidth' )
    } else {
      if (this.newDisplayname.trim() == '') {
        this.newDisplayname = this.newUsername;
      }

      this.apiManagerService.addUser(this.newUsername.trim(), this.newDisplayname.trim()).then((e) => {
        this.loadUsers(true);
      });
      this.toast.showInfoToastMessage('Benutzer wird angelegt', null,'top-center-fullwidth' )
      this.closeModal();
    }
  }

  unhideEditor() {
    setTimeout(() => {
      const d = document.getElementsByClassName('modal-content');
      if (d) {
        for(let i = 0; i < d.length; i++) {
          const t: any = d[i];
          t.style.display = 'flex';
        }
      }
    },75);
  }

  setUserAdmin(userId, enable) {
    if (userId != this.profile.Id) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].Id == userId) {
          this.users[i].IsAdmin = enable;
          break;
        }
      }

      this.apiManagerService.setUserAdmin(userId, enable).then((success) => {
        if (!success) {
          this.loadUsers(true);
        }
      }).catch((e) => {
        this.loadUsers(true);
      });
    } else {
      this.toast.showWarningToastMessage('Eigener Benutzer ist vor Änderungen geschützt', null,'top-center-fullwidth' )
    }
  }

  setUserEnable(userId, enable) {
    if (userId != this.profile.Id) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].Id == userId) {
          this.users[i].IsEnabled = enable;
          break;
        }
      }

      this.apiManagerService.setUserEnable(userId, enable).then((success) => {
        if (!success) {
          this.loadUsers(true);
        }
      }).catch((e) => {
        this.loadUsers(true);
      });
    }
  }

  loadUsers(force = false) {
    if (!force &&this.apiManagerService.cacheUsers != null && this.apiManagerService.cacheUsers.length > 0) {
      this.users = this.apiManagerService.cacheUsers;
      this.loading = false;
    } else {
      this.apiManagerService.getUsers().then((users) => {
        if (users != null) {
          this.users = users;
          Object.entries(users).forEach(([key, user]) => {
            if (user.Id == this.user.Id) {
              this.user = user;
            }
          })
        }

        this.loading = false;
      });
    }
  }

}
