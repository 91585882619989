import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-as-dependend-label',
  templateUrl: './as-dependend-label.component.html',
  styleUrls: ['./as-dependend-label.component.css']
})
export class AsDependendLabelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
