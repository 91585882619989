<div class="col-md-12" id="as-form-modal_{{formId}}">
  <div class="form-group row" *ngIf="hasLogos">
    <label class="col-sm-4 col-form-label">Logo</label>
    <div class="col-sm-8">
      <input style="margin: 2px;" type="file" (change)="handleFileInput($any($event.target).files)" class="form-control logo-upload" accept="image/*" />
    </div>
  </div>
  <div class="form-group row" *ngFor="let field of _filteredSchema">
    <label class="col-sm-4 col-form-label">{{field.DisplayName}}</label>
    <div class="col-sm-8" *ngIf="field.FieldType == 'text' || ((!tenant.GooglePlaces || tenant.GoogleApiKey == '') && field.FieldType.startsWith('google-search')) || field.FieldType.startsWith('google-result')">
      <input style="margin: 2px;" type="text" [value]="tableRow.Fields[field.Id]" (input)="tableRow.Fields[field.Id]=$any($event.target).value; reportChanges()" class="form-control" />
    </div>
    <div class="col-sm-8" *ngIf=" (tenant.GooglePlaces && tenant.GoogleApiKey != '' && field.FieldType.startsWith('google-search'))">
      <input style="margin: 2px;" ngx-google-places-autocomplete [value]="tableRow.Fields[field.Id]" (input)="tableRow.Fields[field.Id]=$any($event.target).value; reportChanges()" [options]="googleOptions" (onAddressChange)="handleAddressChange(field, $event)" class="form-control form_field_{{field.Id}}"/>
    </div>
    <div class="col-sm-8" *ngIf="(field.FieldType == 'linked' || field.FieldType == 'linked1') && _lazyLoadCount == 0">
      <as-ng-select2 [options]="options" dropdownParent="as-form-modal_{{formId}}" [data]="_dropOptions[field.Id]" [value]="tableRow.LinkedId1" (valueChanged)="setLink(field.FieldType, field.LinkedTable, field.Id, $event)"> </as-ng-select2>
    </div>
    <div class="col-sm-8" *ngIf="field.FieldType == 'linked2' && _lazyLoadCount == 0">
      <as-ng-select2 [options]="options" dropdownParent="as-form-modal_{{formId}}" [data]="_dropOptions[field.Id]" [value]="tableRow.LinkedId2" (valueChanged)="setLink(field.FieldType, field.LinkedTable, field.Id, $event)"> </as-ng-select2>
    </div>
    <div class="col-sm-8" *ngIf="field.FieldType == 'linked3' && _lazyLoadCount == 0">
      <as-ng-select2 [options]="options" dropdownParent="as-form-modal_{{formId}}" [data]="_dropOptions[field.Id]" [value]="tableRow.LinkedId3" (valueChanged)="setLink(field.FieldType, field.LinkedTable, field.Id, $event)"> </as-ng-select2>
    </div>
    <div class="col-sm-8" *ngIf="field.FieldType == 'linked4' && _lazyLoadCount == 0">
      <as-ng-select2 [options]="options" dropdownParent="as-form-modal_{{formId}}" [data]="_dropOptions[field.Id]" [value]="tableRow.LinkedId4" (valueChanged)="setLink(field.FieldType, field.LinkedTable, field.Id, $event)"> </as-ng-select2>
    </div>
    <div class="col-sm-8" *ngIf="field.FieldType == 'dropdown'">
      <as-ng-select2 dropdownParent="as-form-modal_{{formId}}" [data]="_dropOptions[field.Id]" [value]="tableRow.Fields[field.Id]" (valueChanged)="tableRow.Fields[field.Id]=$event; reportChanges()"> </as-ng-select2>
    </div>
  </div>
</div>
