import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import {AuthUser} from './api-classes/api-classes';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {ApiManagerService} from './api-manager/api-manager.service';
import {Select2OptionData} from "./as-ng-select2/lib/ng-select2.interface";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation : ViewEncapsulation.None
})
export class AppComponent {
  title = 'Astralus Admin Portal';
  loading = true;
  userDisplay = '';
  userId = '00000000-0000-0000-0000-000000000000';
  profileVersion = 0;
  location: string|null = null;
  profile: AuthUser = null;
  theme: string;
  isCompact = false;
  isDetached = false;
  isVertical = true;
  isDark = false;
  isMobile = false;
  halfLoading = false;
  loadingTenantFinalized = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private apiManagerService: ApiManagerService) {
    this.apiManagerService.localhost = 'localhost:8000';
    this.apiManagerService.recordOpenTargets['contacts'] = '/crm/contacts/{id}';
    this.apiManagerService.recordOpenTargets['suppliers'] = '/crm/suppliers/{id}';
    this.apiManagerService.recordOpenTargets['leads'] = '/crm/leads/{id}';
    this.apiManagerService.recordOpenTargets['deals'] = '/crm/deals/{id}';
    this.theme = 'aos';

    apiManagerService.select2Tables = [
      {
        id: 'leads',
        text: 'Leads'
      },
      {
        id: 'deals',
        text: 'Verkaufschancen'
      },
      {
        id: 'suppliers',
        text: 'Dienstleister'
      },
      {
        id: 'contacts',
        text: 'Kontakte'
      },
    ]
    apiManagerService.initLoaderEvents();
    this.profile = this.apiManagerService.getProfile(true);
    if (this.profile != null) {
      this.halfLoading = true;
      this.loading = false;
      setTimeout(() => {
        const avatar = document.getElementById('mnuAvatar');
        if (avatar != null) {
          // @ts-ignore
          const origSrc = avatar.src;
          if (this.profile.IdentiqaId != undefined && this.profile.IdentiqaId != null) {
            // @ts-ignore
            avatar.src = 'https://account.identiqa.com/get/userimage/' + this.profile.IdentiqaId + '?size=64x64&v=' + this.profileVersion;
          }
        }

        const avatar2 = document.getElementById('mnuAvatar2');
        if (avatar2 != null) {
          // @ts-ignore
          const origSrc2 = avatar2.src;
          if (this.profile.IdentiqaId != undefined && this.profile.IdentiqaId != null) {
            // @ts-ignore
            avatar2.src = 'https://account.identiqa.com/get/userimage/' + this.profile.IdentiqaId + '?size=64x64&v=' + this.profileVersion;
          }
        }
      }, 250);
    }

    apiManagerService.initCompleted.subscribe((profile) => {
      this.profile = profile;
      if (profile != null) {
        this.userDisplay = profile.DisplayName;
        this.userId = profile.Id;
        this.profileVersion = this.apiManagerService.profileVersion;
        this.setDesign();
        setTimeout(() => {
          const avatar = document.getElementById('mnuAvatar');
          if (avatar != null) {
            // @ts-ignore
            const origSrc = avatar.src;
            // @ts-ignore
            avatar.src = 'https://account.identiqa.com/get/userimage/' + profile.IdentiqaId + '?size=64x64&v=' + this.profileVersion;
          }

          const avatar2 = document.getElementById('mnuAvatar2');
          if (avatar2 != null) {
            // @ts-ignore
            const origSrc2 = avatar2.src;
            // @ts-ignore
            avatar2.src = 'https://account.identiqa.com/get/userimage/' + profile.IdentiqaId + '?size=64x64&v=' + this.profileVersion;
          }
        }, 250);
      }

      setTimeout(() => this.doCheckAuth(this), 100);
      setTimeout(() => this.checkTenant(this), 100);
    });

    router.events.forEach((event) => {
      this.checkNavigationStart(event);
    });
  }

  checkTenant(self: AppComponent) {
    if (self.apiManagerService.tenant != null && self.apiManagerService.tenant.Id != '00000000-0000-0000-0000-000000000000') {
      self.loadingTenantFinalized = true;
    } else {
      setTimeout(() => this.checkTenant(this), 100);
    }
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    this.isMobile = w < 768;
    this.setDesign();
  }


  setDesign() {
    if (this.profile != null) {
      this.isVertical = this.profile.MenuDetached;
      this.isCompact = this.profile.MenuCompact;
      document.body.setAttribute('data-layout', (!this.isMobile && this.isVertical) ? '' : this.isDetached ? 'detached' : 'topnav');
      document.body.setAttribute('data-leftbar-compact-mode', (!this.isMobile && this.isVertical && this.isCompact) ? 'condensed' : '');
      if (this.isDark) {
        this.isDark = this.profile.Theme === 'dark';
        if (!this.isDark) {
          location.reload();
        }
      } else {
        this.isDark = this.profile.Theme === 'dark';
      }


    }
  }

  checkNavigationStart(event) {
    if (event instanceof NavigationStart) {
      this.location = event['url'];
      this.checkAuth();
    }
  }

  private doLauncher() {
    setTimeout(() => this.doLauncherDelay(this), 500);
  }

  private doLauncherDelay(self: AppComponent) {
    const profile = this.apiManagerService.getProfile();
    /*(window as any).launcher.startLauncher(profile.Username, true, true, 1040);
    (window as any).launcher.registerBlockId('as-ordiance');
    //(window as any).launcher.registerBlockedGroup('aos');
    self.detectScreenSize();
    (window as any).launcher.setLauncherTheme(this.isDark);*/
  }

  private checkAuth() {
    this.doCheckAuth(this);
  }

  private doCheckAuth(self) {
    if (self.apiManagerService.initComplete && self.location != null) {
        document.title = 'Astralus Admin Portal';

      /*if ((window as any).launcher === undefined || (window as any).launcher == null) {
        setTimeout(() => self.doCheckAuth(self), 100);
        return;
      }*/

      if (self.location.startsWith('/identiqa/login')) {
        if (self.apiManagerService.tokenInvalid) {
          //(window as any).launcher.hideLauncher();
          self.apiManagerService.logout();
        } else if (self.apiManagerService.loginRequired) {
          self.apiManagerService.loadToken();
        } else {
          self.apiManagerService.startConnection();
          self.apiManagerService.addSignalRListener();

          self.apiManagerService.buildCache();
          if (self.apiManagerService.cacheUsers == null || self.apiManagerService.cacheUsers.length == 0) {
            self.apiManagerService.getUsers().then((users) => {

            });
          }
          //self.doLauncher();
          self.router.navigateByUrl('/dashboard');
        }
      } else if (self.location.startsWith('/login') && !self.location.startsWith('/login/azure')) {
        if (self.apiManagerService.loginRequired) {
          //(window as any).launcher.hideLauncher();
          self.apiManagerService.authRedirect();
        } else {
          self.apiManagerService.startConnection();
          self.apiManagerService.addSignalRListener();

          self.apiManagerService.buildCache();
          if (self.apiManagerService.cacheUsers == null || self.apiManagerService.cacheUsers.length == 0) {
            self.apiManagerService.getUsers().then((users) => {

            });
          }
          self.doLauncher();
          self.router.navigateByUrl('/dashboard');
        }
      } else if (self.location.startsWith('/logout')) {
        //(window as any).launcher.hideLauncher();
        self.apiManagerService.logout();
      } else {
        if (self.apiManagerService.loginRequired) {
          //(window as any).launcher.hideLauncher();
          self.apiManagerService.authRedirect();
        } else {
          self.apiManagerService.startConnection();
          self.apiManagerService.addSignalRListener();

          self.apiManagerService.buildCache();
          if (self.apiManagerService.cacheUsers == null || self.apiManagerService.cacheUsers.length == 0) {
            self.apiManagerService.getUsers().then((users) => {

            });
          }
          self.loading = false;
          self.halfLoading = false;
          //self.doLauncher();
        }
      }
    }
  }

  onNgDestroy() {
    try {
      this.apiManagerService.initCompleted.unsubscribe();
    } catch (e) {
    }
  }
}
