import { Component, OnInit } from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {AuthUser, Feature} from "../api-classes/api-classes";

@Component({
  selector: 'app-as-app-shop-dg-details',
  templateUrl: './as-app-shop-dg-details.component.html',
  styleUrls: ['./as-app-shop-dg-details.component.css']
})
export class AsAppShopDgDetailsComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private activatedRoute: ActivatedRoute, private router: Router, private toast: AsToastService) { }

  features: Feature[] = [];
  profile: AuthUser
  feature: Feature = new Feature();
  featureId = '';
  loading = true;
  orderDate = '';

  ngOnInit(): void {
    this.profile = this.apiManagerService.getProfile();
    if (!this.profile.IsAdmin) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.activatedRoute.paramMap.subscribe(params => {
        this.featureId = params.get('id');
        this.loadShop();
      });
    }
  }

  loadShop(force = false) {
    if (!force &&this.apiManagerService.cacheApps != null && this.apiManagerService.cacheApps.length > 0) {
      this.features = this.apiManagerService.cacheApps;
      this.loadFeature();
    } else {
      this.apiManagerService.getAppShop().then((features) => {
        if (features != null) {
          this.features = features;
        }

        this.loadFeature()
      });
    }
  }

  order() {
    this.loading = true;
    this.apiManagerService.orderFeature(this.featureId).then((e) => {
      this.apiManagerService.loadTenant()
      this.loadShop(true);
      this.toast.showSuccessToastMessage('Bestellung ausgeführt', null,'top-center-fullwidth' )
    }).catch((e) => {
      this.loadShop(true);
    });
  }

  cancel() {
    this.loading = true;
    this.apiManagerService.cancelFeature(this.featureId).then((e) => {

      this.apiManagerService.loadTenant()
      this.toast.showSuccessToastMessage('Kündigung ausgeführt', null,'top-center-fullwidth' )
      this.loadShop(true);
    }).catch((e) => {
      this.loadShop(true);
    });
  }

  loadFeature() {
    let found = false;
    for(let i = 0; i < this.features.length; i++) {
      if (this.features[i].Id == this.featureId) {
        found = true;
        this.feature = this.features[i];
        if (this.feature.OrderTime != null && this.feature.OrderTime !== '') {
          const tmp = new Date(this.feature.OrderTime)
          this.orderDate = this.apiManagerService.formatDate(tmp, "d.M.yyyy")
        } else {
          this.orderDate = '';
        }
        break;
      }
    }

    if (found) {
      this.loading = false;
    } else {
      this.router.navigateByUrl('/admin/apps');
    }
  }

}
