import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FieldMeta, TableRow} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {MatMenuTrigger} from "@angular/material/menu";
import crc32 from 'crc/crc32';
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";
import {AsToastService} from "../as-toast.service";
import * as FileSaver from 'file-saver';
import { ApiNotification, ApiNotifications} from "../api-notifications/api-notifications";

@Component({
  selector: 'app-as-flex-table',
  templateUrl: './as-flex-table.component.html',
  styleUrls: ['./as-flex-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsFlexTableComponent implements OnInit, OnDestroy {


  stateFilter = 'open';
  ownerFilter = 'all';
  tablesFiltered: Array<Select2OptionData> = []

  fieldTypes: Array<Select2OptionData> = [
    {
      id: 'text',
      text: 'Textfeld'
    },
    {
      id: 'google-search-name',
      text: 'Google Suche (Name)'
    },
    {
      id: 'google-result-name',
      text: 'Google Ergebniss (Name)'
    },
    {
      id: 'google-search-street',
      text: 'Google Suche (Straße)'
    },
    {
      id: 'google-result-street',
      text: 'Google Ergebniss (Straße)'
    },
    {
      id: 'google-search-postalcode',
      text: 'Google Suche (PLZ)'
    },
    {
      id: 'google-result-postalcode',
      text: 'Google Ergebniss (PLZ)'
    },
    {
      id: 'google-search-city',
      text: 'Google Suche (Stadt)'
    },
    {
      id: 'google-result-city',
      text: 'Google Ergebniss (Stadt)'
    },
    {
      id: 'google-search-country',
      text: 'Google Suche (Land)'
    },
    {
      id: 'google-result-country',
      text: 'Google Ergebniss (Land)'
    },
    {
      id: 'linked1',
      text: 'Verknüpfte Tabelle 1'
    },
    {
      id: 'linked2',
      text: 'Verknüpfte Tabelle 2'
    },
    {
      id: 'linked3',
      text: 'Verknüpfte Tabelle 3'
    },
    {
      id: 'linked4',
      text: 'Verknüpfte Tabelle 4'
    }
  ]
  _tableName = '';
  _linkedRowId = '';
  _linkedTable = '';
  @Input() hasStateSearch = false;
  @Input() newTab = false;
  @Input() hasLogos = false;
  @Input() hasSearch = false;
  @Input() addBtnStyle = '';
  @Input() allowEdit = true;
  @Input() divCss = 'table-responsive';
  @Input() tableCss = 'table table-hover mb-0';
  @Input() inlineEdit = false;
  @Input() allowRenameField = false;
  @Input() allowAddField = false;
  @Input() allowDrag = true;
  @Input() allowResize = false;
  @Input() allowExport = false;
  @Input() detailsPageLink = '';
  @Input() noBreakSearch = true;
  @Input() noLinkTable = '';
  @Input() useLinkIcon = true;
  @Input() set revision(value: string) {
    if (this._tableName !== '') {
      if (this.apiManagerService.cacheSchemas[this._tableName]) {
        this.tableSchema = this.apiManagerService.cacheSchemas[this._tableName];
        this.filterSchema();
        this.tableRows = this.apiManagerService.syncRowsSchema(this.tableRows, this.tableSchema);
        this.filterRows();
        if (this.loading) {
          setTimeout(() => this.loading2 = false, 500);
        }
        this.loading = false;
      }
    }
  }
  @Input() set linkedRowId(value: string) {
    this._linkedRowId = value;
    this.reloadAll();
    this.tablesFiltered = [];
    Object.entries(this.apiManagerService.select2Tables).forEach( ([key,item]) => {
      if (item.id != value) {
        this.tablesFiltered.push(item);
      }
    });
  }
  @Input() set tableName(value: string) {
    this._tableName = value;
    this.reloadAll();
    this.tablesFiltered = [];
    Object.entries(this.apiManagerService.select2Tables).forEach( ([key,item]) => {
      if (item.id != value) {
        this.tablesFiltered.push(item);
      }
    });
  }

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  @ViewChild('editRowModalRef', { read: TemplateRef }) editRowModalRef:TemplateRef<any>;

  blockOpen = false;
  menuTopLeftPosition =  {x: '0', y: '0'}
  loading = true;
  loading2 = true;
  tableSchema: FieldMeta[] = [];
  filteredSchema: FieldMeta[] = [];
  tableRows: TableRow[] = [];
  tableFilter = '';
  tableRowsFiltered: TableRow[] = [];

  _hasChanges = false;
  resetOnClose = false;
  tableRowLogo: File = null;
  tableRow: TableRow;
  isNewRow = false;

  newField = false;
  editField: FieldMeta = null;
  editFieldName = false;
  editFieldNameText = '';
  editFieldId = '';
  lastOrder = 99999;
  //lastFieldId = 1;
  lastNewFieldId = 1;
  dragField: FieldMeta = null;
  editFieldWidth = 150;
  nameEditorFocusTimer = null;
  coreUpdateTimer = null;
  lastFieldId = '';
  public activeModal: NgbModalRef = null;
  public activeModal2: NgbModalRef = null;


  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  notificationSession = '';
  ngOnDestroy(): void {
    this.apiManagerService.notifications.unsubscribe(this.notificationSession);
  }

  checkAddReady(self: AsFlexTableComponent) {
    if (self.loading) {
      setTimeout(() => self.checkAddReady(self), 250);
    } else {
      self.addRow(self.editRowModalRef);
    }
  }

  ngOnInit(): void {
    this.notificationSession = this.apiManagerService.notifications.subscribe((notification: ApiNotification) => {
      if (notification.EventType == 'signalr-row-update') {
        const data:TableRow = notification.EventData;
        if (data != null && data.TableName == this._tableName) {
          this.reloadAll();
        }
      }

      if ('add-row-flex-' + this._tableName == notification.EventType) {
        this.checkAddReady(this);
      }

      if ('export-rows-flex-' + this._tableName == notification.EventType) {
        this.exportXlsx()
      }
      if ('full-export-rows-flex-' + this._tableName == notification.EventType) {
        this.exportXlsx(true)
      }
    });

    this.route.queryParams
      .subscribe(params => {
          console.log(params);
          if (params['filter-owner'] != undefined && params['filter-owner'] != null) {
            if (params['filter-owner'] == 'self') {
              this.setOwnerFilter('self');
            }
          }
          if (params['filter-state'] != undefined && params['filter-state'] != null) {
            if (params['filter-state'] == 'open') {
              this.setStateFilter('open');
            }
            if (params['filter-state'] == 'closed') {
              this.setStateFilter('closed');
            }
            if (params['filter-state'] == 'all') {
              this.setStateFilter('all');
            }
          }
        }
      );

  }

  exportXlsx(fullExport = false) {
    const tmp = fullExport? this.getExportData(this.tableRows):this.getExportData(this.tableRowsFiltered);
    if (tmp.length == 0) {
      this.toast.showWarningToastMessage('Keine Daten für Export vorhanden');
    } else {
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(tmp);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, this._tableName);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getExportData(rows: TableRow[]) {
    let exportData: any[] = [];
    Object.entries(rows).forEach(([k, row]) => {
      let tmp: any = {};
      Object.entries(this.tableSchema).forEach(([key, meta]) => {
        if (!meta.IsHidden || !meta.IsFormHidden) {
          let data = '';
          if (row.Fields[meta.Id] != undefined && row.Fields[meta.Id] != null) {
            data = row.Fields[meta.Id];
          }

          Object.defineProperty(tmp, meta.DisplayName, {value : data,
            writable : true,
            enumerable : true,
            configurable : true});

        }
      });
      exportData.push(tmp);
    });

    return exportData;
  }

  openLink(row: TableRow, field: FieldMeta) {
    if (field.FieldType.startsWith('link') && field.LinkedTable != this.noLinkTable) {
      if (field.LinkedTable != null && field.LinkedTable.trim() != '') {
        const t = field.LinkedTable;
        if (this.apiManagerService.recordOpenTargets[t] != undefined && this.apiManagerService.recordOpenTargets[t] != null && this.apiManagerService.recordOpenTargets[t].trim() != '') {
          let targetId = ''
          switch(field.FieldType) {
            case 'linked':
            case 'linked1':
              targetId = row.LinkedId1;
              break;
            case 'linked2':
              targetId = row.LinkedId2;
              break;
            case 'linked3':
              targetId = row.LinkedId3;
              break;
            case 'linked4':
              targetId = row.LinkedId4;
              break;
          }


          if (targetId != null && targetId != '') {
            const tmp = this.apiManagerService.recordOpenTargets[t].replace('{id}', targetId);
            //if (this.newTab) {
            window.open(tmp);
            this.blockOpen = true;
            return;
            //} else {
            //  this.router.navigateByUrl(tmp);
            //}
          }
        }
      }
    }
  }

  openDetails(row: TableRow, field: FieldMeta) {
    this.blockOpen = false;
    if (field.FieldType.startsWith('link') && !this.useLinkIcon) {
      if (field.LinkedTable != null && field.LinkedTable.trim() != '') {
        const t = field.LinkedTable;
        if (this.apiManagerService.recordOpenTargets[t] != undefined && this.apiManagerService.recordOpenTargets[t] != null && this.apiManagerService.recordOpenTargets[t].trim() != '') {
          let targetId = ''
          switch(field.FieldType) {
            case 'linked':
            case 'linked1':
              targetId = row.LinkedId1;
              break;
            case 'linked2':
              targetId = row.LinkedId2;
              break;
            case 'linked3':
              targetId = row.LinkedId3;
              break;
            case 'linked4':
              targetId = row.LinkedId4;
              break;
          }


          if (targetId != null && targetId != '') {
            const tmp = this.apiManagerService.recordOpenTargets[t].replace('{id}', targetId);
            //if (this.newTab) {
            window.open(tmp);
            return;
            //} else {
            //  this.router.navigateByUrl(tmp);
            //}
          }
        }
      }
    }
    if (this.detailsPageLink !== '') {
      const tmp = this.detailsPageLink.replace('{id}', row.PublicId);
      if (this.newTab) {
        window.open(tmp);
      } else {
        this.router.navigateByUrl(tmp);
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.editFieldName) {
      this.cancelEditFieldName();
    }
  }

  saveVisible() {
    this.closeModal();
    this.doSchemaSave(this);
  }

  setFilter(newFilter) {
    this.tableFilter = newFilter;
    this.filterRows()
  }

  setOwnerFilter(value: string) {
    this.ownerFilter = value;
    this.filterRows();
  }
  setStateFilter(value: string) {
    this.stateFilter = value;
    this.filterRows();
  }


  filterRows() {
    const profile = this.apiManagerService.getProfile();
    let finalRows: TableRow[] = []
    if (this.tableRows != null) {
      if (!this.hasSearch || this.tableFilter.trim() === '') {
        if (this.ownerFilter == 'all') {
          if (this.tableFilter.trim() == "") {
            finalRows = this.tableRows;
          } else {
            Object.entries(this.tableRows).forEach(([key, row]) => {
              let added = false;
              Object.entries(row.Fields).forEach(([key, value]) => {
                if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
                  if (!added) {
                    finalRows.push(row);
                    added = true;
                  }
                }
              });
              Object.entries(row.FieldsTranslated).forEach(([key, value]) => {
                if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
                  if (!added) {
                    finalRows.push(row);
                    added = true;
                  }
                }
              });
            });
          }
        } else {
          Object.entries(this.tableRows).forEach(([key, row]) => {
            if (row.OwnerId == profile.Id) {
              if (this.tableFilter.trim() == "") {
                finalRows.push(row);
              } else {
                let added = false;
                Object.entries(row.Fields).forEach(([key, value]) => {
                  if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
                    if (!added) {
                      finalRows.push(row);
                      added = true;
                    }
                  }
                });
                Object.entries(row.FieldsTranslated).forEach(([key, value]) => {
                  if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
                    if (!added) {
                      finalRows.push(row);
                      added = true;
                    }
                  }
                });
              }
            }
          });
        }
      } else {
        Object.entries(this.tableRows).forEach(([key, row]) => {
          let added = false;
          if (row.OwnerId == profile.Id || this.ownerFilter == 'all') {
            Object.entries(row.Fields).forEach(([key, value]) => {
              if (value.toLowerCase().includes(this.tableFilter.toLowerCase()) || this.tableFilter.trim() == "") {
                if (!added) {
                  finalRows.push(row);
                  added = true;
                }
              }
            });
          }
        });
      }
    }

    if (this.stateFilter == 'all') {
      this.tableRowsFiltered = [];//finalRows;

      this.tableRowsFiltered = [];
      Object.entries(finalRows).forEach(([key, row]) => {
        this.apiManagerService.translateRow(row, this.tableSchema)
        if (this.tableFilter.trim() == "") {
          this.tableRowsFiltered.push(row);
        } else {
          let added = false;
          Object.entries(row.Fields).forEach(([key, value]) => {
            if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
              if (!added) {
                this.tableRowsFiltered.push(row);
                added = true;
              }
            }
          });
          Object.entries(row.FieldsTranslated).forEach(([key, value]) => {
            if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
              if (!added) {
                finalRows.push(row);
                added = true;
              }
            }
          });
        }

      });



    } else {
      this.tableRowsFiltered = [];
      Object.entries(finalRows).forEach(([key, row]) => {
        this.apiManagerService.translateRow(row, this.tableSchema)
        if ((this.stateFilter == 'open' && !row.IsComplete) || (this.stateFilter == 'closed' && row.IsComplete)) {
          if (this.tableFilter.trim() == "") {
            this.tableRowsFiltered.push(row);
          } else {
            let added = false;
            Object.entries(row.Fields).forEach(([key, value]) => {
              if (value.toLowerCase().includes(this.tableFilter.toLowerCase())) {
                if (!added) {
                  this.tableRowsFiltered.push(row);
                  added = true;
                }
              }
            });
          }
        }
      });
    }
  }

  setFieldDisplayName(name: string) {
    this.editField.DisplayName = name;
    if (!this.editField.IsEssential) {
      const search = ' ';
      const searchRegExp = new RegExp(search, 'g');
      const replaceWith = '_';
      const result = name.replace(searchRegExp, replaceWith);
      this.editField.Name = result + '-' + crc32(this.editField.Id).toString(16);
    }
  }

  cancelEditFieldName() {
    this.editFieldName = false;
    this.editField = null;
    this.editFieldId = '';
  }

  saveFieldName() {
    if (this.editField != null) {
      this.editField.DisplayName = this.editFieldNameText;
      this.prepareSchemaSave();
      this.cancelEditFieldName();
    }
  }

  onLogoSet(file: File) {
    this.tableRowLogo = file;
    this._hasChanges = true;
  }

  addRow(modalRef) {
    this.closeModal();
    try {
      this.cancelEditFieldName();
    } catch (e) {

    }

    this._hasChanges = false;
    this.resetOnClose = false;
    this.isNewRow = true;
    this.tableRowLogo = null;
    this.tableRow = this.syncSingleRowSchema(new TableRow());
    const p = this.apiManagerService.getProfile();
    this.tableRow.TenantId = p.TenantId;
    this.tableRow.OwnerId = p.Id;
    this.tableRow.TableName = this._tableName;
    this.activeModal = this.modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
    this.unhideEditor();
  }

  closeModal() {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }

    this.closeModal2();
  }

  openModal(modalRef) {
    this.closeModal();
    this.activeModal = this.modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
    this.unhideEditor();
  }

  unhideEditor() {
    setTimeout(() => {
      const d = document.getElementsByClassName('modal-content');
      if (d) {
        for(let i = 0; i < d.length; i++) {
          const t: any = d[i];
          t.style.display = 'flex';
        }
      }
    },75);
  }

  editRow(modalRef, row: TableRow) {
    this.closeModal();
    try {
      this.cancelEditFieldName();
    } catch (e) {

    }

    try {
      this.matMenuTrigger.closeMenu();
    }catch (e) {

    }

    this._hasChanges = false;
    this.resetOnClose = true;
    this.isNewRow = false;
    this.tableRowLogo = null;
    this.tableRow =  JSON.parse(JSON.stringify(this.syncSingleRowSchema(row)));
    this.activeModal = this.modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
    /*this.activeModal.result.then((res) => {
      //console.log('got result');
      //console.log(res);
    }).catch((e) => {
      //console.log('got exception');
      //console.log(e);
      if (this.resetOnClose && this._hasChanges) {
        this.reloadAll(true);
      }
    });*/
    this.unhideEditor();
  }

  saveRow() {
    this.closeModal();
    if (this._hasChanges) {
      const file = this.tableRowLogo;
      this.resetOnClose = false;
      if (this.isNewRow) {
        this.apiManagerService.translateRow(this.tableRow, this.tableSchema)
        this.apiManagerService.setTableEntity(this.tableRow).then((e) => {
          if (e != null) {
            this.tableRows.push(e);
            this.tableRows = this.apiManagerService.syncRowsSchema(this.tableRows, this.tableSchema);
            this.filterRows();
            if (file != null) {
              this.apiManagerService.uploadEntityFile(e.Id, file, true).then((success) => {
                this.reloadAll(true);
              }).catch((e) => {
                this.reloadAll(true);
              })
            }
          }
        });
      } else {
        for (let i = 0; i < this.tableRows.length; i++) {
          if (this.tableRows[i].Id == this.tableRow.Id) {
            this.tableRows[i] = this.tableRow;
          }
        }

        this.apiManagerService.translateRow(this.tableRow, this.tableSchema)
        this.apiManagerService.setTableEntity(this.tableRow).then((e) => {
          if (file != null) {
            this.apiManagerService.uploadEntityFile(e.Id, file, true).then((success) => {
              this.reloadAll(true);
            }).catch((e) => {
              this.reloadAll(true);
            })
          }
        });
      }
    } else {
      this.toast.showInfoToastMessage('Keine Änderungen festgestellt');
    }
  }


  doSchemaSave(self: AsFlexTableComponent) {
    if (self.dragField != null) {
      self.prepareSchemaSave();
      return;
    }
    self.coreUpdateTimer = null;
    self.tableRows = self.apiManagerService.syncRowsSchema(self.tableRows, self.tableSchema);
    self.filterSchema()
    self.apiManagerService.setTableSchema(this._tableName, self.tableSchema).then((schema) => {
      self.tableSchema = schema;
      self.tableRows = this.apiManagerService.syncRowsSchema(self.tableRows, self.tableSchema);
      self.filterSchema()
      self.apiManagerService.cacheSchemas[self._tableName] = self.tableSchema;
      console.log('table schema updated');
    })
  }

  prepareSchemaSave() {
    //this.checkLastVisible();

    if (this.coreUpdateTimer != null) {
      clearTimeout(this.coreUpdateTimer);
    }

    this.coreUpdateTimer = setTimeout(() => this.doSchemaSave(this), 3000);
  }

  prepareFocus() {
    if (this.nameEditorFocusTimer != null) {
      clearTimeout(this.nameEditorFocusTimer);
      this.nameEditorFocusTimer = null;
    }

    this.nameEditorFocusTimer = setTimeout(() => this.doFocus(this), 250);
  }

  doFocus(self: AsFlexTableComponent) {
    const field = document.getElementById('name-editor');
    if (field != null) {
      field.focus();
      const s2ct = document.getElementsByClassName('select2-container');
      if (s2ct != null && s2ct.length > 0) {
        for (let i = 0; i < s2ct.length; i++) {
          try {
            s2ct[i].remove();
          } catch (e) {
            //
          }
        }
      }
    }
  }

  nameEditLostFocus() {
    this.cancelEditFieldName();
  }

  syncSingleRowSchema(row: TableRow): TableRow {
    let exists: { [name: string]: boolean } = {}
    for (let x = 0; x < this.tableSchema.length; x++) { // reset the existing state per row
      exists[this.tableSchema[x].Id] = false;
    }

    Object.entries(row.Fields).forEach( // detect existing fields
      ([key, value]) => {
        exists[key] = true;
      }
    );

    Object.entries(exists).forEach( // create missing fields
      ([key, value]) => {
        if (!value) {
          row.Fields[key] = '';
        }
      }
    );

    this.apiManagerService.translateRow(row, this.tableSchema);
    return row;
  }



  closeModal2() {
    if (this.activeModal2 != null) {
      try {
        this.activeModal2.dismiss();
      } catch (e) {
        //
      }

      this.activeModal2 = null;
    }
  }


  saveFieldProperties() {
    this.closeModal2();
    if (this.editField != null) {
      if (this.newField) {
        this.tableSchema.push(this.editField);
      } else {
        for (let i = 0; i < this.tableSchema.length; i++) {
          if (this.tableSchema[i].Id === this.editField.Id) {
            this.tableSchema[i] = this.editField;
            break;
          }
        }
      }

      this.filterSchema();
      this.editField = null;
      this.newField = false;
      this.prepareSchemaSave();
    }
  }

  askRemoveField(modalRef) {
    const tmp = this.editField;
    const tmp2 = this.newField;
    try {
      this.cancelEditFieldName();
    } catch (e) {

    }

    this.editField = tmp;
    this.newField = tmp2;
    if (this.editField != null) {
      if (!this.newField) {
        this.closeModal2();
        this.activeModal2 = this.modalService.open(modalRef,
          {backdropClass: 'light-backdrop'});
        this.unhideEditor();
      }
    }
  }

  removeFieldProperties() {
    this.closeModal2();
    if (this.editField != null) {
      if (!this.newField) {
        for (let i = 0; i < this.tableSchema.length; i++) {
          if (this.tableSchema[i].Id === this.editField.Id) {
            this.tableSchema.splice(i, 1);
            this.filterSchema();
            this.newField = false;
            this.prepareSchemaSave();
            break;
          }
        }
      }
    }
  }

  createFieldProperties(modalRef, defaultTab = '00000000-0000-0000-0000-000000000000', sidebar = false) {
    this.filterSchema();
    this.closeModal2();
    this.editField = new FieldMeta();
    this.editField.DisplayName = 'Feld ' + this.lastNewFieldId.toString();
    this.editField.Name = 'Feld_' + this.lastNewFieldId.toString() + '-' + crc32(this.editField.Id).toString(16);
    this.lastNewFieldId++;
    this.editField.FieldType = 'text';

    this.editField.TableOrder = this.lastOrder;
    this.lastOrder += 10;
    this.newField = true;
    if (!sidebar) {
      this.activeModal2 = this.modalService.open(modalRef,
        {backdropClass: 'light-backdrop'});
      this.unhideEditor();
    } else {
      this.activeModal2 = this.modalService.open(modalRef,
        {scrollable: true, windowClass: 'modal-left', backdropClass: 'light-backdrop', size: 'lg'});
      this.unhideEditor();
    }
  }

  startEditFieldName(fieldId) {
    if (!this.allowRenameField) {
      return;
    }

    this.editField = this.tableSchema.find(f => f.Id === fieldId);
    this.newField = false;
    if (this.editField != null) {
      this.filterSchema();
      this.editFieldNameText = this.editField.DisplayName;
      this.editFieldId = fieldId;
      this.editFieldName = true;
      this.prepareFocus();
    }
  }

  editFieldProperties(fieldId, modalRef, sidebar = false) {
    this.cancelEditFieldName();
    this.filterSchema();
    this.closeModal2();
    const field = this.tableSchema.find(f => f.Id === fieldId);
    if (field != null) {
      this.editField = field;
      this.newField = false;
      if (!sidebar) {
        this.activeModal2 = this.modalService.open(modalRef,
          {backdropClass: 'light-backdrop'});
        this.unhideEditor();
      } else {
        this.activeModal2 = this.modalService.open(modalRef,
          {scrollable: true, windowClass: 'modal-left', backdropClass: 'light-backdrop', size: 'lg'});
        this.unhideEditor();
      }
    }
  }

  filterSchema() { // filter the schema fields to only show visible fields in the table
    let schema = this.tableSchema;
    schema.sort((a,b) => a.TableOrder - b.TableOrder);
    let countId = 0;
    for (let i = 0; i < schema.length; i++) {
      schema[i].TableOrder = countId;
      countId += 10;
    }

    this.tableSchema = schema;
    let filtered: FieldMeta[] = [];
    for (let i = 0; i < schema.length; i++) {
      if (!schema[i].IsHidden) {
        filtered.push(schema[i]);
      }
    }

    Object.entries(schema).forEach(
      ([string, meta]) => {
        if (meta.FieldType.startsWith("linked")) {
          if (!this.apiManagerService.cacheRows[meta.LinkedTable]) {
            this.apiManagerService.getTableEntities(meta.LinkedTable). then((rows) => {
              if (rows != null) {
                this.apiManagerService.cacheRows[meta.LinkedTable] = rows;
              } else {
                this.apiManagerService.cacheRows[meta.LinkedTable] = [];
              }
            });
          }
        }
      });


    this.lastFieldId = '';
    for (let i = filtered.length -1; i >=0 ;  i--) {
      if (!filtered[i].IsHidden) {
        this.lastFieldId = filtered[i].Id;
        break;
      }
    }

    this.filteredSchema = filtered;
  }

  reloadAll(force = false){
    if (this._tableName.trim() == "") {
      this.tableSchema = [];
      this.tableRows = [];
      if (this.loading) {
        setTimeout(() => this.loading2 = false, 500);
      }
      this.loading = false;
    } else {
      if (!force) {
        this.loading2 = true;
        this.loading = true;
      }
      if (this.apiManagerService.cacheSchemas[this._tableName] &&  this.apiManagerService.cacheRows[this._tableName] && !force && (this._linkedRowId == null || this._linkedRowId.trim() == '')) {
        this.tableSchema = this.apiManagerService.cacheSchemas[this._tableName];
        this.tableRows =  this.apiManagerService.cacheRows[this._tableName];
        if (this.tableRows  == null) {
          this.tableRows = [];
        }

        this.filterSchema();
        this.tableRows = this.apiManagerService.syncRowsSchema(this.tableRows, this.tableSchema);

        this.filterRows();
        if (this.loading) {
          setTimeout(() => this.loading2 = false, 500);
        }
        this.loading = false;
      }
      this.apiManagerService.getTableSchema(this._tableName).then((schema) => {
        if (schema != null) {
          this.tableSchema = schema;
          this.filterSchema();
          this.apiManagerService.cacheSchemas[this._tableName] = this.tableSchema;
          if (this._linkedRowId != null && this._linkedRowId.trim() != '') {
            this.apiManagerService.getTableEntity(this._linkedRowId).then((row) => {
              this._linkedTable = row.TableName;
            });

            this.apiManagerService.getTableRowDependencies(this._linkedRowId, this._tableName).then((rows) => {
              if (rows != null) {
                this.tableRows = this.apiManagerService.syncRowsSchema(rows, this.tableSchema);
              } else {
                this.tableRows = [];
              }

              this.filterRows();

              if (this.loading) {
                setTimeout(() => this.loading2 = false, 500);
              }
              this.loading = false;
            }).catch((e) => {
              if (this.loading) {
                setTimeout(() => this.loading2 = false, 500);
              }
              this.loading = false;
            });
          } else {
            this.apiManagerService.getTableEntities(this._tableName).then((rows) => {
              if (rows != null) {
                this.tableRows = this.apiManagerService.syncRowsSchema(rows, this.tableSchema);
                if (this.tableRows  == null) {
                  this.tableRows = [];
                }

                this.filterRows();
                this.apiManagerService.cacheRows[this._tableName] = this.tableRows;
              }

              if (this.loading) {
                setTimeout(() => this.loading2 = false, 500);
              }
              this.loading = false;
            }).catch((e) => {
              if (this.loading) {
                setTimeout(() => this.loading2 = false, 500);
              }
              this.loading = false;
            });
          }
        }
      })
    }
  }

  dragFieldStart(event, field: FieldMeta) {
    this.setDragCursor(event);

    this.dragField = field;

    let dummy: HTMLElement;

    dummy = document.getElementById('table_dummy_' + this.dragField.Id);

    /*if (!this.isDark) {
      dummy.style.backgroundColor = '#f1f3fa';
      dummy.style.color = '#919ca7';
      dummy.style.border = '1px solid #eef2f7';
    } else {*/
    dummy.style.backgroundColor = '#464f5b';
    dummy.style.color = '#dee2e6';
    dummy.style.border = '1px solid #464f5b';
    //}

    const fw = this.getColWidth(field.Id) - 10;

    dummy.style.width = fw + 'px';


    dummy.style.display = 'flex';
    dummy.style.position = 'fixed';
    dummy.style.top = (event.y - 20) + 'px';
    dummy.style.left = (event.x - 20) + 'px';
    dummy.style.zIndex = '1200';
    dummy.style.pointerEvents = 'none';

    const img = document.createElement('img');
    img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==';
    img.style.height = '1px';
    img.style.width = '1px';
    event.dataTransfer.setDragImage(img, 25, 25);
  }

  dragStop(evt) {
    if (this.dragField != null) {
      let dummy: HTMLElement;
      dummy = document.getElementById('table_dummy_' + this.dragField.Id );

      dummy.style.display = 'none';
    }
    this.dragField = null;
    document.body.style.cursor = '';
    evt.target.style.cursor = '';
  }

  dragFieldMove(event) {
    let dummy: HTMLElement;
    dummy = document.getElementById('table_dummy_' + this.dragField.Id );

    dummy.style.top = (event.y - 20) + 'px';
    dummy.style.left = (event.x - 20) + 'px';
  }

  dropField(event, field: FieldMeta) {
    document.body.style.cursor = '';
    event.target.style.cursor = '';
    let dummy: HTMLElement;
    dummy = document.getElementById('table_dummy_' + this.dragField.Id);

    dummy.style.display = 'none';

    if (this.dragField != null && this.dragField.Id !== field.Id) {
      if (this.dragField.TableOrder < field.TableOrder) {
        this.dragField.TableOrder = field.TableOrder + 1;
      } else {
        this.dragField.TableOrder = field.TableOrder - 1;
      }

      this.filterSchema();
      this.prepareSchemaSave();
    }

  }


  dragOverFieldStart(event, field: FieldMeta) {
    if (this.dragField != null && this.dragField.Id !== field.Id) {
      event.preventDefault();
    }
  }

  setDragCursor(evt) {
    /*if (this.isDark) {
      document.body.style.cursor = 'grabbing'; // 'url(/img/cursor/flip_to_front_white.cur),pointer';
      evt.target.style.cursor = 'grabbing'; // 'url(/img/cursor/flip_to_front_white.cur),pointer';
    } else {
      document.body.style.cursor = 'grabbing'; //'url(/img/cursor/flip_to_front_black.cur),pointer';
      evt.target.style.cursor = 'grabbing'; // 'url(/img/cursor/flip_to_front_black.cur),pointer';
    }*/
  }

  onScroll(evt) {

  }

  onRightClick(event: MouseEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = {item: item}

    // we open the menu
    this.matMenuTrigger.openMenu();

  }

  getColWidth(fieldId): number {
    const field = document.getElementById('col_' + fieldId);
    return field.clientWidth;
  }
}
