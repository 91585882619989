import { Component, OnInit } from '@angular/core';
import {TransportEntry} from "../api-classes/api-classes";
import {ApiManagerService} from "../api-manager/api-manager.service";

@Component({
  selector: 'app-blocked-senders',
  templateUrl: './blocked-senders.component.html',
  styleUrls: ['./blocked-senders.component.css']
})
export class BlockedSendersComponent implements OnInit {

  blockList: TransportEntry[] = [];
  newAlias = '';
  constructor(private apiManagerService: ApiManagerService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.apiManagerService.getBlockedSenders().then((blocks) => {
      this.blockList = blocks;
    });
  }

  addAlias() {
    if (this.newAlias.trim() !== '') {
      this.apiManagerService.blockSender(this.newAlias).then((res) => {
        this.newAlias = '';
        this.refresh();
      })
    }
  }

  removeAlias(member: string) {
    this.apiManagerService.unblockSender(member).then((res) => {
      this.refresh();
    })
  }


}
