import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldMeta, Page, PageItem, PageItemMove, TableRow} from "../../api-classes/api-classes";
import {Select2OptionData} from "../../as-ng-select2/lib/ng-select2.interface";

@Component({
  selector: 'app-as-renderer-button',
  templateUrl: './as-renderer-button.component.html',
  styleUrls: ['./as-renderer-button.component.css']
})
export class AsRendererButtonComponent implements OnInit {

  _styleEditMode = 'border-color: gray;border-style: solid;border-width: thin;';
  @Output() moveRequest = new EventEmitter<PageItemMove>();
  @Output() buttonClicked = new EventEmitter<string>();
  @Input() sessionId = '';
  @Input() parentId = '';
  @Input() page: Page
  @Input() item: PageItem;
  @Input() row: TableRow;
  @Input() mode = 0;
  @Input() schema: FieldMeta[] = [];
  @Input() schemaResolver: { [name: string]: FieldMeta } = {}
  @Input() dropOptions: { [name: string]: Array<Select2OptionData> } = {};
  @Input() lazyLoadCount = 0;
  constructor() { }

  ngOnInit(): void {
  }

  doClick() {
    this.buttonClicked.emit(this.item.Options2);
  }

}
