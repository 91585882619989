import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {TreemapController, TreemapElement} from 'chartjs-chart-treemap';
import { color } from 'chart.js/helpers';
import {Title} from "@angular/platform-browser";
import {Chart} from "angular-highcharts";
import {XrangePointOptionsObject} from "highcharts";

@Component({
  selector: 'app-as-renderer-gauge',
  templateUrl: './as-renderer-gauge.component.html',
  styleUrls: ['./as-renderer-gauge.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AsRendererGaugeComponent implements OnInit {

  constructor() {
    //this.plugins = [this.gaugeNeedle];
    let x: XrangePointOptionsObject;

  }

  // @ts-ignore
  chart = new Chart( {
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    chart: {
      type: 'solidgauge'
    },
    title: {
      text: ''
    },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: 'white',
        innerRadius: '60%',
        outerRadius: '90%',
        shape: 'arc'
      }
    },
    tooltip: {
      enabled: true
    },
    // the value axis
    yAxis: {
      stops: [
        [0.34, '#DF5353'], // green
        [0.67, 'yellow'], // yellow
        [1, 'green'] // red
      ],
      length: 5,
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70
      },
      labels: {
        y: 16
      },
      min: 0,
      max: 100,
      plotBands: [
        { from: 0, to: 34, color: 'red', label:'Nervös' },
        { from: 34, to: 67, color: 'yellow', label: 'Neutral' },
        { from: 67, to: 100, color: 'green', label: 'Entspannt' }
      ]
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: false
        }
      }
    },
    series: [
      {
        data: [33]
      }
    ],
    credits: {
      enabled: false
    }
  } as any);

  public plugins = [];
  public labels: string[] = ['Nervös' , 'Neutral', 'Entspannt'];
  public dataset: any[] = [{
    label: '# of Votes',
    data: [33, 33, 33],
    backgroundColor: [
      "rgba(255, 26, 104, 0.8)",
      "rgba(255, 206, 86, 0.8)",
      color('green').alpha(0.8).rgbString(),
    ],
    borderColor: "white",
    borderWidth: 2,
    cutout: "95%",
    circumference: 180,
    rotation: 270,
    borderRadius: 5,
  }]
  public dataset1: any[] = [
    {
      label: "Gauge",
      data: [190, 175, 120],
      backgroundColor: [
        "rgba(255, 26, 104, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        color('green').alpha(0.8).rgbString(),
        //"rgba(75, 192, 192, 0.8)",
      ],
      needleValue: 50,
      borderColor: "white",
      borderWidth: 2,
      //cutout: "95%",
      circumference: 180,
      rotation: 270,
      borderRadius: 5,
    },
    //{ data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        yAlign: "bottom",
        displayColors: false,
        callbacks: {
          label: function (tooltipItem, data, value) {
            return tooltipItem.label;
          },
        },
      },
    }
  }

  //gaugeNeedle
  gaugeNeedle = {
    id: "gaugeNeedle",
    afterDatasetDraw(chart, args, options) {
      const {
        ctx,
        config,
        data,
        chartArea: { top, right, bottom, left, width, height },
      } = chart;
      ctx.save();
      const needleValue = data.datasets[0].needleValue;
      const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);

      /*if (needleValue <= 100) {
        var angle = Math.PI + (1 / 200) * needleValue * Math.PI;
        console.log(angle);
      } else if (needleValue <= 10000) {
        var angle =
          Math.PI +
          (1 / 200) * 100 * Math.PI +
          ((1 / 200) * needleValue * Math.PI * 65) / 10000;
      } else if (needleValue <= 1000000) {
        var angle =
          Math.PI +
          (1 / 200) * 100 * Math.PI +
          ((1 / 200) * 10000 * Math.PI * 65) / 10000 +
          ((1 / 200) * needleValue * Math.PI * 35) / 1000000;
      } else {
        var angle = 0;
      }*/
      let angle = 0;
      angle = Math.PI + (1 / 200) * needleValue * Math.PI;
      console.log('angle');
      console.log(angle);
      if (needleValue > 0) {
        if (needleValue<100) {
          angle = 180 / 100 * needleValue;
        } else {
          angle = 180;
        }
      } else {
        angle = 0;
      }

      console.log(angle);
      angle = 4.7;

      const cx = width / 2;
      const cy = chart._metasets[0].data[0].y;

      //needle
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -2);
      ctx.lineTo(height - ctx.canvas.offsetTop - 160, 0); // change 160 value if the needle size gets changed
      ctx.lineTo(0, 2);
      ctx.fillStyle = "#444";
      ctx.fill();
      //needle dot
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, 10);
      ctx.fill();
      ctx.restore();

      //text
      ctx.font = "20px Ubuntu";
      ctx.fillStyle = "#444";
      //ctx.fillText(needleValue + "%", cx, cy + 50);
      ctx.font = "10px Ubuntu";
      //ctx.fillText(0, 5, cy + 20);
      //ctx.fillText(100, cx, 90);
      //ctx.fillText("10k", cx + 185, 200); // change values if the position gets changed
      //ctx.fillText("1M", cx + 193, 320); // change values if the position gets changed
      ctx.textAlign = "center";
      ctx.restore();
    },
  };

  ngOnInit(): void {
  }

}
