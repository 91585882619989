<div class="hk-wrapper hk-vertical-nav {{themeOptions.Minimize?'hk-nav-toggle':''}} {{themeOptions.Scroll ?'scrollable-nav':''}} {{showOptions ? 'hk-settings-toggle':''}}" >

  <!-- Top Navbar -->
  <nav class="navbar navbar-expand-xl {{themeOptions.TopDark ? 'navbar-dark':'navbar-light'}} fixed-top hk-navbar" style="min-height: 57px; {{themeOptions.TopDark && themeOptions.Gradient == 1?gradientCode:''}}">
    <a class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" href="javascript:void(0);"><span class="feather-icon"><i data-feather="more-vertical"></i></span></a>
    <a (click)="switchMinimize()" id="navbar_toggle_btn" class="navbar-toggle-btn nav-link-hover" style="display: flex; vertical-align: middle;" href="javascript:void(0);"><span style="font-size: 20px;" class="fas fa-bars"><!--<i data-feather="menu"></i>--></span></a>
    <a class="navbar-brand" href="/">
      <!--<img class="brand-img d-inline-block" src="/assets/images/astralus/logo{{themeOptions.TopDark?'':'-black'}}.svg?20204021034"style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />-->
      <img class="brand-img d-inline-block" src="/v1.0/auth/logo/{{themeOptions.TopDark?'dark':'light'}}?{{sessionId}}"style="max-width: 150px;max-height: 30px;" alt="brand" />
    </a>
    <ul class="navbar-nav hk-navbar-content order-xl-2">
      <li class="nav-item">
        <a id="navbar_search_btn" class="nav-link nav-link-hover" href="javascript:void(0);"><span class="feather-icon"><i data-feather="search"></i></span></a>
      </li>
      <li class="nav-item" style="display: unset !important">
        <a style="cursor: pointer; vertical-align: middle; display: flex;"  class="nav-link nav-link-hover" *ngIf="profile.IsAdmin" routerLink="/admin/apps">
          <!--<a class="nav-link" data-toggle="dropdown" href="#" style="cursor: pointer;vertical-align: middle;display: flex;" role="button" (click)="openCloseMenu($event)" aria-haspopup="false" aria-expanded="false">-->
          <!--<i class="mat-icon notranslate material-icons mat-icon-no-color" style="font-size: 35px;">apps</i>-->
          <i class="fa-solid fa-grid-2-plus" style="font-size: 22px;"></i>
          <!--<img src="/assets/images/astralus/{{themeOptions.TopDark?'launcher-white.svg':'launcher-dark.svg'}}" style="width: 20px;"/>-->
        </a>
      </li>
      <li class="nav-item" *ngIf="!hideSettings">
        <a style="cursor: pointer; vertical-align: middle; display: flex;" id="settings_toggle_btn" class="nav-link nav-link-hover" (click)="switchOptions()" href="javascript:void(0);">
          <span class="material-icons">
            settings
          </span>
        </a>
      </li>
      <li ngbDropdown  class="nav-item dropdown dropdown-notifications" *ngIf="notifications.length > 0">
        <a ngbDropdownToggle class="nav-link dropdown-toggle no-caret" href="#" onclick="return false;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer; vertical-align: middle; display: flex;">
          <span class="material-icons-outlined">notifications</span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
          <h6 class="dropdown-header">Notifications</h6>
          <div *ngFor="let notification of topNotifications">
            <a ngbDropdownItem href="javascript:void(0);" class="dropdown-item" onclick="return false;">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                    <img src="/dg/img/avatar1.jpg" alt="user" class="avatar-img rounded-circle">
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text">{{notification.Message}}</div>
                    <div class="notifications-time">{{notification.CreatedString}}</div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </li>
      <!--<li class="nav-item dropdown dropdown-notifications">
        <a class="nav-link dropdown-toggle no-caret" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="feather-icon bell"><i data-feather="bell"></i></span></a>
        <div class="dropdown-menu dropdown-menu-right" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
          <h6 class="dropdown-header">Notifications <a href="javascript:void(0);" class="">View all</a></h6>
          <div class="notifications-nicescroll-bar">
            <a href="javascript:void(0);" class="dropdown-item">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                    <img src="dg/img/avatar1.jpg" alt="user" class="avatar-img rounded-circle">
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text"><span class="text-dark text-capitalize">Evie Ono</span> accepted your invitation to join the team</div>
                    <div class="notifications-time">12m</div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0);" class="dropdown-item">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                    <img src="dg/img/avatar2.jpg" alt="user" class="avatar-img rounded-circle">
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text">New message received from <span class="text-dark text-capitalize">Misuko Heid</span></div>
                    <div class="notifications-time">1h</div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0);" class="dropdown-item">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                                            <span class="avatar-text avatar-text-primary rounded-circle">
													<span class="initial-wrap"><span><i class="zmdi zmdi-account font-18"></i></span></span>
                                            </span>
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text">You have a follow up with<span class="text-dark text-capitalize"> dashgrin head</span> on <span class="text-dark text-capitalize">friday, dec 19</span> at <span class="text-dark">10.00 am</span></div>
                    <div class="notifications-time">2d</div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0);" class="dropdown-item">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                                            <span class="avatar-text avatar-text-success rounded-circle">
													<span class="initial-wrap"><span>A</span></span>
                                            </span>
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text">Application of <span class="text-dark text-capitalize">Sarah Williams</span> is waiting for your approval</div>
                    <div class="notifications-time">1w</div>
                  </div>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="javascript:void(0);" class="dropdown-item">
              <div class="media">
                <div class="media-img-wrap">
                  <div class="avatar avatar-sm">
                                            <span class="avatar-text avatar-text-warning rounded-circle">
													<span class="initial-wrap"><span><i class="zmdi zmdi-notifications font-18"></i></span></span>
                                            </span>
                  </div>
                </div>
                <div class="media-body">
                  <div>
                    <div class="notifications-text">Last 2 days left for the project</div>
                    <div class="notifications-time">15d</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </li>-->

      <li class="nav-item" style="display: unset !important">
        <a style="cursor: pointer; vertical-align: middle; display: flex;"  class="nav-link nav-link-hover" (click)="openCloseMenu($event)" href="javascript:void(0);">
          <!--<a class="nav-link" data-toggle="dropdown" href="#" style="cursor: pointer;vertical-align: middle;display: flex;" role="button" (click)="openCloseMenu($event)" aria-haspopup="false" aria-expanded="false">-->
          <!--<i class="mat-icon notranslate material-icons mat-icon-no-color" style="font-size: 35px;">apps</i>-->
          <i class="mat-icon notranslate material-icons mat-icon-no-color" style="font-size: 30px;">apps</i>

        </a>
      </li>


      <li class="nav-item dropdown dropdown-authentication">
        <a class="nav-link dropdown-toggle no-caret" onclick="return false;" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="media">
            <div class="media-img-wrap">
              <div class="avatar">
                <img src="assets/images/users/user-regular.svg" alt="user" id="mnuAvatar" class="avatar-img rounded-circle">
              </div>
              <span class="badge badge-success badge-indicator"></span>
            </div>
            <div class="media-body">
              <span>{{profile.DisplayName}} ({{profile.TenantName}})<!--<i class="zmdi zmdi-chevron-down"></i>--></span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right" data-dropdown-in="flipInX" data-dropdown-out="flipOutX">
          <a class="dropdown-item" href="profile.html"><i class="dropdown-icon zmdi zmdi-account"></i><span>Profile</span></a>
          <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-card"></i><span>My balance</span></a>
          <a class="dropdown-item" href="inbox.html"><i class="dropdown-icon zmdi zmdi-email"></i><span>Inbox</span></a>
          <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-settings"></i><span>Settings</span></a>
          <div class="dropdown-divider"></div>
          <div class="sub-dropdown-menu show-on-hover">
            <a href="#" class="dropdown-toggle dropdown-item no-caret"><i class="zmdi zmdi-check text-success"></i>Online</a>
            <div class="dropdown-menu open-left-side">
              <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-check text-success"></i><span>Online</span></a>
              <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-circle-o text-warning"></i><span>Busy</span></a>
              <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-minus-circle-outline text-danger"></i><span>Offline</span></a>
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#"><i class="dropdown-icon zmdi zmdi-power"></i><span>Log out</span></a>
        </div>
      </li>
    </ul>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-0">
        <li class="nav-item">
          <a href="#" onclick="return false;" class="nav-link">Admin</a>
        </li>
        <li class="nav-item" *ngIf="showSupport">
          <a routerLink="/support" class="nav-link">Support</a>
        </li>
        <!--<li class="nav-item">
          <a href="#" class="nav-link active">Marketing</a>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Sales</a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Separated link</a>
          </div>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link">Help Desk</a>
        </li>
        <li class="nav-item">
          <a href="calendar.html" class="nav-link">Calendar</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link">Email</a>
        </li>
        <li class="nav-item">
          <a href="file-manager.html" class="nav-link">File Manager</a>
        </li>-->
      </ul>
    </div>

  </nav>
  <form role="search" class="navbar-search">
    <div class="position-relative">
      <a href="javascript:void(0);" class="navbar-search-icon"><span class="feather-icon"><i data-feather="search"></i></span></a>
      <input type="text" name="example-input1-group2" class="form-control" placeholder="Type here to Search">
      <a id="navbar_search_close" class="navbar-search-close" href="#"><span class="feather-icon"><i data-feather="x"></i></span></a>
    </div>
  </form>
  <!-- /Top Navbar -->

  <!-- Vertical Nav -->
  <nav class="hk-nav {{themeOptions.NavDark? 'hk-nav-dark':'hk-nav-light'}}">
    <a href="javascript:void(0);" id="hk_nav_close" class="hk-nav-close" (click)="switchMinimize()"><span class="material-icons">clear</span></a>
    <div class="nicescroll-bar">
      <div class="navbar-nav-wrap">
        <div class="nav-header" style="width: 30px;">
          <span>Admin</span>
          <span>ADM</span>
        </div>
        <ul class="navbar-nav flex-column">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/dashboard">
              <i class="fa-solid fa-rectangles-mixed" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Dashboard</span>
            </a>
          </li>
          <!--<li class="nav-item active">
            <a class="nav-link" routerLink="/crm/leads">
              <i class="fa-solid fa-filter-list" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Leads</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/crm/deals">
              <i class="fa-solid fa-location-crosshairs" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Verkaufschancen</span>
            </a>
          </li>-->
          <li class="nav-item active">
            <a class="nav-link" routerLink="/mail/mailboxes">
              <i class="fa-solid fa-mailbox" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Mailboxen</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/mail/distributionlists">
              <i class="fa-solid fa-mail-bulk" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Verteiler</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/mail/blocked-senders">
              <i class="fa-solid fa-circle-xmark" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Gesperrte Absender</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/tilda">
              <i class="fa-solid fa-window" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Tilda</span>
            </a>
          </li>
          <!--<li class="nav-item active">
            <a class="nav-link" routerLink="/crm/suppliers">
              <i class="fa-solid fa-building" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Dienstleister</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/crm/contacts">
              <i class="fa-solid fa-folder-user" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Kontakte</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/crm/orders">
              <i class="fa-solid fa-location-crosshairs" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Bestellungen</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/crm/contracts">
              <i class="fa-solid fa-location-crosshairs" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Verträge</span>
            </a>
          </li>-->
          <li class="nav-item active" *ngIf="profile.IsAdmin">
            <a class="nav-link" routerLink="/admin/features">
              <i class="fa-solid fa-rectangle-history-circle-plus" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Features</span>
            </a>
          </li>

<!--          <li class="nav-item active">
            <a class="nav-link" routerLink="/settings">
              <i class="fa-solid fa-sliders-simple" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Einstellungen</span>
            </a>
          </li>-->
          <li class="nav-item active" *ngIf="profile.IsAdmin">
            <a class="nav-link link-with-badge" style="cursor: pointer" (click)="settingsOpen=!settingsOpen" data-toggle="collapse" data-target="#app_drp">
              <i class="fa-solid fa-sliders-simple" style="font-size: 15px; min-width: 17px;"></i>
              <span class="nav-link-text">Einstellungen</span>
            </a>
            <ul id="app_drp" class="nav flex-column {{!settingsOpen?'collapse':''}} collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/admin/settings"><span class="nav-link-text">Allgemein</span></a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/admin/users"><span class="nav-link-text">Benutzer</span></a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <!--<li class="nav-item">
            <a class="nav-link link-with-badge" href="javascript:void(0);" data-toggle="collapse" data-target="#app_drp">
              <i class="ion ion-md-appstore"></i>
              <span class="nav-link-text">Application</span>
              <span class="badge badge-success badge-pill">Hot</span>
            </a>
            <ul id="app_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="chats.html">Chat</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="calendar.html">Calendar</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="email.html">Email</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="file-manager.html">File Manager</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#auth_drp">
              <i class="ion ion-md-contact"></i>
              <span class="nav-link-text">Authentication</span>
            </a>
            <ul id="auth_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#signup_drp">
                      Sign Up
                    </a>
                    <ul id="signup_drp" class="nav flex-column collapse collapse-level-2">
                      <li class="nav-item">
                        <ul class="nav flex-column">
                          <li class="nav-item">
                            <a class="nav-link" href="signup.html">Cover</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="signup-simple.html">Simple</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#signin_drp">
                      Login
                    </a>
                    <ul id="signin_drp" class="nav flex-column collapse collapse-level-2">
                      <li class="nav-item">
                        <ul class="nav flex-column">
                          <li class="nav-item">
                            <a class="nav-link" href="login.html">Cover</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="login-simple.html">Simple</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#recover_drp">
                      Recover Password
                    </a>
                    <ul id="recover_drp" class="nav flex-column collapse collapse-level-2">
                      <li class="nav-item">
                        <ul class="nav flex-column">
                          <li class="nav-item">
                            <a class="nav-link" href="forgot-password.html">Forgot Password</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="reset-password.html">Reset Password</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="lock-screen.html">Lock Screen</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="404.html">Error 404</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="maintenance.html">Maintenance</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#pages_drp">
              <i class="ion ion-md-document"></i>
              <span class="nav-link-text">Pages</span>
            </a>
            <ul id="pages_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="profile.html">Profile</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="invoice.html">Invoice</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="gallery.html">Gallery</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="activity.html">Activity</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="faq.html">Faq</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>-->
        </ul>
        <!--
        <hr class="nav-separator">
        <div class="nav-header">
          <span>User Interface</span>
          <span>UI</span>
        </div>
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#Components_drp">
              <i class="ion ion-md-outlet"></i>
              <span class="nav-link-text">Components</span>
            </a>
            <ul id="Components_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="alerts.html">Alerts</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="avatar.html">Avatar</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="badge.html">Badge</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="buttons.html">Buttons</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="cards.html">Cards</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="carousel.html">Carousel</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="collapse.html">Collapse</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="dropdowns.html">Dropdown</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="list-group.html">List Group</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="modal.html">Modal</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="nav.html">Nav</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="navbar.html">Navbar</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="nestable.html">Nestable</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="pagination.html">Pagination</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="popovers.html">Popovers</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="progress.html">Progress</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="tooltip.html">Tooltip</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#content_drp">
              <i class="ion ion-md-clipboard"></i>
              <span class="nav-link-text">Content</span>
            </a>
            <ul id="content_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="typography.html">Typography</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="images.html">Images</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="media-object.html">Media Object</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#utilities_drp">
              <i class="ion ion-md-git-branch"></i>
              <span class="nav-link-text">Utilities</span>
            </a>
            <ul id="utilities_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="background.html">Background</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="border.html">Border</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="colors.html">Colors</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="embeds.html">Embeds</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="icons.html">Icons</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="shadow.html">Shadow</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="sizing.html">Sizing</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="spacing.html">Spacing</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#forms_drp">
              <i class="ion ion-md-calculator"></i>
              <span class="nav-link-text">Forms</span>
            </a>
            <ul id="forms_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="form-element.html">Form Elements</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="input-groups.html">Input Groups</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="form-layout.html">Form Layout</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="form-mask.html">Form Mask</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="form-validation.html">Form Validation</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="form-wizard.html">Form Wizard</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="file-upload.html">File Upload</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="editor.html">Editor</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#tables_drp">
              <i class="ion ion-md-grid"></i>
              <span class="nav-link-text">Tables</span>
            </a>
            <ul id="tables_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="basic-table.html">Basic Table</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="data-table.html">Data Table</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="responsive-table.html">Responsive Table</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="editable-table.html">Editable Table</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#charts_drp">
              <i class="ion ion-md-stats"></i>
              <span class="nav-link-text">Charts</span>
            </a>
            <ul id="charts_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="line-charts.html">Line Chart</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="area-charts.html">Area Chart</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="bar-charts.html">Bar Chart</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="pie-charts.html">Pie Chart</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="realtime-charts.html">Realtime Chart</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="mini-charts.html">Mini Chart</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0);" data-toggle="collapse" data-target="#maps_drp">
              <i class="ion ion-md-map"></i>
              <span class="nav-link-text">Maps</span>
            </a>
            <ul id="maps_drp" class="nav flex-column collapse collapse-level-1">
              <li class="nav-item">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="google-map.html">Google Map</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="vector-map.html">Vector Map</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <hr class="nav-separator">
        <div class="nav-header">
          <span>Getting Started</span>
          <span>GS</span>
        </div>
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a class="nav-link" href="documentation.html">
              <i class="ion ion-md-bookmarks"></i>
              <span class="nav-link-text">Documentation</span>
            </a>
          </li>
        </ul>
        -->
      </div>
    </div>
  </nav>
  <div id="hk_nav_backdrop" class="hk-nav-backdrop" (click)="switchMinimize()"></div>
  <!-- /Vertical Nav -->

  <!-- Setting Panel -->
  <div class="hk-settings-panel">
    <div class="nicescroll-bar position-relative">
      <div class="settings-panel-wrap">
        <div class="settings-panel-head">
          <img class="brand-img d-inline-block align-top" src="/assets/images/astralus/ordiance-color.svg?20204021034"style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />
          <a href="javascript:void(0);" id="settings_panel_close" class="settings-panel-close" (click)="switchOptions()"><span class="material-icons">clear</span></a>
        </div>

        <hr>
        <h6 class="mb-5">Navigation</h6>
        <p class="font-14">Menu comes in two modes: dark & light</p>
        <div class="button-list hk-nav-select mb-10">
          <button type="button" id="nav_light_select" class="btn {{themeOptions.NavDark?'btn-outline-light':'btn-outline-success'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchNav(false)"><span class="icon-label"><i class="fa fa-sun-o"></i> </span><span class="btn-text">Light Mode</span></button>
          <button type="button" id="nav_dark_select" class="btn {{themeOptions.NavDark?'btn-outline-success':'btn-outline-light'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchNav(true)"><span class="icon-label"><i class="fa fa-moon-o"></i> </span><span class="btn-text">Dark Mode</span></button>
        </div>
        <hr>
        <h6 class="mb-5">Top Nav</h6>
        <p class="font-14">Choose your liked color mode</p>
        <div class="button-list hk-navbar-select mb-10">
          <button type="button" id="navtop_light_select" class="btn {{themeOptions.TopDark?'btn-outline-light':'btn-outline-success'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchTopNav(false)"><span class="icon-label"><i class="fa fa-sun-o"></i> </span><span class="btn-text">Light Mode</span></button>
          <button type="button" id="navtop_dark_select" class="btn {{themeOptions.TopDark?'btn-outline-success':'btn-outline-light'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchTopNav(true)"><span class="icon-label"><i class="fa fa-moon-o"></i> </span><span class="btn-text">Dark Mode</span></button>
        </div>
        <hr>
        <h6 class="mb-5" *ngIf="themeOptions.TopDark && gradientCode != ''">Top Nav Style</h6>
        <p class="font-14" *ngIf="themeOptions.TopDark && gradientCode != ''">Choose your liked style</p>
        <div class="button-list hk-navbar-select mb-10" *ngIf="themeOptions.TopDark && gradientCode != ''">
          <button type="button" id="navtop_nograd_select" class="btn {{themeOptions.Gradient==1?'btn-outline-light':'btn-outline-success'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchGradient(0)"><span class="icon-label"><i class="fa fa-sun-o"></i> </span><span class="btn-text">No Gradient</span></button>
          <button type="button" id="navtop_grad_select" class="btn {{themeOptions.Gradient==1?'btn-outline-success':'btn-outline-light'}} btn-sm btn-wth-icon icon-wthot-bg" (click)="switchGradient(1)"><span class="icon-label"><i class="fa fa-moon-o"></i> </span><span class="btn-text">Gradient</span></button>
        </div>
        <hr *ngIf="themeOptions.TopDark && gradientCode != ''">
        <!--<h6 class="mb-5" *ngIf="profile.IsAdmin">Sonstiges</h6>
        <div class="row" style="display: flex;">
        <div class="col-12" style="width: 100%;" >
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Dark Logo:</label>
            <div class="col-sm-10" style="display: flex;">
              <input style="margin: 2px;margin-top: auto;margin-bottom: auto;" type="file" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Light Logo:</label>
            <div class="col-sm-10" style="display: flex;">
              <input style="margin: 2px;margin-top: auto;margin-bottom: auto;" type="file" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Google API Key:</label>
            <div class="col-sm-10" style="display: flex;">
              <input style="margin: 2px;margin-top: auto;margin-bottom: auto;" type="text" [value]="googleKey" (input)="googleKey=$any($event.target).value;" class="form-control" />
            </div>
          </div>
          <button class="btn btn-success btn-block btn-reset mt-30" (click)="saveGoogle()">Speichern</button>
        </div>
        </div>-->
        <div class="d-flex justify-content-between align-items-center">
          <!--<h6>Scrollable Header</h6>
          <div class="toggle toggle-sm toggle-simple toggle-light toggle-bg-success scroll-nav-switch">
            <div *ngIf="!themeOptions.Scroll" class="toggle-slide"><div (click)="switchScroll()" class="toggle-inner" style="width: 50px; margin-left: -15px;"><div class="toggle-on" style="height: 20px; width: 25px; text-indent: -6.66667px; line-height: 20px;"></div><div class="toggle-blob" style="height: 20px; width: 20px; margin-left: -10px;"></div><div class="toggle-off active" style="height: 20px; width: 25px; margin-left: -10px; text-indent: 6.66667px; line-height: 20px;"></div></div></div>
            <div *ngIf="themeOptions.Scroll" class="toggle-slide"><div (click)="switchScroll()" class="toggle-inner" style="width: 50px; margin-left: 0px;"><div class="toggle-on active" style="height: 20px; width: 25px; text-indent: -6.66667px; line-height: 20px;"></div><div class="toggle-blob" style="height: 20px; width: 20px; margin-left: -10px;"></div><div class="toggle-off" style="height: 20px; width: 25px; margin-left: -10px; text-indent: 6.66667px; line-height: 20px;"></div></div></div>
          </div>-->
        </div>
        <button id="reset_settings" class="btn btn-success btn-block btn-reset mt-30" (click)="reset()">Reset</button>
      </div>
    </div>
    <!--<img class="d-none" src="/assets/images/astralus/logo.svg?20204021034" style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />-->
    <img class="d-none" src="/v1.0/auth/logo/dark?{{sessionId}}" style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />
    <img class="d-none" src="/v1.0/auth/logo/light?{{sessionId}}" style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />
    <!--<img class="d-none" src="/assets/images/astralus/logo-black.svg?20204021034" style="width: 150px; height: 30px; margin-top: -12px;" alt="brand" />-->
  </div>
  <!-- /Setting Panel -->

  <!-- Main Content -->
  <div class="hk-pg-wrapper">
    <!-- Container -->
    <div id="main-container" class="container-fluid {{apiManagerService.noContainerBorder?'pa-0':'mt-xl-50 mt-sm-30 mt-15 px-xxl-65 px-xl-20'}}">
      <router-outlet *ngIf="!halfLoading"></router-outlet>
      <div class="line-center" *ngIf="halfLoading">
        <div class="loading">
          <p>Initialisiere</p>
          <span></span>
        </div>
      </div>
    </div>
    <!-- /Container -->

    <!-- Footer -->
    <div class="hk-footer-wrap container-fluid px-xxl-65 px-xl-20">
      <footer class="footer">
        <div class="row">
          <div class="col-md-6 col-sm-12">
           <!-- <p>Pampered by<a href="https://www.astralus.com/" class="text-dark" target="_blank">Astralus GmbH</a> © 2022</p>-->
            <div style="display: flex; flex-wrap: nowrap; margin-bottom: 10px; margin-top:10px;">Powered by <a target="_blank" href="https://www.ordiance.com"><img src="/assets/images/astralus/ordiance.svg" alt="Astralus Admin Portal" style="height: 12px; margin-left: 5px; margin-right: 5px;margin-top: 3px;"></a></div>
          </div>
          <!--<div class="col-md-6 col-sm-12">
            <p class="d-inline-block">Follow us</p>
            <a href="#" class="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span class="btn-icon-wrap"><i class="fa fa-facebook"></i></span></a>
            <a href="#" class="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span class="btn-icon-wrap"><i class="fa fa-twitter"></i></span></a>
            <a href="#" class="d-inline-block btn btn-icon btn-icon-only btn-indigo btn-icon-style-4"><span class="btn-icon-wrap"><i class="fa fa-google-plus"></i></span></a>
          </div>-->
        </div>
      </footer>
    </div>
    <!-- /Footer -->
  </div>
  <!-- /Main Content -->

</div>
