import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import axios, {AxiosRequestConfig} from 'axios';
import {
  ApiResponseCacheEntry,
  AuthUser,
  BroadcastData,
  Company,
  Contact,
  DomainData,
  Feature,
  FieldMeta,
  FileUploadResults,
  Mailbox,
  MailboxShort,
  MListData,
  Page,
  TableAttachment,
  TableRow,
  Tenant,
  TenantRange, TildaPage,
  TildaProject, TransportEntry,
  User,
} from '../api-classes/api-classes';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import * as signalR from "@microsoft/signalr";
import {DomSanitizer} from "@angular/platform-browser";
import {ApiNotifications} from "../api-notifications/api-notifications";

export interface IUpload {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

export interface IApiManagerModule {
  initComplete();

  saveSession();

  loadSession();

  clearSession();
}

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {
  notifications: ApiNotifications = new ApiNotifications();
  id_clientId = '21d0b8cd-80a8-442c-a696-71b4a393341c';
  select2MailTemplates: Array<Select2OptionData> = [];
  initComplete = false;
  initCompleted: BehaviorSubject<AuthUser>;
  uploadProgress: BehaviorSubject<IUpload>;
  coreBroadcaster: any = null;
  talentRefreshTimer: any = null;
  loginRequired = false;
  tokenInvalid = false;
  profileVersion = 0;
  isMultiTenant = false;
  cacheFeatures: Feature[] = null;
  cacheApps: Feature[] = null;
  cacheUsers: User[] = null;
  cacheRows : { [name: string]: TableRow[] } = {}
  cacheRanges :  TenantRange[];
  cacheSchemas : { [name: string]: FieldMeta[] } = {}
  recordOpenTargets : { [name: string]: string|null } = {}
  mailboxCache: Mailbox[] = null;
  distributionCache : { [name: string]: MListData[] } = {}
  idBuffer = '';
  htmlBuffer = '';
  tenant: Tenant = new Tenant();
  activeGoogleApiKey = '';
  signalRActive = false;
  signalRListener = false;
  noContainerBorder = false;
  localhost = 'localhost:8000';
  appToken = 'rz8Yq6b5zM2hmcpwGDsfbVEOvmVvmZ6xnMlqAJjNm0YmUphI60RamtLefaqPaR9D';
  apiHost = 'api.mxconfig.cybacor.tech'
  private renewFailCounter = 0;
  private coreModules: IApiManagerModule[] = [];

  constructor(private deviceDetector: DeviceDetectorService, private sanitizer: DomSanitizer) {
  }

  monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
  ];

  select2Tables: Array<Select2OptionData> = []

  activeModal: NgbModalRef;

  private hubConnection: signalR.HubConnection
  public startConnection = () => {
    if (!this.signalRActive) {
      this.signalRActive = true;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl('/signalr')
        .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 90000, 120000, 150000, 180000, 210000, 240000, 270000, 300000, null])
        .build();
      this.hubConnection
        .start()
        .then(() => {
          console.log('signalr connection started');
          const profile = this.getProfile();
          this.hubConnection.send("AuthenticateToken", profile.Token).then(() => {
            console.log('signalr auth complete');
          }).catch((e) => {
            console.error('signalr auth failed');
          });

        })
        .catch(err => console.log('Error while starting connection: ' + err))

      this.hubConnection.onreconnected((connectionId) => {
        console.log('signalr reconnected');
        const profile = this.getProfile();
        this.hubConnection.send("AuthenticateToken", profile.Token).then(() => {
          console.log('signalr auth complete');
        }).catch((e) => {
          console.error('signalr auth failed');
        });
      });
    }
  }

  currencyConvert(value: string, noSign = false): string {
    let euroGerman = Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    });

    if (value != undefined && value != null && value.trim() != '') {
      let raw =  value.trim();
      let re = /\./gi;
      let re2 = /,/gi;

      let result = raw.replace(re, "");
      result = result.replace(re2, ".");
      let parsed = parseFloat(result);
      value = euroGerman.format(parsed);
    } else {
      value = "0,00 €"
    }

    if (noSign) {
      value = value.replace(' €', '');
    }

    return value;
  }

  public addSignalRListener = () => {
    if (!this.signalRListener) {
      this.signalRListener = true;
      this.hubConnection.on('row-update', (data:TableRow) => {
        console.log('row-update');
        console.log(data);
        if (data != null) {
          if (this.cacheRows[data.TableName] != undefined && this.cacheRows[data.TableName] != null && this.cacheRows[data.TableName].length > 0) {
            let found = false;
            for (let i = 0; i < this.cacheRows[data.TableName].length; i++) {
              if (this.cacheRows[data.TableName][i].Id == data.Id) {
                found = true;
                this.cacheRows[data.TableName][i] = data;
              }
            }

            if (this.cacheSchemas[data.TableName] != undefined && this.cacheSchemas[data.TableName] != null && this.cacheSchemas[data.TableName].length > 0) {
              this.cacheRows[data.TableName] = this.syncRowsSchema(this.cacheRows[data.TableName], this.cacheSchemas[data.TableName]);
            }

            this.notifications.notify('signalr-row-update', data);
          }
        }
      });

      this.hubConnection.on('page-update', (data) => {
        console.log('page-update');
        console.log(data);
        this.notifications.notify('signalr-page-update', data);
      });

      this.hubConnection.on('schema-update', (data) => {
        console.log('schema-update');
        console.log(data);
        this.notifications.notify('signalr-schema-update', data);
      });

    }
  }


  closeModal(modalService: NgbModal) {
    if (this.activeModal != null) {
      try {
        this.activeModal.dismiss();
      } catch (e) {
        //
      }

      this.activeModal = null;
    }
  }

  openModal(modalService: NgbModal,  modalRef) {
    this.closeModal(modalService);
    this.activeModal = modalService.open(modalRef,
      {backdropClass: 'light-backdrop'});
    this.unhideEditor();
  }

  unhideEditor() {
    setTimeout(() => {
      const d = document.getElementsByClassName('modal-content');
      if (d) {
        for(let i = 0; i < d.length; i++) {
          const t: any = d[i];
          t.style.display = 'flex';
        }
      }
    },75);
  }

  formatDate(date, patternStr): string{
    if (!patternStr) {
      patternStr = 'M/d/yyyy';
    }
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = this.twoDigitPad(h),
      HH = this.twoDigitPad(hour),
      mm = this.twoDigitPad(minute),
      ss = this.twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = this.dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = this.twoDigitPad(day),
      M = month + 1,
      MM = this.twoDigitPad(M),
      MMMM = this.monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
    ;
    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh).replace('h', h)
      .replace('HH', HH).replace('H', hour)
      .replace('mm', mm).replace('m', minute)
      .replace('ss', ss).replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd).replace('d', day)

      .replace('EEEE', EEEE).replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
    }
    else {
      patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
    }
    return patternStr;
  }
  private twoDigitPad(num): string {
    return num < 10 ? "0" + num : num;
  }

  buildCache() {
    if (this.mailboxCache == null) {
      this.getMailboxes().then((data) => {

      });
    }

    this.getMailDomains().then((domains) => {
      if(domains != null) {
        for(let i = 0; i < domains.length; i++) {
          if (domains[i].domainName in this.distributionCache) {

          } else {
            this.getMailLists(domains[i].domainName).then((data) => {

            });
          }
        }
      }
    });
  }

  coreRegisterModule(module: IApiManagerModule) {
    this.coreModules.push(module);
    if (this.initComplete) {
      module.initComplete();
    }
  }

  coreSaveSession() {
    // sessionStorage.setItem('cache-vacancies', JSON.stringify(this.talentVacancies));

    for (let i = 0; i < this.coreModules.length; i++) {
      try {
        this.coreModules[i].saveSession();
      } catch {
        //
      }
    }
  }

  coreLoadSession() {
    /*const tmp = sessionStorage.getItem('cache-vacancies');
    if (tmp !== undefined && tmp != null && tmp !== '') {
      this.talentVacancies = JSON.parse(tmp);
    }*/

    for (let i = 0; i < this.coreModules.length; i++) {
      try {
        this.coreModules[i].loadSession();
      } catch {
        //
      }
    }
  }

  translateRow(row: TableRow, schema: FieldMeta[]) {
    row.FieldsTranslated = {}
    Object.keys(row.Fields).forEach((key) => {
      if (key == 'value') {
        row.Fields[key] = this.currencyConvert(row.Fields[key], true);
      }

      let found = false;
      let defaultVal = '';
      for (let x = 0; x < schema.length; x++) {
        if (schema[x].Id == key) {
          if (schema[x].FieldType == "dropdown") {
            if (schema[x].DropOptions != null) {
              Object.entries(schema[x].DropOptions).forEach(([key2, meta]) => {
                if (meta.IsDefault) {
                  defaultVal = meta.Description;
                }

                if (meta.Id == row.Fields[key]) {
                  row.FieldsTranslated[key] = meta.Description;
                  found = true;
                }
              });
            }
          }
          if (schema[x].FieldType == "generate_id") {
            if (this.cacheRanges != null) {
              if (schema[x].ExtraMeta != null && schema[x].ExtraMeta.trim() != '') {
                if (row.Fields[key] != undefined && row.Fields[key] != null) {
                  for(let y = 0; y < this.cacheRanges.length; y++) {
                    if (this.cacheRanges[y].RangeTag.trim() == schema[x].ExtraMeta.trim()) {
                      let tmp = row.Fields[key].padStart(this.cacheRanges[y].SeedLen, "0")
                      if (this.cacheRanges[y].Prepend != null) {
                        tmp = this.cacheRanges[y].Prepend + tmp;
                      }

                      if (this.cacheRanges[y].Append != null) {
                        tmp = tmp + this.cacheRanges[y].Append;
                      }

                      row.FieldsTranslated[key] = tmp;
                      found = true;
                    }
                  }
                }
              }
            }
          }
          if (schema[x].FieldType == "datetime-local") {
            found = true;
            if (row.Fields[key] != '' && row.Fields[key] != null) {
              row.FieldsTranslated[key] = this.formatDate2(new Date(Date.parse( row.Fields[key])), false)
            } else {
              row.FieldsTranslated[key] = '';
            }
          }
        }
      }

      if (row.Description == '') {
        let desc: string = row.Description;
        if (desc == '') {
          let keys = Object.keys(row.Fields);
          for(let x = 0; x < keys.length; x++) {
            if (desc == '' && (keys[x] == 'company' || keys[x] == 'companyname') && row.Fields[keys[x]] != null) {
              desc = row.Fields[keys[x]];
              break;
            }
          }

          if (desc == '') {
            for (let x = 0; x < keys.length; x++) {
              if (desc == '' && keys[x] == 'lastname' && row.Fields[keys[x]] != null) {
                desc = row.Fields[keys[x]];
                break;
              }
            }
          }

          row.Description = desc;
        }
      }

      if (!found) {
        if (defaultVal != '') {
          row.FieldsTranslated[key] = defaultVal;
        } else {
          row.FieldsTranslated[key] = row.Fields[key]
        }
      }
    });
  }

  // make sure that always all fields from the schema exists in the rows (could be missing for new fields that were not set for the record
  syncRowsSchema(rows: TableRow[], schema: FieldMeta[]): TableRow[] {
    let exists: { [name: string]: boolean } = {} // dictionary to keep track of existing fields

    for (let i = 0; i < rows.length; i++) {
      rows[i].HasLogo = rows[i].LogoHash != null && rows[i].LogoHash.trim() !== '';
      if (rows[i].HasLogo) {
        rows[i].LogoUrl = '/v1.0/entity-attachments/' + rows[i].Id + '?file=logo&token=' + encodeURIComponent(this.getProfile().Token) + '&rev=' + rows[i].LogoHash.substring(0,16);
      } else {
        rows[i].LogoUrl = '';
      }

      let short = false;
      for (let x = 0; x < schema.length; x++) { // reset the existing state per row
        exists[schema[x].Id] = false;
        if (schema[x].Id == "companyname" && schema[x].IsDefaultDisplay) {
          short = true;
        }
      }

      let tmpInitials = '';
      let tmpSplit = rows[i].Description.trim().replace('  ', '').split(' ')
      for (let x = 0; x < tmpSplit.length; x++) {
        if (tmpSplit[x].trim() != '') {
          tmpInitials = tmpInitials + tmpSplit[x].substring(0,1);
        }

        if (tmpInitials.length >= 3) {
          break;
        }
      }

      if (short) {
        tmpInitials = rows[i].Description.trim().substring(0,1);
      }


      rows[i].LogoInitials = tmpInitials;//rows[i].Description.substring(0,1);



      Object.entries(rows[i].Fields).forEach( // detect existing fields
        ([key, value]) => {
          exists[key] = true;
        }
      );

      Object.entries(exists).forEach( // create missing fields
        ([key, value]) => {
          if (!value) {
            rows[i].Fields[key] = '';
          }
        }
      );

      this.translateRow(rows[i], schema);
    }

    rows.sort((a, b) => (a.Description > b.Description) ? 1 : (a.Description === b.Description) ? 0 : -1)
    return rows;
  }

  coreClearSession() {
    for (let i = 0; i < this.coreModules.length; i++) {
      try {
        this.coreModules[i].clearSession();
      } catch {
        //
      }
    }
    localStorage.setItem('auth', 'false');
    localStorage.setItem('token', '');
    localStorage.setItem('userid', '');
    this.tenant = new Tenant();
    localStorage.clear();
    sessionStorage.clear();
    window['profile'] = null;
    this.loginRequired = true;
    this.profileVersion++;
    this.initCompleted.next(null);
  }

  /*getTableEntityDependencies(id: string, table: string): Promise<TableRow[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entity/' + id + '?link=' + table).then((data: TableRow[]) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }*/

  hasStructureData(): boolean {
    if (this.tenant != null) {
      return this.tenant.Modules.includes('13526f76-04be-4295-9fbb-bd61197d4312')
    }

    return false;
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }

  getStructureJson(id: string, query: string): Promise<ApiResponseCacheEntry> {
    // /v1.0/northdata/company/
    return new Promise((resolve, reject) => {
      if (!this.hasStructureData()) {
        reject('structure module not active')
      }
      if (!query.startsWith('?')) {
        query = '?' + query;
      }

      this.apiGetAsync('/v1.0/structure/company/' + id + query).then((data: ApiResponseCacheEntry) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }



  getMailLists(domain: string, forceRefresh: boolean = false): Promise<MListData[]> {
    return new Promise((resolve, reject) => {
      if (this.distributionCache[domain] != null && this.distributionCache[domain] != undefined && !forceRefresh){
        resolve(this.distributionCache[domain]);
      } else {
        this.apiGetAsync('https://'+this.apiHost+'/api/distributiongroups/' + domain + '?token=' + this.appToken, true).then((data: MListData[]) => {
          if (data == null) {
            reject('no data received');
          } else {
            this.distributionCache[domain] = data;
            resolve(data);
          }
        }).catch((e) => reject(e));
      }
    });
  }

  getBlockedSenders(): Promise<TransportEntry[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/mailboxes/get-blocked-sender?token=' + this.appToken, true).then((data: TransportEntry[]) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  blockSender(sender:string): Promise<boolean> {
    sender = encodeURIComponent(sender);
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/mailboxes/block-sender?token=' + this.appToken + '&sender=' + sender, true).then((data: boolean) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  unblockSender(sender:string): Promise<boolean> {
    sender = encodeURIComponent(sender);
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/mailboxes/unblock-sender?token=' + this.appToken + '&sender=' + sender, true).then((data: boolean) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  getMailboxes(forceRefresh: boolean = false): Promise<Mailbox[]> {
    return new Promise((resolve, reject) => {
      if (this.mailboxCache != null && !forceRefresh){
        resolve(this.mailboxCache);
      } else {
        this.apiGetAsync('https://'+this.apiHost+'/api/mailboxes?token=' + this.appToken, true).then((data: Mailbox[]) => {
          if (data == null) {
            reject('no data received');
          } else {
            this.mailboxCache = data;
            resolve(data);
          }
        }).catch((e) => reject(e));
      }
    });
  }

  getDomainMailboxes(domain: string): Promise<Mailbox[]> {
    return new Promise((resolve, reject) => {
      this.getMailboxes().then((mailboxes) => {
        if (mailboxes == null) {
          resolve([]);
        } else {
          let filter: Mailbox[] = [];
          for(let i = 0; i < mailboxes.length; i++) {
            if (mailboxes[i].username.endsWith("@" + domain)) {
              filter.push(mailboxes[i]);
            }
          }

          resolve(filter);
        }
      }).catch((e) => reject(e));
    });
  }

  setMailbox(mailbox: Mailbox): Promise<number|null> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('https://'+this.apiHost+'/api/mailboxes?token=' + this.appToken, mailbox, true).then((data: number|null) => {
        if (data == null) {
          reject('no data received');
        } else {
          this.getMailboxes(true).then((e) => {
            resolve(data);
          }).catch((e) => {
            resolve(data);
          });
        }
      }).catch((e) => {
        reject(e)
      });
    });
  }

  removeMailList(domain: string, id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiDeleteAsync('https://'+this.apiHost+'/api/distributiongroups/' + id + '?token=' + this.appToken, true).then((data: boolean) => {
        this.getMailLists(domain, true).then((d) => {
          resolve(data);
        });
      }).catch((e) => reject(e));
    });
  }

  getTildaProjects(): Promise<TildaProject[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/tilda?token=' + this.appToken, true).then((data: TildaProject[]) => {
        if (data == null || !data) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  getTildaProjectPages(projectId: number): Promise<TildaPage[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/tilda?token=' + this.appToken + '&pageList=' + projectId, true).then((data: TildaPage[]) => {
        if (data == null || !data) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  exportTildaProjectPage(exportId: number, pageId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/tilda?token=' + this.appToken + '&export=' + exportId + '&exportPage=' + pageId, true).then((data: boolean) => {
        if (data == null || !data) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  exportTildaProject(exportId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/tilda?token=' + this.appToken + '&export=' + exportId, true).then((data: boolean) => {
        if (data == null || !data) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  setMailList(list: MListData): Promise<number|null> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('https://'+this.apiHost+'/api/distributiongroups?token=' + this.appToken, list, true).then((data: number|null) => {
        if (data == null) {
          reject('no data received');
        } else {
          let domain = list.listname.split('@')[1];
          this.getMailLists(domain, true).then((d) => {
            resolve(data);
          })
        }
      }).catch((e) => reject(e));
    });
  }

  getMailDomains(): Promise<DomainData[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('https://'+this.apiHost+'/api/domains?token=' + this.appToken, true).then((data: DomainData[]) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  getStructureSearchJson(id: string, query: string): Promise<ApiResponseCacheEntry> {
    // /v1.0/northdata/company/
    return new Promise((resolve, reject) => {
      if (!this.hasStructureData()) {
        reject('structure module not active')
      }
      if (!query.startsWith('?')) {
        query = '?' + query;
      }

      this.apiGetAsync('/v1.0/structure/search/' + id + query).then((data: ApiResponseCacheEntry) => {
        if (data == null) {
          reject('no data received');
        } else {
          resolve(data);
        }
      }).catch((e) => reject(e));
    });
  }

  getTableEntity(id: string): Promise<TableRow> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entity/' + id).then((data: TableRow) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getTableEntityAttachments(id: string): Promise<TableAttachment[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entity-attachments/' + id).then((data: TableAttachment[]) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  removeTableEntity(id: string): Promise<TableRow> {
    return new Promise((resolve, reject) => {
      this.apiDeleteAsync('/v1.0/entity/' + id).then((data: TableRow) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  uploadLogo(file: File, logoType: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.uploadAsync('/v1.0/auth/logo/' + logoType, file).then((success: boolean) => {
        resolve(success);
      }).catch((e) => resolve(false));
    });
  }

  uploadEntityFile(rowId: string, file: File, isLogo = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let logo = ''
      if (isLogo) {
        logo = '?type=logo'
      }
      this.uploadAsync('/v1.0/entity-attachments/' + rowId + logo, file).then((success: boolean) => {
        resolve(success);
      }).catch((e) => resolve(false));
    });
  }

  setTableEntity(row: TableRow): Promise<TableRow> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/entity/' + row.Id, row).then((data: TableRow) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getPage(pageName: string): Promise<Page> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/pages/' + pageName).then((data: Page) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  setPage(page: Page): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/pages', page).then((data: boolean) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  formatDate2(date: Date, fromActivity: boolean, dateOnly = false, intlDate = false): string {
    var hh = String(date.getDate()).padStart(2, '0');
    var min = String(date.getMinutes() + 1).padStart(2, '0');
    var ss = String(date.getSeconds()).padStart(2, '0');
    var dd = String(date.getDate());
    var mm = String(date.getMonth() + 1); //January is 0!
    var yyyy = date.getFullYear();

    if (dateOnly) {
      if (intlDate) {
        return yyyy + '-' + mm + '-' + dd
      }
      return dd + '.' + mm + '.' + yyyy;
    }

    let final =  dd + '.' + mm + '.' + yyyy + ' ' + date.toLocaleTimeString('de-DE');
    if (fromActivity) {
      switch (date.getDay()) {
        case 0:
          final = "Sonntag, " + final.trim();
          break;
        case 1:
          final = "Montag, " + final.trim();
          break;
        case 2:
          final = "Dienstag, " + final.trim();
          break;
        case 3:
          final = "Mittwoch, " + final.trim();
          break;
        case 4:
          final = "Donnerstag, " + final.trim();
          break;
        case 5:
          final = "Freitag, " + final.trim();
          break;
        case 6:
          final = "Samstag, " + final.trim();
          break;
      }
    }

    if (!final.trim().endsWith('AM') && !final.trim().endsWith('PM')) {
      if (fromActivity) {
        final = final.trim().substring(0, final.trim().length - 3) + ' Uhr';
      } else {
        final = final.trim().substring(0, final.trim().length - 3); //+ ' Uhr';
      }
    }

    return final; //+ hh + ':' + min + ':' + ss;
  }



  getTableEntitiesOfLinkTable(tableName: string, linkTable: string): Promise<TableRow[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entities/' + tableName + '?link-table=' + linkTable).then((data: TableRow[]) => {
        resolve(data);
      }).catch((e) => {
        reject(e)
      });
    });
  }

  getTenantRanges(preloadCall = false): Promise<TenantRange[]> {
    return new Promise((resolve, reject) => {
      if (this.cacheRanges != undefined && this.cacheRanges != null && preloadCall) {
        resolve(this.cacheRanges);
      }

      this.apiGetAsync('/v1.0/ranges').then((data: TenantRange[]) => {
        if (data != null) {
          if (this.cacheRanges == undefined || this.cacheRanges == null || !preloadCall) {
            this.cacheRanges = data;
          }
        }
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getTableEntities(tableName: string, preloadCall = false): Promise<TableRow[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entities/' + tableName).then((data: TableRow[]) => {
        if (data != null) {
          if (this.cacheRows[tableName] == undefined || this.cacheRows[tableName] == null || !preloadCall) {
            this.cacheRows[tableName] = data;
          }
        }
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getTableRowDependencies(rowId: string, tableName: string): Promise<TableRow[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entity/' + rowId + '?link=' + tableName).then((data: TableRow[]) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  addUser(username: string, displayname: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      username = encodeURIComponent(username);
      displayname = encodeURIComponent(displayname);
      this.apiGetAsync('/v1.0/users/add?username=' + username + '&displayname=' + displayname).then((data: boolean) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  setUserAdmin(userId: string, enable: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
    this.apiGetAsync('/v1.0/users/set-admin?userId=' + userId + '&enable=' + enable).then((data: boolean) => {
      resolve(data);
    }).catch((e) => reject(e));
    });
  }

  setUserEnable(userId: string, enable: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/users/set-enable?userId=' + userId + '&enable=' + enable).then((data: boolean) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      const profile = this.getProfile();
      if (!profile.IsAdmin) {
        resolve([])
        return
      }
      this.apiGetAsync('/v1.0/users/list').then((data: User[]) => {
        if (data != null) {
          Object.entries(data).forEach(([key, user]) => {
            if (user.LastLogin == null || user.LastLogin == '') {
              user.LastLoginParsed = '(kein/e)';
            } else {
              user.LastLoginParsed = this.formatDate2(new Date(Date.parse(user.LastLogin)), false);
            }
          });
          this.cacheUsers = data;

        resolve(data);
      }}).catch((e) => reject(e));
    });
  }

  getFeatureShop(): Promise<Feature[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/features/list').then((data: Feature[]) => {
        if (data != null) {
          this.cacheFeatures = data;
        }

        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getAppShop(): Promise<Feature[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/features/list/apps').then((data: Feature[]) => {
        if (data != null) {
          this.cacheApps = data;
        }

        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  orderFeature(featureId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/features/order/' + featureId).then((data: boolean) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  cancelFeature(featureId: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/features/cancel/' + featureId).then((data: number) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  preloadTables(tableNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      if (tableNames != null && tableNames.length > 0) {
        const activeTable = tableNames[0];
        const nextTables = tableNames.slice(1);
        if (this.cacheSchemas[activeTable] != undefined && this.cacheSchemas[activeTable] != null) {
          this.getTableSchema(activeTable, true).then((data) => {
            this.getTableEntities(activeTable, true).then((data) => {
              this.preloadTables(nextTables).then(() => {
                resolve();
              }).catch((e) => {
                resolve();
              });
            }).catch((e) => {
              this.preloadTables(nextTables).then(() => {
                resolve();
              }).catch((e) => {
                resolve();
              });
            });
          }).catch((e) => {
            this.preloadTables(nextTables).then(() => {
              resolve();
            }).catch((e) => {
              resolve();
            });
          });
        }else {
          this.preloadTables(nextTables).then(() => {
            resolve();
          }).catch((e) => {
            resolve();
          });
        }
      } else {
        this.getTenantRanges(true).then((data) => {

        });
        this.getAppShop().then((data) => {
          this.getFeatureShop().then((data) => {
            resolve();
          }).catch((e) => {
            resolve();
          });
        }).catch((e) => {
          this.getFeatureShop().then((data) => {
            resolve();
          }).catch((e) => {
            resolve();
          });
        });
      }
    });
  }

  getTableSchema(tableName: string, preloadCall = false): Promise<FieldMeta[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/entity-schema/' + tableName).then((data: FieldMeta[]) => {
        if (this.cacheSchemas[tableName] == undefined || this.cacheSchemas[tableName] == null || !preloadCall) {
          this.cacheSchemas[tableName] = data;
        }

        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  setTableSchema(tableName: string, fields: FieldMeta[]): Promise<FieldMeta[]> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/entity-schema/' + tableName, fields).then((data: FieldMeta[]) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  setUserSettings(settings: string|null): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/auth/settings', settings).then(() => {
        resolve();
      }).catch((e) => reject(e));
    });
  }

  setTenantGradient(settings: Tenant): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/auth/tenant-gradient', settings).then((data) => {
        this.loadTenant();
        resolve(data);
      }).catch((e) => resolve(false));
    });
  }

  setTenantName(settings: Tenant): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/auth/tenant-name', settings).then((data) => {
        this.loadTenant();
        resolve(data);
      }).catch((e) => resolve(false));
    });
  }

  setTenantDomain(settings: Tenant): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/auth/tenant-domain', settings).then((data) => {
        this.loadTenant();
        resolve(data);
      }).catch((e) => resolve(false));
    });
  }

  verifySubdomain(subdomain:string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/auth/verify-subdomain/' + subdomain).then((data) => {
        resolve(data);
      }).catch((e) =>reject(e));
    });
  }

  setGoogleSettings(settings: Tenant): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/auth/google', settings).then((data) => {
        this.loadTenant();
        resolve(data);
      }).catch((e) => resolve(false));
    });
  }

  getTenant(): Promise<Tenant> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/auth/tenant').then((data: Tenant) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getTenantContacts() : Promise<Contact[]> {
    return new Promise((resolve, reject) => {
      let ret: Contact[] = [];
      this.getTableEntities('contacts').then((e) => {
        for(let i = 0; i < e.length; i++) {
          ret.push(this.mapContact(e[i]));
        }

        resolve(ret);
      }).catch((e) => {
        reject(e);
      })
      /*this.apiGetAsync('/v1.0/contacts/list').then((data: Contact[]) => {
        resolve(data);
      }).catch((e) => reject(e));*/
    });
  }

  getContact(contactId: string) : Promise<Contact> {
    return new Promise((resolve, reject) => {
      this.getTableEntity(contactId).then((e) => {
        resolve(this.mapContact(e));
      }).catch((e) => reject(e));
      /*this.apiGetAsync('/v1.0/contacts/' + contactId).then((data: Contact) => {
        resolve(data);
      }).catch((e) => reject(e));*/
    });
  }

  mapContact(row: TableRow): Contact {
    const contact = new Contact();
    contact.Id = row.Id;
    contact.TenantId = row.TenantId;
    contact.CompanyId = row.LinkedId1;
    contact.CompanyName = row.Fields['companyname'];
    contact.Displayname = row.Fields['displayname'];
    contact.Firstname = row.Fields['firstname']
    contact.Lastname = row.Fields['lastname'];
    contact.Email = row.Fields['email'];
    contact.Phone = row.Fields['phone'];
    contact.Fax = row.Fields['fax'];
    contact.Mobile = row.Fields['mobile'];
    contact.Street = row.Fields['street'];
    contact.Street2 = row.Fields['street2'];
    contact.City = row.Fields['city'];
    contact.Region = row.Fields['region'];
    contact.PostalCode = row.Fields['postalcode'];
    contact.Country = row.Fields['country'];
    return contact;
  }

  setContact(contact: Contact): Promise<Contact> {
    return new Promise((resolve, reject) => {
      this.getTableEntity(contact.Id).then((row) => {
        row.Description = contact.Displayname;
        row.LinkedId1 = contact.CompanyId;
        row.Fields['displayname'] = row.Description;
        row.Fields['companyname'] = contact.CompanyName;
        row.Fields['firstname'] = contact.Firstname;
        row.Fields['lastname'] = contact.Lastname;
        row.Fields['email'] = contact.Email;
        row.Fields['phone'] = contact.Phone;
        row.Fields['fax'] = contact.Fax;
        row.Fields['mobile'] = contact.Mobile;
        row.Fields['street'] = contact.Street;
        row.Fields['street2'] = contact.Street2;
        row.Fields['city'] = contact.City;
        row.Fields['region'] = contact.Region;
        row.Fields['postalcode'] = contact.PostalCode;
        row.Fields['country'] = contact.Country;
        this.setTableEntity(row).then((res) => {
          contact.Id = res.Id
          resolve(contact);
        }).catch((ex) => reject(ex));
      }).catch((e) => {
        const row = new TableRow();
        row.Id = contact.Id;
        row.TableName = 'contacts';
        row.LinkedId1 = contact.CompanyId;
        row.TenantId = this.getProfile().TenantId;
        row.Description = contact.Displayname;
        row.LinkedId1 = contact.CompanyId;
        row.Fields['displayname'] = row.Description;
        row.Fields['companyname'] = contact.CompanyName;
        row.Fields['firstname'] = contact.Firstname;
        row.Fields['lastname'] = contact.Lastname;
        row.Fields['email'] = contact.Email;
        row.Fields['phone'] = contact.Phone;
        row.Fields['fax'] = contact.Fax;
        row.Fields['mobile'] = contact.Mobile;
        row.Fields['street'] = contact.Street;
        row.Fields['street2'] = contact.Street2;
        row.Fields['city'] = contact.City;
        row.Fields['region'] = contact.Region;
        row.Fields['postalcode'] = contact.PostalCode;
        row.Fields['country'] = contact.Country;
        this.setTableEntity(row).then((res) => {
          contact.Id = res.Id
          resolve(contact);
        }).catch((ex) => reject(ex));
      })
      /*this.apiPostJsonAsync('/v1.0/contacts/', contact).then((data: Contact) => {
        resolve(data);
      }).catch((e) => reject(e));*/
    });
  }

  getTenantCompanies() : Promise<Company[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/companies/list').then((data: Company[]) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getCompany(companyId: string) : Promise<Company> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/companies/' + companyId).then((data: Company) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  getCompanyContacts(companyId: string) : Promise<Contact[]> {
    return new Promise((resolve, reject) => {
      this.apiGetAsync('/v1.0/companies/' + companyId+ '?get-contacts=true').then((data: Contact[])  => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  setCompany(company: Company): Promise<Company> {
    return new Promise((resolve, reject) => {
      this.apiPostJsonAsync('/v1.0/companies/', company).then((data: Company) => {
        resolve(data);
      }).catch((e) => reject(e));
    });
  }

  init(): Promise<void> {
    this.prepareEvents();
    this.initCompleted = new BehaviorSubject<AuthUser>(null);
    return new Promise((resolve, reject) => {
      const data = window['profile'];
      if (data === undefined || data === null || !data.IsSuccess) {
        if (localStorage.getItem('token') !== '') {
          const profile: AuthUser = JSON.parse(localStorage.getItem('token'));
          if (profile != null && profile.IsSuccess) {
            window['profile'] = profile;
          } else {
            this.loginRequired = true;
          }
        } else {
          this.loginRequired = true;
        }
      }

      this.initComplete = true;
      this.coreLoadSession();
      for (let i = 0; i < this.coreModules.length; i++) {
        try {
          this.coreModules[i].initComplete();
        } catch {
          //
        }
      }

      if (this.isBroadcastValid()) {
        this.coreBroadcaster = new BroadcastChannel('aos-data-ordiance');
        this.coreBroadcaster.onmessage = (ev) => {
          if (ev.data != null) {
            this.processBroadCast(ev.data);
          }
        };
      }

      if (!this.loginRequired) {
        this.apiUpdateTokenAsync(true).then(() => {
          resolve(undefined);
        });
      } else {
        resolve(undefined);
      }

    });
  }

  isBroadcastValid(): boolean {
    if (this.deviceDetector.isDesktop()) {
      const validBrowsers = ['Chrome', 'Firefox', 'MS-Edge', 'MS-Edge-Chromium'];
      return validBrowsers.includes(this.deviceDetector.browser);
    }
    return false;
  }

  prepareEvents() {
    this.uploadProgress = new BehaviorSubject<IUpload>(null);
  }

  initLoaderEvents() {
    const data = window['profile'];
    this.initCompleted = new BehaviorSubject<AuthUser>(data);
  }

  authRedirect() {
    let redirUrl: string;
    if (document.location.hostname === 'localhost') {
      redirUrl = 'http://' + this.localhost;
    } else {
      redirUrl = 'https://' + document.location.hostname;
    }

    redirUrl += '/identiqa/login';
    redirUrl = encodeURIComponent(redirUrl);
    document.location.href =
      'https://auth.identiqa.com/authorize?client_id=' + this.id_clientId + '&response_type=code&scope=auth&redirect_uri=' +
      redirUrl +
      '&state=%2Fdashboard';
  }

  loadToken() {
    const query = this.parseQuery();
    const code = query['code'];
    let userId = query['userid'];
    if (userId === undefined) {
      userId = query['userId'];
      if (userId === undefined) {
        userId = null;
      }
    }
    if (code !== '' && userId !== '' && code !== null && userId !== null) {
      const referer = encodeURIComponent(location.href);
      fetch('/v1.0/auth/get?userId=' + userId + '&code=' + code + '&url=' + referer).then(
        response => response.json()
      ).then(
        (data) => {
          if (data != null && data.IsSuccess) {
            localStorage.setItem('token', JSON.stringify(data));
            localStorage.setItem('userid', userId);
            window['profile'] = data;
            this.loadTenant();
            this.verifyProfileRefer(data);
            this.loginRequired = false;
            this.profileVersion++;
            this.initCompleted.next(data);
          } else {
            this.isMultiTenant = false;
            this.tokenInvalid = true;
            this.initCompleted.next(null);
          }
        },
        (e) => {
          this.isMultiTenant = false;
          this.tokenInvalid = true;
          this.profileVersion++;
          this.initCompleted.next(null);
          console.log('auth error ' + e);
        });
    }
  }

  loadTenant() {
    this.getTenant().then((tenant) => {
      if (tenant != null) {
        if (tenant.Notifications == null) {
          tenant.Notifications = [];
        }

        this.tenant = tenant;
        if (tenant.GoogleApiKey != undefined && tenant.GoogleApiKey !== this.activeGoogleApiKey) {
          if (this.activeGoogleApiKey != '') {
            const oldScript = document.getElementById('script-google');
            if (oldScript != null) {
              oldScript.remove();
            }
          }

          if (tenant.GoogleApiKey != null && tenant.GoogleApiKey !== '') {
            const newScript = document.createElement('script');
            newScript.id = "script-google";
            newScript.type = 'text/javascript';
            newScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + tenant.GoogleApiKey + '&libraries=places,maps&language=de';
            const head = document.getElementsByTagName('head')[0];
            head.appendChild(newScript);
          }

          this.activeGoogleApiKey = tenant.GoogleApiKey
        }

        this.notifications.notify('tenant-update', tenant);
      }
    })
  }

  logout() {
    const profile = this.getProfile()
    let redir = 'https://mxconfig.cybacor.tech';
    if ( document.location.hostname != "localhost") {
      redir = 'https://' +  document.location.hostname;
    }

    if (profile != null && profile.TenantUrl != null && profile.TenantUrl.trim() !== '') {
      redir = profile.TenantUrl;
    }
    this.coreClearSession();
    location.href = 'https://auth.identiqa.com/logout?redirect_uri=' + encodeURIComponent(redir);
  }

  getProfile(initCall = false): AuthUser | null {
    let data = window['profile'];
    if (data === undefined || data === null) {
      if (initCall) {
        if (localStorage.getItem('token') !== '') {
          const profile: AuthUser = JSON.parse(localStorage.getItem('token'));
          return profile;
        }

        return null;
      }
      data = new AuthUser;
      data.IsVisitor = true;
    }
    return data;
  }

  getCurrentToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const profile: AuthUser | null = window['profile'];
      if (profile == null) {
        reject('Profile not loaded');
      } else {
        let token: string;
        const now = new Date();
        const validUntil = new Date(profile.TokenValidUntil);
        token = profile.Token;
        const valid = now < validUntil;

        if (valid) {
          resolve(token);
        } else {
          this.apiUpdateTokenAsync().then(() => {
            resolve(profile.Token);
          }).catch(e => reject(e));
        }
      }
    });
  }

  apiPostFormAsync(apiFunction: string, data: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      const profile: AuthUser | null = window['profile'];
      if (profile == null) {
        reject('Profile not loaded');
      } else {
        let token: string;
        const now = new Date();
        const validUntil = new Date(profile.TokenValidUntil);
        token = profile.Token;
        const valid = now < validUntil;

        if (valid) {
          fetch(apiFunction,
            {
              method: 'POST',
              headers: {'X-AosToken': token},
              body: data
            }
          ).then(
            response => response.json()
          ).then((data1) => {
            resolve(data1);
          }).catch(e => reject(e));
        } else {
          this.apiUpdateTokenAsync().then(() => this.apiGetAsync(apiFunction).then((data1) => resolve(data1)).catch(
            e => {
              reject(e);
            })).catch(e => reject(e));
        }
      }
    });
  }

  apiPostJsonAsync(apiFunction: string, data: any, noAuthHeader = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const profile: AuthUser | null = window['profile'];
      if (profile == null) {
        reject('Profile not loaded');
      } else {
        let token: string;
        const now = new Date();
        const validUntil = new Date(profile.TokenValidUntil);
        token = profile.Token;
        const valid = now < validUntil;

        if (valid) {
          let headers = {};
          if (noAuthHeader) {
            headers = {'Content-Type': 'application/json'};
          } else {
            headers = {'Content-Type': 'application/json', 'X-AosToken': token};
          }

          fetch(apiFunction,
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data)
            }
          ).then(
            response => response.json()
          ).then((data1) => {
            resolve(data1);
          }).catch(e => reject(e));
        } else {
          this.apiUpdateTokenAsync().then(() => this.apiGetAsync(apiFunction).then((data1) => resolve(data1)).catch(
            e => {
              reject(e);
            })).catch(e => reject(e));
        }
      }
    });
  }

  apiGetAsync(apiFunction: string, noAuthHeader = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const profile: AuthUser | null = window['profile'];
      if (profile == null) {
        reject('Profile not loaded');
      } else {
        let token: string;
        const now = new Date();
        const validUntil = new Date(profile.TokenValidUntil);
        token = profile.Token;
        const valid = now < validUntil;

        if (valid) {
          let header = {};
          if (!noAuthHeader) {
            header = {'X-AosToken': token};
          }
          fetch(apiFunction, {headers: header}).then(
            response => response.json()
          ).then(data => {
            resolve(data);
          }).catch(e => reject(e));
        } else {
          this.apiUpdateTokenAsync().then(() => this.apiGetAsync(apiFunction).then(data => resolve(data)).catch(e => {
            reject(e);
          })).catch(e => reject(e));
        }
      }
    });
  }

  apiDeleteAsync(apiFunction: string, noAuthHeader = false): Promise<any> {
    return new Promise((resolve, reject) => {
      const profile: AuthUser | null = window['profile'];
      if (profile == null) {
        reject('Profile not loaded');
      } else {
        let token: string;
        const now = new Date();
        const validUntil = new Date(profile.TokenValidUntil);
        token = profile.Token;
        const valid = now < validUntil;

        if (valid||noAuthHeader) {
          let header = {};
          if (!noAuthHeader) {
            header = {'X-AosToken': token};
          }
          fetch(apiFunction, {
            method: 'DELETE',
            headers: header
          }).then(
            response => response.json()
          ).then(data => {
            resolve(data);
          }).catch(e => reject(e));
        } else {
          this.apiUpdateTokenAsync().then(() => this.apiDeleteAsync(apiFunction).then(data => resolve(data)).catch(e => {
            reject(e);
          })).catch(e => reject(e));
        }
      }
    });
  }

  uploadAsync(apiFunction: string, file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const profile = this.getProfile();
      const data = new FormData();
      data.append('file', file);
      const config: AxiosRequestConfig = {
        headers: {'X-AosToken': profile.Token},
        onUploadProgress: progressEvent => {
          const newProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100);
          const progressData: IUpload = {
            progress: newProgress,
            state: 'IN_PROGRESS'
          };
          try {
            this.uploadProgress.next(progressData);
          } catch {
            //
          }
        }
      };


      if (profile.IsSuccess) {
        const progress: IUpload = {
          progress: 0,
          state: 'PENDING'
        };
        try {
          this.uploadProgress.next(progress);
        } catch {
          //
        }

        axios.post(
          apiFunction,
          data,
          config
        ).then(result => {
          if (result) {
            const progressData: IUpload = {
              progress: 100,
              state: 'DONE'
            };
            this.uploadProgress.next(progressData);
            resolve(true);
          } else {
            reject('Upload failed');
          }
        }).catch((e) => reject(e));
      } else {
        reject('Profile not ready');
      }
    });
  }

  verifyProfileRefer(profile: AuthUser) {
    if (profile != null && profile.IsSuccess) {
      if (profile.TenantUrl != null && profile.TenantUrl.trim() !== '') {
        if (!location.href.startsWith(profile.TenantUrl)) {
          if (location.href.startsWith("https://ordiance.astralus.app")) {
            location.href = profile.TenantUrl
          } else {
            this.logout()
          };
        }
      } else {
        this.logout();
      }
    }
  }

  setTheme(theme: string) {
    this.apiGetAsync('/v1.0/auth/settheme/' + theme).then((result: boolean) => {
      if (result) {
        const profile = this.getProfile();
        profile.Theme = theme;
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        if (this.deviceDetector.isDesktop()) {
          const bData = new BroadcastData();
          bData.EventType = 'aos-theme';
          bData.EventData = theme;
          this.coreBroadcaster.postMessage(bData);
        }

        this.verifyProfileRefer(profile);
      }
    });
  }

  setMenuDetached(detached: boolean) {
    const state = detached ? 'true' : 'false';
    let profile = this.getProfile();
    profile.MenuDetached = detached;
    this.initCompleted.next(profile);
    this.apiGetAsync('/v1.0/auth/setmenudetached/' + state).then((result: boolean) => {
      if (result) {
        profile = this.getProfile();
        profile.MenuDetached = detached;
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        if (this.deviceDetector.isDesktop()) {
          const bData = new BroadcastData();
          bData.EventType = 'aos-menu';
          bData.EventData = detached ? 'detached' : 'topnav';
          this.coreBroadcaster.postMessage(bData);
        }

        this.verifyProfileRefer(profile);
      }
    });
  }

  setMenuCompact(compact: boolean) {
    const state = compact ? 'true' : 'false';
    let profile = this.getProfile();
    profile.MenuCompact = compact;
    this.initCompleted.next(profile);
    this.apiGetAsync('/v1.0/auth/setmenucompact/' + state).then((result: boolean) => {
      if (result) {
        profile = this.getProfile();
        profile.MenuCompact = compact;
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        if (this.deviceDetector.isDesktop()) {
          const bData = new BroadcastData();
          bData.EventType = 'aos-menu-compact';
          bData.EventData = compact ? 'true' : 'false';
          this.coreBroadcaster.postMessage(bData);
        }

        this.verifyProfileRefer(profile);
      }
    });
  }

  private processBroadCast(data: BroadcastData) {
    const profile = this.getProfile();
    switch (data.EventType) {

      case 'aos-theme':
        profile.Theme = data.EventData;
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        break;
      case 'aos-menu':
        profile.MenuDetached = data.EventData === 'detached';
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        break;
      case 'aos-menu-compact':
        profile.MenuCompact = data.EventData === 'true';
        window['profile'] = profile;
        this.initCompleted.next(profile);
        localStorage.setItem('token', JSON.stringify(profile));
        break;
    }

    this.verifyProfileRefer(profile);
  }


  private parseQuery() {
    const queryString = window.location.search;
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  private apiUpdateTokenAsync(fromInit: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      let profile: AuthUser | null = window['profile'];
      const referer = encodeURIComponent(location.href);
      fetch('/v1.0/auth/renew?url=' + referer, {headers: {'X-AosToken': profile.RenewToken}}).then(response => response.json())
        .then(data => {
          if (data.IsSuccess) {
            localStorage.setItem('token', JSON.stringify(data));
            profile = data;
            window['profile'] = data;

            this.loadTenant();
            this.verifyProfileRefer(profile);
            resolve(undefined);
          } else {
            this.renewFailCounter++;
            if (this.renewFailCounter > 5 || fromInit) {
              this.coreClearSession();
              this.authRedirect();
            }
            reject('Token renew failed (token denied)');
          }
        }).catch(
        e => {
          this.renewFailCounter++;
          if (this.renewFailCounter > 5 || fromInit) {
            this.coreClearSession();
            this.authRedirect();
          }

          reject('Token renew failed (' + e + ')');
        });
    });
  }
}
