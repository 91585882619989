<div class="hk-pg-header" *ngIf="(item.Title != null && item.Title != '') || mode==-1"><h4 class="hk-pg-title" *ngIf="mode!=-1 && item.Title.trim()==''">&nbsp;</h4><h4 class="hk-pg-title" *ngIf="mode==-1||item.Title.trim()!=''">{{mode!=-1?item.Title:''}}<input style="max-width: 200px;" *ngIf="mode==-1" type="text" [value]="item.Title" (input)="item.Title=$any($event.target).value" placeholder="Titel" /></h4></div>

<div *ngIf="mode==-1" style="display: flex; margin: 4px;"><i (click)="addItem('', false)"  style="margin-left: auto; cursor: pointer; margin-right: auto; font-size: 20px;" class="fa-regular fa-plus-circle" title="Element hier hinzufügen"></i></div>
<div class="row">
  <div id="item_{{child.Id}}" *ngFor="let child of item.ChildrenHeader" [draggable]="mode==-1" style="{{mode==-1?_styleEditMode:''}}" class="{{child.CssClasses}}" style="{{child.Style}}">
    <div *ngIf="mode==-1" style="display: flex; margin: 4px;">
      <input style="max-width: 100px;" placeholder="CSS Klassen" type="text" [value]="child.CssClasses" (input)="child.CssClasses=$any($event.target).value"/>&nbsp;
      <input style="max-width: 100px;" placeholder="Style" type="text" [value]="child.Style" (input)="child.Style=$any($event.target).value"/>
      <i style="margin-left: auto; cursor: pointer;" class="fa-solid fa-trash" title="Element entfernen" (click)="removeItem(child)"></i>
    </div>
    <app-as-renderer-card (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='card'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-card>
    <app-as-renderer-div (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='div'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-div>
    <app-as-renderer-ro-text [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type.startsWith('ro_field')" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-ro-text>
    <app-as-renderer-input [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type.startsWith('input-') || child.Type=='input'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input>
    <app-as-renderer-input-label [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='input_label'  || child.Type=='ro_input_label'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input-label>
    <app-as-renderer-activity-tab (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='activity_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-activity-tab>
    <app-as-renderer-contact-link-list (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='link-contacts'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-link-list>
    <app-as-renderer-button (buttonClicked)="buttonClick($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='button'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-button>
    <app-as-renderer-gauge *ngIf="child.Type=='gauge'"></app-as-renderer-gauge>
    <app-as-renderer-stars *ngIf="child.Type=='stars'"></app-as-renderer-stars>
    <app-as-renderer-communication (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='communication_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-communication>
    <app-as-renderer-contact-details (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='contact_details'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-details>
  </div>
  <div id="item_{{child.Id}}" *ngFor="let child of item.Children" [draggable]="mode==-1" style="{{mode==-1?_styleEditMode:''}}" class="{{child.CssClasses}}" style="{{child.Style}}">
    <div *ngIf="mode==-1" style="display: flex; margin: 4px;">
      <input style="max-width: 100px;" placeholder="CSS Klassen" type="text" [value]="child.CssClasses" (input)="child.CssClasses=$any($event.target).value"/>&nbsp;
      <input style="max-width: 100px;" placeholder="Style" type="text" [value]="child.Style" (input)="child.Style=$any($event.target).value"/>
      <i style="margin-left: auto; cursor: pointer;" class="fa-solid fa-trash" title="Element entfernen" (click)="removeItem(child)"></i>
    </div>
    <app-as-renderer-card (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='card'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-card>
    <app-as-renderer-div (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='div'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-div>
    <app-as-renderer-ro-text [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type.startsWith('ro_field')" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-ro-text>
    <app-as-renderer-input [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type.startsWith('input-') || child.Type=='input'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input>
    <app-as-renderer-input-label [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='input_label'  || child.Type=='ro_input_label'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input-label>
    <app-as-renderer-activity-tab (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='activity_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-activity-tab>
    <app-as-renderer-contact-link-list (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='link-contacts'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-link-list>
    <app-as-renderer-button (buttonClicked)="buttonClick($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='button'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-button>
    <app-as-renderer-gauge *ngIf="child.Type=='gauge'"></app-as-renderer-gauge>
    <app-as-renderer-stars *ngIf="child.Type=='stars'"></app-as-renderer-stars>
    <app-as-renderer-communication (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='communication_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-communication>
    <app-as-renderer-contact-details (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='contact_details'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-details>
  </div>
  <div id="item_{{child.Id}}" *ngFor="let child of item.ChildrenFooter" [draggable]="mode==-1" style="{{mode==-1?_styleEditMode:''}}" class="{{child.CssClasses}}" style="{{child.Style}}">
    <div *ngIf="mode==-1" style="display: flex; margin: 4px;">
      <input style="max-width: 100px;" placeholder="CSS Klassen" type="text" [value]="child.CssClasses" (input)="child.CssClasses=$any($event.target).value"/>&nbsp;
      <input style="max-width: 100px;" placeholder="Style" type="text" [value]="child.Style" (input)="child.Style=$any($event.target).value"/>
      <i style="margin-left: auto; cursor: pointer;" class="fa-solid fa-trash" title="Element entfernen" (click)="removeItem(child)"></i>
    </div>
    <app-as-renderer-card (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='card'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-card>
    <app-as-renderer-div (buttonClicked)="buttonClick($event)" (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type=='div'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-div>
    <app-as-renderer-ro-text [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" [parentId]="item.Id" *ngIf="child.Type.startsWith('ro_field')" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-ro-text>
    <app-as-renderer-input [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type.startsWith('input-') || child.Type=='input'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input>
    <app-as-renderer-input-label [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='input_label'  || child.Type=='ro_input_label'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-input-label>
    <app-as-renderer-activity-tab (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='activity_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-activity-tab>
    <app-as-renderer-contact-link-list (uploadFile)="handleFileInput($event)" (pageAddRequest)="onPageAddRequest($event)" (removeRequest)="onRemoveRequest($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='link-contacts'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-link-list>
    <app-as-renderer-button (buttonClicked)="buttonClick($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='button'" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-button>
    <app-as-renderer-gauge *ngIf="child.Type=='gauge'"></app-as-renderer-gauge>
    <app-as-renderer-stars *ngIf="child.Type=='stars'"></app-as-renderer-stars>
    <app-as-renderer-communication (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='communication_tab'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-communication>
    <app-as-renderer-contact-details (uploadFile)="handleFileInput($event)" [dropOptions]="dropOptions" [lazyLoadCount]="lazyLoadCount" [sessionId]="sessionId" *ngIf="child.Type=='contact_details'" class="{{child.CssClasses}}" style="{{child.Style}}" [page]="page" [mode]="mode" [item]="child" [row]="row" [schema]="schema" [schemaResolver]="schemaResolver"></app-as-renderer-contact-details>
  </div>
</div>
<div *ngIf="mode==-1 && item != null && (item.ChildrenHeader.length > 0 || item.Children.length > 0 || item.ChildrenFooter.length > 0)" style="display: flex; margin: 4px;"><i (click)="addItem('', true)" style="cursor: pointer; margin-left: auto; margin-right: auto; font-size: 20px;" class="fa-regular fa-plus-circle" title="Element hier hinzufügen"></i></div>

