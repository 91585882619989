import { Component, OnInit } from '@angular/core';
import {ApiManagerService} from "../api-manager/api-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AsToastService} from "../as-toast.service";
import {DomainData, Mailbox, MListData} from "../api-classes/api-classes";
import {Select2OptionData} from "../as-ng-select2/lib/ng-select2.interface";

@Component({
  selector: 'app-mailboxes',
  templateUrl: './mailboxes.component.html',
  styleUrls: ['./mailboxes.component.css']
})
export class MailboxesComponent implements OnInit {

  constructor(private apiManagerService: ApiManagerService, private modalService: NgbModal, private router: Router, private toast: AsToastService, private route: ActivatedRoute) { }

  domains: DomainData[] = [];
  mailboxes: Mailbox[] = [];
  mailboxesFiltered: Mailbox[] = [];
  mailboxesPage: Mailbox[] = [];
  pageId = 0;
  visiblePageId = 1;
  pageCount = 1;
  pageSize = 15;
  filter = '';

  activeDomain = '';
  dropDomains: Array<Select2OptionData> = [];



  filterUpdate(filter) {
    this.filter = filter;
    this.calcPaging();
  }

  doFilter() {
    this.mailboxesFiltered = [];
    if (this.filter.trim() == '') {
      this.mailboxesFiltered = this.mailboxes;
    } else {
      for(let i = 0; i < this.mailboxes.length; i++) {
        let mailbox = this.mailboxes[i];
        if (mailbox.username.toLowerCase().indexOf(this.filter.toLowerCase().trim()) != -1 || mailbox.properties.displayname.toLowerCase().indexOf(this.filter.toLowerCase().trim()) != -1) {
          this.mailboxesFiltered.push(mailbox);
        }
      }
    }
  }

  calcPaging() {
    this.doFilter();
    this.pageId = 0;
    this.pageCount = 1;
    this.visiblePageId = 1;
    this.mailboxesPage = [];
    this.pageCount = Math.floor(this.mailboxesFiltered.length / this.pageSize);
    if (this.mailboxesFiltered.length / this.pageSize - this.pageCount > 0){
      this.pageCount++;
    }

    this.setPage(0);
  }

  setPage(pageId) {
    if (pageId < 0){
      pageId = 0;
    }

    if (pageId > this.pageCount-1) {
      this.pageId = this.pageCount - 1;
    }

    this.pageId = pageId;
    this.visiblePageId = this.pageId + 1;
    this.mailboxesPage = [];

    for(let i = pageId * this.pageSize; i < this.mailboxesFiltered.length; i++) {
      this.mailboxesPage.push(this.mailboxesFiltered[i]);
      if (this.mailboxesPage.length == this.pageSize) {
        break;
      }
    }

    return false;
  }

  ngOnInit(): void {
    this.apiManagerService.getMailDomains().then((data) => {
      this.domains = data;
      if (data.length > 0) {
        this.setDomain(data[0].domainName);
      }

      let dropDomains: Array<Select2OptionData> = [];
      for(let i = 0; i < data.length; i++) {
        dropDomains.push({
          id: data[i].domainName,
          text: data[i].domainName
        });
      }

      this.dropDomains = dropDomains;
    })
  }

  setDomain(domain) {
    this.pageId = 0;
    this.pageCount = 1;
    this.mailboxesFiltered = [];
    this.activeDomain = domain;
    this.mailboxes = [];
    this.apiManagerService.getDomainMailboxes(domain).then((data) => {
      this.mailboxes = data;
      this.calcPaging();
    });
  }

  editMailbox(listname: string) {
    this.router.navigateByUrl('/mail/mailboxes/' + listname)
  }

}
